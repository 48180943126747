import React, {useEffect, useState, useRef} from "react"
import {Animated, Easing, Text, TouchableOpacity, View} from "react-native"
import {useRoute} from "@react-navigation/native"
import Icon from "./RealnoteIcon/Icon"
import {strings} from "../i18n"
import RealnoteLogger from "../bridges/RealnoteLogger"
import AppStyles from "../styles/AppStyles"
import StyleConstants from "../styles/StyleConstants"
// import {NavigationActions, StackActions} from "react-navigation";
import Realnote from "../bridges/RealnoteNative"
import Global from "../Global"
import AppColors from "../styles/AppColors"
import initThree from "../initThree"
import initWeb1 from "../initWeb1"
import ArMode from "../ArMode"
const log = new RealnoteLogger("AnimatedLiveButton")

export default function AnimatedLiveButton(props) {
  const {navigation} = props
  const route = useRoute()
  const duration = 200
  const inLiveScreen = route.name === "Live"
  const showEdit = false
  const [animationRunning, setAnimationRunning] = useState(false)
  const [noteGuid, setNoteGuid] = useState("")
  const [isSculpture, setIsSculpture] = useState(false)
  const [oldIcon, setOldIcon] = useState(inLiveScreen ? "NewBox" : "PlusNew")
  const [newIcon, setNewIcon] = useState(inLiveScreen ? "PlusNew" : "NewBox")
  const downScaleAnimation = useRef(new Animated.Value(1)).current
  const upScaleAnimation = useRef(new Animated.Value(0)).current

  // let oldIcon = inLiveScreen ? "NewBox" : "PlusNew"
  // let newIcon = inLiveScreen ? "PlusNew" : "NewBox"

  useEffect(() => {
    // Realnote.handleClickNoNote = onNoteClicked()
    // Realnote.handleClickNote = this.noteClickHandler
    if (inLiveScreen) {
      Realnote.handleFocus = onNoteFocused
      Realnote.handleNoFocus = onNoNoteFocused
      console.log("set noteFocus listeners")
    }

    if (!animationRunning) {
      runAnimation()
    }
    return () => {
      // Realnote.handleClickNote = null
      // Realnote.handleClickNoNote = null
      if (inLiveScreen) {
        Realnote.handleFocus = null
        Realnote.handleNoFocus = null
        console.log("cleaned noteFocus listeners")
      }
    }
  }, [])

  function onNoteFocused(data) {
    // log.v("onNoteFocused " + JSON.stringify(data))
    if (data.isEditable) {
      setNoteGuid(data.sceneGuid)
      setIsSculpture(data.isSculpture)
      setOldIcon("PlusNew")
      setNewIcon("EditPencil")
    }
  }

  function onNoNoteFocused() {
    log.v("no note focused")
    setNoteGuid("")
    setIsSculpture(false)
    setNewIcon("PlusNew")
    setOldIcon("EditPencil")
  }

  async function runAnimation() {
    try {
      log.v("runAnimation with icons " + newIcon + oldIcon)
      // if (this.props.challengeMode) {
      //   log.v("no animation in challengeMode")
      //   const result = true
      //   this.setState({challengeMode: result})
      //   return
      // }
      if (animationRunning) {
        log.v("animation running")
        return
      }
      setAnimationRunning(true)
      Animated.sequence([
        Animated.timing(downScaleAnimation, {
          toValue: 0,
          duration: duration,
          easing: Easing.ease,
          useNativeDriver: true,
        }),
        Animated.timing(upScaleAnimation, {
          toValue: 1,
          duration: duration * 1.3,
          easing: Easing.ease,
          useNativeDriver: true,
        }),
      ]).start(({finished}) => {
        if (!finished) {
          log.d("runAnimation animation was canceled before completion")
          upScaleAnimation.current = new Animated.Value(1)
        } else {
          log.d("runAnimation finished")
        }
      })
      setTimeout(() => {
        log.v("animation finished")
        setAnimationRunning(false)
      }, duration * 2.3)
    } catch (e) {
      log.e("runAnimation error: " + e)
    }
  }
  function renderIcon(iconName) {
    try {
      let viewSize = 50
      let bottom = -15
      let iconSize = StyleConstants.menuButtonSize
      let text = strings("MenuButton.ar")
      if (iconName === "PlusNew") {
        viewSize = 150
        bottom = 0
        iconSize = StyleConstants.menuButtonSize * 2
        text = strings("MenuButton.animated.ar")
      }
      if (iconName === "EditPencil") {
        viewSize = 150
        bottom = 0
        iconSize = StyleConstants.menuButtonSize * 2
        text = strings("editSculpture")
      }
      return (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            width: viewSize,
            bottom: bottom,
          }}>
          <Icon
            name={iconName}
            fill={AppColors.white}
            stroke={AppColors.white}
            height={iconSize}
            width={iconSize}
          />
          <Text
            style={[
              AppStyles.boldButtonText,
              {textAlign: "center", color: AppColors.white},
            ]}>
            {text}
          </Text>
        </View>
      )
    } catch (e) {
      log.e("renderRegularIcon error: " + e)
    }
  }

  // navigate to one of the main screens and replace stack with new route
  function onPress() {
    let routeName = inLiveScreen ? "NoteEditorModelSelection" : "Live"
    if (noteGuid !== "") {
      Realnote.sendFirebaseClick("menu_Edit")
      const note = {guid: noteGuid}
      if (isSculpture) {
        navigation.push("SculptureEditor", {
          add: false,
          edit: true,
          noteData: {sceneGuid: noteGuid},
        })
      } else {
        navigation.navigate("NoteEditor", {
          note: note,
          mode: "editExisting",
        })
      }
      return
    }
    if (!inLiveScreen) {
      goToLiveScreen()
    }

    Realnote.sendFirebaseClick("menu_Live")
    navigation.navigate({
      routeName: routeName,
    })
  }

  async function goToLiveScreen() {
    startWebAppDirectly().then(async () => {
      navigation.push("Live")
    })
  }

  function startWebAppDirectly() {
    return new Promise((resolve, reject) => {
      if (window.serviceWorkersStarted) {
        resolve(true)
      } else {
        initThree().then(() => {
          ArMode.startAr()
          resolve(true)
          window.startupServiceWorkers().then(async () => {
            // setup environment
            window.serviceWorkersStarted = true
            initWeb1()
          })
        })
      }
    })
  }

  const yCoordinateInitial = downScaleAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -15],
  })

  const yCoordinateFinal = upScaleAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -15],
  })

  return (
    <TouchableOpacity
      style={[
        {
          width: StyleConstants.menuBarHeight,
          alignItems: "center",
          justifyContent: "center",
          height: StyleConstants.menuBarHeight,
          pointerEvents: "auto",
        },
      ]}
      onPress={onPress}>
      <Animated.View
        style={{
          opacity: downScaleAnimation,
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          transform: [
            {scale: downScaleAnimation},
            {
              translateY: yCoordinateInitial,
            },
          ],
        }}>
        {renderIcon(oldIcon)}
      </Animated.View>
      <Animated.View
        style={{
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          opacity: upScaleAnimation,
          transform: [
            {scale: upScaleAnimation},
            {translateY: yCoordinateFinal},
          ],
        }}>
        {renderIcon(newIcon)}
      </Animated.View>
    </TouchableOpacity>
  )
}

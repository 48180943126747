import React, {PureComponent} from "react"
import {View, Text} from "react-native"
import {connect} from "react-redux"
import StyleConstants from "../../styles/StyleConstants"
import RealnoteLogger from "../../bridges/RealnoteLogger"
import AppColors from "../../styles/AppColors"
import CircularIconButton from "./CircularIconButton"
import MenuButton, {menuButtonType} from "./MenuButton"
// import LinearGradient from "react-native-linear-gradient";
import LinearGradient from "../../components/LinearGradient"
import realnote from "../../bridges/RealnoteNative"
import ContextMenu from "../../components/ContextMenu/ContextMenu"
import AnimatedLiveButton from "../../components/AnimatedLiveButton"

const log = new RealnoteLogger("RealnoteScreen")

export default class RealnoteScreen extends PureComponent {
  constructor(props) {
    super(props)
    try {
      this.state = {
        loading: true,
        _ScreenDim: null,
        _paddingTop: 0,
        _paddingLeft: 0,
        _paddingBottom: 0,
        _paddingRight: 0,
      }
      this.arButton = React.createRef()
      this.contextMenu = React.createRef()
      if (this.props.backgroundFilled) {
        this.colors = ["#FFFFFF", "#FFFFFF"]
        this.locations = [0.5, 1]
      } else {
        this.colors = ["#0000", AppColors.black]
        this.locations = [0, 1]
      }
    } catch (e) {
      log.e("constructor error: " + e)
    }
  }

  async loadSettings() {
    try {
      const dim = await realnote.getDims()
      //log.d("Screen Data: " + JSON.stringify(dim))
      this.setState({
        _paddingTop: Number(dim.reservedTop),
        _paddingLeft: Number(dim.reservedLeft),
        _paddingBottom: Number(dim.reservedBottom),
        _paddingRight: Number(dim.reservedRight),
        _ScreemDim: dim,
      })
    } catch (ex) {
      log.error(ex)
    }
  }

  componentDidMount() {
    try {
      log.d("jj1 didMount")
      this.loadSettings()
      this.blurListener = this.props.navigation.addListener(
        "willBlur",
        this.willBlur,
      )
    } catch (e) {
      log.e("componentDidMount error: " + e)
    }
  }

  componentWillUnmount() {
    try {
      if (this.blurListener) {
        this.blurListener.remove()
      }
    } catch (e) {
      log.e("componentWillUnmount error: " + e)
    }
  }

  willBlur = async () => {
    try {
      if (this.arButton) {
        log.v("willBlur: reinitialize will be called")
        if (this.arButton && this.arButton.current) {
          await this.arButton.current.reinitialize()
        }
      } else {
        log.e("willBlur: arButton not found")
      }
    } catch (e) {
      log.e("didFocus error: " + e)
    }
  }

  render() {
    try {
      let paddingTop = 0
      if (this.props.avoidBackButtonOverlap) {
        paddingTop =
          StyleConstants.realnoteScreenButtonSize +
          StyleConstants.buttonMarginToEdge
      } else if (!this.props.fullscreen) {
        paddingTop = this.state._paddingTop
      }

      if (this.props.buttonsHidden) {
        return (
          <View
            nativeID={this.props.nativeID || "RealnoteBaseScreen"}
            style={[
              {
                width: "100%",
                height: "100%",

                backgroundColor: this.props.backgroundFilled
                  ? AppColors.white
                  : null,
                paddingTop: paddingTop,
              },
              this.props.style,
            ]}>
            {this.props.children}
          </View>
        )
      }

      const iconButtonSize = StyleConstants.realnoteScreenButtonSize
      const buttonColor = !this.props.darkUpperButtons ? AppColors.white : null

      // const shadow =
      //   this.props.upperButtonsShadow == false ||
      //   this.props.upperButtonsShadow == true
      //     ? this.props.upperButtonsShadow
      //     : !this.props.noCircle
      //     ? false
      //       : !this.props.backgroundFilled || this.props.shadow

      const shadow = true

      return (
        <View
          nativeID={this.props.nativeID || "RealnoteBaseScreen"}
          style={[
            {
              width: "100%",
              height: "100%",
              paddingTop: paddingTop,
              paddingLeft: this.props.fullscreen ? 0 : this.state._paddingLeft,
              paddingRight: this.props.fullscreen
                ? 0
                : this.state._paddingRight,
              paddingBottom: this.props.fullscreen
                ? 0
                : this.state._paddingBottom,
              backgroundColor: this.props.backgroundFilled
                ? AppColors.white
                : null,
            },
            this.props.style,
          ]}>
          {this.props.children}

          {(this.props.onBack || this.props.backButton) && (
            <CircularIconButton
              buttonColor={buttonColor}
              noCircle={this.props.noCircle}
              size={iconButtonSize}
              extraBigTouchSurface
              position={"topLeft"}
              name={this.props.closeAsBack ? "Close" : "Back"}
              shadow={shadow}
              onPress={() => {
                log.v("Back pressed")
                if (this.props.onBack) {
                  this.props.onBack()
                } else {
                  if (this.props.navigation) {
                    this.props.navigation.goBack()
                  }
                }
              }}
            />
          )}

          {this.props.profileButton &&
            !(this.props.onBack || this.props.backButton) && (
              <CircularIconButton
                buttonColor={buttonColor}
                noCircle={this.props.noCircle}
                size={iconButtonSize}
                position={"topLeft"}
                name={"Profile"}
                extraBigTouchSurface
                indicator={this.props.indicator}
                shadow={shadow}
                onPress={async () => {
                  if (this.props.navigation) {
                    realnote.sendFirebaseClick("ar_profile")
                    this.props.navigation.navigate("Profile", {
                      reverseAnimateAr:
                        this.props.activeButton === menuButtonType.ar,
                    })
                  }
                }}
              />
            )}

          {this.props.settingsButton && this.props.isOwnProfile && (
            <CircularIconButton
              buttonColor={buttonColor}
              noCircle={this.props.noCircle}
              size={iconButtonSize}
              position={"topRight"}
              name={"Settings"}
              extraBigTouchSurface
              shadow={shadow}
              onPress={() => {
                if (this.props.navigation) {
                  this.props.navigation.navigate("About")
                }
              }}
            />
          )}

          {this.props.menu && (
            <LinearGradient
              nativeID={"RealnoteButton"}
              colors={this.colors}
              locations={this.locations}
              style={[
                {
                  width: "100%",
                  height:
                    StyleConstants.menuBarHeight + this.state._paddingBottom,
                  position: "absolute",
                  bottom: 0,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderTopWidth: !this.props.backgroundFilled ? null : 2,
                  borderColor: StyleConstants.dividingBorderColor,
                },
                this.props.style,
              ]}>
              {/*
               *******************Expore // Challange
               */}
              <MenuButton
                nativeID={"RealnoteButton"}
                shadow={!this.props.backgroundFilled}
                navigation={this.props.navigation}
                reverseAnimateWhenGoingToAr={
                  this.props.activeButton === menuButtonType.ar
                }
                type={menuButtonType.home}
                active={this.props.activeButton === menuButtonType.home}
                disabled={this.props.activeButton === menuButtonType.home}
                iconColor={
                  !this.props.backgroundFilled ? AppColors.white : null
                }
              />

              {/*
               *******************AR / Add
               */}

              <AnimatedLiveButton navigation={this.props.navigation} />

              <MenuButton
                nativeID={"RealnoteButton"}
                shadow={!this.props.backgroundFilled}
                navigation={this.props.navigation}
                reverseAnimateWhenGoingToAr={
                  this.props.activeButton === menuButtonType.ar
                }
                type={menuButtonType.map}
                active={this.props.activeButton === menuButtonType.map}
                disabled={this.props.activeButton === menuButtonType.map}
                iconColor={
                  !this.props.backgroundFilled ? AppColors.white : null
                }
              />
            </LinearGradient>
          )}

          {this.props.showContextMenu && this.props.contextMenuChildren && (
            <ContextMenu
              ref={this.contextMenu}
              navigation={this.props.navigation}>
              {this.props.contextMenuChildren}
            </ContextMenu>
          )}
        </View>
      )
    } catch (e) {
      log.e("render error: " + e)
      return null
    }
  }
}




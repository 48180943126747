import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {
  challengeData_fetchChallengeData
} from "../actionTypes";
import {HttpBridge} from '../../http/HttpBridge';
import socket from '../../http/socket';
import Global from "../../Global"

const http = new HttpBridge()

const initialState = {
  challengeId: null,
  challengeURL: null,
  title: "",
  shortTitle: "",
  backgroundColor: "",
  dist: null,
  isActive: false,
  challengeData: [],
  usersCanPlaceNotes: false,
  status: "uninitialized",
  imageUrl: null,
  participants: [],
  description: "",
}

export const fetchChallengeData = createAsyncThunk(
  challengeData_fetchChallengeData,
  async (args, thunkAPI) => {
    return http.getChallengeData()
  },
)

export const challengeModeSlice = createSlice({
  name: "challengeMode",
  initialState,
  reducers: {
    enterChallenge: (state, action) => {
      const {
        challengeId,
        challengeURL,
        challengeTitle,
        backgroundColor,
        shortTitle,
        usersCanPlaceNotes,
        distance,
        imageUrl,
        participants,
        description,
      } = action.payload
      if(state.challengeId){
        socket.leaveChat(state.challengeId)
      }
      socket.joinChat(challengeId)
      state.challengeId = challengeId
      state.challengeURL = challengeURL
      state.title = challengeTitle
      state.backgroundColor = backgroundColor
      state.shortTitle = shortTitle
      state.usersCanPlaceNotes = usersCanPlaceNotes
      state.dist = distance
      state.isActive = true
      state.imageUrl = imageUrl
      state.participants = participants
      state.description = description

      Global.challengeId = challengeId
    },
    leaveChallenge: state => {
      socket.leaveChat(state.challengeId)
      state.challengeId = null
      state.challengeURL = null
      state.title = ""
      state.backgroundColor = ""
      state.shortTitle = ""
      state.dist = null
      state.isActive = false
      state.usersCanPlaceNotes = false
      state.imageUrl = null
      state.participants = []
      state.description = ""

      Global.challengeId = null
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchChallengeData.pending, (state, action) => {
      state.status = "pending"
    })
    builder.addCase(fetchChallengeData.fulfilled, (state, {payload}) => {
      state.status = "fulfilled"
      state.challengeData = payload
    })
    builder.addCase(fetchChallengeData.rejected, (state, action) => {
      state.status = "rejected"
    })
  },
})

export const selectChallengeModeStatus = state => state.challengeMode.status;
export const selectChallengeID = state => state.challengeMode.challengeId;
export const selectChallengeActive = state => state.challengeMode.isActive;
export const selectChallengeData = state => state.challengeMode.challengeData;
export const selectChallengeCanUsersPlaceNotes = state => state.challengeMode.usersCanPlaceNotes;

export const {enterChallenge, leaveChallenge} = challengeModeSlice.actions;

export default challengeModeSlice.reducer;

import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import Global from "../../Global"
import Account, {serverResponse} from "../../dataProvider/Account"
import realnote from "../../bridges/RealnoteNative"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {strings} from "../../i18n"
import _realnote from "../../bridges/RealnoteNative"
import {HttpBridge} from "../../http/HttpBridge"
import Realnote from "../../bridges/RealnoteNative"
import {captureException} from "../../components/Sentry";

const http = new HttpBridge()

const handleError = (err = "No error provided!") => {
  captureException(err.message)
  return {
    error: {
      status: 500,
      statusText: "Internal Server Error",
      data: err,
    },
  }
}

const prepareHeaderHandler = async (headers, {getState}) => {
  const token = await AsyncStorage.getItem("token")
  headers.set("authorization", `Bearer ${token}`)
  return headers
}

const getScrollingNoteHandler = async ({skip, type, userId, limit}) => {
  const {latitude, longitude} = await _realnote.getLastKnownLocation()
  const body = {
    ownUserId: Account.userId,
    userId,
    skip,
    type,
    latitude,
    longitude,
    limit,
    radius: 999999,
    appVersion: "100",
  }
  const response = await http.fetchJSONRejectable(
      "note/getScrollingNotesData",
      body,
  )
  if (!response) {
    return handleError()
  }
  return {data: response}
}

const createComment = async args => {
  const {contentGuid, tx, userId, commentGuid} = args

  const response = await realnote.createComment(
      contentGuid,
      tx,
      userId,
      commentGuid,
  )

  if (!response) {
    return handleError()
  }

  if (response.comment === "@SPAM") {
    realnote.showToastOld(strings("Note.spamComment"), true)
    return {
      error: {
        status: 500,
        statusText: "Internal Server Error",
        data: "Coin landed on it's edge!",
      },
    }
  }
  return {data: response}
}

const deleteComment = async args => {
  const {contentGuid, userId, guid} = args
  const response = await http.deleteComment(contentGuid, guid, userId)

  if (response.status === 200) {
    return {data: response}
  }

  return handleError()
}

const upvoteNote = async args => {
  const {contentGuid, userId, sceneGuid} = args

  const response = await http.upvoteContent(contentGuid, userId, sceneGuid)

  if (!response) {
    return handleError()
  }
  return {data: response}
}

const downvoteNote = async args => {
  const {contentGuid, userId, sceneGuid} = args

  const response = await http.deleteUpvote(contentGuid, userId, sceneGuid)

  if (!response) {
    return handleError()
  }
  return {data: response}
}

const getNoteForMap = async args => {
  const {userId, guid} = args
  try {
    const note = await Realnote.getNoteDataForGuid(userId, guid)
    return {data: note}
  } catch (err) {
    return handleError(err)
  }
}

const deleteNotes = async args => {
  let promises = []
  const {notes} = args
  notes.forEach(note =>
      promises.push(Realnote.deleteScene(note.sceneGuid || note.guid)),
  )

  const result = await Promise.all(promises)

  if (Array.isArray(result)) {
    return {data: result}
  }
  return handleError()
}

const getOwnNotes = async args => {
  const {userId, numOfItemsToGet} = args
  try {
    const notes = await Realnote.getUserScenes(userId, 0, numOfItemsToGet)
    return {data: notes}
  } catch (err) {
    return handleError(err)
  }
}

const getComments = async args => {
  const {contentGuid, len, limit} = args

  const response = await http.getComments(contentGuid, len, limit)

  if (!response) {
    return handleError()
  }
  return {data: response}
}

const followUser = async args => {
  const {userId} = args
  const response = await http.followUser(Account.userId, userId)

  if (!response) {
    return handleError()
  }
  return {data: response}
}

const unfollowUser = async args => {
  const {userId} = args
  const response = await http.unfollowUser(Account.userId, userId)

  if (!response) {
    return handleError()
  }
  return {data: response}
}

const blockUser = async args => {
  const {userId} = args

  const response = await Realnote.blockUser(userId)

  if (response.message === serverResponse.ok) {
    return {data: response}
  } else {
    return handleError()
  }
}

const getIsUserFollowing = async args => {
  const {userId} = args

  const response = await http.getIsUserFollowing(Account.userId, userId)

  if (!response) {
    return handleError()
  }
  return {data: response.follows}
}

const getHotNotes = async args => {
  const {limit, skip} = args
  const response = await http.getListOfContents("Hot", null, skip, limit)

  if (!response) {
    return handleError()
  }
  return {data: response}
}

const getSurroundingNotes = async args => {
  const {limit, skip} = args
  const response = await http.getListOfContents(
      "Surroundings",
      null,
      skip,
      limit,
  )

  if (!response) {
    return handleError()
  }
  return {data: response}
}

const getRewardPoints = () => {
  return {
    url: `note/pointsOfUser/` + Account.userId,
    method: 'GET',
  }
}

export const scrollingNoteApi = createApi({
  reducerPath: "scrollingNote",
  baseQuery: fetchBaseQuery({
    baseUrl: Global.baseUrl,
    prepareHeaders: prepareHeaderHandler,
  }),
  tagTypes: [
    "Comment",
    "vote",
    "NoteForMap",
    "Note",
    "OwnNote",
    "Commentary",
    "IsUserFollowing",
    "RewardPoints"
  ],
  endpoints: builder => ({
    getScrollingNote: builder.query({
      queryFn: getScrollingNoteHandler,
      providesTags: ["Comment", "vote", "Note"],
    }),
    getNoteForMap: builder.query({
      queryFn: getNoteForMap,
      providesTags: ["NoteForMap"],
    }),
    getOwnNotes: builder.query({
      queryFn: getOwnNotes,
      providesTags: ["OwnNote"],
    }),
    getComments: builder.query({
      queryFn: getComments,
      providesTags: ["Commentary"],
      keepUnusedDataFor: 0,
    }),
    getRewardPoints: builder.query(
        {
          query: getRewardPoints,
          providesTags: ['RewardPoints'],
        }),
    getIsUserFollowing: builder.query({
      queryFn: getIsUserFollowing,
      providesTags: ["IsUserFollowing"],
    }),
    getHotNotes: builder.query({queryFn: getHotNotes, providesTags: ["vote"]}),
    getSurroundingNotes: builder.query({
      queryFn: getSurroundingNotes,
      providesTags: ["vote"],
    }),
    createComment: builder.mutation({
      queryFn: createComment,
      invalidatesTags: ["Comment", "NoteForMap", "Commentary"],
    }),
    deleteComment: builder.mutation({
      queryFn: deleteComment,
      invalidatesTags: ["Comment", "NoteForMap", "Commentary"],
    }),
    upvoteNote: builder.mutation({
      queryFn: upvoteNote,
      invalidatesTags: ["vote", "NoteForMap"],
    }),
    downvoteNote: builder.mutation({
      queryFn: downvoteNote,
      invalidatesTags: ["vote", "NoteForMap"],
    }),
    deleteNotes: builder.mutation({
      queryFn: deleteNotes,
      invalidatesTags: ["Note", "OwnNote"],
    }),
    followUser: builder.mutation({
      queryFn: followUser,
      invalidatesTags: ["Note", "IsUserFollowing"],
    }),
    unfollowUser: builder.mutation({
      queryFn: unfollowUser,
      invalidatesTags: ["Note", "IsUserFollowing"],
    }),
    blockUser: builder.mutation({
      queryFn: blockUser,
      invalidatesTags: ["Note"],
    }),
  }),
})

export const {
  useGetScrollingNoteQuery,
  useUpdateScrollingNoteQuery,
  useGetNoteForMapQuery,
  useGetOwnNotesQuery,
  useGetCommentsQuery,
  useGetIsUserFollowingQuery,
  useGetHotNotesQuery,
  useGetSurroundingNotesQuery,
  useGetRewardPointsQuery,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useDownvoteNoteMutation,
  useDeleteNotesMutation,
  useUpvoteNoteMutation,
  useFollowUserMutation,
  useUnfollowUserMutation,
  useBlockUserMutation,
} = scrollingNoteApi

import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import Account from "../dataProvider/Account"
import Global from "../Global"
import {HttpBridge} from "../http/HttpBridge"

const http = new HttpBridge()

const handleError = (err = "No error provided!") => ({
  error: {
    status: 500,
    statusText: "Internal Server Error",
    data: err,
  },
})

const getPlaygroundsHandler = (skip, itemCount) => {
  const s = skip || 0
  const n = itemCount || 10
  return {
    url: "note/getPlaygrounds",
    method: "POST",
    body: {
      userId: Account.userId,
      skip: s.toString(),
      limit: n.toString(),
    },
  }
}

const setPlaygroundPlaced = async args => {
  const {userId, sceneGuid} = args
  const response = http.setPlaygroundPlaced(userId, sceneGuid)

  if (!response) {
    return handleError()
  }
  return {data: response}
}

export const playgroundApi = createApi({
  reducerPath: "playgrounds",
  baseQuery: fetchBaseQuery({baseUrl: Global.baseUrl}),
  tagTypes: ["Playground"],
  endpoints: builder => ({
    getPlaygrounds: builder.query({
      query: getPlaygroundsHandler,
      providesTags: ["Playground"],
    }),
    setPlaygroundPlaced: builder.mutation({
      queryFn: setPlaygroundPlaced,
      invalidatesTags: ["Playground"],
    }),
  }),
})

export const {useGetPlaygroundsQuery, useSetPlaygroundPlacedMutation} =
  playgroundApi

/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */

import {ColorSelection} from "./screens/NoteEditor/ColorSelection"

/* eslint-disable no-restricted-globals */
var cssRenderer = null

function laster() {
  cssRenderer = new CSS3DRenderer()
  cssRenderer.setSize(window.innerWidth, window.innerHeight)

  window.cssRenderer = cssRenderer
  cssRenderer.domElement.style.position = "absolute"
  cssRenderer.domElement.style.top = 0
  cssRenderer.domElement.style.pointerEvents = "none"
  ///// mwe 18.2.22 mindestens for testing  auskommentiert  cssRenderer.domElement.style.zIndex = -1
  cssRenderer.domElement.id = "cssRenderer"
  container.appendChild(cssRenderer.domElement)

  window.CSS3DRenderer = CSS3DRenderer
  window.CSS3DObject = CSS3DObject

  // window.THREE = THREE

  window.OrbitControls = OrbitControls

  window.MeshLine = exports.MeshLine
  window.MeshLineMaterial = exports.MeshLineMaterial
  window.MeshLineRaycast = exports.MeshLineRaycast
}

export default async function initThree() {
  return new Promise((resolve, reject) => {
    // check if THREE is defined
    // var x = THREE
    // var y = fabric}

    if (window.scene) {
      resolve(true)
    }

    console.log("indexjs: HostSceneRender ", window.HostSceneRenderer)

    var cameraPose = {}

    var container, controls
    var renderer
    var scene
    var camera
    var t1 = null
    var hostWrapper = null
    var isEmbedded = false

    var appIsStarted = false

    function onAppStarted(web1) {
      appIsStarted = true

      if (!document.body.style.height) {
        document.body.style.width = window.innerWidth
        document.body.style.height = window.innerHeight
      }

      // fetchScreenDimensions() ist jetzt in HostWrapper.kt

      if (window.HostGeneral) {
        isEmbedded = true
      }
      setTimeout(function () {
        //
        // this code enables pointer events for all TouchableOpacity
        // this code build on the fact that TouchableOpacity set the css property
        //    touchAction: 'manipulation'
        //
        var x = $("*").filter(function () {
          return (
            $(this).css("touchAction").toLowerCase().indexOf("manipulation") >
            -1
          )
        })
        x.css({"pointer-events": "auto"})
        // end TouchableOpacity xx

        $("div[id^=item-][id$=-top]").hide()

        var yy = $("[id^=RealnoteButton]")
        yy.css({"pointer-events": "auto"})

        $(".enabletouch").css({"pointer-events": "auto"})
        $("#RealnoteButton").css({"pointer-events": "auto"})
        $("button").css({"pointer-events": "auto"})
        $("text").css({"pointer-events": "auto"})
      }, 2000)
    }
    window.onAppStarted = onAppStarted

    init()

    resolve(true)

    function init() {
      if ("caches" in window) {
        // alert("caches found")
      }

      if (window.renderer) {
        console.log("window.renderer != null, threejs is initialized")
        return
      }

      container = document.getElementById("container")

      camera = new THREE.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        0.25,
        1000,
      )
      //camera = new THREE.PerspectiveCamera(45, 1, 0.25, 1000);

      camera.position.set(0, 0, 0)
      camera.lookAt(0, -0.5, -2)
      camera.up.set(0, 1, 0)

      camera.name = "mainCamera"

      window.camera = camera

      scene = new THREE.Scene()
      // scene.background = new THREE.Color(0xff0000);

      window.scene = scene

      const skyColor = 0xb1e1ff // light blue
      const groundColor = 0xb97a20 // brownish orange
      const intensity = 0.5
      var light = new THREE.HemisphereLight(skyColor, groundColor, intensity)
      scene.add(light)

      // var lightH2 = new THREE.HemisphereLight(0xFF0000, 0, 0.5);
      // scene.add(lightH2)

      // light
      var spotLight = new THREE.SpotLight(0x606060)
      spotLight.name = "spotLight"
      //spotLight.position.set(0, 20, 0);
      spotLight.position.set(1, 2, 50)
      spotLight.castShadow = false
      scene.add(spotLight)

      window.spotLight = spotLight

      var ambientLight = new THREE.AmbientLight(0x404040) // soft white light
      scene.add(ambientLight)

      var canvas = document.createElement("canvas")
      var context = canvas.getContext("webgl2", {
        preserveDrawingBuffer: true,
      })

      if (context) {
        console.log("THREE.WebGLRenderer use webgl2 !!!" + context)
      } else {
        context = canvas.getContext("webgl")
      }

      //Create renderer
      renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
        canvas: canvas,
        context: context,
      })

      window.renderer = renderer

      renderer.setPixelRatio(window.devicePixelRatio)
      renderer.setSize(window.innerWidth, window.innerHeight)
      renderer.toneMapping = THREE.ACESFilmicToneMapping
      renderer.toneMappingExposure = 0.8
      renderer.outputEncoding = THREE.sRGBEncoding
      renderer.setClearColor(0, 0)
      renderer.alpha = true
      //			renderer.domElement.style.pointerEvents = "none";

      window.render = render

      container.appendChild(renderer.domElement)

      // Mipmapped Radiance Environment Map (currently not used)
      //
      // var pmremGenerator = new THREE.PMREMGenerator(renderer);
      // pmremGenerator.compileEquirectangularShader();

      window.addEventListener("resize", onWindowResize, false)

      if (!isEmbedded) {
        document.getElementById("debugView").style.visibility = "hidden"
        document.getElementById("debugView").style.visibility = "hidden"
      }
    }

    function startRendering() {
      if (!isEmbedded) {
        var doRender = function () {
          try {
            if (!noteEditorActive) {
              var p = camera.position
              spotLight.position.set(p.x, p.y + 3, p.z)

              // WebGL.flush()  // gibt es garnicht
              renderer.render(scene, camera)
              cssRenderer.render(scene, camera)
              requestAnimationFrame(doRender)
            }
          } catch (ex) {
            console.error(ex)
          }
        }
        requestAnimationFrame(doRender)

        /////////////////////////////////////////////////

        setInterval(function () {
          try {
            if (renderer) {
              if (hostWrapper) {
                hostWrapper.animate()
              }
            }
          } catch (ex) {
            console.error(ex)
          }
        }, 30)
      }
    }

    window.isLandingPage = false
    window.activateLandingpageCanvasStyle = function (active) {
      window.isLandingPage = active

      if (isLandingPage) {
        var w = 300
        var h = 300

        var marginTop = (window.innerHeight - h) / 2

        renderer.domElement.style.borderRadius = "20px"

        renderer.domElement.style.width = w + "px"
        renderer.domElement.style.height = h + "px"

        container.style.width = w + "px"
        container.style.height = h + "px"

        container.style.borderRadius = "20px"
        container.style.margin = "auto"
        container.style.marginTop = marginTop + "px"
        container.style.boxShadow =
          "0 19px 38px rgba(0,0,0,0.20), 0 15px 12px rgba(0,0,0,0.12)"

        container.style.background = "linear-gradient(#f0f0ff, #d0d0d0 100%)" // "linear-gradient(#ffffff #a0a0a0)"
        //        "linear-gradient(#f0f0ff, rgba(2,194,162, 1.0) 100%)"
        document.body.style.background = "#02c2a2"
      } else {
        renderer.domElement.style.width = "100%"
        renderer.domElement.style.height = "100%"
        container.style.width = "100%"
        container.style.height = "100%"

        renderer.domElement.style.borderRadius = null
        container.style.borderRadius = null
        container.style.margin = null
        container.style.marginTop = null
        container.style.boxShadow = null
        container.style.background =
          "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(2,194,162,0.5) 100%);"
      }

      onWindowResize()
    }

    function onWindowResize() {
      document.body.style.height = window.innerHeight
      document.body.style.width = window.innerWidth

      var w = window.innerWidth
      var h = window.innerHeight

      setTimeout(function () {
        if (window.isLandingPage) {
          w = 300
          h = 300
        }

        cssRenderer?.setSize(w, h, true)
        window.renderer.setSize(w, h, true)

        window.camera.aspect = w / h
        window.camera.updateProjectionMatrix()

        window.renderer.render(window.scene, window.camera)
      }, 10)

      // render();
    }

    //
    function render() {
      if (renderer) {
        if (window.HostSceneRenderer) {
          console.log("don't render in webview if HostSceneRenderer is present")
        } else {
          renderer.render(scene, camera)
        }
      }
    }
  })
}

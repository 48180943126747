import React, {PureComponent} from "react"
import RealnoteLogger from "../../bridges/RealnoteLogger"
import {TouchableOpacity} from "react-native"
import AppColors from "../../styles/AppColors"
import Icon from "../RealnoteIcon/Icon"
import AppStyles from "../../styles/AppStyles"
import StyleConstants from "../../styles/StyleConstants"

const log = new RealnoteLogger("ProfilePictureOverlay")

export default class ProfilePictureOverlay extends PureComponent {
  render() {
    try {
      if (this.props.hidden) {
        return null
      }
      return (
        <TouchableOpacity
          style={[
            AppStyles.center,
            {
              backgroundColor:
                this.props.backgroundColor || AppColors.veryTransparentWhite,
              borderRadius: this.props.imageSize / 2,
              width: this.props.imageSize,
              height: this.props.imageSize,
            },
            this.props.style,
          ]}
          disabled={!this.props.onPress}
          onPress={this.props.onPress}>
          <Icon
            name="PlusProfileImage"
            fill={this.props.iconColor || StyleConstants.buttonColor}
            stroke={this.props.iconColor || StyleConstants.buttonColor}
            style={{
              borderRadius: this.props.imageSize / 3,
              position: "relative",
              top: 2,
            }}
            height={this.props.imageSize / 1.5}
            width={this.props.imageSize / 1.5}
          />
        </TouchableOpacity>
      )
    } catch (e) {
      log.e("render error: " + e)
    }
  }
}

import React, {useMemo} from "react"
import {Text, TouchableOpacity, View, StyleSheet} from "react-native"
import Account from "../dataProvider/Account"
import AppStyles from "../styles/AppStyles"
import {strings} from "../i18n"
import ProfilePicture from "../components/ProfilePicture/ProfilePicture"
import Username from "../components/Profile/Username"
import {
  useFollowUserMutation,
  useUnfollowUserMutation,
} from "../store/reducers/scrollingNoteSlice"

export default function NoteHeader(props) {
  const {userId, note, navigation, noteData} = props
  const [followUser] = useFollowUserMutation()
  const [unfollowUser] = useUnfollowUserMutation()

  function follow() {
    followUser({userId})
  }

  function unfollow() {
    unfollowUser({userId})
  }

  const FollowComponent = useMemo(() => {
    if (Account.userId === userId) {
      return null
    }

    return (
      <TouchableOpacity onPress={note.follows ? unfollow : follow}>
        <Text style={[AppStyles.btnTextLight, {padding: 0, textAlign: "left"}]}>
          {strings(note.follows ? "NoteHeader.unfollow" : "NoteHeader.follow")}
        </Text>
      </TouchableOpacity>
    )
  }, [userId, note.follows])

  const name = useMemo(() => {
    return note.username.startsWith("@realnoteuser")
      ? userId !== Account.userId
        ? note.username.substr(1)
        : strings("Profile.usernamePlaceholder")
      : note.username
  }, [userId, note.username])

  return (
    <View style={styles.container}>
      <ProfilePicture
        navigation={navigation}
        profilePictureUrl={note.profilePictureUrl}
        imageSize={60}
        userId={userId}
      />
      <View style={{marginLeft: 15}}>
        <Username
          navigation={navigation}
          userId={userId}
          largeTextSize
          textStyle={{textAlign: "left"}}
          username={name}
        />
        {FollowComponent}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
    marginHorizontal: 15,
    flexDirection: "row",
    alignItems: "center",
  },
})

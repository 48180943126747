function forceTestModeViaUrl() {
  const s = window.location.href.toLocaleLowerCase()
  return s.indexOf("&test") >= 0 || s.indexOf("?test") >= 0
}

function forceProductionViaUrl() {
  const s = window.location.href.toLocaleLowerCase()
  return s.indexOf("&prod") >= 0 || s.indexOf("?prod") >= 0
}

function forceDebugViaUrl() {
  const s = window.location.href.toLocaleLowerCase().toLowerCase()
  return s.indexOf("&dev") >= 0 || s.indexOf("?dev") >= 0
}

let _realnoteReactGlobal = {
  baseUrl: "https://realnote-dev.azurewebsites.net/api/",
  token: "",
  welcomeNotesNeeded: false,
  debugMode: false,
  testMode: false,
  isReactNative: false,
  environment: "app.web", // "app.ios" // "app.android"  // "homepage.NoteEditor"
  location: [50.71358286319306, 7.074374627026155], // use RN HQ as default
  // location: [0, 0],
  city: "Bonn",

  isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
  isAndroid: /Android/.test(navigator.userAgent) && !window.MSStream,
  isMobileOrTablet: /(android|iphone|ipad|mobile)/i.test(navigator.userAgent),

  challengeId: null,
  isEmbedded: function () {
    if (window["HostGeneral"]) {
      return true
    } else {
      return false
    }
  },

  arActive: false,

  globalChallenge: {
    _id: "625ec76b2a8191837726bc13",
    global: true,
    article: [],
    public: true,
    challengeId: "00000000-0000-0000-0000-000000000000",
    title: "Global",
    shortTitle: "Global",
    backgroundColor: "#02c2a2",
    description: "Global",
    startDate: "2022-04-19T14:30:03.608Z",
    endDate: "1970-01-01T00:00:05.000Z",
    imageUrl:
      "https://realnote-dev.azurewebsites.net/api/public/previewImage/00000000-0000-0000-0000-000000000000_earth.png",
    website: "https://realnote.one",
    locDescription: "Global",
    config: {
      _id: "625ec76b2a8191837726bc12",
      usersCanPlaceNotes: true,
    },
    geoLocation: {
      coordinates: [50.713706, 7.074229],
      _id: "625ec76b2a8191837726bc15",
      type: "Point",
    },
    participants: [
      {
        _id: "625ed1a9f18e3c29f0e5243a",
        userId: "1149710997116101",
        dateEntered: "2022-04-19T15:13:45.113Z",
        profilePictureUrl:
          "https://realnote-dev.azurewebsites.net/api/public/previewImage/undefined",
      },
      {
        _id: "625ed27cf18e3c29f0e525be",
        userId: "36fe035c-c1c0-42b7-a709-4e2bfde995d9-rn",
        dateEntered: "2022-04-19T15:17:16.037Z",
        profilePictureUrl:
          "https://realnote-dev.azurewebsites.net/api/public/previewImage/undefined",
      },
    ],
    __v: 86,
    dist: {
      calculated: 6297182.813503326,
    },
  },

  sentryDsn:
    "https://7e1b6bd2c9c247b2ab1cdcf772e00d6c@o945359.ingest.sentry.io/6382414",
  normalizeDepth: 10,
  release: "2.3",
  dist: "1",
  tracesSampleRate: 1.0,
  enableDebugMode: function (isDebug) {
    console.log("enableDebugMode-a", isDebug)

    if (isDebug) {
      if (forceProductionViaUrl()) {
        isDebug = false
      }
    } else {
      if (forceDebugViaUrl()) {
        isDebug = true
      }
    }

    this.debugMode = isDebug

    console.log("enableDebugMode-b", isDebug)

    if (this.debugMode) {
      _realnoteReactGlobal.baseUrl =
        "https://realnote-dev.azurewebsites.net/api/"

      console.log("Realnote React is in debug mode")
    } else {
      _realnoteReactGlobal.baseUrl = "https://realnote.azurewebsites.net/api/"
      console.log("Realnote React is in release mode")
    }

    if (forceTestModeViaUrl()) {
      this.testMode = true
    }

    if (window) {
      window.realnoteReactGlobal = _realnoteReactGlobal
    }
  },

  gpsPermissionIsGranted: function () {
    console.log("gpsPermissionIsGranted called")

    return new Promise((resolve, reject) => {
      var storagePrefix = ""
      if (this.debugMode) {
        storagePrefix = "DEBUG-"
      } else {
        storagePrefix = "PRODUCTION-"
      }

      if (window.navigator && window.navigator.permissions) {
        window.navigator.permissions
          .query({name: "geolocation"})
          .then(permissionStatus => {
            var granted = permissionStatus.state == "granted"
            console.log("gpsPermissionIsGranted resolve" + granted)
            localStorage.setItem(storagePrefix + "gpsGranted", granted)
            resolve(granted)
          })
      } else {
        //
        // in Safari-IOS "window.navigator.permissions" is not existing
        // so we act as if the permission is already granted,
        // so the permission screen pops up on IOS without a "warning" to the user
        //
        const gpsGranted = localStorage.getItem(storagePrefix + "gpsGranted")
        if (gpsGranted === "true") {
          console.log("gpsPermissionIsGranted resolve true")
          resolve(true)
          return
        }
        resolve(true)
      }
    })
  },

  gpsPermissionIsDenied: function () {
    console.log("gpsPermissionIsDenied called")

    return new Promise(resolve => {
      if (window.navigator && window.navigator.permissions) {
        window.navigator.permissions
          .query({name: "geolocation"})
          .then(permissionStatus => {
            var denied = permissionStatus.state == "denied"
            console.log("gpsPermissionIsGranted denied" + denied)
            resolve(denied)
          })
      } else {
        //
        // in Safari-IOS "window.navigator.permissions" is not existing
        resolve(false)
      }
    })
  },
}

export default _realnoteReactGlobal

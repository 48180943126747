import React, {PureComponent} from "react"
import {
  Animated,
  Dimensions,
  Easing,
  Text,
  TouchableOpacity,
  View,
} from "react-native"
import Icon from "../../components/RealnoteIcon/Icon"
import {strings} from "../../i18n"
import RealnoteLogger from "../../bridges/RealnoteLogger"
import AppStyles from "../../styles/AppStyles"
import StyleConstants from "../../styles/StyleConstants"
// import {NavigationActions, StackActions} from "react-navigation";
import Realnote from "../../bridges/RealnoteNative"

const log = new RealnoteLogger("MenuButton")

export const menuButtonType = {
  // explore: "explore",
  ar: "ar",
  map: "map",
  home: "home",
  // challenge: "challenge",
}

const iconName = {
  // explore: "Menu",
  ar: "NewBox",
  map: "Map",
  home: "Menu",
  // challenge: "Friends",
}

const routeName = {
  // explore: "Explore",
  ar: "Live",
  map: "Map",
  home: "Home",
  // challenge: "Challenge",
}

const bigIconName = {
  ar: "PlusNew",
}

export default class MenuButton extends PureComponent {
  constructor(props) {
    super(props)
    try {
      this.state = {
        transform:
          (this.props.active && this.props.type === menuButtonType.ar) ||
          this.props.reverseAnimate ||
          this.props.animateArFromNoteFocus,
        downScaleAnimation: new Animated.Value(1),
        upScaleAnimation: new Animated.Value(0),
        animationFinished: false,
      }
      this.duration = 200
    } catch (e) {
      log.e("constructor error: " + e)
    }
  }

  async componentDidMount() {
    if (this.state.transform) {
      this.animationHasRun = true
      this.runAnimation()
    }
  }

  componentDidCatch(error, info) {
    log.e("componentDidCatch error: " + error + ", info: " + info)
  }

  reinitialize = async () => {
    try {
      log.v("reinitialize called")
      this.animationHasRun = false
      await this.setState({
        downScaleAnimation: new Animated.Value(1),
        upScaleAnimation: new Animated.Value(0),
        animationFinished: false,
      })
    } catch (e) {
      log.e("reinitialize error: " + e)
    }
  }

  async runAnimation() {
    try {
      log.v("runAnimation")
      if (this.props.challengeMode) {
        log.v("no animation in challengeMode")
        const result = true
        this.setState({challengeMode: result})
        return
      }
      if (this.animationHasRun) {
        log.v("animation running")
        return
      }
      this.animationHasRun = true
      Animated.sequence([
        Animated.timing(this.state.downScaleAnimation, {
          toValue: 0,
          duration: this.duration,
          easing: Easing.ease,
          useNativeDriver: true,
        }),
        Animated.timing(this.state.upScaleAnimation, {
          toValue: 1,
          duration: this.duration * 1.3,
          easing: Easing.ease,
          useNativeDriver: true,
        }),
      ]).start(({finished}) => {
        if (!finished) {
          log.e("runAnimation animation was not finished correctly")
          this.setState({upScaleAnimation: new Animated.Value(1)})
        }
        //log.v("Animated sequence complete, upscale value: " + JSON.stringify(this.state.upScaleAnimation));
      })
      setTimeout(() => {
        try {
          if (this.props.onAnimationDone) {
            this.props.onAnimationDone()
          }
          this.setState({animationFinished: true})
        } catch (e) {
          log.e("runAnimation onAnimationDone error: " + e)
        }
      }, this.duration * 2.3)
    } catch (e) {
      log.e("runAnimation error: " + e)
    }
  }

  render() {
    try {
      log.v(
        this.props.type +
          " render called with challengeMode " +
          this.props.challengeMode,
      )

      if (
        this.state.transform &&
        !this.animationHasRun &&
        !this.props.challengeMode
      ) {
        this.runAnimation()
      }

      // if reverseAnimate is passed as prop, then we want to animate
      // from big button to a regular size button, else the other way round
      // if reverseAnimate is passed, the first animated view will show
      // the bigger button instead of the regular one, therefore the
      // animation values are adjusted for that case
      const regularRange = this.props.animateArFromNoteFocus ? [0, -15] : [0, 0]
      const bigRange = [0, -15]

      const yCoordinateInitial = this.state.downScaleAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: this.props.reverseAnimate ? bigRange : regularRange,
      })

      const yCoordinateFinal = this.state.upScaleAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: this.props.reverseAnimate ? regularRange : bigRange,
      })

      // if the button is active (currently the screen corresponding to
      // the menuButtonType is shown), show icon in realnote color,
      // i. e. highlighted
      const highlightColor = this.props.active
        ? this.props.highlightedIconColor ||
          StyleConstants.highlightedButtonColor
        : this.props.iconColor || StyleConstants.buttonColor
      const iconColor = highlightColor

      const textStyle = [
        this.props.shadow ? AppStyles.textShadow : null,
        {color: iconColor},
      ]

      return (
        <TouchableOpacity
          style={[
            {
              width: StyleConstants.menuBarHeight,
              alignItems: "center",
              justifyContent: "center",
              height: StyleConstants.menuBarHeight,
              pointerEvents: "auto",
            },
            this.props.style,
          ]}
          disabled={this.props.disabled}
          onPress={this.onPress}>
          {!this.state.animationFinished && (
            <Animated.View
              style={{
                opacity: this.state.downScaleAnimation,
                position: "absolute",
                alignItems: "center",
                justifyContent: "center",
                transform: [
                  {scale: this.state.downScaleAnimation},
                  {
                    translateY: this.props.challengeMode
                      ? 0
                      : yCoordinateInitial,
                  },
                ],
              }}>
              {this.props.challengeMode
                ? this.renderRegularIcon(iconColor, textStyle)
                : this.props.reverseAnimate
                ? this.renderBigIcon(textStyle)
                : this.renderRegularIcon(iconColor, textStyle)}
            </Animated.View>
          )}

          {this.state.transform && (
            <Animated.View
              style={{
                position: "absolute",
                alignItems: "center",
                justifyContent: "center",
                opacity: this.state.upScaleAnimation,
                transform: [
                  {scale: this.state.upScaleAnimation},
                  {translateY: yCoordinateFinal},
                ],
              }}>
              {this.props.reverseAnimate
                ? this.renderRegularIcon(iconColor, textStyle)
                : this.renderBigIcon(textStyle)}
            </Animated.View>
          )}
        </TouchableOpacity>
      )
    } catch (e) {
      log.e("render error: " + e)
      return null
    }
  }

  // regular size icon shown in menu button
  renderRegularIcon(iconColor, textStyle) {
    try {
      return (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            width: 50,
          }}>
          <Icon
            name={iconName[this.props.type]}
            fill={iconColor}
            stroke={iconColor}
            height={StyleConstants.menuButtonSize}
            width={StyleConstants.menuButtonSize}
            shadow={this.props.shadow || false}
          />
          <Text
            style={[
              AppStyles.boldButtonText,
              textStyle,
              {textAlign: "center"},
              this.props.textStyle,
            ]}>
            {this.props.challengeShortTitle ||
              strings("MenuButton." + this.props.type)}
          </Text>
        </View>
      )
    } catch (e) {
      log.e("renderRegularIcon error: " + e)
    }
  }

  // bigger icon shown in menu button, always show this button in white or
  // default button color (from StyleConstants), never use hightlight color,
  // i. e. RealNote color
  renderBigIcon(textStyle, icon, text) {
    icon = icon || bigIconName[this.props.type]

    try {
      return (
        <View
          nativeID={"bigIcon"}
          style={{
            pointerEvents: "auto",
            alignItems: "center",
            justifyContent: "center",
            width: 150, // Dimensions.get("screen").width / 3,
          }}>
          <Icon
            name={icon || bigIconName[this.props.type]}
            fill={this.props.iconColor || StyleConstants.buttonColor}
            stroke={this.props.iconColor || StyleConstants.buttonColor}
            height={StyleConstants.menuButtonSize * 2}
            width={StyleConstants.menuButtonSize * 2}
            shadow={this.props.shadow || false}
          />

          <Text
            style={[
              AppStyles.text,
              AppStyles.textShadow,
              textStyle,
              {
                color: this.props.iconColor || StyleConstants.buttonColor,
                textAlign: "center",
              },
              this.props.textStyle,
            ]}>
            {text || strings("MenuButton.animated." + this.props.type)}
          </Text>
        </View>
      )
    } catch (e) {
      log.e("renderBigIcon error: " + e)
    }
  }

  // navigate to one of the main screens and replace stack with new route
  onPress = () => {
    const {
      onPress,
      navigation,
      type,
      reverseAnimateWhenGoingToAr,
      challengeActive,
    } = this.props
    if (onPress) {
      onPress(challengeActive)
    } else {
      if (navigation) {
        Realnote.sendFirebaseClick("menu_" + routeName[type])
        navigation.navigate({
          routeName: routeName[type],
          params: {reverseAnimateAr: reverseAnimateWhenGoingToAr},
          key: routeName[type],
        })
      } else {
        log.e("render: onPress error: no navigation passed as prop")
      }
    }
  }
}

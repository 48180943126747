import React from "react"
import {Text, TextInput, View} from "react-native"

import AppColors from "../../styles/AppColors"
import DetectionList from "./DetectionList"

const TAG = "DebugScreen"

/**
 * component that shows debug info for developers
 * including scene and content guid
 * and DetectionList with detection info
 */
export default class DebugNoteView extends React.Component {
  render() {
    return (
      (
        <View style={{marginBottom: 100}}>
          <Text style={{color: AppColors.primary}}>contentGuid:</Text>
          <TextInput
            style={{
              height: 40,
              borderColor: "gray",
              borderWidth: 1,
              color: AppColors.black,
            }}
            value={this.props.contentGuid}
          />
          <Text style={{color: AppColors.primary}}>sceneGuid:</Text>
          <TextInput
            style={{
              height: 40,
              borderColor: "gray",
              borderWidth: 1,
              color: AppColors.black,
            }}
            value={this.props.sceneGuid}
          />
        </View>
      ),
      (
        <View style={{paddingLeft: "5%", paddingRight: "5%", width: "100%"}}>
          <DetectionList sceneGuid={this.props.sceneGuid} />
        </View>
      )
    )
  }
}

import {combineReducers, configureStore} from "@reduxjs/toolkit"
import {setupListeners} from "@reduxjs/toolkit/query"
import {scrollingNoteApi} from "./reducers/scrollingNoteSlice"
import {playgroundApi} from "../dataProvider/PlaygroundsProvider"
import challengeModeSlice from "./reducers/challengeModeSlice"
import challengeDataSlice from "./reducers/challengeDataSlice"
import {challengeFeedsApi} from "./reducers/ChallengeFeedsSlice";
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import * as Sentry from "@sentry/react";

let middleware = []

middleware.push(playgroundApi.middleware)
middleware.push(scrollingNoteApi.middleware)
middleware.push(challengeFeedsApi.middleware)

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["challengeMode"],
}

const rootReducer = combineReducers({
  challengeMode: challengeModeSlice,
  challengeData: challengeDataSlice,
  [scrollingNoteApi.reducerPath]: scrollingNoteApi.reducer,
  [playgroundApi.reducerPath]: playgroundApi.reducer,
  [challengeFeedsApi.reducerPath]: challengeFeedsApi.reducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ["persist/PERSIST"],
        },
      }).concat(middleware),
  enhancers: [sentryReduxEnhancer]
})

const persistor = persistStore(store);
export {store, persistor};

setupListeners(store.dispatch)

// persistor.purge()

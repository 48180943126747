import React, {Component} from "react"
import {TouchableOpacity, View} from "react-native"
import RealnoteLogger from "../../bridges/RealnoteLogger"
import StyleConstants from "../../styles/StyleConstants"
import Icon from "../RealnoteIcon/Icon"
import TextMenu from "./TextMenu"

const TAG = "ContextMenu"
const log = new RealnoteLogger(TAG)

export default class ContextMenu extends Component {
  constructor(props) {
    try {
      super(props)
      this.state = {
        showMenu: false,
      }
      this.iconSize = 0.75 * StyleConstants.realnoteScreenButtonSize
    } catch (error) {
      log.e("constructor error: " + error)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    try {
      if (nextProps.showMenu && nextProps.showMenu !== this.state.showMenu) {
        this.setState({showMenu: nextProps.showMenu})
      }
      log.v(
        "shouldComponentUpdate nextState and this.state showMenu: " +
          nextState.showMenu +
          ", " +
          this.state.showMenu,
      )
      return (
        nextProps.children !== this.props.children ||
        nextState.showMenu !== this.state.showMenu
      )
    } catch (error) {
      log.e("shouldComponentUpdate error: " + error)
    }
  }

  componentDidCatch(error, errorInfo) {
    log.e(
      "componentDidCatch error: " +
        error +
        ", info: " +
        JSON.stringify(errorInfo),
    )
  }

  show = () => {
    this.setState({showMenu: true})
  }

  hide = () => {
    this.setState({showMenu: false})
  }

  render() {
    try {
      const padding =
        (Math.max(
          1,
          Math.min(this.iconSize / StyleConstants.iconButtonSize, 0.25),
        ) *
          StyleConstants.touchablePadding) /
        2.0
      log.v("render")
      return (
        <View
          style={[
            {
              position: "absolute",
              top: 0,
              right: 0,
            },
            this.props.style,
          ]}>
          <TouchableOpacity
            onPress={this.toggleMenuOverlay}
            style={{
              padding: padding,
              alignSelf: "flex-end",
            }}>
            <Icon
              name={"MoreVert"}
              height={this.iconSize}
              width={this.iconSize}
            />
          </TouchableOpacity>
          {this.state.showMenu && this.props.children}
        </View>
      )
    } catch (error) {
      log.e("render error: " + error)
      return null
    }
  }

  toggleMenuOverlay = () => {
    try {
      log.v("toggleMenuOverlay prevState = " + this.state.showMenu)
      this.setState(prevState => ({
        showMenu: !prevState.showMenu,
      }))
    } catch (error) {
      log.e("showMenuOverlay error: " + error)
    }
  }
}

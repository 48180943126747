import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {HttpBridge} from "../../http/HttpBridge"
import Global from "../../Global"
import {captureException} from "../../components/Sentry"

const http = new HttpBridge()

const handleError = (err = "No error provided!") => {
  captureException(err)
  return {
    error: {
      status: 500,
      statusText: "Internal Server Error",
      data: err,
    },
  }
}

const prepareHeaderHandler = async (headers, {getState}) => {
  const token = await AsyncStorage.getItem("token")
  headers.set("authorization", `Bearer ${token}`)
  return headers
}

const handleActivity = feed => {
  const {content, feedItemId: _id, date, user, additionalData} = feed
  switch (content) {
    case "findNote":
      return {
        _id,
        text: `${user.name} hat eine Note gefunden`,
        createdAt: date,
        system: true,
      }
    case "join":
      return {
        _id,
        text: `${user.name} ist beigetreten`,
        createdAt: date,
        system: true,
      }
    case "placeNote":
      return {
        _id,
        text: `${user.name} hat eine neue Note platziert`,
        createdAt: date,
        system: true,
      }
    case "addComment":
      return {
        _id,
        text: `${user.name} hat eine Note kommentiert`,
        createdAt: date,
        system: true,
      }
    case "likeNote":
      return {
        _id,
        text: `${user.name} gefällt eine Note`,
        createdAt: date,
        system: true,
      }
    case "editNote":
      return {
        _id,
        text: `${user.name} hat eine Note editiert`,
        createdAt: date,
        system: true,
      }
    default:
      console.error("Unknown activity type", content)
      return {
        _id,
        text: `Unknown activity type`,
        createdAt: date,
        system: true,
      }
  }
}

const patchParticipants = (challengeToPatch, srcBase) => {
  const participantsModified = challengeToPatch.participants.map(
    participant => {
      const src = `${srcBase}${participant.profilePictureUrl}`
      return {...participant, profilePictureUrl: src}
    },
  )
  return participantsModified
}

const patchImageUrl = (challengeToPatch, srcBase) => {
  return `${srcBase}${challengeToPatch.imageUrl}`
}

export const transformFeed = feed => {
  const {feedItemId: _id, content, date, user} = feed
  switch (feed.type) {
    case "message":
      return {
        _id,
        user: {...user, _id: user.userId},
        text: content,
        createdAt: date,
      }
    case "image":
      return {
        _id,
        user: {...user, _id: user.userId},
        image: content,
        createdAt: date,
      }
    case "activity":
      return handleActivity(feed)
    default:
      return handleActivity(feed)
  }
}

const getFeeds = async args => {
  const {challengeId, baseUrl} = args
  const response = await fetch(
    `${baseUrl}public/getChallengeFeed?skip=${0}&limit=${0}&challengeId=${challengeId}`,
    {method: "GET"},
  )

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`
    return handleError(message)
  }

  const feedsData = await response.json()
  const feeds_transformed = feedsData.feed.map(transformFeed)
  const response_transformed = {...feedsData, feed: feeds_transformed}
  return {data: response_transformed}
}

const sendMessage = async args => {
  const {challengeId, userId, message, baseUrl} = args
  const response = await fetch(`${baseUrl}public/messageToFeed`, {
    method: "POST",
    headers: new Headers({
      // authorization: "Bearer " + Global,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({challengeId, userId, message}),
  })

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`
    return handleError(message)
  }

  const res = await response.json()
  return {data: res}
}

const createChallenge = async args => {
  try {
    const {challengeData, image} = args

    const res = await http.uploadChallenge(challengeData, image)
    const srcBase = await http.getAssetsLink()

    const createdChallenge = JSON.parse(res)
    const src = patchImageUrl(createdChallenge, srcBase)
    const patchedParticipants = patchParticipants(createdChallenge, srcBase)

    return {
      data: {
        ...createdChallenge,
        imageUrl: src,
        participants: patchedParticipants,
      },
    }
  } catch (err) {
    return handleError(err)
  }
}

const joinChallenge = async args => {
  try {
    const {challengeId} = args
    const res = await http.joinChallenge(challengeId)
    return {data: res}
  } catch (err) {
    return handleError(err)
  }
}

const updateChallenge = async args => {
  try {
    const {challengeData, image} = args

    const srcBase = await http.getAssetsLink()
    const updatedChallenge = await http.updateChallenge(challengeData, image)
    const src = patchImageUrl(updatedChallenge, srcBase)
    const participantsModified = patchParticipants(updatedChallenge, srcBase)
    const challengeModified = {
      ...updatedChallenge,
      imageUrl: src,
      participants: participantsModified,
    }
    return {data: challengeModified}
  } catch (err) {
    return handleError(err.message)
  }
}

const getUserChallenges = async args => {
  try {
    const challenges = await http.getUserChallenges()
    const srcBase = await http.getAssetsLink()

    const challengesModified = challenges.map(challenge => {
      const participantsModified = patchParticipants(challenge, srcBase)
      const src = patchImageUrl(challenge, srcBase)
      return {...challenge, imageUrl: src, participants: participantsModified}
    })

    return {data: challengesModified}
  } catch (err) {
    return handleError(err.message)
  }
}

const getChallengeData = async args => {
  try {
    const challenges = await http.getChallengeData()
    const srcBase = await http.getAssetsLink()

    const challengesModified = challenges.map(challenge => {
      const participantsModified = patchParticipants(challenge, srcBase)
      return {...challenge, participants: participantsModified}
    })

    return {data: challengesModified}
  } catch (err) {
    return handleError(err.message)
  }
}

const getProfilePicture = async args => {
  const {userId} = args
  const response = await fetch(`${Global.baseUrl}note/getProfilePicture`, {
    method: "POST",
    body: JSON.stringify({userId}),
    headers: new Headers({
      authorization: `Bearer ${Global.token}`,
      "Content-Type": "application/json",
    }),
  })

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`
    return handleError(message)
  }

  const blob = await response.blob()
  const objectURL = URL.createObjectURL(blob)

  return {data: objectURL}
}

const getProfilePreview = async args => {
  const {userId} = args
  try {
    const response = http.getProfilePreview(userId)
    return {data: response}
  } catch (err) {
    return handleError(err.message)
  }
}

export const challengeFeedsApi = createApi({
  reducerPath: "challengeFeed",
  baseQuery: fetchBaseQuery({
    baseUrl: Global.baseUrl,
    prepareHeaders: prepareHeaderHandler,
  }),
  tagTypes: [
    "Feed",
    "UserChallenge",
    "ChallengeData",
    "ProfilePicture",
    "ProfilePreview",
  ],
  endpoints: builder => ({
    getFeeds: builder.query({
      queryFn: getFeeds,
      providesTags: ["Feed"],
    }),
    sendMessage: builder.mutation({
      queryFn: sendMessage,
      invalidatesTags: [],
    }),
    createChallenge: builder.mutation({
      queryFn: createChallenge,
      invalidatesTags: ["UserChallenge", "ChallengeData"],
    }),
    joinChallenge: builder.mutation({
      queryFn: joinChallenge,
      invalidatesTags: ["UserChallenge", "ChallengeData"],
    }),
    updateChallenge: builder.mutation({
      queryFn: updateChallenge,
      invalidatesTags: ["UserChallenge", "ChallengeData"],
    }),
    getUserChallenges: builder.query({
      queryFn: getUserChallenges,
      providesTags: ["UserChallenge"],
    }),
    getChallengeData: builder.query({
      queryFn: getChallengeData,
      providesTags: ["ChallengeData"],
    }),
    getProfilePicture: builder.query({
      queryFn: getProfilePicture,
      providesTags: ["ProfilePicture"],
    }),
    getProfilePreview: builder.query({
      queryFn: getProfilePreview,
      providesTags: ["ProfilePreview"],
    }),
  }),
})

export const {
  useGetFeedsQuery,
  useSendMessageMutation,
  useCreateChallengeMutation,
  useJoinChallengeMutation,
  useUpdateChallengeMutation,
  useGetUserChallengesQuery,
  useGetChallengeDataQuery,
  useGetProfilePictureQuery,
  useGetProfilePreviewQuery,
} = challengeFeedsApi

import React from "react"
import RealnoteScreen from "./MenuBarScreen/RealnoteScreen"
import CircularIconButton from "./MenuBarScreen/CircularIconButton"
import AppColors from "../styles/AppColors"
import {KeyboardAvoidingView, Platform} from "react-native"

export default function RealnoteBaseScreen(props) {
  const {
    nativeID,
    onBack,
    style,
    navigation,
    noFill,
    noCircle,
    darkUpperButtons,
    backButton,
    children,
    onOk,
    okIcon,
    okDisabled,
    isNoteEditor,
    fullscreen,
    avoidBackButtonOverlap
  } = props

  return (
    <RealnoteScreen
      nativeID={nativeID || "RealnoteBaseScreen"}
      onBack={onBack}
      navigation={navigation}
      style={style}
      backgroundFilled={!noFill}
      noCircle={noCircle}
      darkUpperButtons={darkUpperButtons}
      backButton={backButton}
      avoidBackButtonOverlap={avoidBackButtonOverlap}
      fullscreen={fullscreen}>
      <KeyboardAvoidingView
        style={[style, {flex: 1}]}
        behavior={
          Platform.OS === "ios" ? "height" : isNoteEditor ? "padding" : "null"
        }>
        {children}
        {onOk && (
          <CircularIconButton
            name={okIcon ? okIcon : "Success"}
            position={"bottomRight"}
            extraBigTouchSurface
            noCircle
            bigButton
            buttonColor={AppColors.primary}
            disabled={okDisabled}
            onPress={onOk}
          />
        )}
      </KeyboardAvoidingView>
    </RealnoteScreen>
  )
}

import React, {PureComponent} from "react"
import {
  Animated,
  Easing,
  TouchableOpacity,
  View,
  Dimensions,
  Pressable
} from "react-native"
import StyleConstants from "../../styles/StyleConstants"
import Icon from "../../components/RealnoteIcon/Icon"
import RealnoteLogger from "../../bridges/RealnoteLogger"
import AppStyles, {xAppStyles} from "../../styles/AppStyles"
import realnote from "../../bridges/RealnoteNative"

const log = new RealnoteLogger("CircularIconButton")

export default class CircularIconButton extends PureComponent {
  constructor(props) {
    super(props)
    try {
      this.state = {
        hasNotch: false,
        dims: null,
        roundCornerOffset: 0,
        notchOffset: 0,
        reservedBottom: 0,
        reservedTop: 0,
        rightNotchOffset: 0,
        leftNotchOffset: 0,
        longPressed: false,
        upscaling: this.props.animated
          ? new Animated.Value(0)
          : new Animated.Value(1),
      }
      this.duration = 400
      this.animationHasRun = false
    } catch (e) {
      log.e("constructor with props " + JSON.stringify(props) + " error: " + e)
    }
  }

  componentDidMount() {
    this.getHasNotch()
  }

  async getHasNotch() {
    let _dims = await realnote.getDims()
    let _offsetNotch = 0
    let _offsetRoundCorner = 0
    let _reservedBottom = 0
    let _reservedTop = 0
    let _notchWidth = 0
    let _notchPosition = 0
    let _rightNotchOffset = 0
    let _leftNotchOffset = 0
    if (_dims.hasNotch) {
      _offsetNotch = Number(_dims.notchShape.h)
      _notchWidth = Number(_dims.notchShape.w)
      _notchPosition = Number(_dims.notchShape.l)
      _offsetRoundCorner = 5
      log.d(
        "notchPosition " +
          _notchPosition +
          " width - notchwidth: " +
          (_dims.width - _notchWidth),
      )
      if (_notchPosition == 0 && _notchWidth > 0) {
        _leftNotchOffset = _notchWidth
      }
      if (_notchPosition >= _dims.width - _notchWidth && _notchWidth > 0) {
        _rightNotchOffset = _notchWidth
      }
      log.d("cornerOffset: " + _rightNotchOffset)
    }
    if (this.props.usePadding) {
      _reservedBottom = _dims.reservedBottom + 15
      _reservedTop = _dims.reservedTop
    }
    log.d(
      "jj1 Offsets: " +
        String(_offsetRoundCorner) +
        " " +
        String(_offsetRoundCorner),
    )
    this.setState({
      hasNotch: _dims.hasNotch,
      dims: _dims,
      notchOffset: _offsetNotch,
      roundCornerOffset: _offsetRoundCorner,
      reservedBottom: _reservedBottom,
      reservedTop: _reservedTop,
      rightNotchOffset: _rightNotchOffset,
      leftNotchOffset: _leftNotchOffset,
    })
    log.d("reservedBottom " + this.state.reservedBottom)
  }

  setStylesAndPosition() {
    try {
      this.positionalStyle = {}
      this.size = this.props.size || StyleConstants.iconButtonSize
      this.iconSize = this.props.bigButton ? this.size : this.size * 0.75
      this.radius = this.size / 2.0
      if (this.props.position) {
        switch (this.props.position) {
          case "topLeft":
            this.positionalStyle = {
              position: "absolute",
              left:
                StyleConstants.buttonMarginToEdge +
                this.state.roundCornerOffset +
                this.state.leftNotchOffset,
              top: StyleConstants.buttonMarginToEdge, //+ this.state.reservedTop,
            }
            break
          case "topRight":
            this.positionalStyle = {
              position: "absolute",
              right:
                StyleConstants.buttonMarginToEdge +
                this.state.roundCornerOffset +
                this.state.rightNotchOffset,
              top: StyleConstants.buttonMarginToEdge, //+ this.state.reservedTop,
            }
            break
          case "bottomLeft":
            this.positionalStyle = {
              position: "absolute",
              left:
                StyleConstants.buttonMarginToEdge +
                this.state.roundCornerOffset,
              bottom:
                StyleConstants.buttonMarginToEdge + this.state.reservedBottom,
            }
            break
          case "bottomRight":
            this.positionalStyle = {
              position: "absolute",
              right:
                StyleConstants.buttonMarginToEdge +
                this.state.roundCornerOffset,
              bottom:
                StyleConstants.buttonMarginToEdge + this.state.reservedBottom,
            }
            break
          case "bottomCenter":
            this.positionalStyle = {
              position: "absolute",
              left:
                0.5 * (Dimensions.get("screen").width - this.iconSize) +
                this.state.roundCornerOffset,
              bottom:
                StyleConstants.buttonMarginToEdge + this.state.reservedBottom,
            }
            break
          case "bottomCenterLeft":
            this.positionalStyle = {
              position: "absolute",
              left:
                0.5 * (0.5 * Dimensions.get("screen").width - this.iconSize) +
                this.state.roundCornerOffset,
              bottom:
                StyleConstants.buttonMarginToEdge + this.state.reservedBottom,
            }
            break
          case "bottomCenterRight":
            this.positionalStyle = {
              position: "absolute",
              right:
                0.5 * (0.5 * Dimensions.get("screen").width - this.iconSize) +
                this.state.roundCornerOffset,
              bottom:
                StyleConstants.buttonMarginToEdge + this.state.reservedBottom,
            }
            break
          case "lowerCenterRight":
            this.positionalStyle = {
              position: "absolute",
              right:
                StyleConstants.buttonMarginToEdge +
                this.state.roundCornerOffset,
              bottom: this.iconSize + this.state.reservedBottom,
            }
            break
          case "costum":
            this.positionalStyle = {
              position: "absolute",
              right: this.props.right,
              top: this.props.top,
            }
            break
        }
      }

      this.invisibleAreaStyle = this.positionalStyle
      this.invisibleAreaSize = this.size
      if (this.props.extraBigTouchSurface) {
        this.invisibleAreaSize =
          this.size +
          Math.max(
            1,
            Math.min(this.size / StyleConstants.iconButtonSize, 0.25),
          ) *
            StyleConstants.touchablePadding
        if (this.props.position) {
          this.invisibleAreaStyle = {
            position: "absolute",
            right:
              this.positionalStyle.right != null
                ? this.positionalStyle.right
                : null,
            left:
              this.positionalStyle.left != null
                ? this.positionalStyle.left
                : null,
            top:
              this.positionalStyle.top != null
                ? this.positionalStyle.top
                : null,
            bottom:
              this.positionalStyle.bottom != null
                ? this.positionalStyle.bottom
                : null,
          }
        }
      }

      this.progressStyle = {}
      const progressSize = this.iconSize * 3
      if (this.state.longPressed) {
        const margin = StyleConstants.buttonMarginToEdge
        const pos = progressSize - this.iconSize
        if (this.props.position) {
          this.progressStyle = {
            position: "absolute",
            right: this.positionalStyle.right ? -pos / 2 + margin : null,
            left: this.positionalStyle.left ? -pos / 2 + margin : null,
            top: this.positionalStyle.top ? -pos / 2 + margin : null,
            bottom: this.positionalStyle.bottom ? -pos / 2 + margin : null,
          }
        }
      }
    } catch (e) {
      log.e("setPosition error: " + e)
    }
  }

  runAnimation = () => {
    try {
      this.animationHasRun = true
      Animated.timing(this.state.upscaling, {
        toValue: 1,
        duration: this.duration,
        easing: Easing.bounce,
        useNativeDriver: true,
      }).start()
    } catch (e) {
      log.e("runAnimation error: " + e)
    }
  }

  onPress = () => {
    if (this.state.longPressed) {
      this.props.onLongPressRelease()
      this.setState({
        longPressed: false,
      })
    } else {
      this.props.onPress(false)
    }
  }

  render() {
    const style = {
      // boxShadow: "4px 4px 6px #00000040",
      ...xAppStyles.center,
      width: this.invisibleAreaSize,
      height: this.invisibleAreaSize,
      borderRadius: this.invisibleAreaSize / 2.0,
      pointerEvents: "auto",
      ...this.invisibleAreaStyle,
      ...this.props.style,
      backgroundColor: "transparent",
      borderWidth: 0,
    }

    for (var i in style) {
      if (typeof style[i] !== "string" && isNaN(style[i])) {
        delete style[i]
      }
    }

    var shadow = null
    if (this.props.shadow) {
      shadow = "4px 4px 6px #00000040"
    }

    try {
      this.setStylesAndPosition()
      if (!this.animationHasRun && this.props.animated) {
        setTimeout(() => {
          this.runAnimation()
        }, 200)
      }
      return (
        <Pressable
          id={"CircularButton"}
          onPress={this.onPress}
          // onLongPress={this.onLongPress}
          // onPressOut={this.onPressOut}
          disabled={this.props.disabled}
          style={style}>
          {!this.props.invisible && (
            <Animated.View
              style={[
                AppStyles.center,
                {
                  opacity: this.state.upscaling,
                  transform: [{scale: this.state.upscaling}],
                  width: this.size,
                  height: this.size,
                  borderRadius: this.radius,
                  backgroundColor: this.props.noCircle
                    ? null
                    : this.props.backgroundColor ||
                      StyleConstants.buttonBackgroundColor,
                  borderColor:
                    this.props.buttonColor || StyleConstants.buttonColor,
                  borderWidth: this.props.border ? 1 : 0,
                  boxShadow: shadow,
                },
                this.props.iconStyle,
              ]}>
              <Icon
                name={this.props.name}
                height={this.iconSize}
                width={this.iconSize}
                fill={this.props.buttonColor || StyleConstants.buttonColor}
                stroke={this.props.buttonColor || StyleConstants.buttonColor}
                shadow={this.props.shadow}
              />
              {this.props.indicator !== null && this.props.indicator}
              {/* {this.state.longPressed && <View style={this.progressStyle}>
                <Progress.CircleSnail
                    color={[AppColors.highlight,]} height={this.progressSize}
                    size={this.progressSize}
                    width={this.progressSize}
                    thickness={4}/>
              </View>} */}
            </Animated.View>
          )}
        </Pressable>
      )
    } catch (e) {
      log.e("render error: " + e)
    }
  }

  onLongPress = () => {
    try {
      log.v("onLongPress")
      if (this.props.onLongPress) {
        log.v("onLongPress had props.onLongPress")
        this.props.onLongPress()
        this.setState({
          longPressed: true,
        })
      } else {
        log.v("onLongPress no props.onLongPress, thus executing onPress")
        this.props.onPress()
      }
    } catch (e) {
      log.e("onLongPress error: " + e)
    }
  }

  onPressOut = () => {
    try {
      log.d("onPressOut")
      if (this.state.longPressed) {
        if (this.props.onLongPressRelease) {
          if (!this.props.clickToReleaseLongPress) {
            this.props.onLongPressRelease()
            this.setState({
              longPressed: false,
            })
          }
        } else {
          this.setState({
            longPressed: false,
          })
        }
      }
    } catch (e) {
      log.e("onPressOut error: " + e)
    }
  }
}

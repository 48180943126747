
const AppConstants =
{
    textSizeDefault: 14,
    textSizeHeader: 20,

    maxWidth: 500,

}

export default AppConstants
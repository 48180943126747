import React, {useState} from "react"
import {Modal, Text, StyleSheet, View} from "react-native"
import Account from "../dataProvider/Account"
import {strings} from "../i18n"
import ProfilePicture from "../components/ProfilePicture/ProfilePicture"
import AppColors from "../styles/AppColors"
import AppStyles from "../styles/AppStyles"
import FullScreenOverlay from "../components/FullScreenOverlay"

export default function Comment(props) {
  const {comment, navigation, contentGuid, deleteItem} = props
  const {push, navigate} = navigation
  const {
    comment: commentMessage,
    timestamp,
    profilePictureUrl,
    userId,
    guid,
    username,
  } = comment
  const [overlayVisibleReport, setOverlayVisibleReport] = useState(false)
  const [pressed, setPressed] = useState(false)

  function formattedDate(unformattedDate) {
    const date = new Date(unformattedDate)
    const differenceMillis = Date.now() - date.getTime()
    // difference between date and now in days
    let formatted = Math.floor(differenceMillis / (24 * 60 * 60 * 1000))
    if (formatted < 1) {
      // difference between date and now in hours
      formatted = Math.floor(differenceMillis / (60 * 60 * 1000))
      if (formatted < 1) {
        // difference between date and now in minutes
        formatted = Math.floor(differenceMillis / (60 * 1000))
        if (formatted < 1) {
          return strings("Note.justNow")
        }
        return `${strings("Note.ago")} ${formatted} ${strings("Note.minutes")}`
      }
      return `${strings("Note.ago")} ${formatted} ${strings("Note.hours")}`
    } else if (formatted > 30) {
      return (
        `${strings("Note.on")} ` +
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear()
      )
    } else {
      if (formatted < 2) {
        return `${strings("Note.ago")} ${formatted} ${strings("Note.day")}`
      }
      return `${strings("Note.ago")} ${formatted} ${strings("Note.days")}`
    }
  }

  function reportItemAnimation(comment) {
    setOverlayVisibleReport(true)
  }
  function onUsernamePress() {
    if (!pressed) {
      setPressed(true)
      if (Account.userId === userId) {
        navigate("Profile", {userId})
      } else {
        navigate("VisitedProfile", {userId})
      }
    }
  }
  function onDeletePress() {
    deleteItem(guid)
  }

  function onReportPress() {
    reportItemAnimation(commentMessage)
  }

  return (
    <View style={{flexDirection: "row"}}>
      <View style={styles.container}>
        <ProfilePicture
          profilePictureUrl={profilePictureUrl}
          userId={userId}
          navigation={navigation}
          imageSize={30}
        />
      </View>
      <View style={styles.wrapper}>
        {overlayVisibleReport && (
          <Modal transparent={true}>
            <FullScreenOverlay
              type={"reportComment"}
              contentGuid={contentGuid}
              commentGuid={guid}
              creatorUserId={userId}
              commentMessage={commentMessage}
              hide={() => setOverlayVisibleReport(false)}
              paddingOn={true}
            />
          </Modal>
        )}
        <Text>
          <Text
            style={[AppStyles.text, styles.username]}
            onPress={onUsernamePress}>
            {username}
          </Text>{" "}
          <Text style={[AppStyles.text, styles.commentMessage]}>
            {commentMessage}
          </Text>
        </Text>
        <Text>
          <Text style={[AppStyles.text, styles.date]}>
            {formattedDate(timestamp)}
          </Text>
          {" | "}
          {Account.userId == userId ? (
            <Text
              style={[AppStyles.text, {color: AppColors.grayText}]}
              onPress={onDeletePress}>
              {strings("Note.delete")}
            </Text>
          ) : (
            <Text
              style={[AppStyles.text, {color: AppColors.grayText}]}
              onPress={onReportPress}>
              {strings("Note.report")}
            </Text>
          )}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    marginLeft: 10,
    paddingTop: 10,
    width: 40,
  },
  wrapper: {
    flex: 1,
    borderBottomWidth: 1,
    borderColor: AppColors.panelBorder,
    padding: 5,
  },
  username: {
    color: AppColors.black,
    fontWeight: "bold",
  },
  commentMessage: {
    color: AppColors.black,
  },
  date: {
    color: AppColors.grayText,
  },
})

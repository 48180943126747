import React, {useEffect, useState} from "react"
import Card from "./Card"
import Global from "../../Global"
import {HttpBridge} from "../../http/HttpBridge"
import xstyles from "./styles"
import {useGetChallengeDataQuery} from "../../store/reducers/ChallengeFeedsSlice"
import ArMode from "../../ArMode"
import initWeb1 from "../../initWeb1"
import {useSelector} from "react-redux"

const http = new HttpBridge()
var nearScenes = null

export default function Card_GotoAr(props) {
  const {navigation} = props
  const [nearScenesCount, setNearScenesCount] = useState(0)
  const [gpsPermissionsNeeded, setGpsPermissionsNeeded] = useState(true)
  const [gpsPermissionIsDenied, setGpsPermissionIsDenied] = useState(false)
  const [gpsLocation, setGpsLocation] = useState(window.lastLocation)
  const [geolocationWatchId, setGeolocationWatchId] = useState(0)
  const challengeMode = useSelector(state => state.challengeMode)
  const {refetch: refetchchallengeData} = useGetChallengeDataQuery()

  useEffect(async () => {
    if (Global.isEmbedded()) {
      setGpsPermissionsNeeded(false)
    } else {
      const b1 = await Global.gpsPermissionIsDenied()
      setGpsPermissionIsDenied(b1)
      const b = await Global.gpsPermissionIsGranted()
      setGpsPermissionsNeeded(!b)
      if (b) {
        startGps()
      }
    }
    return () => {
      if (geolocationWatchId) {
        navigator.geolocation.clearWatch(geolocationWatchId)
      }
    }
  }, [])

  function filterNearScenes(scenes) {
    let filteredScenes = scenes
    if (challengeMode.isActive) {
      filteredScenes = scenes.filter(
        scene =>
          scene.challengeItemInfo?.challengeGuid === challengeMode.challengeId,
      )
    }
    setNearScenesCount(filteredScenes.length)
    nearScenes = filteredScenes
    // setNearScenes(filteredScenes)
  }

  function updateNearScenes() {
    http.getNearScenesForHomepage().then(res => {
      if (res) {
        filterNearScenes(res)
      } else {
        // setNearScenes(null)
        setNearScenesCount(0)
      }
    })
  }

  useEffect(() => {
    updateNearScenes()
  }, [gpsLocation, challengeMode])
  const startGps = () => {
    const successHandler = async value => {
      window.lastLocation = value.coords
      setGpsLocation(window.lastLocation)

      const b = await Global.gpsPermissionIsGranted()
      setGpsPermissionsNeeded(!b)

      const id = navigator.geolocation?.watchPosition(value => {
        window.lastLocation = value.coords
        // Niels: Commented out to prevent constant rerender of screen.
        // TODO check what wanted behavior is
        // setGpsLocation(window.lastLocation)
        // updateNearScenes()
      })
      setGeolocationWatchId(id)
      refetchchallengeData()
    }

    const errorHandler = async value => {
      const b1 = await Global.gpsPermissionIsDenied()

      setGpsPermissionIsDenied(true) // to force repaint

      setGpsPermissionIsDenied(b1)
    }

    navigator.geolocation?.getCurrentPosition(successHandler, errorHandler)
  }

  if (gpsPermissionsNeeded) {
    return Card_AllowGPS()
  }

  function Card_AllowGPS() {
    const button = {
      text: "Zugriff erlauben",
      onPress: () => {
        startGps()
      },
    }

    if (gpsPermissionIsDenied) {
      return (
        <Card title={"Du hast uns den Zugriff auf Deinen Standort verboten"}>
          Dadurch ist die Funktionalität von Realnote stark eingeschränkt.
          <div style={xstyles.br} />
          Dies lässt sich nur beheben, indem die Berechtigung für Realnote
          selbst setzt, oder zurück setzt und danach diese Seite neu lädst.
          <div style={xstyles.br} />
          Du auf das Schloß oder Ausrufezeichen links oben in der Adressleiste
          tippen
        </Card>
      )
    } else {
      return (
        <Card title="Standort" button={button}>
          Realnote darf Deinen genauen Standort zur Zeit nicht verwenden. Um
          Notes in Deiner Umgebung zu finden, musst Du den Zugriff auf den
          Standort erlauben
        </Card>
      )
    }
  }
  var target = "Live"
  var text = ""

  if (nearScenesCount || challengeMode) {
    text = "Schau Dich um"
    target = "Live"
  } else {
    text = "Erstelle die erste Note"
    target = "NoteEditorModelSelection"
  }

  const button = {
    text: text,
    onPress: async () => {
      ArMode.startAr()
      initWeb1()

      var ask = false
      if (!Global.isEmbedded()) {
        ask = !(await Global.gpsPermissionIsGranted())
      }
      //
      // to speedup time to display first note
      // ues nearScene #1 in the same way as the first challenge note or a shared note
      // and force direct display without floor detect etc.
      //
      var noteData = null
      if (challengeMode.isActive && nearScenes && nearScenes.length > 0) {
        const guid = nearScenes[0].guid
        noteData = {
          guid: guid,
          mode: "editExisting",
        }
      }

      if (ask) {
        navigation.navigate("AskForGps", {
          forwardTo: target,
          noteData: noteData,
        })
      } else {
        navigation.navigate(target, {forwardTo: target, noteData: noteData})
      }
    },
  }

  const w1 = 150

  const video1 = (
    <div
      style={{
        width: w1,
        height: w1,
        paddingBottom: 0,
      }}
      dangerouslySetInnerHTML={{
        __html: `<video style='width:${w1}; height: ${w1}; object-fit: cover' autoplay muted loop playsinline crossorigin='anonymous' src='./assets/videos/findnotes-1.mp4'/>`,
      }}
    />
  )

  const nearScenesFound = nearScenesCount != 0
  const title = "Augmented Reality"
  if (nearScenesFound) {
    return (
      <Card title={title} button={button} waitForWeb1>
        Im Umkreis von 100 Metern
        <br /> gibt es {nearScenesCount} Notes.
        <br />
        Scanne Deine Umgebung
        <br /> und sieh sie Dir an
      </Card>
    )
  } else {
    if (challengeMode) {
      return (
        <Card title={title} button={button} waitForWeb1>
          Siehe Dir die Notes in Deiner Nähe an.
        </Card>
      )
    } else {
      return (
        <Card title={title} button={button} waitForWeb1>
          Im Umkreis von 100 Metern gibt es noch keine Notes.
          <br />
          <br />
          Deine Chance...
        </Card>
      )
    }
  }
}

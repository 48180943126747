import React, {PureComponent} from "react"
import RealnoteLogger from "../../bridges/RealnoteLogger"
import {Text, TouchableOpacity, View} from "react-native"
import StyleConstants from "../../styles/StyleConstants"
import AppColors from "../../styles/AppColors"
import AppStyles from "../../styles/AppStyles"
import {strings} from "../../i18n"
import Username from "../../components/Profile/Username"
import _account from "../../dataProvider/Account"

const log = new RealnoteLogger("NoteTopBar")

export default class NoteTopBar extends PureComponent {
  render() {
    try {
      let isOwnProfile = this.props.userId == _account.userId
      log.v("rendering with isOwnProfile: " + isOwnProfile)
      return (
        <View
          style={{
            height: StyleConstants.topBarHeight,
            width: "100%",
            borderBottomWidth: 3,
            borderBottomColor: AppColors.panelBorder,
            alignItems: "center",
            justifyContent: "center",
          }}>
          <View style={{width: "100%", justifyContent: "center"}}>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
              }}>
              <Text
                style={[
                  AppStyles.largeText,
                  {
                    color: AppColors.black,
                  },
                ]}>
                {isOwnProfile && this.props.listType == "profile"
                  ? strings("Explore.ownProfile")
                  : strings(`Explore.${this.props.listType}`)}
              </Text>

              {this.props.listType == "profile" &&
                this.props.userId &&
                !isOwnProfile && (
                  <Username userId={this.props.userId} largeTextSize black />
                )}

              {/* <Text>{this.props.street}</Text> */}
            </View>
          </View>
        </View>
      )
    } catch (e) {
      log.e("render error: " + e)
    }
  }
}

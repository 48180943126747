import React from "react";
import {ScrollView, Text, View, StyleSheet} from "react-native";
import RealnoteBaseScreen from "./RealnoteBaseScreen";
import AppColors from "../styles/AppColors";
import {strings} from "../i18n";
import NoteTopBar from "./Note/NoteTopBar";
import Account from "../dataProvider/Account";
import EmptyContentImage, {types} from "../components/EmptyContentImage";

import {
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useDownvoteNoteMutation,
  useGetNoteForMapQuery,
  useUpvoteNoteMutation,
} from "../store/reducers/scrollingNoteSlice";
import Note from "./Note/Note";


export default function NoteInfoForMap(props) {
  const {navigation} = props;
  const {getParam, goBack} = navigation;
  const noteInfo = getParam("note");
  const sceneGuid = getParam("sceneGuid");
  const guid = noteInfo ? noteInfo.guid : null;
  const {
    data: note,
    isLoading,
    refetch,
  } = useGetNoteForMapQuery({userId: Account.userId, guid: guid || sceneGuid || ""});
  const [createComment] = useCreateCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();
  const [upvoteNote] = useUpvoteNoteMutation();
  const [downvoteNote] = useDownvoteNoteMutation();
  console.log("NoteInfoForMap", note);

  return (
    <RealnoteBaseScreen
      navigation={navigation}
      onBack={goBack}
      darkUpperButtons
    >
      {isLoading ? (
        <View style={styles.loadingContainer}>
          <Text style={styles.loadingText}> {strings("loading")} </Text>
        </View>
      ) : (
        <>
          <NoteTopBar listType={"title"} />
          {note !== undefined && note !== "" ? (
            <ScrollView
              persistentScrollbar={false}
              contentContainerStyle={{paddingBottom: 100}}
            >
              <Note
                createComment={createComment}
                deleteComment={deleteComment}
                upvoteNote={upvoteNote}
                downvoteNote={downvoteNote}
                refetchScrollingNotes={refetch}
                navigation={navigation}
                note={note}
                scrollingNote={false}
              />
            </ScrollView>
          ) : (
            <EmptyContentImage
              type={types.internet}
              imageStyle={{
                width: "90%",
                height: "30%",
              }}
              isVisible={true}
            />
          )}
        </>
      )}
    </RealnoteBaseScreen>
  );
}

const styles = StyleSheet.create({
  loadingContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  loadingText: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 15,
    borderRadius: 20,
    height: 40,
    width: 200,
    alignSelf: "center",
    alignContent: "center",
    color: AppColors.primary,
  },
});

import React, {PureComponent} from "react"
import {StyleSheet, Text, TouchableOpacity, View} from "react-native"
import AppStyles from "../styles/AppStyles"
import AppColors from "../styles/AppColors"
import RealnoteLogger from "../bridges/RealnoteLogger"

const TAG = "Hint"
const log = new RealnoteLogger(TAG)

export default class Hint extends PureComponent {
  state = {
    hide: this.props.hidden || false,
    error: null,
  }

  // region error handling

  setError(error) {
    try {
      this.setState({error: error})
    } catch (e) {
      log.e("setError error: " + e)
    }
  }

  hideError() {
    try {
      this.setState({error: null})
    } catch (e) {
      log.e("hideError error: " + e)
    }
  }

  // endregion

  // region hiding of hints

  setHidden(hide) {
    try {
      this.setState({hide})
    } catch (e) {
      log.e("setHidden error: " + e)
    }
  }

  onPressHide() {
    try {
      this.setState(prevState => ({
        hide: !prevState.hide,
      }))
    } catch (e) {
      log.e("onPressHide error: " + e)
    }
  }

  // endregion

  render() {
    try {
      if (this.state.hide) {
        return null
      }

      if (this.state.error) {
        return this.renderError()
      } else {
        return this.renderInfo()
      }
    } catch (e) {
      log.e("render error: " + e)
    }
  }

  renderInfo() {
    try {
      const styles = this.props.withFrame ? framedStyles : noFrameStyles
      const iSizeStyle = this.props.withFrame
        ? AppStyles.largeText
        : [AppStyles.xlText, AppStyles.textShadow]
      const sizeStyle = this.props.withFrame
        ? AppStyles.text
        : [AppStyles.largeText, AppStyles.textShadow]

      return (
        <TouchableOpacity
          nativeID={"Hint"}
          style={this.props.style}
          onPress={() => {
            this.onPressHide()
          }}>
          <View style={[styles.style, this.props.style]}>
            {this.props.showI && (
              <Text
                style={[iSizeStyle, styles.iTextStyle, this.props.iTextStyle]}>
                i
              </Text>
            )}
            <Text style={[sizeStyle, styles.textStyle, this.props.textStyle]}>
              {this.props.text}
            </Text>
          </View>
        </TouchableOpacity>
      )
    } catch (e) {
      log.e("renderInfo error: " + e)
    }
  }

  renderError() {
    try {
      return (
        <View
          onPress={() => {
            this.onPressHide()
          }}>
          <View
            style={[
              {
                margin: 20,
                padding: 5,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                borderWidth: 0,
                borderRadius: 25,
                backgroundColor: AppColors.veryTransparentWhite,
              },
              this.props.style,
            ]}>
            <Text
              style={[
                AppStyles.largeText,
                {
                  fontWeight: "bold",
                  borderColor: AppColors.highlight,
                  color: AppColors.highlight,
                  borderRadius: 13,
                  height: 26,
                  width: 26,
                  borderWidth: 2,
                  position: "relative",
                  left: 15,
                  textAlign: "center",
                },
              ]}>
              X
            </Text>
            <Text
              style={[
                AppStyles.text,
                {
                  marginLeft: 30,
                  marginRight: 10,
                },
              ]}>
              {this.state.error}
            </Text>
          </View>
        </View>
      )
    } catch (e) {
      log.e("renderError error: " + e)
    }
  }
}

const framedStyles = StyleSheet.create({
  style: {
    alignSelf: "center",
    margin: 20,
    padding: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 0,
    borderRadius: 25,
    backgroundColor: AppColors.veryTransparentWhite,
  },
  iTextStyle: {
    fontWeight: "bold",
    borderColor: "#000",
    color: "#000",
    borderRadius: 13,
    marginRight: 20,
    height: 26,
    width: 26,
    borderWidth: 2,
    position: "relative",
    left: 20,
    textAlign: "center",
  },
  textStyle: {
    marginHorizontal: 10,
  },
})
const noFrameStyles = StyleSheet.create({
  style: {
    alignSelf: "center",
    margin: 20,
    padding: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  iTextStyle: {
    fontWeight: "bold",
    color: AppColors.white,
    borderRadius: 13,
    marginRight: 20,
    height: 26,
    width: 26,
    borderWidth: 2,
    borderColor: AppColors.white,
    textAlign: "center",
  },
  textStyle: {
    color: AppColors.white,
    marginHorizontal: 10,
    textAlign: "center",
  },
})

// @ts-ignore
import {v4 as uuidv4} from "uuid"
import Logger from "../bridges/RealnoteLogger"
import Account from "../dataProvider/Account"
import {HttpBridge} from "../http/HttpBridge"
import global from "../Global"
import {store} from "../store/store"
import ArMode from "../ArMode"
import realnote from "../host"
import {enterChallenge} from "../store/reducers/challengeModeSlice"
import {challengeFeedsApi} from "../store/reducers/ChallengeFeedsSlice"

const TAG = "RealnoteNative"
const log = new Logger(TAG)
const http = new HttpBridge()
const listeners: any = {}

const eventEmitterToReact = {
  index: 0,

  listenersByEventName: {},

  addListener: function (eventName, callback) {
    let listenersByEventName = this.listenersByEventName[eventName]
    if (listenersByEventName == null) {
      this.listenersByEventName[eventName] = []
    }

    const res = this.index++
    this.listenersByEventName[eventName][res] = callback

    var self = this
    return {
      remove() {
        try {
          delete self.listenersByEventName[eventName][res]
        } catch (ex) {
          console.error(ex)
        }
      },
    }
  },

  sendEventToReact: function (eventName, paramsJsonString) {
    const eventArray = this.listenersByEventName[eventName]
    if (eventArray) {
      for (var i in eventArray) {
        try {
          const evListener = eventArray[i]
          const params = JSON.parse(paramsJsonString)
          evListener.apply(null, [params])
        } catch (ex) {
          console.error(ex)
        }
      }
    }
  },
}

const _emitter = {
  os_emitter: null,

  init: function () {
    ;(window as any).eventEmitterToReact = eventEmitterToReact
    this.os_emitter = eventEmitterToReact
  },

  addListener: function (
    component: string,
    eventName: any,
    callback: any,
    multiInstances: boolean,
  ) {
    const ev = new Event(eventName)

    log.v(`addListener ${component} listens to event ${eventName}`)
    let listenersForComponent = listeners[component]
    if (listenersForComponent == null) {
      listeners[component] = []
      listenersForComponent = listeners[component]
    }
    if (!multiInstances) {
      if (listenersForComponent.includes(eventName)) {
        log.d(`${component} listens to event ${eventName} multilpe times`)
      }
    }
    listenersForComponent.push(eventName)

    var subscription = this.os_emitter.addListener(eventName, callback)

    return {
      remove: function () {
        let index = listenersForComponent.indexOf(eventName)
        listenersForComponent.splice(index, 1)
        if (subscription) {
          subscription.remove()
        }
      },
    }
  },
}

_emitter.init()

export function emitter(): any {
  return _emitter
}

class Realnote {
  ScreenDims: any
  freezeLocationLongitude: String = ""
  freezeLocationLatitude: String = ""

  /**
   * This attribute is a flag for whether the WebApp is fully initialized and callable.
   * It should only be set once in callWebApp by the first call that wants to communicate with it.
   * It's content is the promise returned by the native App for the call "awaitWebAppInitialization",
   * which is resolved to true as soon as the WebApp is initialized and callable.
   * All calls via callWebApp are executed via webAppInitialized.then(()=>{...}), so they are delayed
   * until after this promise is resolved.
   */
  webAppInitializedPromise: Promise<boolean> | null

  constructor() {
    this.ScreenDims = null
    this.webAppInitializedPromise = null

    emitter().addListener(TAG, "onGpsChanged", data => {
      try {
        this.onGpsChanged && this.onGpsChanged(data)
      } catch (ex) {
        log.error(ex)
      }
    })

    // Check if there is any content in focus
    emitter().addListener(TAG, "newNoteInFocus", data => {
      try {
        log.d(
          "newNoteInFocus event received. sceneGuid: " +
            data["sceneGuid"] +
            ", displayGuid: " +
            data["displayGuid"] +
            ", contentGuid: " +
            data["contentGuid"] +
            ", isOwnNote: " +
            data["isOwnNote"],
        )
        this.handleFocus && this.handleFocus(data)
      } catch (ex) {
        log.error(ex)
      }
    })

    // Check if there is no content in focus
    emitter().addListener(TAG, "noNoteInFocus", () => {
      try {
        log.d("noNoteInFocus event received.")
        this.handleNoFocus && this.handleNoFocus()
      } catch (ex) {
        log.error(ex)
      }
    })
    emitter().addListener(TAG, "qualityGood", data => {
      try {
        log.d("quality good")
        this.qualityGoodUpdate && this.qualityGoodUpdate(data)
      } catch (ex) {
        log.error(ex)
      }
    })
    emitter().addListener(TAG, "qualityBad", data => {
      try {
        log.d("quality bad")
        this.qualityBadUpdate && this.qualityBadUpdate(data)
      } catch (ex) {
        log.error(ex)
      }
    })
    emitter().addListener(TAG, "percentageEvent", data => {
      try {
        log.d("percentage received")
        this.percentageUpdate && this.percentageUpdate(data)
      } catch (ex) {
        log.error(ex)
      }
    })
    emitter().addListener(TAG, "connectionEvent", data => {
      try {
        log.d("connection received")
        this.handleConnection && this.handleConnection(data)
      } catch (ex) {
        log.error(ex)
      }
    })

    emitter().addListener(TAG, "receivedChatLocalMessage", data => {
      try {
        log.d("onReceivedChatLocalMessage received")
        this.handleReceivedChatLocalMessage &&
          this.handleReceivedChatLocalMessage(data)
      } catch (ex) {
        log.error(ex)
      }
    })

    // Check if there is any content in focus
    emitter().addListener(TAG, "sceneDownloaded", eventData => {
      try {
        log.d("sceneDownloaded: " + JSON.stringify(eventData))

        this.handleSceneDownloaded &&
          !eventData.error &&
          this.handleSceneDownloaded(eventData)
      } catch (ex) {
        log.error(ex)
      }
    })

    emitter().addListener(TAG, "noteClicked", data => {
      try {
        log.d("Note clicked")
        this.handleClickNote && this.handleClickNote(data)
      } catch (ex) {
        log.error(ex)
      }
    })

    emitter().addListener(TAG, "noNoteClicked", data => {
      try {
        log.d("No Note clicked")
        this.handleClickNoNote && this.handleClickNoNote("dismiss")
      } catch (ex) {
        log.error(ex)
      }
    })

    emitter().addListener(TAG, "sceneSelect", data => {
      try {
        log.d("sceneSelect " + data.guid)
        this.handleSceneSelect && this.handleSceneSelect(data)
      } catch (ex) {
        log.error(ex)
      }
    })

    emitter().addListener(TAG, "chosePicture", data => {
      try {
        log.d("Picture chosen")
        this.chosePicture && this.chosePicture(data)
      } catch (ex) {
        log.error(ex)
      }
    })

    emitter().addListener(TAG, "showOnboarding", data => {
      try {
        log.d("Show onboarding!!!!!: " + JSON.stringify(data))
        this.showOnboarding && this.showOnboarding(data)
      } catch (ex) {
        log.error(ex)
      }
    })

    emitter().addListener(TAG, "receivedSharedData", data => {
      try {
        log.d("receivedSharedData")
        if (this.receivedSharedData) {
          log.d("call receivedSharedData")
          this.receivedSharedData(data)
        }
      } catch (ex) {
        log.error(ex)
      }
    })

    emitter().addListener(TAG, "receivedNotificationIntent", data => {
      try {
        log.d("receivedNotificationIntent")
        if (this.receivedNotificationIntent) {
          log.d("call receivedNotificationIntent")
          this.receivedNotificationIntent(data)
        }
      } catch (ex) {
        log.error(ex)
      }
    })

    emitter().addListener(TAG, "windowResized", data => {
      try {
        log.d("windowResized")
        if (this.windowResized) {
          log.d("call windowResized")
          this.windowResized(data)
        }
      } catch (ex) {
        log.error(ex)
      }
    })

    emitter().addListener(TAG, "newModelAdded", eventData => {
      try {
        log.d("newRealnoteModelAdded")
        if (this.handleNewModelAdded) {
          this.handleNewModelAdded(eventData)
        }
      } catch (ex) {
        log.error(ex)
      }
    })

    emitter().addListener(TAG, "orientation", eventData => {
      try {
        log.d("orientation changed")
        if (this.orientationHandler) {
          this.orientationHandler(eventData)
        }
      } catch (ex) {
        log.error(ex)
      }
    })
  }

  //
  // Attributes
  //
  public longitude = 0
  public latitude = 0

  //
  // Events
  //

  public onGpsChanged: (data: Object) => void
  public qualityGoodUpdate: (data: Object) => void
  public qualityBadUpdate: (data: Object) => void
  public percentageUpdate: (data: Object) => void

  public handleFocus: (data: Object) => void
  public handleNoFocus: () => void
  public handleConnection: (data: Object) => void
  public orientationHandler: (data: Object) => void

  public handleReceivedChatLocalMessage: (data: Object) => void

  public handleSceneDownloaded: (guid: Object) => void
  public handleClickNote: (data: Object) => void
  public handleClickNoNote: (data: Object) => void

  public handleSceneSelect: (guid: String) => void
  public chosePicture: (data: Object) => void
  public showOnboarding: (data: Object) => void

  public receivedSharedData: (data: Object) => void
  public windowResized: (data: Object) => void
  public receivedNotificationIntent: (data: Object) => void

  public handleNewModelAdded: (data: Object) => void

  // /**
  //  * places a new note after having previewed it
  //  * final step when creating a new note/scene
  //  * @param data: data of new note
  //  */
  placeNewNote(data: Object): Promise<String> {
    log.d("placeNewNote is called")
    if (data != null) {
      return this.callWebApp("placeNewNote", [JSON.stringify(data)])
    } else {
      return new Promise(resolve => resolve(""))
    }
  }

  async pluginCommand(command: String, params: Object): Promise<String> {
    log.d("pluginCommand is called " + command + " " + JSON.stringify(params))

    return this.callWebApp("pluginCommand", [command, JSON.stringify(params)])
  }

  async noteEditorCanvas(
    command: String,
    params: Object = {},
  ): Promise<String> {
    return this.callWebApp("noteEditorCanvas", [
      command,
      JSON.stringify(params),
    ])
  }

  setWebPlaneURL(url: String) {
    this.callWebApp("setWebPlaneURL", [url])
  }

  // /**
  //  * previews a new note/scene
  //  * first step when creating a new scene
  //  * after having created the content
  //  * @param data
  //  */
  previewNewNote(data: Object): Promise<String> {
    if (data != null) {
      log.d("previewNewNote is called")

      return this.callWebApp("previewNewNote", [JSON.stringify(data)])
    } else {
      log.e("previewNewNote with no data!")
      return new Promise(resolve => resolve(""))
    }
  }

  // //todo realnotenative ts aufteilen in 3 module

  // /**
  //  * abort the placing of a new note/scene
  //  * gets rid of the corresponding content bitmap
  //  * and detections
  //  */
  abortNotePreview(): Promise<String> {
    log.d("previewNewNote is aborted")
    return this.callWebApp("abortNotePreview", [])
  }

  /**
   * deletes a scene
   * @param sceneGuid: guid of the scene
   */
  deleteScene(sceneGuid: String): Promise<boolean> {
    if (sceneGuid != null) {
      return new Promise<boolean>(resolve => {
        this.callWebApp("deleteScene", [sceneGuid])
          .then(sResult => {
            resolve(sResult)
            this.fetchString("note/deleteScene", {guid: sceneGuid}).then(
              res => {
                log.v("deleteScene on server res = " + res)
              },
            )
          })
          .catch(err => {
            log.e("deleteScene error: " + err)
            resolve(false)
          })
      })
    } else {
      log.e("deleteScene error scene or content guid is missing: " + sceneGuid)
      return Promise.resolve(false)
    }
  }

  /**
   *
   * @param contentGuid: ContentGuid
   * @param comment: The Comment
   *
   */
  async createComment(
    contentGuid: String,
    comment: String,
    contentOwnerId: String,
    commentGuid: String,
  ) {
    if (
      contentGuid !== null &&
      comment !== null &&
      contentOwnerId !== null &&
      commentGuid !== null
    ) {
      const userId = Account.userId
      var storeState = store.getState()
      var challengeId = storeState.challengeMode.challengeID
      var challengeActive = storeState.challengeMode.isActive
      if (challengeActive) {
        return this.fetchJson("note/createComment", {
          guid: commentGuid,
          contentGuid: contentGuid,
          userId: userId,
          comment: comment,
          contentOwnerId: contentOwnerId,
          currentChallengeGuid: challengeId,
        })
      } else {
        return this.fetchJson("note/createComment", {
          guid: commentGuid,
          contentGuid: contentGuid,
          userId: userId,
          comment: comment,
          contentOwnerId: contentOwnerId,
        })
      }
    }
  }

  sendTokenToBridge(token: string) {
    realnote.sendToken(token)
    this.callWebApp("sendToken", [token], true)
  }

  logAppsFlyerEvent(event: string) {
    // realnote.logAppsFlyerEvent(event);
  }

  sendStatusOfWelcomeNotesToWebApp(showWelcomeNotes: boolean) {
    this.callWebApp(
      "sendStatusOfWelcomeNotesToWebApp",
      [showWelcomeNotes],
      true,
    )
  }

  sendWelcomeReactEvent() {
    this.callWebApp("sendWelcomeReactEvent", [])
  }

  showConsecutiveDaysModel(numberOfDays: number) {
    this.callWebApp("showConsecutiveDaysModel", [numberOfDays])
  }

  setCoachingViewVisibility(visible: boolean) {
    realnote.coachingVisible(visible)
  }

  /**
   * saves upvoted state to scenes collection
   * @param sceneGuid: guid of scene the content belongs to
   * @param upvoted: boolean saying whether user has upvoted
   */
  saveUpvoteToLocalScene(sceneGuid: String, upvoted: boolean) {
    return this.callWebApp("saveUpvoteToLocalScene", [sceneGuid, upvoted])
  }

  getAdId(): Promise<string> {
    return new Promise<string>(resolve => {
      realnote
        .getAdId()
        .then((sResult: string) => {
          // Offiziell sind UUIDs keine Strings sondern Zahlen. Der RFC Standard definiert, dass
          // die Hexadezimalziffern a-f in Textform klein zu schreiben sind, daher wende ich toLowerCase() an,
          // da wir die ids als Strings und nicht als Zahlen verwalten und Apple sie mit Großbuchstaben
          // liefert.
          resolve(sResult.toLowerCase())
        })
        .catch((err: any) => {
          resolve("")
        })
    })
  }

  // /**
  //  * logs user in to RealNote
  //  * @param userId
  //  * @param username
  //  * @param password
  //  */
  logIn(userId: String, username: String) {
    if (!userId) userId = ""

    if (!username) {
      username = ""
    }

    this.callWebApp("login", [userId, username]).then(res => {
      log.v("RealnoteManagerWeb" + res)
    })
    realnote.login(userId, username)
  }

  callWebApp(
    functionsName: string,
    paramsArray: any,
    noErrorIfWeb1IsNotYetLoaded: Boolean = false,
  ): Promise<any> {
    var promise = new Promise<Object>((resolve, reject) => {
      const web1: any = (window as any).web1
      if (web1) {
        var m = (window as any).WebApp_RealnoteManger
        if (m === undefined) {
          console.error("webApp not yet loaded calling: " + functionsName)
          resolve("@error@")
          return
        }
        var f =
          web1.one.realnote.app.ReactNativeInterface.RealnoteManager[
            functionsName
          ]

        if (f === undefined) {
          log.e("undefined function: " + functionsName)
          resolve("@error@")
          return
        }

        var s1 = f.apply(m, paramsArray)

        if (s1) {
          var ss = s1.split("@P@:")
          if (ss.length == 2) {
            var id = parseInt(ss[1])
            var res = (window as any).promisesResults[id]
            if (res) {
              resolve(res)
            } else {
              const pendingResolves = (window as any).pendingResolves
              pendingResolves[id] = function (v) {
                resolve(v)
              }
            }
          } else {
            return s1
          }
        } else {
          return
        }
      } else {
        if (noErrorIfWeb1IsNotYetLoaded) {
          resolve(false)
        } else {
          reject("web1 not loaded calling: " + functionsName)
        }
      }
    })

    return promise
  }

  logOut(userId: String) {
    this.logout(userId)
  }

  logout(userId: String) {
    realnote.logout()
    return new Promise<Object>((resolve, reject) => {
      if (userId != "") {
        this.fetchJson("account/logout", {
          user: userId,
        })
          .then(result => {
            log.d("logOut result " + result)
            resolve(result)
          })
          .catch(error => {
            log.e("logOut error: " + error.toString())
          })
      } else {
        reject("empty userId")
      }
    })
  }

  // /**
  //  * Sets the flag "hasLoggedOnWithFullyRegisteredAccount" to true. This flag will then remain true until
  //  * the App is uninstalled and reinstalled.
  //  *
  //  * @param loggedOn only exists for debugging purposes and shouldn't be set manually except via toggle in Settings
  //  *
  //  * A fully registered Account has a username and a password and does not depend on the AdID
  //  */
  setHasLoggedOnWithFullyRegisteredAccount(loggedOn: Boolean = true) {
    realnote.setHasLoggedOn(loggedOn)
  }

  // /**
  //  * registers a new user
  //  * @param username: username the new user has chosen
  //  * @param email: email of user
  //  * @param password: chosen password of user
  //  */
  async signUp(username: String, email: String, password: String) {
    if (username != null && email != null && password != null) {
      await http.getServerEndpoint()
      return http.fetchJSONWithoutReject("account/registerLocal", {
        username: username,
        email: email,
        password: password,
      })
    }
  }

  // /**
  //  * requests a token to set up a new password
  //  * for users who have forgotten their password
  //  * @param email: email of user (the email they provided upon registering)
  //  */
  forgot(email: String): Promise<Object | null> {
    if (email != null) {
      return this.fetchJson("account/forgot", {
        email: email,
      })
    }
  }

  // /**
  //  * @deprecated Use the react http Bridge.
  //  * resets the password to the chosen new one for a user
  //  * if the correct password reset token is provided
  //  * @param userId: userId of user who wants to reset his/her password
  //  * @param token: password reset token
  //  * @param password: new password of user
  //  * @param email: email address of user
  //  * @param username: username of user
  //  */
  reset(
    userId: String,
    token: String,
    password: String,
    email: String,
    username: String,
  ): Promise<Object | null> {
    return this.fetchJson("account/reset", {
      userId: userId,
      token: token,
      password: password,
      email: email,
      username: username,
    })
  }

  deleteAccount(password: String, adIdToDelete: String) {
    const pw = password || "realnote123"
    let adId = adIdToDelete ? adIdToDelete.toString() : null
    let userId = adId || Account.userId

    log.v("deleteAccount with password: " + pw)

    let username
    if (
      !http.hasUserRegistered(Account.userId) ||
      !Account.username ||
      adId !== null
    ) {
      username = adId || Account.userId
    } else {
      username = Account.username.startsWith("@realnoteuser")
        ? Account.userId.trim()
        : Account.username
    }

    return this.fetchJson("account/deleteAccount", {
      userId: userId.trim(),
      username: username.trim().toLowerCase(),
      password: pw,
    })
  }

  /**
   * start a server call to fetch a string
   * @param endpoint: server api the request is directed to
   * @param params: parameters sent with the request
   */
  fetchString(endpoint: string, params: Object): Promise<string> {
    if (endpoint != null && params != null) {
      return http.fetchString(endpoint, params)
    } else {
      return new Promise(resolve => resolve(""))
    }
  }

  /**
   * start a server call to fetch a (JSON) string, then return it as
   * an object
   * @param endpoint
   * @param params
   */
  fetchJson(endpoint: string, params: Object): Promise<Object | null> {
    let value = http.fetchJSONRejectable(endpoint, params).then(
      (resolve: JSON) => {
        return resolve
      },
      (reject: any) => {
        return null
      },
    )
    return value
  }

  // /**
  //  * saves favorites (a sorted list of templates) as new favored templates
  //  * @param favorites
  //  */
  setFavouredContentTemplates(
    favorites: String[],
    listType: String,
  ): Promise<void> {
    if (favorites != null) {
      const s = JSON.stringify(favorites)
      return this.callWebApp("setFavouredContentTemplates", [s, listType])
    }
  }

  // /**
  //  * gets a list of templates with a sorting according to user favoring
  //  */
  getFavouredContentTemplates(listType: String): Promise<object[]> {
    log.v("getFavouredContentTemplates")
    return new Promise<Object[]>(resolve => {
      this.callWebApp("getFavouredContentTemplates", [
        Account.userId,
        listType,
      ]).then(res => {
        log.v("Web App Return FavouredContentTemplates ") // + res)
        const templatesJSON = JSON.parse(res)
        resolve(templatesJSON)
      })
    })
  }

  // getContentTemplatePreviewImageAsDataUrl(guid: String, tiny: Boolean): Promise<String> {
  //   return new Promise<String>((resolve) => {
  //     this.callWebApp("getContentTemplatePreviewImageAsDataUrl", [guid, tiny]).then((res) => {
  //       resolve(res)
  //     })
  //   })
  // }

  setNoteEditorOpen(isOpen: Boolean) {
    log.v("setNoteEditorOpen to " + isOpen)
    this.callWebApp("setNoteEditorOpen", [isOpen])
  }

  /**
   * getUserScenes
   * gets complete list of user scenes
   *
   * @param userId: userId of user whose contents are requested
   * @param skip: how many items to skip when getting scenes
   * @param limit: number of items to get (at most)
   */
  getUserScenes(
    userId: String,
    skip: Number,
    limit: Number,
  ): Promise<object[]> {
    try {
      if (skip == null) {
        skip = 0
      }
      if (limit == null) {
        limit = 10
      }
      return new Promise<Object[]>(resolve => {
        log.v("getUserScenes will call webApp getUserScenesFromCache")
        this.callWebApp("getUserScenes", [userId, skip, limit])
          .then(sResult => {
            const result = JSON.parse(sResult)
            log.d("getUserScenes received user scenes: " + sResult)
            resolve(result)
          })
          .catch(err => {
            log.e("getUserScenes error: " + err)
            resolve(null)
          })
      })
    } catch (e) {
      log.e("getUserScenes error: " + e)
    }
  }

  // /**
  //  * getNoteAddress
  //  *
  //  * @param lat
  //  * @param lng
  //  */
  getAddress(lat: number, lng: number): Promise<String> {
    if (lat != null && lng != null) {
      return new Promise<String>(resolve => {
        realnote.getAddress(lat, lng).then(res => {
          log.d("Address answer: " + res)
          resolve(res)
        })
      })
    } else {
      return new Promise<String>(resolve => {
        resolve("")
      })
    }
  }

  setLookAroundNotShown() {
    realnote.setLookAroundNotShown()
  }

  getLastKnownLocation() {
    return new Promise(resolve => {
      const location = (window as any).lastLocation
      resolve(location)
    })
  }

  /**
   * gets note data for a certain scene
   * i. e. gets info about scene
   * @param guid: guid of scene
   */
  getNoteDataForGuid(userId, guid: any): Promise<Object[] | null> {
    if (guid == null) {
      guid = ""
    }
    return new Promise<Object[] | null>(async resolve => {
      var url = `note/getSingleNoteForMap/${guid}/${userId}`
      var serverUrl = await this.getServerUrl()
      // get request with fetch
      fetch(serverUrl + url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.token,
        },
      }).then(response => {
        if (response.status == 200) {
          response.json().then(data => {
            resolve(data)
          })
        } else {
          resolve(null)
        }
      })
    })
  }

  setSharedPrefString(key: any, val: any): Promise<void> {
    return realnote.setSharedPrefString(key, val)
  }

  getSharedPrefString(key: string): Promise<String> {
    return new Promise<String>(resolve => {
      realnote
        .getSharedPrefString(key)
        .then((res: string | String | PromiseLike<String> | undefined) => {
          resolve(res)
        })
    })
  }

  setSharedPrefBoolean(key: any, val: any): Promise<void> {
    if (key != null && val != null) {
      return realnote.setSharedPrefBoolean(key, val)
    }
  }

  getSharedPrefBoolean(
    key: string,
  ): Promise<string | Boolean | PromiseLike<Boolean>> {
    if (key == null) {
      key = ""
    }
    return new Promise<string | Boolean | PromiseLike<Boolean>>(resolve => {
      realnote
        .getSharedPrefBoolean(key)
        .then((res: string | Boolean | PromiseLike<Boolean>) => {
          resolve(res)
        })
    })
  }

  setSharedPrefInt(key: any, val: any): Promise<void> {
    if (key != null && val != null) {
      return realnote.setSharedPrefInt(key, val)
    }
  }

  getSharedPrefInt(key: String): Promise<number> {
    return new Promise<number>(resolve => {
      realnote
        .getSharedPrefInt(key)
        .then((res: number | PromiseLike<number> | undefined) => {
          resolve(res)
        })
    })
  }

  setSharedPrefLong(key: any, val: any): Promise<void> {
    if (key != null && val != null) {
      return realnote.setSharedPrefLong(key, val)
    }
  }

  getSharedPrefLong(key: String): Promise<number> {
    return new Promise<number>(resolve => {
      realnote
        .getSharedPrefLong(key)
        .then((res: number | PromiseLike<number> | undefined) => {
          resolve(res)
        })
    })
  }

  /**
   * @returns a number that uniquely identifies each app version.
   */
  async getAppVersion(): Promise<number> {
    try {
      const versionString = await realnote.getAppVersion()
      const partialVersionStrings = versionString.split(".", 2)
      let exponent = 100
      let versionNumber = 0
      for (let i = 0; i < partialVersionStrings.length; i++) {
        versionNumber += parseInt(partialVersionStrings[i]) * exponent
        log.v("versionNumber:" + versionNumber)
        exponent /= 100
      }
      return versionNumber
    } catch (ex) {
      log.error(ex)
      return 0
    }
  }

  // setDeveloperOptions(options: Object) {
  //   realnote.setDeveloperOptions(options)
  // }

  // getDeveloperOptions() {
  //   return realnote.getDeveloperOptions()
  // }

  async hasShownTooltips(): Promise<string | Boolean | PromiseLike<Boolean>> {
    return true
    // this.getSharedPrefBoolean(
    //   "one.realnote.app.tooltipsAlreadyShown");
  }

  /**
   * set: note is to be analyzed
   * for developers
   * @param guidScene
   */
  setAnalyzeNote(guidScene: String) {
    realnote.setAnalyzeNote(guidScene)
  }

  //
  // endregion
  //

  /**
   * gets profile information of a user
   * @param userId
   * @param usernameAndPoints: only need the username and number of reward points of user
   * @param usernameAndFollowers: only need the username and number of followers of user
   * @param notesFollowerFollowees: only need the number of notes, followers and followees of user
   * @param username: only need the user's username
   * @param appVersion: also needs the app version which was used by the user to login the last time
   * @returns a JSON object with keys: username, numOfPostedNotes, totalRating and numOfFollowers.
   */
  getProfilePreview(
    userId: String,
    usernameAndPoints = false,
    usernameAndFollowers = false,
    notesFollowerFollowees = false,
    username = false,
    appVersion = false,
  ) {
    return this.fetchJson("note/getProfilePreview", {
      userId: userId,
      onlyUsernamePoints: usernameAndPoints.toString(),
      onlyUsernameFollowers: usernameAndFollowers.toString(),
      onlyNotesFollowerFollowees: notesFollowerFollowees.toString(),
      onlyUsername: username.toString(),
      appVersion: appVersion.toString(),
    })
  }

  /**
   * get detailed info about user reward points,
   * i. e. how many of each reward point relevant action have led to how many points
   * @param userId
   */
  async getUserRewards(userId: String) {
    return this.fetchJson("note/usersRewardInfo", {userId: userId})
  }

  /**
   * get info about affiliated users
   * i. e. the users that were invited and how much points this user got from them
   * @param userId
   */
  async getAffiliatedUsers(userId: String): Promise<Object[] | null> {
    return new Promise<Object[] | null>(async resolve => {
      var url = `note/affiliatedUsers/${userId}`
      var serverUrl = await this.getServerUrl()
      // get request with fetch
      fetch(serverUrl + url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.token,
        },
      }).then(response => {
        if (response.status == 200) {
          response.json().then(data => {
            resolve(data)
          })
        } else {
          resolve(null)
        }
      })
    })
  }

  /**
   * download a scene
   * @param guid: scene guid
   */
  downloadScene(guid: String, forceUpdate: String): Promise<String> {
    if (guid == null) {
      guid = ""
    }
    return new Promise<String>(resolve => {
      this.callWebApp("downloadScene", [guid, forceUpdate])
        .then((sResult: String | PromiseLike<String> | undefined) => {
          resolve(sResult)
        })
        .catch((err: String) => {
          log.e("downloadScene failed with error: " + err)
          resolve("")
        })
    })
  }

  // /**
  //  * set AR_VIEW_VISIBLE to true,
  //  * start detection
  //  */
  startDetection() {
    return new Promise(resolve => {
      log.v("starting Detection")
      this.freezeLocationLatitude = ""
      this.freezeLocationLongitude = ""
      realnote.startDetection()
      this.callWebApp("startDetection", [])
      resolve(true)
    })
  }

  // /**
  //  * Sets the state of the object detection to the webapp, which sets it in the native
  //  * part
  //  *
  //  * @param processingMode 0 = inactive, 1 = createNew, 2 = detect and match
  //  */
  setProcessingMode(processingMode) {
    return new Promise(resolve => {
      log.v("setProcessingMode to " + processingMode)
      this.callWebApp("setProcessingMode", [processingMode])
      resolve(true)
    })
  }

  // canShowOnboarding() {
  //   return realnote.showOnboarding()
  // }

  // /**
  //  * set AR_VIEW_VISIBLE to false,
  //  * stop detection
  //  */
  stopDetection() {
    return new Promise(resolve => {
      log.v("stopping Detection")
      realnote.stopDetection()
      this.callWebApp("stopDetection", [])
      resolve(true)
    })
  }

  /**
   * set AR_VIEW_VISIBLE to false,
   * stop detection but continues updating camera position to ensure correct rendering
   */
  stopDetectionButContinueUpdatingCameraPosition() {
    return new Promise(resolve => {
      log.v("stopping Detection")
      realnote.stopDetectionButContinueUpdatingCameraPosition()
      this.callWebApp("stopDetection", [])
      resolve(true)
    })
  }

  /**
   * set AR_VIEW_VISIBLE to false,
   * stop detection but continues updating camera position to ensure correct rendering
   */
  stopUpdatingCameraPosition() {
    return new Promise(resolve => {
      log.v("stopping Detection")
      realnote.stopUpdatingCameraPosition()
      resolve(true)
    })
  }

  // /**
  //  * show openGL renderings
  //  */
  startCamera() {
    log.v("start camera called")
    this.callWebApp("startArMode", [])
    ArMode.startAr()
    realnote.startCamera()
  }

  stopCamera() {
    log.d("stop camera called")
    this.callWebApp("stopArMode", [])
    // commented out stopAr as cheapest method to not stop AR when leaving LiveScreen, since it lead to resizing and rerendering of the screen
    // ArMode.stopAr()
    realnote.stopCamera()
  }

  pauseAR() {
    log.d("pauseAR called")
    this.callWebApp("pauseArMode", [])
    ArMode.pauseAr()
  }

  resumeAR() {
    log.d("resumeAR called")
    this.callWebApp("resumeArMode", [])
    ArMode.resumeAR()
  }

  hideUi() {
    return realnote.hideUi()
  }

  showUi() {
    return realnote.showUi()
  }

  getPpmItems() {
    log.d("getPpmItems")
    return new Promise(resolve => {
      realnote
        .getPpmItems()
        .then((sResult: string) => {
          var result = JSON.parse(sResult)
          this.callWebApp("getPpmItems", []).then(sResult1b => {
            try {
              var sResult1 = atob(sResult1b)
              var result1 = JSON.parse(sResult1)
              result1 = result1.concat(result)
              resolve(result1)
            } catch (ex) {
              log.error(ex)
              resolve(result)
            }
          })
        })
        .catch((err: any) => {
          resolve(null)
        })
    })
  }

  /**
   * opens share menu for a photo
   */
  openShareMenuWithDataUrl(
    dataUrl: string,
    appToShareTo: string,
  ): Promise<String> {
    return new Promise<String>(resolve => {
      realnote
        .openShareMenu(dataUrl, appToShareTo)
        .then(sResult => {
          resolve(sResult)
        })
        .catch(err => {
          log.e(err)
          resolve("")
        })
    })
  }

  /**
   * opens share menu for a url
   */
  openShareMenuWithUrlSpecificApp(
    url: string,
    appToShareTo: string,
  ): Promise<String> {
    return new Promise<String>(resolve => {
      realnote
        .openShareMenuUrlSpecificApp(url, appToShareTo)
        .then(sResult => {
          resolve(sResult)
        })
        .catch(err => {
          log.e(err)
          resolve("")
        })
    })
  }

  /**
   * opens share menu for a video
   * @param uri of video
   */
  openVideoShareMenu(uri: String, networkToShareTo: string) {
    if (uri == null) {
      uri = ""
    }

    return new Promise<String>(resolve => {
      realnote
        .openVideoShareMenu(uri, networkToShareTo)
        .then(sResult => {
          resolve(sResult)
        })
        .catch(err => {
          log.e(err)
          resolve("")
        })
    })
  }

  // /**
  //  * @param type
  //  * @param skip
  //  */
  getAssetsData(type, skip): Promise<JSON> {
    if (type != null) {
      return new Promise<JSON>(resolve => {
        http
          .getAssetsData(type, skip)
          .then(result => {
            resolve(result)
          })
          .catch(e => {
            log.e("" + e)
            resolve(null)
          })
      })
    } else {
      return new Promise<JSON>(resolve => resolve(null))
    }
  }

  // /**
  //  * @param type
  //  * @param skip
  //  */

  getMoreModelData(type, skip): Promise<JSON> {
    if (type != null) {
      return new Promise<JSON>(resolve => {
        http
          .getMoreGroupModels(type, skip)
          .then(result => {
            resolve(result)
          })
          .catch(e => {
            log.e("" + e)
            resolve(null)
          })
      })
    } else {
      return new Promise<JSON>(resolve => resolve(null))
    }
  }

  getAsset(type: String, name: String, guid: String): Promise<String> {
    if (type != null && name != null && guid != null) {
      return new Promise<String>(resolve => {
        log.d("getAsset is called. name: " + name)
        if (
          name.endsWith(".svg") ||
          name.endsWith(".png") ||
          name.endsWith(".webp")
        ) {
          this.callWebApp("getSvgSticker", [type, name, guid])
            .then(result => {
              resolve(result)
            })
            .catch(e => {
              log.e("" + e)
              resolve(null)
            })
        } else if (name.endsWith(".zip")) {
          this.callWebApp("getModelFile", [type, name, guid])
            .then(result => {
              resolve(result)
            })
            .catch(e => {
              log.e("" + e)
              resolve(null)
            })
        }
        // else {
        //   // this.getFavouredContentTemplates().then((items: Object[]) => { resolve(JSON.stringify(items[0])) });
        // }
      })
    } else {
      return new Promise<String>(resolve => resolve(""))
    }
  }

  getAssetsLink(): Promise<String> {
    return http.getAssetsLink()
  }

  checkQueueShareIntent(): void {
    realnote.checkQueueShareIntent()
  }

  checkNotificationIntent(): void {
    realnote.checkNotificationIntent()
  }

  saveImageToGallery(uriString: String) {
    if (uriString != null) {
      realnote.saveImageToGallery(uriString)
    }
  }

  letReactHandleUi(reactNativeOnly: boolean) {
    return new Promise<boolean>(resolve => {
      if (reactNativeOnly != null) {
        realnote
          .letReactHandleUi(reactNativeOnly)
          .then((sResult: boolean) => {
            resolve(true)
          })
          .catch((err: string) => {
            log.e("hideScreenshotMask error: " + err)
            resolve(false)
          })
      } else {
        resolve(false)
      }
    })
  }

  sendFirebaseEvent(name: String, params: any) {
    if (name != null) {
      realnote.sendFirebaseEvent(name, params)
    }
  }

  sendFirebaseClick(name: String) {
    if (name != null) {
      realnote.sendFirebaseEvent("Click_" + name)
    }
  }

  startFirebaseTimingEvent(name: String) {
    if (name != null) {
      realnote.startFirebaseTimingEvent(name)
    }
  }

  stopFirebaseTimingEvent(name: String) {
    if (name != null) {
      realnote.stopFirebaseTimingEvent(name)
    }
  }

  async getServerUrl(): Promise<String> {
    return global.baseUrl
  }

  deselectAllModels() {
    return new Promise<Object>(resolve => {
      this.callWebApp("deselectAllModels", [])
        .then(res => {
          log.v("deselectAllModels = " + res)
          resolve(true)
        })
        .catch(e => {
          log.e("deselectAllModels error: " + e)
          resolve(false)
        })
    })
  }

  restartRendingAllScenes() {
    return this.callWebApp("restartRendingAllScenes", [])
  }

  async getDims(): Promise<Object> {
    log.d("jj1 inRNBridge")
    try {
      if (this.ScreenDims === null) {
        log.d("getting new screenDims")
        return this.getDimsSync()
      } else {
        return this.ScreenDims
      }
    } catch (ex) {
      log.error(ex)
    }
  }

  getDimsSync(): Object {
    const widthRestricted = Math.min(1000, window.innerWidth)
    this.ScreenDims = {
      reservedLeft: 0,
      reservedTop: 0,
      reservedRight: 0,
      reservedBottom: 0,
      height: window.innerHeight,
      width: window.innerWidth,
      hasNotch: false,
      notchShape: {
        w: 0,
        l: 0,
        h: 0,
      },
      widthRestricted: widthRestricted,
    }
    return this.ScreenDims
  }

  getSceneIsSculpture(sceneGuid: String): Promise<Boolean> {
    return new Promise<Boolean>(resolve => {
      this.callWebApp("getSceneIsSculpture", [sceneGuid])
        .then(res => {
          log.v("getSceneIsSculpture res = " + res)
          resolve(res == "true")
        })
        .catch(e => {
          log.e("getSceneIsSculpture error: " + e)
          resolve(false)
        })
    })
  }

  sendDeviceToken(userId: String, sessionId: String = null) {
    var uid = userId || Account.userId
    var sid = sessionId || Account.sessionId
    if (uid === null || uid === undefined) {
      uid = ""
    }
    if (sid === null || sid === undefined) {
      sid = ""
    }
    return realnote.sendDeviceToken(uid, sid)
  }

  getBlockedUsers(skip: Number, limit: Number) {
    skip = skip || 0
    limit = limit
    return new Promise<Object>((resolve, reject) => {
      this.fetchJson("note/getBlockedUsers", {
        userId: Account.userId,
        limit: limit,
        skip: skip,
      }).then(result => {
        resolve(result)
      })
    })
  }

  setBlockedUsers(userIds: Array<String>) {
    log.v("setBlockedUsers called with " + userIds.length + " userIds")
    return this.callWebApp("setBlockedUsers", [JSON.stringify(userIds)])
  }

  exportScene(sceneGuid: String): Promise<String> {
    log.v("exportScene called with sceneGuid: " + sceneGuid)
    return new Promise<String>(resolve => {
      this.callWebApp("exportScene", [sceneGuid]).then(res => {
        log.v("shareLink res = " + res)
        resolve(res)
      })
    })
  }

  shareModelFromNoteEditor(
    isGpsNote: Boolean,
    imageBlob: Blob,
  ): Promise<String> {
    log.v("exportScene called with sceneGuid: ")
    return new Promise<String>(resolve => {
      this.callWebApp("shareModelFromNoteEditor", [isGpsNote, imageBlob]).then(
        res => {
          log.v("shareLink res = " + res)
          resolve(res)
        },
      )
    })
  }

  uploadModelAsTemplate(
    isGpsNote: Boolean,
    authorName: String,
    imageData: String,
  ) {
    return new Promise<String>(resolve => {
      this.callWebApp("uploadModelAsTemplate", [
        isGpsNote,
        authorName,
        imageData,
      ]).then(res => {
        log.v("uploadModelAsTemplate res = " + res)
        resolve(res)
      })
    })
  }

  async uploadTemplateFromFile(
    modelData: Blob,
    imageData: Blob,
    isGpsNote: Boolean,
  ) {
    var JSZip = require("jszip")
    var zip = new JSZip()
    const guid = uuidv4()
    const modelJson = {}
    modelJson["scale"] = 1.0
    const authorName = Account.username
    const modelPreviewJson = {}
    const description = {}
    description["de"] = guid
    description["en"] = guid
    modelPreviewJson["name"] = guid
    modelPreviewJson["description"] = description
    modelPreviewJson["isGpsNote"] = isGpsNote
    modelPreviewJson["authorName"] = authorName

    const modelJsonString = modelJson.toString()
    const modelPreviewJsonString = modelPreviewJson.toString()
    const assetFilename = "model.glb"
    zip.file(assetFilename, modelData)
    zip.file("model.json", modelJsonString)
    zip.file("modelPreview.json", modelPreviewJsonString)
    zip.file("modelPreview.png", imageData)
    return new Promise<String>(resolve => {
      zip.generateAsync({type: "blob"}).then(async function (blob) {
        let formData = new FormData()
        const assetFilename = `${guid}_asset.zip`
        const preViewFilename = `${guid}_preview_asset.png`
        formData.append("Default", "false")
        formData.append("Public", "true")
        formData.append("Starter", "false")
        formData.append("Collectible", "true")
        formData.append("Asset", blob, assetFilename)
        formData.append("Preview", imageData, preViewFilename)
        formData.append("Guid", guid)
        formData.append("Type", "model")
        formData.append("Group", "userTemplates")
        formData.append("userId", Account.userId)
        const res = await http.fetchString(
          "/note/uploadUserAssets",
          {},
          false,
          formData,
        )
        if (res == "" || !res || res == "[]") {
          return resolve("error")
        }
        resolve(res)
      })
    })
  }

  startChallengeMode(challengeID: String) {
    return this.callWebApp("startChallengeMode", [challengeID])
  }

  stopChallengeMode() {
    return this.callWebApp("stopChallengeMode", [])
  }

  joinChallenge(challengeId: String) {
    store
      .dispatch(
        challengeFeedsApi.endpoints.joinChallenge.initiate({challengeId}),
      )
      .unwrap()
      .then(
        (res: any) => {
          if (res.geoLocation.coordinates) {
            ;(window as any).lastLocation = {
              latitude: res.geoLocation.coordinates[1],
              longitude: res.geoLocation.coordinates[0],
            }
          }
        },
        err => {
          log.e("joinChallenge err: " + err)
        },
      )
  }

  joinChallengeAndEnterChallengeMode(challengeData) {
    const challengeId = challengeData.challengeId || challengeData.challengeGuid
    this.joinChallenge(challengeId)
    this.startChallengeMode(challengeId)
    this.sendFirebaseClick("join_challenge_and_enter_challengemode")
    if (challengeData.config && challengeData.config.usersCanPlaceNotes) {
      store.dispatch(
        enterChallenge({
          challengeId,
          challengeURL: challengeData.website,
          usersCanPlaceNotes: true,
          challengeTitle: challengeData.title,
          backgroundColor: challengeData.backgroundColor,
          shortTitle: challengeData.shortTitle,
          distance: challengeData.dist,
          imageUrl: challengeData.imageUrl,
          participants: challengeData.participants,
          description: challengeData.description,
          geoLocation: challengeData.geoLocation
        }),
      )
    } else {
      store.dispatch(
        enterChallenge({
          challengeId,
          challengeURL: challengeData.website,
          usersCanPlaceNotes: false,
          challengeTitle: challengeData.title,
          backgroundColor: challengeData.backgroundColor,
          shortTitle: challengeData.shortTitle,
          imageUrl: challengeData.imageUrl,
          participants: challengeData.participants,
          description: challengeData.description,
          distance: challengeData.dist,
          geoLocation: challengeData.geoLocation
        }),
      )
    }
  }

  async createDeepLink(
    pageAndParams: String,
    callingPage: String = "",
    challengeId: String = "",
  ) {
    try {
      const apiKey = "AIzaSyD8KDHMlLSPvtjTT1GQYtYKYdgctE8cAx8"
      var createDeepLinkUrl =
        "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=" +
        apiKey

      const data = {
        dynamicLinkInfo: {
          domainUriPrefix: "https://realnote.page.link",
          link: "https://www.realnote.one/" + pageAndParams,
          androidInfo: {
            androidPackageName: "one.realnote.app",
          },
          iosInfo: {
            iosBundleId: "com.real-note.Realnote",
            iosAppStoreId: "1555250355",
          },
          analyticsInfo: {
            googlePlayAnalytics: {
              utmSource: callingPage,
              utmCampaign: challengeId,
            },
          },
        },
      }

      // iosBundleId: "com.real-note.Realnote",
      //       appStoreID: "1555250355"
      const response = await fetch(createDeepLinkUrl, {
        method: "POST",
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })

      const json = await response.json()
      const url = json.shortLink

      return url
    } catch (ex) {
      return null
    }
  }

  uploadSceneTexture(sceneGuid: String) {
    return this.callWebApp("uploadSceneTexture", [sceneGuid])
  }

  async startUp() {
    //preload ScreenDims
    log.d("startup called")
    this.getDims()
  }

  forceRenderAsNearScene(guid: String) {
    this.callWebApp("forceRenderAsNearScene", [guid])
  }

  // in der WebApp Umgebung brauchen wir diese Funktionen zur Zeit nicht
  openChallengeSite() {}

  closeChallengeSite() {}

  pauseShareNotificationTimer() {}

  askForArPermissionNeeded(): Promise<String> {
    return this.callWebApp("askForArPermissionNeeded", [])
  }
}

var _realnote = new Realnote()
export default _realnote

import React from "react"
import Svgs from "./Svgs"
import SvgIcon from "./SvgIcon"
import {ErrorBoundary} from "react-error-boundary"

const Icon = props => {
  const errorHandler = (error, info) => {
    console.log(
      "Icon errorHandler (" + props.name + "): " + error.message + " " + info,
    )
  }

  function ErrorFallback({error, resetErrorBoundary}) {
    console.log("Icon ErrorFallback (" + props.name + "): " + error.message)

    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
      </div>
    )
  }

  return (
    <ErrorBoundary
      onError={errorHandler}
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}>
      <SvgIcon {...props} svgs={Svgs} />
    </ErrorBoundary>
  )
}

export default Icon

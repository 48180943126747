import React, {PureComponent} from 'react';
import RealnoteLogger from "../../bridges/RealnoteLogger";
import AppColors from "../../styles/AppColors";
import {Text, TouchableOpacity, View} from "react-native";
import Icon from "../RealnoteIcon/Icon";
import AppStyles from "../../styles/AppStyles";
import {HttpBridge} from "../../http/HttpBridge";
import StyleConstants from '../../styles/StyleConstants';

const http = new HttpBridge();
const log = new RealnoteLogger('RewardPointsChip');
const pictureHeight = StyleConstants.profileHeaderImageSize > 74 ? StyleConstants.profileHeaderImageSize : 74

export default class RewardPointsChip extends PureComponent {

  constructor(props) {
    super(props);
    try {
      this.state = {
        rewardPoints: this.props.rewardPoints,
      };
    } catch (e) {
      log.e("constructor error: " + e);
    }
  }

  componentDidMount() {
    try {
      if (!this.state.rewardPoints) {
        this.getRewardPoints();
      }
    } catch (e) {
      log.e("componentDidMount error: " + e);
    }
  }

  getRewardPoints = async () => {
    try {
      log.v("getRewardPoints");
      if (this.props.userId) {
        log.v("getRewardPoints getting data");
        const data = await http.getProfilePreview(this.props.userId,
            true,
            false,
            false,
            false,
        );
        log.v("getRewardPoints got data = " + JSON.stringify(data));
        if (data != null) {
          const rewardPoints = data.numOfRewardPoints || null;
          this.setState({
            rewardPoints,
          });
        }
      }
    } catch (e) {
      log.e(`getProfileData error: ${e}`);
    }
  };

  render() {
    try {
      if (this.props.hidden) {
        return null;
      }
      if(!this.state.rewardPoints){
        return null;
      }
      return (
          <TouchableOpacity
              onPress={this.onPressPoints}
              style={{
                flexDirection: 'row',
                position: 'absolute',
                bottom: 0,
                right: -30,
                borderRadius: 20,
                borderWidth: 3,
                borderColor: AppColors.panelBorder,
                backgroundColor: AppColors.screenBackground,
                height: pictureHeight * 0.3,
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 3,
              }}
          disabled={!this.props.isOwn}
          >
            <View style={{
              paddingHorizontal: 10
            }}>
              <Icon
                  name={"Star"}
                  height={pictureHeight * 0.2}
                  width={pictureHeight * 0.2}
                  fill={AppColors.highlight2}
              />
            </View>
            <View style={{
              paddingRight: 10,
            }}>
              <Text
                  styles={AppStyles.largeText}>
                {this.state.rewardPoints || this.props.rewardPoints || 0}
              </Text>
            </View>
          </TouchableOpacity>
      );
    } catch (e) {
      log.e("render error: " + e);
    }
  }

  onPressPoints = () => {
    try {
      if (this.props.navigation) {
        this.props.navigation.navigate("Rewards", {
          ownRewardInfo: this.props.isOwn,
          profileImageSet: this.props.profileImageSet,
        })
      }
      if (this.props.onPress) {
        this.props.onPress();
      }
    } catch (e) {
      log.e(`onPressPoints error: ${e}`);
    }
  };
}

import React from "react"
import AwesomeAlert from "../alerts"
import AppColors from "../../styles/AppColors"
import {strings} from "../../i18n"
import {useBlockUserMutation} from "../../store/reducers/scrollingNoteSlice"

export default function BlockUserAlert(props) {
  const {onCancel, onBlockDone, blockedUserId, show} = props
  const [blockUser] = useBlockUserMutation()

  async function onBlock(userId) {
    blockUser({userId})
      .unwrap()
      .then(payload => onBlockDone(userId))
      .catch(error => console.error("rejected", error))
  }

  function onConfirmPressed() {
    onBlock(blockedUserId)
  }

  return show ? (
    <AwesomeAlert
      nativeID={"ArTouchable-4"}
      show={true}
      showProgress={false}
      contentContainerStyle={{backgroundColor: AppColors.backgroundGray}}
      overlayStyle={{backgroundColor: "#00000000"}}
      title={strings("blockUser.blockUser")}
      message={strings("blockUser.reallyBlockUser")}
      closeOnTouchOutside={false}
      closeOnHardwareBackPress={false}
      showCancelButton={true}
      showConfirmButton={true}
      cancelText={strings("Account.abortCap")}
      confirmText={strings("ok")}
      confirmButtonColor="#DD6B55"
      onCancelPressed={onCancel}
      onConfirmPressed={onConfirmPressed}
    />
  ) : (
    <></>
  )
}

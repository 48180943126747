import * as Sentry from "@sentry/react";

function captureException(err) {
  Sentry.captureException(err);
}

function captureMessage(message) {
  Sentry.captureMessage(message);
}

export {
  captureException,
  captureMessage,
}

import React, {useState, useEffect, useRef} from "react"
import {
  Text,
  View,
} from "react-native"
import {strings} from "../../i18n"
import AppColors from "../../styles/AppColors"
import AppStyles, {xAppStyles} from "../../styles/AppStyles"
import AppConstants from "../../styles/AppConstants"
import xstyles from "./styles"

var cardColorIndex = 0

function resetCardColorIndex() {
  cardColorIndex = 0
}

const cardColors = [
  AppColors.secondary1_Light,
  AppColors.secondary2_Light,
  AppColors.complementary_Light,
]

function nextCardColor() {
  var res = cardColors[cardColorIndex]
  cardColorIndex = (cardColorIndex + 1) % cardColors.length
  return res
}

function CardActionButton({children, onPress, style, disabled, text}) {
  const buttonRef = useRef(null)
  return (
    <button
      ref={buttonRef}
      pointerEvents={disabled ? "none" : "auto"}
      disabled={disabled}
      style={{borderWidth: 0, ...xstyles.cardActionButton, ...style}}
      onClick={event => {
        buttonRef.current.style.backgroundColor = "orange"
        buttonRef.current.style.boxShadow = "2px 2px 2px #ffffff"
        buttonRef.current.style.transform = "translate(2px, 2px)"

        onPress(event)
      }}>
      {children}
      <Text>{text}</Text>
    </button>
  )
}

export default function Card({
  title,
  children,
  button,
  waitForWeb1,
  style,
  imageLeft,
}) {

  const [color, setColor] = useState(nextCardColor())
  var pressDone = false

  const onPress = button?.onPress
  let onTouchCard = event => {
    if (pressDone) {
      return
    }
    pressDone = true
    event.stopPropagation()
    onPress()
  }

  let buttonText = null
  if (button) {
    buttonText = button.text
  }

  if (button?.disabled) {
    onTouchCard = null
  }

  if (button && buttonText) {
    button = (
      <CardActionButton
        disabled={button.disabled}
        style={{borderColor: color, backgroundColor: color}}
        onPress={onTouchCard}
        text={buttonText}></CardActionButton>
    )
  }

  let layout = (
    <div style={{minHeight: 0, height: "100%"}}>
      {title && <Text style={xstyles.headerText}>{title}</Text>}
      <br />
      {children}
      {buttonText && (
        <div style={xstyles.cardActionButtonSpace}>{buttonText}</div>
      )}
    </div>
  )

  if (imageLeft) {
    layout = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          //maximixe space between items
          justifyContent: "space-between",
        }}>
        {imageLeft}

        <div
          style={{
            paddingLeft: 20,
            marginBottom: 0,
          }}>
          {title && <Text style={xstyles.headerText}>{title}</Text>}
          <br />
          {children}
          {buttonText && (
            <div style={xstyles.cardActionButtonSpace}>{buttonText}</div>
          )}
        </div>
      </div>
    )
  }

  return (
    <View
      style={{
        ...xstyles.card,
        ...style,
      }}>
      <div className="card">
        <View
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: 5,
            height: "100%",
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            backgroundColor: color,
          }}></View>

        {layout}

        {button}
      </div>
    </View>
  )
}

export {resetCardColorIndex}


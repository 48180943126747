import React, {useState, useEffect, useMemo} from "react"
import {TouchableOpacity, StyleSheet} from "react-native"
import Account from "../../dataProvider/Account"
import Realnote from "../../bridges/RealnoteNative"
import {HttpBridge} from "../../http/HttpBridge"
import FastImage from "../XFastImage"

const http = new HttpBridge()

export default function ProfilePicture(props) {
  const {
    navigation,
    userId,
    user,
    onPressProfileImage,
    hidden,
    disabled,
    style,
    imageStyle,
    profilePictureUrl,
  } = props
  const [imageUrl, setImageUrl] = useState(null)
  const [fallbackImageUsed, setFallbackImageUsed] = useState(false)
  const [pressed, setPressed] = useState(false)
  const imageSize = props.imageSize || 40
  let focusListener

  useEffect(() => {
    getProfileImage()
  }, [profilePictureUrl])

  useEffect(() => {
    if (navigation) {
      focusListener = navigation.addListener("didFocus", didFocus)
    }
    return () => focusListener?.remove()
  }, [])

  const source = useMemo(() => {
    return fallbackImageUsed
      ? {uri: imageUrl}
      : {
          isStatic: false,
          uri: imageUrl,
        }
  }, [imageUrl, fallbackImageUsed])

  function didFocus() {
    setPressed(false)
  }

  async function getProfileImage() {
    const domain = await http.getProfileImageLink()
    if (profilePictureUrl) {
      const result = domain + profilePictureUrl
      setImageUrl(result)
      setFallbackImageUsed(false)
    } else {
      var indexOfRandomAvatar = "18";
      if(userId != null) {
        indexOfRandomAvatar = (parseInt(userId.replace(/[^0-9]/g, "")) % 27)
            + 1;
      }
      const nameOfRandomAvatar = "avatar_" + indexOfRandomAvatar + ".jpg";
      const result = domain + nameOfRandomAvatar;
      setImageUrl(result)
      setFallbackImageUsed(false)
    }
  }

  function onPressImage() {
    if (pressed) {
      return
    }
    setPressed(true)
    if (onPressProfileImage) {
      onPressProfileImage(userId)
      setPressed(false)
    }
    if (navigation) {
      if (Account.userId === userId) {
        navigation.navigate("Profile", {userId})
      } else {
        navigation.navigate("VisitedProfile", {userId})
      }
    }
  }

  async function onErrorHandler() {
    const domain = await http.getProfileImageLink()
    var indexOfRandomAvatar = "18";
    if(userId != null) {
      indexOfRandomAvatar = (parseInt(userId.replace(/[^0-9]/g, "")) % 27)
          + 1;
    }
    const nameOfRandomAvatar = "avatar_" + indexOfRandomAvatar + ".jpg";
    const result = domain + nameOfRandomAvatar;
    setImageUrl(result)
    setFallbackImageUsed(false)
  }

  if (!userId || hidden) {
    return null
  }

  return (
    <TouchableOpacity
      disabled={disabled}
      style={[
        {
          width: imageSize,
          height: imageSize,
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: imageSize / 2,
        },
        style,
      ]}
      onPress={onPressImage}>
      <FastImage
        style={[
          {
            width: imageSize,
            height: imageSize,
            borderRadius: imageSize / 2,
          },
          imageStyle,
        ]}
        source={source}
        onError={onErrorHandler}
      />
    </TouchableOpacity>
  )
}

import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import reportWebVitals from "./reportWebVitals"
import {store, persistor} from "./store/store"
import {Provider} from "react-redux"
import {PersistGate} from "redux-persist/integration/react"
import RealnoteMain from "./RealnoteMain"
import Global from "./Global"
import {load} from "./i18n"
import Realnote from "./host"

let useDebugMode = true
if (process.env.NODE_ENV == "development") {
  if (process.env.REACT_APP_FORCE_PROD) {
    let forceProduction =
      process.env.REACT_APP_FORCE_PROD.toLocaleLowerCase() == "true"
    useDebugMode = !forceProduction
  } else {
    useDebugMode = true
  }
} else {
  useDebugMode = false
}

const {sentryDsn, normalizeDepth, release, dist, tracesSampleRate} = Global

Sentry.init({
  dsn: sentryDsn,
  environment: useDebugMode ? "Development" : "Production",
  normalizeDepth,
  release,
  dist,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false,
      dom: true,
      xhr: false,
      fetch: true,
      beacon: true,
      history: true,
    }),
  ],
  tracesSampleRate,
})

Global.enableDebugMode(useDebugMode)

// check for webXR Support
if ("xr" in navigator) {
  navigator.xr.isSessionSupported("immersive-ar").then(function (supported) {
    if (supported) {
      console.log("init: use WebXR")
      window.useWebXR = true
    } else {
      console.log("init: DONT use WebXR")
    }
  })
}

function sendToAnalytics(metric) {
  const body = JSON.stringify(metric)
  console.log("Vitals", metric)
  Realnote.sendEventToServer("Vitals", metric)
}

function startReactUi() {
  Realnote.sendFirebaseEvent("App.Start")

  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RealnoteMain />
      </PersistGate>
    </Provider>,
    document.getElementById("root"),
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  // reportWebVitals(sendToAnalytics)
}

window.startReactUi = startReactUi

function delay(milliseconds) {
  return new Promise(resolve => {
    setTimeout(resolve, milliseconds)
  })
}

document.addEventListener("visibilitychange", async function () {
  if (document.visibilityState == "hidden") {
    Realnote.sendEventToServer("App.Leave")
    await delay(1000)
  }
  return true
})

window.addEventListener("load", function () {
  load().then(() => {
    window.startReactUi()
  })
})

import React, {PureComponent} from "react"
import {Animated, Easing, View} from "react-native"
import RealnoteLogger from "../../bridges/RealnoteLogger"
import Icon from "../RealnoteIcon/Icon"
import StyleConstants from "../../styles/StyleConstants"

const log = new RealnoteLogger("HeartAnimation")

export default class HeartAnimation extends PureComponent {
  constructor(props) {
    super(props)
    try {
      this.state = {
        opacityAnimation: new Animated.Value(0),
        scaleAnimation: new Animated.Value(0),
      }
    } catch (e) {
      log.e("constructor error: " + e)
    }
  }

  startAnimation = () => {
    try {
      log.v("startAnimation")
      const d = this.props.duration || 700
      Animated.parallel([
        Animated.sequence([
          Animated.timing(this.state.scaleAnimation, {
            toValue: 1,
            duration: d * 0.7,
            useNativeDriver: true,
            easing: Easing.elastic(1),
          }),
          Animated.timing(this.state.scaleAnimation, {
            toValue: 0,
            duration: d * 0.3,
            useNativeDriver: true,
            easing: Easing.ease,
          }),
        ]),
        Animated.sequence([
          Animated.timing(this.state.opacityAnimation, {
            toValue: 1,
            duration: d * 0.7,
            useNativeDriver: true,
            easing: Easing.cubic,
          }),
          Animated.timing(this.state.opacityAnimation, {
            toValue: 0,
            duration: d * 0.3,
            useNativeDriver: true,
            easing: Easing.cubic,
          }),
        ]),
      ]).start()
    } catch (e) {
      log.e(`startAnimation error: ${e}`)
    }
  }

  render() {
    try {
      if (this.props.hidden) {
        return null
      }
      return (
        <View style={this.props.style}>
          <Animated.View
            style={{
              opacity: this.state.opacityAnimation,
              transform: [{scale: this.state.scaleAnimation}],
            }}>
            <Icon
              name={"Upvoted"}
              height={this.props.size || StyleConstants.standardImageSize}
              width={this.props.size || StyleConstants.standardImageSize}
            />
          </Animated.View>
        </View>
      )
    } catch (e) {
      log.e("render error: " + e)
      return null
    }
  }
}

import React, {PureComponent} from "react"
import Hint from "./Hint"
import RealnoteLogger from "../bridges/RealnoteLogger"
import AppStyles from "../styles/AppStyles"
import Realnote, {emitter} from "../bridges/RealnoteNative"

const TAG = "LocalHintManager"
const log = new RealnoteLogger(TAG)
const duration = {
  short: 3000,
  long: 6000,
  xLong: 10000,
}

export default class LocalHintManager extends PureComponent {
  constructor(props) {
    try {
      super(props)
      this.state = {
        hintsUpdated: false,
        activeHintRef: null,
        hidden: true,
      }

      this.hints = {}

      this.hintShowTimes = {
        attach: 0,
        welcome: 0,
      }

      this.hintsInLineRefs = []
    } catch (e) {
      log.e("constructor error: " + e)
    }
  }

  // region life cycle

  componentDidMount() {
    try {
      this.newToastListener = emitter().addListener(TAG, "newToast", params => {
        try {
          if (params.ref.startsWith("local")) {
            this.handleNewToast(params)
          }
        } catch (ex) {
          log.error(ex)
        }
      })
      this.hideToastListener = emitter().addListener(
        TAG,
        "hideToast",
        params => {
          try {
            if (params.ref.startsWith("local")) {
              this.handleHideToast(params)
            }
          } catch (ex) {
            log.error(ex)
          }
        },
      )
    } catch (e) {
      log.e("componentDidMount error: " + e)
    }
  }

  componentWillUnmount() {
    try {
      if (this.newToastListener) {
        this.newToastListener.remove()
      }
      if (this.hideToastListener) {
        this.hideToastListener.remove()
      }
    } catch (e) {
      log.e("componentWillUnmount error: " + e)
    }
  }

  // endregion

  // region actions for list of hints

  // hides all active hints and
  // removes hints to be shown in the future
  hideAllHints() {
    try {
      this.setState({
        hidden: true,
      })
    } catch (e) {
      log.e("hideHints error: " + e)
    }
  }

  showAllHints() {
    try {
      this.setState({
        hidden: false,
      })
    } catch (e) {
      log.e("showAllHints error: " + e)
    }
  }

  handleNewToast(params) {
    try {
      //log.d("handleNewToast: " + JSON.stringify(params)+ " ref: " + params.ref + " this.state.activeHintRef: ")
      if (
        ((this.state.activeHintRef &&
          this.state.activeHintRef === params.ref) ||
          this.hintsInLineRefs.includes(params.ref)) &&
        this.hints[this.state.activeHintRef].text === params.text
      ) {
        return
      }

      // log.v("handleNewToast: " + params.ref);

      if (
        (this.hints[params.ref] &&
          this.hints[params.ref].text !== params.text) ||
        !this.hints[params.ref]
      ) {
        this.upsertHint(params.ref, {
          ref: params.ref,
          text: params.text,
          duration: params.duration,
        })
      }

      this.showHint(params.ref)
    } catch (e) {
      log.e("handleNewToast error: " + e)
    }
  }

  handleHideToast(params) {
    try {
      if (params.ref === "all") {
        this.hideAllHints()
      } else {
        this.hideHint(params.ref)
      }
    } catch (e) {
      log.e("handleHideToast error: " + e)
    }
  }

  upsertHint(ref, newHint) {
    try {
      this.hints[ref] = newHint
      this.hintShowTimes[ref] = 0
      this.setState({
        hintsUpdated: true,
      })
    } catch (e) {
      log.e("updateHint error: " + e)
    }
  }

  // endregion

  // region actions for individual hints

  showHint(ref) {
    try {
      if (!this.hints || !ref) {
        log.v("showHint: no hints or hint has been shown " + ref)
        return
      }

      if (this.state.activeHintRef == null) {
        this.setState({
          activeHintRef: ref,
          hidden: false,
        })

        if (this.hints[ref].duration) {
          setTimeout(() => {
            this.hideHint(ref)
          }, duration[this.hints[ref].duration])
        }
      } else {
        if (
          !this.state.activeHintRef === ref &&
          !this.hintsInLineRefs.includes(ref)
        ) {
          this.hintsInLineRefs.push(ref)
          // log.v("++ new hintsInLine: " + JSON.stringify(this.hintsInLineRefs));
        }
      }
    } catch (e) {
      log.e("componentDidMount error: " + e)
    }
  }

  hideHint(ref) {
    try {
      if (this.state.activeHintRef === ref) {
        const nextRef = this.hintsInLineRefs[0]
        this.setState({activeHintRef: null})
        if (nextRef) {
          this.showHint(nextRef)
          this.hintsInLineRefs.splice(0, 1)
          // log.v("hiding " + ref + " and going to show " + nextRef
          //     + " with text: " + this.hints[nextRef].text);
        }
      } else if (this.hintsInLineRefs.length > 0) {
        if (this.hintsInLineRefs.includes(ref)) {
          this.hintsInLineRefs = this.hintsInLineRefs.splice(
            this.hintsInLineRefs.indexOf(ref),
            1,
          )
        }
      }
    } catch (e) {
      log.e("hideHint error: " + e)
    }
  }

  addToQueue(ref) {
    try {
      if (!this.state.activeHintRef) {
        this.setState({
          activeHintRef: ref,
        })
      } else {
        if (
          !this.hintsInLineRefs.includes(ref) &&
          this.state.activeHintRef !== ref
        ) {
          this.hintsInLineRefs.push(ref)
        }
      }
    } catch (e) {
      log.e("putInLine error: " + e)
    }
  }

  // endregion

  render() {
    try {
      if (this.state.hintsUpdated === true) {
        this.state.hintsUpdated = false
      }

      if (this.state.activeHintRef && !this.state.hidden) {
        const hint = this.hints[this.state.activeHintRef]

        // log.v("render hint: " +
        //     JSON.stringify(this.hints[this.state.activeHintRef]));
        // log.v("render: " +
        //     "current " + this.state.activeHintRef +
        //     "\nhintsInLine: " + JSON.stringify(this.hintsInLineRefs));

        //log.v("this.props.style = " + JSON.stringify(this.props.style));

        return (
          <Hint
            ref={hint.ref}
            style={[
              AppStyles.textShadow,
              {
                position: "absolute",
                bottom: 30,
                width: "100%",
                marginHorizontal: 10,
              },
              hint.style,
              this.props.style,
            ]}
            textStyle={this.props.textStyle}
            text={hint.text}
            hidden={false}
          />
        )
      } else {
        return null
      }
    } catch (e) {
      log.e("render error: " + e)
    }
  }
}

import React, {Suspense} from "react"
import {useState, useEffect, useRef} from "react"
import {View} from "react-native"
import {NavigationContainer} from "@react-navigation/native"
import {createNativeStackNavigator} from "@react-navigation/native-stack"
import Realnote from "./bridges/RealnoteNative"
import Home from "./screens/Home"
import NoteInfoForMap from "./screens/NoteInfoForMap"
import AppColors from "./styles/AppColors"
import Account from "./dataProvider/Account"
import {HttpBridge} from "./http/HttpBridge"
import EnterUsername from "./screens/EnterUsername"
import {ErrorBoundary} from "react-error-boundary"
import Global from "./Global"
import {store} from "./store/store"

const http = new HttpBridge()
const NoteEditor = React.lazy(() => import("./screens/NoteEditor"))

const ExploreScreen = React.lazy(() =>
  import("./screens/ExploreScreen/Explore"),
)
const SculptureEditor = React.lazy(() => import("./screens/SculptureEditor"))
const NoteEditorModelSelection = React.lazy(() =>
  import("./screens/NoteEditorModelSelection"),
)
const AddressForm = React.lazy(() => import("./components/AddressForm"))
const CreateUserChallenges = React.lazy(() =>
  import("./screens/CreateUserChallenge"),
)
const ShareChallenge = React.lazy(() => import("./screens/ShareChallenge"))
const Live = React.lazy(() => import("./screens/Live/Live"))
const Map = React.lazy(() => import("./screens/Map/Map"))
const Profile = React.lazy(() => import("./screens/ProfileScreen/Profile"))
const SignUp = React.lazy(() => import("./screens/SignUp"))
const SignIn = React.lazy(() => import("./screens/SignIn"))
const PasswordRequest = React.lazy(() => import("./screens/PasswordRequest"))
const PasswordReset = React.lazy(() => import("./screens/PasswordReset"))
const WebPlaneEditor = React.lazy(() => import("./screens/WebPlaneEditor"))
const ChallengePage = React.lazy(() =>
  import("./screens/ExploreScreen/ChallengePage"),
)
const Notes = React.lazy(() => import("./screens/Notes"))
const ChallengeFeeds = React.lazy(() => import("./screens/ChallengeFeeds"))
const ChallengeLanding = React.lazy(() => import("./screens/ChallengeLanding"))
const AskForGps = React.lazy(() => import("./screens/AskForGpsPermissions"))
const CropScreen = React.lazy(() => import("./screens/NoteEditor/CropScreen"))
const UploadTemplate = React.lazy(() => import("./screens/UploadTemplate"))
const EditAccount = React.lazy(() => import("./screens/EditAccountNew"))
const ProfileImage = React.lazy(() =>
  import("./screens/ProfileImageScreen/ProfileImage"),
)
const ConfirmProfileImage = React.lazy(() =>
  import("./screens/ConfirmProfileImage"),
)
const SuccessScreen = React.lazy(() => import("./screens/SuccessScreen"))

const UploadTemplateSuccessScreen = React.lazy(() =>
  import("./screens/NoteEditor/UploadTemplateSuccessScreen"),
)
const OnboardingSuccess = React.lazy(() =>
  import("./screens/Onboarding/OnboardingSuccess"),
)
const Viewer = React.lazy(() => import("./screens/Viewer"))
const Rewards = React.lazy(() => import("./screens/Rewards"))
const PpmList = React.lazy(() => import("./screens/PpmList"))
const SimpleHtmlContentPage = React.lazy(() =>
  import("./screens/SimpleHtmlContentPage"),
)
const SignInLanding = React.lazy(() => import("./screens/SignInLanding"))
const ChallengeParticipantsScreen = React.lazy(() =>
  import("./screens/ChallengeParticipantsScreen"),
)

const Stack = createNativeStackNavigator()

const useWeb1 = [
  "NoteEditorModelSelection",
  "NoteEditor",
  "Live",
  "Viewer",
  "SculptureEditor",
]

function FullScreenWrapper(Component, props, uiOnly) {
  const {innerHeight, innerWidth} = window
  const [h, setH] = useState(innerHeight)
  const [w, setW] = useState(innerWidth)

  function onWindowResize() {
    const {innerHeight, innerWidth} = window
    setH(innerHeight)
    setW(innerWidth)

    // ugly fix for a timing problem with the resize
    setTimeout(() => {
      setH(innerHeight)
      setW(innerWidth)
    }, 100)
  }

  window.addEventListener("resize", onWindowResize, false)

  props.navigation.getParam = name => {
    if (props.route.params) {
      return props.route?.params[name]
    }
    return null
  }

  if (props.navigation.originalNavigate) {
  } else {
    props.navigation.originalNavigate = props.navigation.navigate
    props.navigation.originalAddListener = props.navigation.addListener
    props.navigation.originalPush = props.navigation.push
  }

  props.navigation.push = (arg1, arg2) => {
    console.log("navigation.push: " + arg1)

    var uiOnly = true
    for (var i in useWeb1) {
      if (useWeb1[i] === arg1) {
        uiOnly = false
      }
    }

    Realnote.sendFirebaseEvent("NavigateTo_" + arg1)

    props.navigation.originalPush(arg1, arg2)
  }

  props.navigation.navigate = (arg1, arg2) => {
    console.log("navigation.navigate: " + arg1)

    var uiOnly = true
    for (var i in useWeb1) {
      if (useWeb1[i] === arg1) {
        uiOnly = false
      }
    }

    if (typeof arg1 === "object") {
      const {routeName, params} = arg1
      Realnote.sendFirebaseEvent("NavigateTo_" + routeName)
      props.navigation.originalNavigate(routeName, params)
    } else {
      Realnote.sendFirebaseEvent("NavigateTo_" + arg1)
      props.navigation.originalNavigate(arg1, arg2)
    }
  }

  props.navigation.addListener = (name, fun) => {
    console.log("addListner " + name)
    if (name == "didFocus") {
      name = "focus"
    }

    if (name == "willBlur") {
      name = "blur"
    }

    const removeListener = props.navigation.originalAddListener(name, fun)
    return {
      remove: () => {
        console.log("remove " + name)
        removeListener()
      },
    }
  }

  props.navigation.currentRoute = () => {
    const state = props.navigation.getState()
    const route = state.routes[state.routes.length - 1]
    return route
  }

  if (typeof Component === "string") {
    Component = React.lazy(() => import(Component))
  }

  const errorHandler = (error, info) => {
    console.log("RealnoteMain Handler: " + error.message + " " + info)
  }

  function ErrorFallback({error, resetErrorBoundary}) {
    console.log("Icon ErrorFallback: " + error.message)

    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
      </div>
    )
  }

  return (
    <ErrorBoundary
      onError={errorHandler}
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}>
      <View
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: h,
          width: "100vw",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Suspense fallback={<div className="loader"></div>}>
          <Component {...props} />
        </Suspense>
      </View>
    </ErrorBoundary>
  )
}

function RealnoteMain() {
  window.lastLocation = {
    latitude: 50.71358286319306,
    longitude: 7.074374627026155,
    accuracy: 0,
  }
  const style1 = {
    background: "transparent",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    touchAction: "none",
    pointerEvents: "none",
    fontFamily: "Roboto, sans-serif",
  }
  const storeState = store.getState()
  const activeChallenge = storeState.challengeMode.challengeId

  Realnote.sendFirebaseEvent("RealnoteMain.Render")

  function screen_Register({navigation}) {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          alignItems: "center",
        }}>
        <View
          style={{
            width: "100%",
            height: "100%",
            maxWidth: "800px",
          }}>
          <SignUp navigation={navigation} navigator={navigator} />
        </View>
      </View>
    )
  }

  function AutoLoginScreen({navigation}) {
    useEffect(() => {
      async function login() {
        http
          .getUserLocationViaIP()
          .then(res => {
            const city = res.data.geo.city
            const location = [res.data.geo.latitude, res.data.geo.longitude]

            if (window.lastLocation) {
              console.log(
                "AutoLoginScreen window.lastLocation already set, ignore location from login",
              )
            } else {
              window.lastLocation = res.data.geo
            }
            Global.city = city
          })
          .catch(err => {
            console.error(`Error getting UserLocation via IP: ${err}`)
          })

        const hasCredentialsStored = await Account.hasCredentialsStored()
        console.log(
          "AutoLoginScreen hasCredentialsStored" + hasCredentialsStored,
        )
        if (!hasCredentialsStored) {
          const id = await Account.getUniqueUserId()
          const autoSignUpId = await Account.autoSignUp(id)
          console.log("AutoLoginScreen autoSignUpId: " + autoSignUpId)
          if (autoSignUpId) {
            const res = await Account.logIn(autoSignUpId)
            console.log("AutoLoginScreen login done " + res)
          } else {
            console.log("AutoLoginScreen logging in as @web@ as fallback")
            await Account.logIn("@web@", "realnote")
          }

          //
          // dont open NoteEditor befor Login succeds
          //
        } else {
          console.log(
            "AutoLoginScreen logging in with credentials: " +
              Account.username +
              Account.password,
          )
          await Account.logIn(Account.username, Account.password)
        }

        document.getElementById("splashMessage").style.display = "none"

        const params = new URLSearchParams(window.location.search)

        //
        // check if we are redirected from share
        //
        if (params.has("share")) {
          const sharedSceneGuid = params.get("note")
          Realnote.sendFirebaseEvent("shareRedirect")
          navigation.navigate("Live", {noteData: {guid: sharedSceneGuid}})
          return
        }
        Global.challengeId = activeChallenge
        //
        // check if we have set a ChallengeId in the URL
        //
        const challengeFromParams = params.get("challengeId")
        if (challengeFromParams) {
          Global.challengeId = challengeFromParams
        }

        //
        // check if we have set a Username in the redirect page
        //

        if (params.has("user")) {
          const user = params.get("user")
          Realnote.sendFirebaseEvent("UserFromRedirect")
          navigation.navigate("SignInLanding", {username: user})
          return
        }

        var initialRouteName = getInitialScreen()
        navigation.navigate(initialRouteName)
      }
      login()
    }, [])

    return <View></View>
  }

  async function openRealnote() {
    var url = await Realnote.createDeepLink("editor?template=xxxxx")
    window.open(url)
  }

  function getInitialScreen() {
    const params = new URLSearchParams(window.location.search)
    if (params.has("n")) {
      const s = params.get("n")
      return s
    }

    if (params.has("editor")) {
      global.environment = "homepage.NoteEditor"
      return "NoteEditorModelSelection"
    }

    return "Home"
  }

  console.log("RENDER RealnoteMain")

  return (
    <div className="RealnoteMain" style={style1}>
      <NavigationContainer>
        <Stack.Navigator>
          <Stack.Screen
            name="Empty"
            component={props => FullScreenWrapper(AutoLoginScreen, props)}
          />

          <Stack.Screen
            name="NoteEditorModelSelection"
            component={props =>
              FullScreenWrapper(NoteEditorModelSelection, props)
            }
          />
          <Stack.Screen
            name="NoteEditor"
            component={props => FullScreenWrapper(NoteEditor, props)}
          />
          <Stack.Screen
            name="Live"
            component={props => FullScreenWrapper(Live, props)}
          />
          <Stack.Screen
            name="SignIn"
            component={props => FullScreenWrapper(SignIn, props)}
          />
          <Stack.Screen
            name="SignUp"
            component={props => FullScreenWrapper(screen_Register, props)}
          />
          <Stack.Screen
            name="CropScreen"
            component={props => FullScreenWrapper(CropScreen, props)}
          />
          <Stack.Screen
            name="SuccessScreen"
            component={props => FullScreenWrapper(SuccessScreen, props)}
          />
          <Stack.Screen
            name="UploadTemplateSuccessScreen"
            component={props =>
              FullScreenWrapper(UploadTemplateSuccessScreen, props)
            }
          />
          <Stack.Screen
            name="Explore"
            component={props => FullScreenWrapper(ExploreScreen, props)}
          />
          <Stack.Screen
            name="CreateUserChallenges"
            component={props => FullScreenWrapper(CreateUserChallenges, props)}
          />
          <Stack.Screen
            name="AddressForm"
            component={props => FullScreenWrapper(AddressForm, props)}
          />
          <Stack.Screen
            name="ShareChallenge"
            component={props => FullScreenWrapper(ShareChallenge, props)}
          />

          <Stack.Screen
            name="Viewer"
            component={props => FullScreenWrapper(Viewer, props)}
          />

          <Stack.Screen
            name="ChallengeFeeds"
            component={props => FullScreenWrapper(ChallengeFeeds, props)}
          />
          <Stack.Screen
            name="ChallengeLanding"
            component={props => FullScreenWrapper(ChallengeLanding, props)}
          />

          <Stack.Screen
            name="Home"
            component={props => FullScreenWrapper(Home, props)}
          />

          <Stack.Screen
            name="SculptureEditor"
            component={props => FullScreenWrapper(SculptureEditor, props)}
          />

          <Stack.Screen
            name="PasswordRequest"
            component={props => FullScreenWrapper(PasswordRequest, props)}
          />
          <Stack.Screen
            name="PasswordReset"
            component={props => FullScreenWrapper(PasswordReset, props)}
          />
          <Stack.Screen
            name="Profile"
            component={props => FullScreenWrapper(Profile, props)}
          />

          <Stack.Screen
            name="ProfileImage"
            component={props => FullScreenWrapper(ProfileImage, props)}
          />

          <Stack.Screen
            name="ConfirmProfileImage"
            component={props => FullScreenWrapper(ConfirmProfileImage, props)}
          />

          <Stack.Screen
            name="WebPlaneEditor"
            component={props => FullScreenWrapper(WebPlaneEditor, props)}
          />

          <Stack.Screen
            name="Challenge"
            component={props => FullScreenWrapper(ChallengePage, props)}
          />

          <Stack.Screen
            name="Map"
            component={props => FullScreenWrapper(Map, props)}
          />
          <Stack.Screen
            name="EditAccount"
            component={props => FullScreenWrapper(EditAccount, props)}
          />

          <Stack.Screen
            name="AskForGps"
            component={props => FullScreenWrapper(AskForGps, props)}
          />

          <Stack.Screen
            name="NoteInfoForMap"
            component={props => FullScreenWrapper(NoteInfoForMap, props)}
          />
          <Stack.Screen
            name="EnterUsername"
            component={props => FullScreenWrapper(EnterUsername, props)}
          />
          <Stack.Screen
            name="UploadTemplate"
            component={props => FullScreenWrapper(UploadTemplate, props)}
          />
          <Stack.Screen
            name="Rewards"
            component={props => FullScreenWrapper(Rewards, props)}
          />
          <Stack.Screen
            name="OnboardingSuccess"
            component={props => FullScreenWrapper(OnboardingSuccess, props)}
          />
          <Stack.Screen
            name="PpmList"
            component={props => FullScreenWrapper(PpmList, props)}
          />

          <Stack.Screen
            name="SimpleHtmlContentPage"
            component={props => FullScreenWrapper(SimpleHtmlContentPage, props)}
          />

          <Stack.Screen
            name="Notes"
            component={props => FullScreenWrapper(Notes, props)}
          />
          <Stack.Screen
            name="SignInLanding"
            component={props => FullScreenWrapper(SignInLanding, props)}
          />
          <Stack.Screen
            name="ChallengeParticipants"
            component={props =>
              FullScreenWrapper(ChallengeParticipantsScreen, props)
            }
          />
        </Stack.Navigator>
      </NavigationContainer>
    </div>
  )
}

export default RealnoteMain

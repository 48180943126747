import React, {PureComponent} from "react"
// import FastImage from 'react-native-fast-image';
import FastImage from "./FastImage"
import RealnoteLogger from "../bridges/RealnoteLogger"
import Global from "../Global"

const log = new RealnoteLogger("XFastImage")

export default class XFastImage extends PureComponent {
  static cacheControl = FastImage.cacheControl

  startsWith = (s, pattern) => {
    const s1 = s.subStr(pattern.length)
    return s1 == pattern
  }

  render() {
    const props = this.props
    const source = props.source

    if (props.debug) {
      //log.d("render source = " + JSON.stringify(source))
      return null
    }

    try {
      if (typeof source === "string" || source instanceof String) {
        // ok
        source = {uri: source}
      }

      if (typeof source === "object" && source !== null) {
        // ok
        if (source.uri) {
          if (typeof source.uri === "string" || source.uri instanceof String) {
            if (
              !(
                source.uri.startsWith("data:") ||
                source.uri.startsWith("https:") ||
                source.uri.startsWith("http:")
              )
            ) {
              log.error(new Error("source.uri is invalid/empty " + source.uri))
              return null
            }

            if (source.uri == "data:") {
              log.error(new Error("source.uri is empty " + source.uri))
              return null
            }
          } else {
            log.error(new Error("source.uri is invalidType " + typeof source))
            return null
          }
        }
      } else {
        log.error(new Error("source is invalidType " + typeof source))
        return null
      }

      if (props.source) {
        if (
          props.source.static != undefined &&
          (!props.source.uri || props.source.uri == null)
        ) {
          log.d("render return null X")
          return null
        }

        if (props.source.uri !== undefined) {
          if (props.source.uri == null) {
            return null
          }
        }
      } else {
        log.d("render return null B")
        return null
      }

      if (Global.isReactNavtive) {
        if (!source.cache) {
          source.cache = FastImage.cacheControl.immutable
        }
      }

      return <FastImage {...props} />
    } catch (ex) {
      log.e(ex)
      log.d("render return null B")
      return null
    }
  }
}

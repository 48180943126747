// import I18n from 'react-native-i18n';
// Import all locales
import RealnoteLogger from "./bridges/RealnoteLogger"

import Logger from "./bridges/RealnoteLogger"

const TAG = "i18n"
const log = new RealnoteLogger(TAG)

function getCurrentLocale() {
  var userLang = navigator.language || navigator.userLanguage
  if (userLang) {
    return userLang
  }
  return "de-de"
}

export function getLocaleDate(dateString) {
  try {
    if (dateString != null) {
      const date = new Date(dateString)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()

      const l = getCurrentLocale()

      if (l.includes("de")) {
        return `${day}.${month}.${year}`
      } else if (l.includes("en")) {
        return `${month}/${day}/${year}`
      } else if (l.includes("es")) {
        return `${day}/${month}/${year}`
      } else if (l.includes("fr")) {
        return `${day}/${month}/${year}`
      } else {
        return `${month}/${day}/${year}`
      }
    }
  } catch (e) {
    log.e("getLocaleDate error: " + e)
  }
}

export function getLocaleTime(dateString) {
  try {
    if (dateString != null) {
      const date = new Date(dateString)
      const hours = date.getHours()
      const minutes = date.getMinutes()

      const zero = minutes < 10 ? "0" : ""

      const l = getCurrentLocale()

      if (l.includes("de")) {
        return `${hours}:${zero}${minutes}`
      } else if (l.includes("en")) {
        return `${hours}:${zero}${minutes}`
      } else if (l.includes("es")) {
        return `${hours}:${zero}${minutes}`
      } else if (l.includes("fr")) {
        return `${hours}:${zero}${minutes}`
      } else {
        return `${hours}:${zero}${minutes}`
      }
    }
  } catch (e) {
    log.e("getLocaleTime error: " + e)
  }
}

var allStrings = null

export function getCurrentLocalShort() {
  const l = getCurrentLocale()


  if (l.includes("de")) {
    return "de"
  } else if (l.includes("es")) {
    return "es"
  } else if (l.includes("fr")) {
    return "fr"
  }

  return "en"
}

export async function load() {
  const l = getCurrentLocalShort()
  allStrings = await import(`./locales/${l}.json`)
}

export function strings(name, params = {}) {
  try {
    if (allStrings == null) {
      return name
    }

    var xx = name.split(".")
    var x = allStrings
    for (var i in xx) {
      x = x[xx[i]]
    }
    return x || name
  } catch (ex) {
    return name
  }
}

import React, {PureComponent} from "react"
import {TouchableHighlight} from "react-native-gesture-handler"
import RealnoteLogger from "../bridges/RealnoteLogger"

const log = new RealnoteLogger("TouchableOpacityMulti")

// Component that can handle multiple taps
// onPress, onDoublePress, onTriplePress, onQuadPress
// wraps TouchableOpacity
export default class TouchableOpacityMulti extends PureComponent {
  constructor(props) {
    super(props)
    try {
      this.lastPress = null
      this.tapCount = 0
      this.timer = null
    } catch (e) {
      log.e("constructor error: " + e)
    }
  }

  onPress = () => {
    try {
      log.v("onPress")
      const thisPress = Date.now()
      const delay = this.props.delay || 300
      if (!this.lastPress || thisPress - this.lastPress <= delay) {
        this.tapCount++
      }
      this.lastPress = thisPress
      if (!this.timer) {
        this.timer = setInterval(this.handleMultiTaps, delay - 50)
      }
    } catch (e) {
      log.e(`onPress error: ${e}`)
      clearInterval(this.timer)
      this.tapCount = 0
    }
  }

  handleMultiTaps = () => {
    try {
      log.v("handleMultiTaps")
      const delay = this.props.delay || 300
      const now = Date.now()
      if (now - this.lastPress > delay) {
        switch (this.tapCount) {
          case 1:
            this.resetTaps()
            log.v("handleMultiTaps 1")
            if (this.props.onPress) {
              this.props.onPress()
            }
            break
          case 2:
            this.resetTaps()
            log.v("handleMultiTaps 2")
            if (this.props.onDoublePress) {
              this.props.onDoublePress()
            }
            break
          case 3:
            this.resetTaps()
            log.v("handleMultiTaps 3")
            if (this.props.onTriplePress) {
              this.props.onTriplePress()
            }
            break
          case 4:
            this.resetTaps()
            log.v("handleMultiTaps 4")
            if (this.props.onQuadPress) {
              this.props.onQuadPress()
            }
            break
        }
      } else {
        log.v(
          "now - this.lastPress " +
            now -
            this.lastPress +
            " is larger than " +
            delay,
        )
      }
    } catch (e) {
      log.e(`handleMultiTaps error: ${e}`)
      this.resetTaps()
    }
  }

  resetTaps = () => {
    try {
      clearInterval(this.timer)
      this.timer = null
      this.tapCount = 0
      this.lastPress = null
    } catch (e) {
      log.e(`resetTaps error: ${e}`)
    }
  }

  render() {
    try {
      return <TouchableHighlight {...this.props} onPress={this.onPress} />
    } catch (e) {
      log.e("render error: " + e)
      return null
    }
  }
}

import React, {Component} from "react"
import Logger from "../bridges/RealnoteLogger"
// import Lottie from "../platform/LottieView";
//import Lottie from "lottie-react"
import {View} from "react-native"
import loadingIndicator from "../assets/animations/loadingIndicator.json"

const TAG = "LoadingIcon"
const log = new Logger(TAG)

/**
 * shows a rotating loading icon
 */
export default class LoadingIcon extends Component {
  render() {
    try {
      if (this.props.hidden) {
        return null
      }
      let sizeStyle = {}
      if (this.props.standardSize || !this.props.style) {
        sizeStyle = {
          width: 150,
          height: 150,
        }
      }
      return (
        // <Text>
        //   Reactivate Lottie
        // </Text>
        <View
          style={[
            sizeStyle,
            this.props.style,
            {
              alignSelf: "center",
            },
          ]}>
          {/* <Lottie
            animationData={loadingIndicator}
            autoPlay
            loop
            // renderMode={"HARDWARE"}
          /> */}
        </View>
      )
    } catch (e) {
      log.e("render error: " + e)
    }
  }
}

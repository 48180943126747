import React, {useRef, useState} from "react"
import {
  SectionList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Dimensions,
} from "react-native"
import FastImage from "./XFastImage"
import {useDispatch, useSelector} from "react-redux"
import {useNavigation} from "@react-navigation/native"
import CircularIconButton from "../screens/MenuBarScreen/CircularIconButton"
import AppColors from "../styles/AppColors"
import appColors from "../styles/AppColors"
import Icon from "./RealnoteIcon/Icon"
import AppStyles from "../styles/AppStyles"
import FacePile from "./FacePile"
import {
  useGetUserChallengesQuery,
  useGetChallengeDataQuery,
} from "../store/reducers/ChallengeFeedsSlice"
import Realnote from "../bridges/RealnoteNative"
import {leaveChallenge} from "../store/reducers/challengeModeSlice"
import Global from "../Global"
import {strings} from "../i18n"

const {height} = Dimensions.get("window")

export default function DropdownHeader(props) {
  const DropdownButton = useRef()
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const [modalVisible, setModalVisible] = useState(false)
  const [isInfoTapped, setIsInfoTapped] = useState(false)
  const activeChallenge = useSelector(state => state.challengeMode)
  const [dropdownTop, setDropdownTop] = useState(0)
  const {
    data: userChallenges,
    isLoading: userChallengesLoading,
    refetch: refetchUserChallenges,
  } = useGetUserChallengesQuery()
  const {
    data: challengeData,
    isLoading: challengeDataLoading,
    refetch: refetchchallengeData,
  } = useGetChallengeDataQuery()

  const toggleDropdown = doesInfoTapped => {
    modalVisible ? setModalVisible(false) : openDropdown()
    setIsInfoTapped(doesInfoTapped)
  }

  const openDropdown = () => {
    DropdownButton.current.measure((_fx, _fy, _w, h, _px, py) => {
      setDropdownTop(py + h)
    })
    setModalVisible(true)
  }

  const joinChallenge = challengeToJoin => {
    dispatch(leaveChallenge())
    Realnote.stopChallengeMode()

    if (challengeToJoin.title !== "Global") {
      Realnote.joinChallengeAndEnterChallengeMode(challengeToJoin)
    }
  }

  const onPressCreateNewGroup = () => {
    Realnote.sendFirebaseEvent("goToCreateGroupFromDropdown")
    navigation.push("CreateUserChallenges")
  }

  const onItemPress = selectedChallenge => {
    joinChallenge(selectedChallenge)
    setModalVisible(false)
  }

  const renderCreateNewGroup = () => {
    return (
      <TouchableOpacity
        style={[styles.itemContainer, styles.createNewGroupTouchable]}
        onPress={onPressCreateNewGroup}>
        <View style={{paddingHorizontal: 15}}>
          <Icon
            name="PlusNew"
            width={40}
            height={40}
            fill={AppColors.primary}
          />
        </View>
        <Text
          style={[
            styles.itemText,
            {color: AppColors.primary, textAlignVertical: "center"},
          ]}>
          Neue Gruppe erstellen
        </Text>
      </TouchableOpacity>
    )
  }

  const renderDropdown = () => {
    const renderSectionHeader = ({section: {title}}) => {
      return (
        <View style={{alignItems: "center"}}>
          <View style={styles.divider} />
          <View style={{paddingVertical: 7}}>
            <Text style={AppStyles.title}>{title}</Text>
          </View>
        </View>
      )
    }

    const renderItem = ({item}) => {
      return (
        <TouchableOpacity
          onPress={() => {
            onItemPress(item)
          }}
          style={styles.itemContainer}>
          <View style={{paddingHorizontal: 15}}>
            <View style={styles.avatarTouchable}>
              <FastImage
                style={styles.avatarImage}
                source={{uri: item.imageUrl}}
              />
            </View>
          </View>
          <View style={{justifyContent: "center"}}>
            <Text style={styles.itemText}>{item.title}</Text>
          </View>
        </TouchableOpacity>
      )
    }

    const renderMenu = (userChallenges, challengeData) => {
      let userChallengesClone = [...userChallenges]
      userChallengesClone.unshift(Global.globalChallenge)
      const challengeDataClone = [...challengeData]
      const userChallengesWithTitle = {
        title: "MEINE GRUPPEN",
        data: userChallengesClone,
      }
      const nearChallengesWithTitle = {
        title: "VORSCHLÄGE",
        data: challengeDataClone,
      }
      let DATA = [userChallengesWithTitle, nearChallengesWithTitle]
      if (challengeDataClone.length < 1) {
        DATA = [userChallengesWithTitle]
      }

      return (
        <View>
          <SectionList
            sections={DATA}
            keyExtractor={(item, index) => item + index}
            renderItem={renderItem}
            renderSectionHeader={renderSectionHeader}
            style={{maxHeight: height / 2}}
          />
          {renderCreateNewGroup()}
        </View>
      )
    }

    const renderInfo = () => {
      if (!activeChallenge) {
        return (
          <View style={styles.noChallengeText}>
            <Text style={styles.itemText}>Wähle bitte eine Gruppe aus</Text>
          </View>
        )
      }

      const {participants, description} = activeChallenge
      const FACES = participants.map(participant => {
        return {
          ...participant,
          id: participant.userId,
          imageUrl: participant.profilePictureUrl,
        }
      })
      const membersText = `${
        !activeChallenge.title ? "über 150.000" : participants.length
      } Teilnehmer`

      return (
        <View style={styles.infoContainer}>
          <TouchableOpacity
            onPress={() => navigation.navigate("ChallengeParticipants")}
            style={styles.facePileContainer}>
            <View style={{marginRight: 15}}>
              <Text style={styles.itemText}>{membersText}</Text>
            </View>
            <FacePile numFaces={5} faces={FACES} />
          </TouchableOpacity>
          <View>
            <Text style={styles.itemText}>{description}</Text>
          </View>
          <TouchableOpacity
            style={{alignItems: "center"}}
            onPress={() => {
              navigation.push("CreateUserChallenges", {activeChallenge})
            }}>
            <Text
              style={[
                AppStyles.largeText,
                {
                  color: AppColors.black,
                  textDecorationLine: "underline",
                  marginBottom: 8,
                },
              ]}>
              Edit
            </Text>
          </TouchableOpacity>
        </View>
      )
    }

    const Loading = (
      <View style={styles.loadingText}>
        <Text style={styles.itemText}>Lädt...</Text>
      </View>
    )

    if (modalVisible) {
      return (
        <TouchableOpacity
          onPress={() => setModalVisible(false)}
          style={[styles.dropdown, {top: dropdownTop}]}>
          {userChallengesLoading || challengeDataLoading
            ? Loading
            : isInfoTapped
            ? renderInfo()
            : renderMenu(userChallenges, challengeData)}
        </TouchableOpacity>
      )
    } else {
      return <></>
    }
  }

  const renderHeaderLeft = () => {
    if (props.headerLeft) {
      return props.headerLeft
    }
    return (
      <TouchableOpacity
        style={styles.avatarTouchable}
        onPress={() => toggleDropdown(false)}>
        <FastImage
          style={styles.avatarImage}
          source={{
            uri: activeChallenge.imageUrl
              ? activeChallenge.imageUrl
              : Global.globalChallengeImage,
          }}
        />
      </TouchableOpacity>
    )
  }

  return (
    <TouchableOpacity
      ref={DropdownButton}
      style={styles.headerContainer}
      onPress={() => toggleDropdown(false)}>
      {renderDropdown()}
      <View>{renderHeaderLeft()}</View>
      <View style={styles.titleContainer}>
        <Text style={AppStyles.title}>
          {activeChallenge.title ? activeChallenge.title : "Global"}
        </Text>
        <View style={styles.titleIconContainer}>
          {!modalVisible && (
            <Icon
              fill={AppColors.white}
              stroke={AppColors.white}
              width={35}
              height={35}
              name="down-arrow"
            />
          )}
        </View>
      </View>
      <CircularIconButton
        nativeID={"ArTouchable-3"}
        buttonColor={AppColors.white}
        noCircle={true}
        size={50}
        name={"InfoIcon"}
        onPress={() => toggleDropdown(true)}
      />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  headerContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: appColors.primary,
    height: 60,
    zIndex: 1,
    paddingHorizontal: 15,
    pointerEvents: "auto",
    shadowColor: "#000000",
    shadowRadius: 4,
    shadowOffset: {height: 4, width: 0},
    shadowOpacity: 0.5,
  },
  buttonText: {
    flex: 1,
    textAlign: "center",
  },
  icon: {
    marginRight: 10,
  },
  dropdown: {
    position: "absolute",
    left: 0,
    right: 0,
    backgroundColor: appColors.primary,
    width: "100%",
    shadowColor: "#000000",
    shadowRadius: 4,
    shadowOffset: {height: 4, width: 0},
    shadowOpacity: 0.5,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
  },
  overlay: {
    width: "100%",
    height: "100%",
  },
  item: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderBottomWidth: 1,
  },
  avatarTouchable: {
    width: 40,
    height: 40,
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
  },
  avatarImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "auto",
    marginLeft: 35,
  },
  itemContainer: {flexDirection: "row", paddingVertical: 8},
  titleIconContainer: {marginLeft: 1, minWidth: 35},
  divider: {
    borderWidth: 0.5,
    borderColor: appColors.white,
    minWidth: "90%",
  },
  itemText: {
    color: AppColors.white,
    fontSize: 16,
    fontFamily: "Poppins",
  },
  infoContainer: {paddingHorizontal: 30, paddingBottom: 20},
  facePileContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
  },
  noChallengeText: {
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  loadingText: {justifyContent: "center", alignItems: "center"},
  createNewGroupTouchable: {
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: AppColors.white,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    alignItems: "center",
  },
})

import React, {useState, useEffect, useMemo} from "react"
import {Text, TouchableOpacity} from "react-native"
import AppStyles from "../../styles/AppStyles"
import {HttpBridge} from "../../http/HttpBridge"
import AppColors from "../../styles/AppColors"
import Account from "../../dataProvider/Account"

const http = new HttpBridge()

export default function Username(props) {
  const {
    navigation,
    userId,
    small,
    largeTextSize,
    standardTextSize,
    bold,
    white,
    black,
    alreadyInProfile,
    textStyle,
  } = props
  const [username, setUsername] = useState(props.username || "")
  var focusListener = navigation
    ? navigation.addListener("didFocus", didFocus)
    : null

    useEffect(() => {
      getUsername()
  
      return () => {
        if (focusListener) {
          focusListener.remove()
        }
      }
    }, [props.username])

  function didFocus() {
    getUsername()
  }

  async function getUsername() {
    if (!userId) {
      return
    }
    if (props.username) {
      setUsername(props.username)
      return
    }
    const data = await http.getProfilePreview(userId, false, false, false, true)
    if (data !== null && data.hasOwnProperty("username")) {
      setUsername(data.username)
    }
  }

  function goToProfile() {
    if (Account.userId === userId) {
      navigation.navigate("Profile", {userId})
    } else {
      navigation.navigate("VisitedProfile", {userId})
    }
  }

  function onPressHandler() {
    if (props.onPress) {
      props.onPress()
    } else {
      if (!alreadyInProfile) {
        goToProfile()
      }
    }
  }

  const style = useMemo(() => {
    let style = small
      ? {...AppStyles.smallText, textAlign: "center"}
      : standardTextSize
      ? {
          ...AppStyles.text,
          textAlign: "center",
        }
      : largeTextSize
      ? {
          ...AppStyles.largeText,
          textAlign: "center",
        }
      : {
          ...AppStyles.hugeText,
          textAlign: "center",
        }
    if (bold) {
      style = {...style, fontWeight: "bold"}
    }
    if (white) {
      style = {...style, color: AppColors.white}
    }
    if (black) {
      style = {...style, color: AppColors.black}
    }
    return style
  }, [standardTextSize, largeTextSize, small, bold, white, black])

  if (!username) {
    return null
  } else {
    return (
      <TouchableOpacity
        style={props.style}
        disabled={navigation === null}
        onPress={onPressHandler}>
        <Text
          numberOfLines={1}
          ellipsizeMode={"tail"}
          style={[style, textStyle]}>
          {username}
        </Text>
      </TouchableOpacity>
    )
  }
}

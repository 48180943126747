import React, { useState, useEffect, useRef } from "react"
import {Pressable} from "react-native"
import RealnoteLogger from "../bridges/RealnoteLogger"
import {xAppStyles} from "../styles/AppStyles"
import AppColors from "../styles/AppColors"

const log = new RealnoteLogger("ChipButton")

var isPressed = false
export default function ChipButton(props) {
  const buttonRef = useRef(null)

  const chipStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // textAlign: 'center',
    backgroundColor: props.outlined
      ? "transparent"
      : props.red
      ? AppColors.highlight
      : AppColors.primary,
    borderWidth: props.outlined ? 2 : 0,
    borderColor: props.red ? AppColors.highlight : AppColors.primary,
    pointerEvents: "auto",
  }

  var style = null
  if (props.standardSize) {
    style = {
      ...chipStyle,

      height: 30,
      borderRadius: 15,
      width: 150,
    }
  } else if (props.standardHeight) {
    style = {
      ...chipStyle,

      height: 30,
      borderRadius: 15,
      paddingLeft: 30,
      paddingRight: 30,
      paddingBottom: 10,
      paddingTop: 10,
    }
  } else {
    style = {
      ...chipStyle,

      paddingLeft: 30,
      paddingRight: 30,
      paddingBottom: 10,
      paddingTop: 10,

      borderRadius: 1000,
    }
  }

  if (props.hidden) {
    return null
  }

  const hiddenViewStyle = props.hiddenButTakingUpSpace
    ? {
        backgroundColor: "transparent",
        borderColor: "transparent",
      }
    : {}

  const hiddenTextStyle = props.hiddenButTakingUpSpace
    ? {
        color: "transparent",
      }
    : {}

  console.log("textStyle", JSON.stringify(props.textStyle))
  const txt = (
    <div
      style={{
        ...xAppStyles.boldButtonText,
        ...props.textStyle,
        ...hiddenTextStyle,
      }}>
      {props.label}
    </div>
  )

  const combinedStyle = {
    ...style,
    ...props.style,
    ...hiddenViewStyle,
  }
  if (props.noShadow) {
  } else {
    combinedStyle.boxShadow = "4px 4px 6px #00000040"
  }

  const handlePress = async () => {
    console.log("chipbutton pressed")
    const res = props.onPress()
    if (res) {
      await res
    }
  }

  return (
    <Pressable
      style={({pressed}) => [
        combinedStyle,
        {
          backgroundColor: pressed ? "orange" : combinedStyle.backgroundColor,
          boxShadow: pressed ? null : combinedStyle.boxShadow,
        },
      ]}
      onPress={handlePress}
      disabled={props.disabled}>
      {props.children}
      {txt}
    </Pressable>
  )
}


import React, {Component} from "react"
import {Text, View} from "react-native"
import AppStyles from "../styles/AppStyles"
import AppColors from "../styles/AppColors"
import {strings} from "../i18n"

import Logger from "../bridges/RealnoteLogger"
import FastImage from "./XFastImage"

const TAG = "EmptyContentImage"
const log = new Logger(TAG)

export const types = {
  users: "users",
  notes: "notes",
  internet: "internet",
}

export default class EmptyContentImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: null,
      text: "",
      src: null,
      isVisible: true,
    }
  }

  componentDidMount() {
    try {
      this.setType(this.props.type)
    } catch (e) {
      log.e("componentDidMount error: " + e)
    }
  }

  setType = t => {
    if (this.state.type != t) {
      const type = t || types.internet

      if (type === types.users) {
        var src = "assets/images/noUsers.png"
        var text = strings("noUsers")
      } else if (type === types.notes) {
        var src = "assets/images/noNotes.png"
        var text = strings("nothingToSee")
      } else {
        var src = "assets/images/noInternet.png"
        var text = strings("checkNetwork")
      }
      this.setState({
        type,
        text,
        src,
      })
    }
  }

  setVisible = v => {
    this.setState({visible: v})
  }

  render() {
    if (
      !this.props.isVisible ||
      !this.state.src ||
      !this.state.text ||
      !this.state.isVisible
    ) {
      return null
    }

    return (
      <View
        ref="top"
        style={[
          {
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          },
          this.props.style,
        ]}>
        <FastImage
          alignSelf="center"
          source={this.state.src}
          style={[this.props.imageStyle]}
          resizeMode={"contain"}
        />
        <Text
          style={[
            AppStyles.text,
            {
              textAlign: "center",
              fontWeight: "bold",
              alignSelf: "center",
              color: AppColors.primary,
            },
          ]}>
          {" "}
          {this.state.text}{" "}
        </Text>
      </View>
    )
  }
}

import Global from "../Global"
import {captureException} from "../components/Sentry";

const io = window.socketio

class SocketIOClient {
  constructor() {
    this.handlers = []
    this.connected = false
  }

  connect() {
    if (this.connected) {
      return
    }

    let url = Global.baseUrl.split("/api")[0]

    this.socket = io(url, {
      withCredentials: false,
      transports: ["websocket"]
    })
    this.addListener("connect", () => {
      this.connected = true
      this.fire("connect", null)
    })

    this.addListener("onReceiveMessage", data => {
      console.log("kasra onReceiveMessage", data)
      this.fire("onReceiveMessage", data)
    })

    this.addListener("disconnect", () => {
      console.log("kasra I am disconnected from the socket")
      this.connected = false
      this.fire("disconnect", null)
    })
    this.addListener("error", error => {
      console.log("kasra error: ", error)
    })
    this.addListener("connected", msg => {
      console.log("kasra connected: ", msg)
    })
    this.addListener("onJoinChat", msg => {
      console.log("kasra onJoinChat: ", msg)
    })
    this.addListener("onLeaveChat", msg => {
      console.log("kasra onLeaveChat: ", msg)
    })
  }

  subscribe(name, fn) {
    let xHandlers = this.handlers[name]
    if (!xHandlers) {
      xHandlers = []
      this.handlers[name] = xHandlers
    }
    xHandlers.push(fn)
  }

  unsubscribe(name, fn) {
    let xHandlers = this.handlers[name]
    if (xHandlers) {
      xHandlers = xHandlers.filter(function (item) {
        if (item !== fn) {
          return item
        }
      })
      this.handlers[name] = xHandlers
    }
  }

  fire(name, data) {
    let xHandlers = this.handlers[name]
    if (xHandlers) {
      xHandlers.forEach(function (item) {
        item.call(this, data)
      })
    }
  }

  sendText(challengeGuid) {
    this.socket.emit("postMessage", {challengeGuid: challengeGuid})
  }

  joinChat(challengeGuid) {
    try {
      if (this.socket !== null) {
        if (!this.connected) {
          console.error("socket is not connected")
        } else {
          this.socket.emit("joinChat", {challengeGuid})
        }
      }
    } catch (ex) {
      captureException(ex)
      console.error(ex)
    }
  }

  leaveChat(challengeGuid) {
    try {
      if (this.socket !== null) {
        if (!this.connected) {
          console.error("socket is not connected")
        } else {
          this.socket.emit("leaveChat", {challengeGuid})
        }
      }
    } catch (ex) {
      captureException(ex)
      console.error(ex)
    }
  }

  addListener(eventName, handler) {
    this.socket.on(eventName, handler)
  }
}

export default new SocketIOClient()

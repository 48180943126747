import React, {useState} from "react"
import RealnoteLogger from "../../bridges/RealnoteLogger"
const log = new RealnoteLogger("SvgIcon")

var svgs = []
var pendingCallbacks = []

function loadSvg(name, callback) {
  if (svgs[name]) {
    callback(svgs[name])
    return
  }

  if (pendingCallbacks[name]) {
    pendingCallbacks[name].push(callback)
    return
  } else {
    pendingCallbacks[name] = []
    pendingCallbacks[name].push(callback)
  }

  fetch(`assets/svgs/${name}.svg`)
    .then(response => response.text())
    .then(text => {
      text = text.trim()
      if (text.substring(0, 4) != "<svg") {
        console.error("invalid svg for: " + name)
        svgs[name] = ""
      } else {
        svgs[name] = text
      }

      const callbacks = pendingCallbacks[name]
      for (var i in callbacks) {
        callbacks[i](text)
      }
      pendingCallbacks[name] = null
    })
}

const SvgIcon = props => {
  const IconInner = React.forwardRef((props, ref) => (
    <div
      ref={ref}
      style={{
        width: props.width,
        height: props.height,
        fill: props.fill,
        stroke: props.stroke,
        strokeWidth: props.strokeWidth,
        padding: 2,
      }}
      // src={`assets/svgs/${props.name}.svg`}
    />
  ))

  const ref = React.createRef()

  try {
    if (!props.name) {
      return null
    }

    var height = props.height || 50
    var width = props.width || 50
    var stroke = props.stroke
    var fill = props.fill

    var strokeWidth = props.strokeWidth || 0.2

    stroke = "grey"

    var name = props.name

    const svg = svgs[name]
    if (svg) {
      return (
        <div
          style={{
            width: width,
            height: height,
            fill: fill,
            stroke: stroke,
            strokeWidth: strokeWidth,
          }}
          dangerouslySetInnerHTML={{__html: svg}}
        />
      )
    }

    loadSvg(name, svg => {
      var elm = ref.current
      if (elm) {
        elm.innerHTML = svg
      }
    })

    return (
      <IconInner
        ref={ref}
        width={width}
        height={height}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    )
  } catch (ex) {
    log.e(ex)
  }
}

export default SvgIcon

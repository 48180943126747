import React, {useState, useEffect, useLayoutEffect} from "react"
import {View, StyleSheet, Text} from "react-native"
import {useDispatch} from "react-redux"
import AppColors from "../../styles/AppColors"
import {GiftedChat} from "../../GiftedChat/GiftedChat"
import Global from "../../Global"
import Socket from "../../http/socket"
import Card from "./Card"
import {
  challengeFeedsApi,
  transformFeed,
  useGetFeedsQuery,
} from "../../store/reducers/ChallengeFeedsSlice"
import Account from "../../dataProvider/Account"
import {renderBubble, renderSystemMessage} from "../ChallengeFeeds"
import AppStyles from "../../styles/AppStyles"

const Card_ChallengeFeeds = props => {
  const dispatch = useDispatch()
  const [title, setTitle] = useState("")
  const [feed, setFeed] = useState([])
  const [user, setUser] = useState({
    _id: Account.userId,
    name: "",
    avatar: "https://i.pravatar.cc/300",
  })
  const queryInput = {challengeId: props.challengeId, baseUrl: Global.baseUrl}

  const {data, refetch, isLoading, isFetching} = useGetFeedsQuery(queryInput, {
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    if (data !== undefined) {
      const dataFixed = JSON.parse(JSON.stringify(data))
      const {title, feed} = dataFixed
      setTitle(title)
      setFeed(feed)
    }
  }, [data])

  useLayoutEffect(() => {
    Socket.subscribe("onReceiveMessage", onReceiveMessageHandler)
    Socket.connect()

    window.addEventListener("message", e => {
      if (e.data === "refreshFeed") {
        onRefresh()
      }
    })
  }, [])

  const onReceiveMessageHandler = function (data) {
    if (data.userId !== user._id) {
      let newItem = transformFeed(data)
      dispatch(
        challengeFeedsApi.util.updateQueryData(
          "getFeeds",
          queryInput,
          draftFeeds => {
            draftFeeds.feed.push(newItem)
          },
        ),
      )
    }
  }

  const onRefresh = () => {
    refetch()
  }

  const button = {
    text: "Zum Chat",
    onPress: () => {
      props.navigation.push("Challenge")
    },
  }

  const loadingText = (
    <View style={styles.loadingText}>
      <Text style={AppStyles.largeText}>Loading ...</Text>
    </View>
  )

  if (props.challengeId === null) return <></>

  return (
    <Card title={title + " Feed"} button={button}>
      <View nativeID={"chatContainer2"} style={styles.container}>
        {isLoading || isFetching ? (
          loadingText
        ) : (
          <GiftedChat
            messages={feed}
            user={user}
            inverted={false}
            textInputStyle={styles.textInputStyle}
            renderUsernameOnMessage={true}
            renderSystemMessage={renderSystemMessage}
            renderBubble={renderBubble}
            alwaysShowSend
            infiniteScroll={false}
            dateFormat={"DD.MM.YYYY"}
            timeFormat={"HH:mm"}
            inputDisabled
            isKeyboardInternallyHandled={false}
          />
        )}
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  container: {flex: 1, pointerEvents: "auto", padding: 10, height: 250},
  loadingText: {justifyContent: "center", alignItems: "center"},
  textInputStyle: {
    backgroundColor: AppColors.lightPlaceholder,
    borderRadius: 10,
    padding: 10,
  },
})

export default Card_ChallengeFeeds

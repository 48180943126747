// import { NativeModules } from 'react-native';
import Global from "../Global"

// const realnoteLogger = NativeModules.RealnoteLogger;

//
// Wrapper just for type safety
//

const hostLog = window["HostLog"]

class RealnoteLogger {
  tag: String = "U.U."

  constructor(tag: String) {
    this.tag = tag
  }

  e(msg: String) {
    if (Global.debugMode || Global.testMode) {
      hostLog?.e(this.tag, msg)
      return console.error(this.tag, msg)
    }
  }

  d(msg: String) {
    if (process.env.NODE_ENV == "development") {
      if (Global.debugMode || Global.testMode) {
        hostLog?.d(this.tag, msg)
        return console.log(this.tag, msg)
      }
    }
  }

  v(msg: String) {
    if (process.env.NODE_ENV == "development") {
      if (Global.debugMode || Global.testMode) {
        hostLog?.v(this.tag, msg)
        return console.log(this.tag, msg)
      }
    }
  }

  error(ex: any) {
    if (Global.debugMode || Global.testMode) {
      if (typeof ex == "string") {
        ex = new Error(ex)
      }

      hostLog?.e(this.tag, ex.message + "\n" + ex.stack)

      return console.error(this.tag, ex.message + "\n" + ex.stack)
    }

    // return realnoteLogger.e(this.tag, ex.message)
  }

  callstack() {
    if (process.env.NODE_ENV == "development") {
      if (Global.debugMode) {
        console.log(this.tag, new Error().stack)
      }
    }
  }
}

export default RealnoteLogger

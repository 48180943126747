import React, {Component} from "react"
import {View} from "react-native"

export default class LinearGradient extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View
        style={[
          {
            background:
              "linear-gradient(to bottom, transparent 0%, black 100%)",
          },
          this.props.style,
        ]}>
        {this.props.children}
      </View>
    )
  }
}

import React from "react"
import {TouchableOpacity, View} from "react-native"
import Icon from "../RealnoteIcon/Icon"
import Realnote from "../../bridges/RealnoteNative"
import {
  useFollowUserMutation,
  useUnfollowUserMutation,
  useGetIsUserFollowingQuery,
} from "../../store/reducers/scrollingNoteSlice"

export default function FollowButton(props) {
  const {userId, style} = props
  const {data: following} = useGetIsUserFollowingQuery({userId})
  const [follow] = useFollowUserMutation()
  const [unfollow] = useUnfollowUserMutation()

  function followUser() {
    Realnote.sendFirebaseClick("followUser")
    follow({userId})
  }

  function unfollowUser() {
    Realnote.sendFirebaseClick("unfollowUser")
    unfollow({userId})
  }

  return (
    <View style={[{width: 80}, style]}>
      {!following && (
        <TouchableOpacity onPress={followUser}>
          <Icon name="follow" height={44} width={80} />
        </TouchableOpacity>
      )}
      {following && (
        <TouchableOpacity onPress={unfollowUser}>
          <Icon name="followed" height={44} width={80} />
        </TouchableOpacity>
      )}
    </View>
  )
}

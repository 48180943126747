import React, {PureComponent} from "react"
import {Text, TouchableOpacity} from "react-native"
import RealnoteLogger from "../../bridges/RealnoteLogger"
import AppStyles from "../../styles/AppStyles"
import AppColors from "../../styles/AppColors"

const TAG = "ContextMenuButton"
const log = new RealnoteLogger(TAG)

export default class ContextMenuButton extends PureComponent {
  render() {
    try {
      if (!this.props.label) {
        log.v("render: no label, thus returning null")
        return null
      }
      return (
        <TouchableOpacity
          onPress={this.props.onPress}
          style={[
            {
              padding: 5,
            },
            this.props.style,
          ]}>
          <Text
            style={[
              AppStyles.largeText,
              {
                color: AppColors.black,
              },
              this.props.textStyle,
            ]}>
            {this.props.label}
          </Text>
        </TouchableOpacity>
      )
    } catch (error) {
      log.e("render error: " + error)
      return null
    }
  }
}

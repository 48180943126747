import React from "react"
import {TouchableOpacity, View} from "react-native"

export class Square extends React.Component {
  constructor(props) {
    super(props)
  }

  onPress = () => {
    this.props.onPress(this.props.color)
  }

  render() {
    return (
      // { onPress, others } = {... this.props}

      <TouchableOpacity
        onPress={this.onPress}
        style={{
          left: this.props.left,
          top: this.props.top,
          height: 45,
          width: 45,
          justifyContent: "center",
          alignItems: "center",
          alignItems: "center",
        }}>
        <View
          style={{
            height: this.props.size,
            width: this.props.size,
            height: this.props.size,
            borderRadius: this.props.size / 2,
            backgroundColor: this.props.color,
            borderColor: this.props.borderColor,
            borderWidth: this.props.borderWidth,
          }}
        />
      </TouchableOpacity>
    )
  }
}

export default {
  primary: "#02C2A2", // primary realnote color "AMAZONITE"
  secondary: "#565655", // brownish gray "DAVY'S GRAY",
  complementary: "#FF6903", // orange // e4572e
  secondary1: "#1459C7", // blue
  secondary2: "#FFA303", // yellow orange

  primary_Light: "#51D2BD", // primary realnote color "AMAZONITE"
  complementary_Light: "#FFA263", // orange // e4572e
  secondary1_Light: "#5F8DD6", // blue
  secondary2_Light: "#FFC663", // yellow orange

  card_Background: "#f0f0f0",

  mapPinGps: "#44c4ae", // primary realnote color, light
  mapPinNormal: "#02C2A2", // primary realnote color
  mapPinYYY: "#44c4ae", // primary realnote color, light

  primaryDark: "#018D76",
  // highlight: "#C66D6D", // salmon red / pink "FUZZY WUZZY"
  white: "#FAFAFA", // white "SNOW"
  trueWhite: "#FFFF",
  grayText: "#696A6A", // light brownish gray "DIM GRAY", same as gray90

  lightPlaceholder: "#999999",

  screenBackground: "#ffff", //

  transparentWhite: "#ffffffee",
  veryTransparentWhite: "#ffffffaa",

  transparentBlack: "#000000aa",

  highlight: "#EA526F",
  highlightTransparent: "#EA526Faa",
  highlight2: "#cad115",
  highlight2Transparent: "#cad115aa",

  waiting: "#cad115",

  text: "#000000",

  backgroundGray: "#E9EFF0",
  transparentGray: "#E9EFF0CC",
  veryTransparentGray: "#E9EFF088",
  transparentSecondary: "#56565588",
  // gray10: "#FAFAFA",
  gray30: "#E8E8E8",
  gray40: "#D3D3D3",
  gray50: "#BFBFBF",
  gray70: "#96939B",
  gray90: "#666666",

  transparentGray10: "#FAFAFA",
  transparentGray30: "#E8E8E8",
  transparentGray40: "#D3D3D3",
  transparentGray50: "#BFBFBFAA",
  transparentGray70: "#96939B",
  transparentGray90: "#696A6A",

  brown: "#22031F",

  black: "#000000",

  menuPanelBackground: "#E9EFF0",

  overlayLightBackground: "rgba(150,150,150,0.88)",
  overlayButton: "rgba(2,194,162,0.88)",

  panelBackground: "rgba(0,0,0,0.60)",
  panelBackgroundLight: "rgba(0,0,0,0.35)",
  panelBorder: "rgba(117,117,117,0.2)",

  settingsPanel: "rgba(0,0,0,0)",

  headingBottomBorder: "#808090",
}

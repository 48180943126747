import AppColors from "../../styles/AppColors"
import AppConstants from "../../styles/AppConstants"

const xstyles = {
  header: {
    marginTop: 10,
    height: 30,
    width: "100%",
    backgroundColor: "white", //AppColors.primary,
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 0,
  },
  headerText: {
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },

  cardActionButtonSpace: {
    position: "relavtive",
    float: "right",
    height: 30,
    marginRight: 5,
    marginBottom: 10,
    marginTop: 10,
    marginLeft: "auto",
    borderRadius: 15,
    paddingLeft: 15,
    paddingRight: 15,
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderColor: "transparent",
    borderWidth: 0,
    boxShadow: "4px 4px 6px #00000040",
    elevation: 3,
    color: "white",
    backgroundColor: "blue",
    visibility: "hidden",
  },

  cardActionButton: {
    position: "absolute",
    height: 30,
    right: 5,
    bottom: 10,
    borderRadius: 15,
    paddingLeft: 15,
    paddingRight: 15,
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: "white",
    fontSize: 14,
    borderColor: "transparent",
    borderWidth: 0,
    boxShadow: "4px 4px 6px #00000040",
    elevation: 3,
  },

  scrollArea: {
    overflowY: "overlay",
    pointerEvents: "auto",
    flex: 1,
  },

  cardArea: {
    marginTop: 10,
    paddingHorizontal: 20,
  },
  main: {
    margin: "auto",
    fontFamily: "Poppins",
    height: "100%",
    width: "100%",
    backgroundColor: "white", //  AppColors.primary_Light,
    flexDirection: "column",
    alignContent: "top",
    justifyContent: "top",
    pointerEvents: "auto",
    maxWidth: AppConstants.maxWidth,
  },
  gpsLink: {
    color: "blue",
    textDecorationLine: "underline",
    fontWeight: "bold",
  },
  card: {
    backgroundColor: AppColors.card_Background,
    width: "100%",
    height: "auto",
    minHeight: 50,
    padding: 10,
    paddingLeft: 15,
    borderRadius: 5,
    flexDirection: "column",
    alignItems: "flexStart",
    justifyContent: "top",
    pointerEvents: "auto",
    marginBottom: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.29,
    shadowRadius: 4.65,
    fontFamily: "Poppins",
    fontSize: 12,
    lineHeight: "1.3em",
    elevation: 7,
  },

  cardTitle: {
    fontWeight: "bold",
    marginBottom: 10,
    //    color: "white",
  },
  br: {
    height: 5,
  },
}

export default xstyles

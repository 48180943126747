import React, {PureComponent} from "react"
import {TouchableWithoutFeedback, View, StyleSheet} from "react-native"
import ProfileHeaderPicture from "./ProfileHeaderPicture"
import Username from "../../components/Profile/Username"
import CompleteProfileButton from "./buttons/CompleteProfileButton"
import Account from "../../dataProvider/Account"
import FollowButton from "../../components/Profile/FollowButton"

export default class ProfileHeader extends PureComponent {
  constructor(props) {
    super(props)
    this.profileHeaderPicture = React.createRef()
  }

  onPressHandler = () => {
    if (this.profileHeaderPicture) {
      this.profileHeaderPicture.current.hideMenu()
    }
  }

  render() {
    const {style, navigation, userId, onPressEditProfile, showRegisterButton, username} =
    this.props
    return (
      <TouchableWithoutFeedback
        style={[{width: "100%"}, style]}
        onPress={this.onPressHandler}>
        <View style={[styles.container, style]}>
          <ProfileHeaderPicture
            ref={this.profileHeaderPicture}
            navigation={navigation}
            userId={userId}
            showPictureMenu={userId === Account.userId}
          />
          <Username
            navigation={navigation}
            userId={userId}
            alreadyInProfile={true}
            username={username}
          />
          {showRegisterButton && (
            <CompleteProfileButton onPressEditProfile={onPressEditProfile} />
          )}
          {userId !== Account.userId && <FollowButton userId={userId} />}
        </View>
      </TouchableWithoutFeedback>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
})

import React, {useState, useEffect, useRef} from "react"
import {Text} from 'react-native'
import AppStyles, {xAppStyles} from "../../styles/AppStyles"
import AppConstants from "../../styles/AppConstants"
import Card from "./Card"
import xstyles from "./styles"

export default function Card_Map(props) {
  const button = {
    text: "Realnote Map",
    onPress: () => {
      props.navigation.push("Map")
    },
  }

  const w1 = 150 - 10 // 10 for padding left



  const imageLeft = (
    <div style={{position: "relative", width: w1, height: w1, display: "flex"}}>
      <img width={w1} height={w1} src="assets/images/Home/Map.webp" />
    </div>
  )
  return (
    <Card
      imageLeft={imageLeft}
      title={"Die Welt ist voller Notes"}
      button={button}
      waitForWeb1>
      Bei über 100.000 veröffentlichten Notes gibt es auch welche in deiner
      Umgebung! Öffne unsere Karte und finde heraus, wo unsere User überall
      aktiv sind.
    </Card>
  )
}

import Global from "./Global"
import Logger from "./bridges/RealnoteLogger"

const TAG = "ArMode"
const log = new Logger(TAG)

var isRunning = false
var isPaused = false
var isStartPending = false
var isStarting = false
var currentWebArSession = null
//
// Configure XR to display the "#root" element with the complete reactUi
// on top of the XR-CameraImage
//

var sessionInit = {
  optionalFeatures: [
    "dom-overlay",
    "bounded-floor",
    // "unbounded-floor", // not supported in chrome 96 on Samsung
    "local",
    "anchors",
    "plane-detection",
  ],
  domOverlay: {root: document.querySelector("#domOverlay")},
  requiredFeatures: ["hit-test"],
}

// renderer.render(scene, camera)

function onSessionStarted(session) {
  try {
    isStarting = false
    session.addEventListener("end", onSessionEnded)
    currentWebArSession = session

    setupThree()

    change()
  } catch (ex) {
    log.e(ex)
  }
}

function onSessionEnded(/*event*/) {
  log.d("onSessionEnded")
  if (isRunning) {
    log.e("armode running, but session ended called")
  }
  currentWebArSession?.removeEventListener("end", onSessionEnded)
  currentWebArSession = null
  if (window.web1ArModeCallback) {
    window.web1ArModeCallback.onSessionEnded()
  }
  change()
}

function renderAr(timestamp, frame) {
  if (isPaused) {
    return
  }

  if (currentWebArSession != null) {
    if (window.web1ArModeCallback) {
      const xr = window.renderer.xr
      if (xr) {
        const session = xr.getSession()
        if (session != currentWebArSession) {
          log.e(
            "invalid xr.session " +
              window.renderer.debugTag +
              "  tag1 " +
              window.renderer.xr.debugTag +
              " tag2  " +
              startSequence +
              " xr.session " +
              session +
              " session" +
              currentWebArSession,
          )
          window.renderer.xr.setSession(currentWebArSession)
        }
      }

      window.web1ArModeCallback.renderAr(timestamp, frame)
      return
    }
  }
}
function resumeAr() {
  log.d("resuming armode")
  if (!isPaused) {
    log.e("resumeAR without pause")
    startAr()
  }
  isPaused = false
  change()
}

function pauseAr() {
  if (isRunning) {
    isPaused = true
    log.d("pausing armode")
  }
  change()
}

function stopAr() {
  setBackgroundTransparent(false)
  isRunning = false
  // noteEditorModelPreview?.previewControls?.enabled = true
  log.d("stopping armode")
  if (currentWebArSession != null) {
    window.renderer.xr.enabled = false
    currentWebArSession.end()
    // currentWebArSession = null is done on onSessionEnded
  }
  change()
}

var startSequence = 0
function setupThree() {
  if (
    currentWebArSession &&
    window.renderer &&
    window.renderer.xr &&
    !window.renderer.xr.enabled
  ) {
    window.renderer.xr.enabled = true

    startSequence++
    window.renderer.xr.debugTag = "Sequ " + startSequence
    window.renderer.debugTag = "Sequ " + startSequence

    window.renderer.xr.setReferenceSpaceType("local")
    window.renderer.xr.setSession(currentWebArSession)
    window.renderer.setAnimationLoop(renderAr)
  } else {
    startArPending = true
  }
}

function startAr() {
  setupThree()

  if (isStarting) {
    return
  }

  if (isRunning && isPaused) {
    resumeAr()
    return
  }

  try {
    const scene = window.scene

    setBackgroundTransparent(true)

    log.d("startAr B")

    // noteEditorModelPreview?.previewControls?.enabled = false
    if (currentWebArSession == null) {
      log.d("startAr C")

      isRunning = true

      const xr = navigator.xr
      if (xr != null) {
        isStarting = true
        change()

        xr.isSessionSupported("immersive-ar").then(supported => {
          if (supported) {
            xr.requestSession("immersive-ar", sessionInit)
              .then(onSessionStarted)
              .catch(ex => {
                console.error(ex)
                isRunning = false
                isStarting = false
                isStartPending = false
                change()
              })
          } else {
            log.d("called startAr, but no WebXR support")
            isRunning = false
            isStarting = false
            isStartPending = false
            change()
          }
        })
      }
    }
  } catch (ex) {
    log.e(ex)
  }
}

var orgColor = null

function isArSupported() {
  return window.useWebXR // || IsEmbedded.check()
}

function setBackgroundTransparent(transparent) {
  const c = document.getElementById("container")
  if (c != null) {
    if (transparent) {
      if (orgColor == null) {
        orgColor = c.style.backgroundColor
      }
      c.style.backgroundColor = "transparent"
    } else {
      if (orgColor != null) {
        c.style.backgroundColor = "red"
      }
    }
  }
}

function restartArNeeded() {
  if (isStartPending || isStarting) {
    return false
  }

  return isRunning && currentWebArSession === null && !Global.isEmbedded()
}

function startArNeeded() {
  if (isStartPending || isStarting) {
    return false
  }

  return (!isRunning || currentWebArSession === null) && !Global.isEmbedded()
}

function startArPending() {
  return isStartPending
}

const events = []

function change() {
  for (var i in events) {
    try {
      const ev = {
        startArNeeded: startArNeeded(),
        restartArNeeded: restartArNeeded(),
      }
      events[i](ev)
    } catch (ex) {
      console.error(ex)
    }
  }
}

function addEventListener(name, callback) {
  if (callback) {
    for (var i in events) {
      if (events[i] === callback) {
        return
      }
    }

    events.push(callback)
  }
}

function removeEventListener(name, callback) {
  if (callback) {
    for (var i in events) {
      if (events[i] === callback) {
        delete events[i]
      }
    }
  }
}

export default {
  startAr,
  restartArNeeded,
  startArNeeded,
  startArPending,
  stopAr,
  pauseAr,
  resumeAr,
  addEventListener,
  removeEventListener,
}

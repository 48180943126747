import AppColors from "./AppColors"
import {Dimensions, PixelRatio} from "react-native"

const height = Dimensions.get("screen").height

function convertToDp(pxValue) {
  var ratio = PixelRatio.get()
  return pxValue / ratio
}

export default {
  convertToDp,

  // height of bottom menu bar
  menuBarHeight: 70,

  // default icon button size
  iconButtonSize: 50,

  // height of title header-like top bar to avoid overlap with top buttons of RealnoteScreen
  //  = this.iconButtonSize + 10 + this.buttonMarginToEdge
  topBarHeight: 65,

  // buttons sizes of buttons in realnote screen apart from menu bar buttons
  // should appear to be equal to menu bar button size,
  // so adjust menuButtonSize when adjusting this size
  realnoteScreenButtonSize: 46,

  // default menu bar button size,
  // don't forget to adjust realnoteScreenButtonSize when adjusting this size
  menuButtonSize: 30,

  // padding of touchable area of buttons (especially CircularIconButtons)
  touchablePadding: 20,

  // distance between (especially CircularIconButton) buttons and the screen border
  buttonMarginToEdge: 0,

  // color for lines that divide different sections on ui
  dividingBorderColor: AppColors.panelBorder,

  // default button color on ui, except for AR view
  buttonColor: AppColors.secondary,

  // default button background color
  buttonBackgroundColor: AppColors.transparentGray50,

  // default button color of highlighted buttons
  highlightedButtonColor: AppColors.primary,

  // image size for profile images in lists and similar images
  standardImageSize: height * 0.1,

  // profile image size in profile header
  profileHeaderImageSize: height * 0.12,

  // delay in milliseconds after which only to show loading indicator
  delayForLoadingIndicator: 700,
}

import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {
  challengeData_fetchChallengeData
} from "../actionTypes";
import {HttpBridge} from '../../http/HttpBridge';

const http = new HttpBridge();

const initialState = {
  challengeData: [],
  status: 'uninitialized',
  challengePopupShown: false
};

export const fetchChallengeData = createAsyncThunk(
    challengeData_fetchChallengeData,
    async (args, thunkAPI) => {
      return http.getChallengeData();
    });

export const challengeDataSlice = createSlice({
  name: 'challengeData',
  initialState,
  reducers: {
    setChallengePopupShown: (state) => {
      state.challengePopupShown = true;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchChallengeData.pending, (state, action) => {
      state.status = 'pending';
    });
    builder.addCase(fetchChallengeData.fulfilled, (state, {payload}) => {
      state.status = 'fulfilled';
      state.challengeData = payload;
    });
    builder.addCase(fetchChallengeData.rejected, (state, action) => {
      state.status = 'rejected';
    });
  }
});

export const selectChallengeDataStatus = state => state.challengeData.status;
export const selectChallengeData = state => state.challengeData.challengeData;
export const selectChallengePopupShown = state => state.challengeData.challengePopupShown;
export const {setChallengePopupShown} = challengeDataSlice.actions;

export default challengeDataSlice.reducer;

import React, {useEffect, useRef, useState} from "react"
import Account from "../dataProvider/Account"
import {emitter} from "../bridges/RealnoteNative"
import {Text, TouchableOpacity, View, StyleSheet} from "react-native"
import AppStyles from "../styles/AppStyles"
import AppColors from "../styles/AppColors"
import Icon from "./RealnoteIcon/Icon"
import Realnote from "../bridges/RealnoteNative"

const TAG = "NoteUpvotes"

export default function NoteUpvotes(props) {
  const {
    note,
    navigation,
    myOwn,
    upvoteNote,
    downvoteNote,
    refetchScrollingNotes,
  } = props
  const {voteCount, hasUserVoted, contentGuid, sceneGuid} = note
  const contentUpvotedSubscription = useRef()
  const contentUpvoteDeletedSubscription = useRef()
  const [userClickedOnUpvote, setUserClickedOnUpvote] = useState(hasUserVoted)

  useEffect(() => {
    contentUpvotedSubscription.current = emitter().addListener(
      TAG,
      "contentUpvoted",
      handleContentUpvotedChange,
    )
    contentUpvoteDeletedSubscription.current = emitter().addListener(
      TAG,
      "contentUpvoteDeleted",
      handleContentUpvotedChange,
    )
    return () => {
      contentUpvotedSubscription.current.remove()
      contentUpvoteDeletedSubscription.current.remove()
    }
  }, [])

  function handleNavigation() {
    navigation.navigate("Likers", {contentGuid: note.contentGuid})
  }

  function handleContentUpvotedChange(eventData) {
    if (
      contentGuid &&
      eventData.contentGuid &&
      contentGuid === eventData.contentGuid
    ) {
      refetchScrollingNotes()
    }
  }

  function upvote() {
    if (myOwn) {
      Realnote.sendFirebaseClick("scrolling_note_click_on_upvotes")
      handleNavigation()
      return
    }
    if (hasUserVoted) {
      Realnote.sendFirebaseClick("deleteUpvote")
      setUserClickedOnUpvote(false);
      downvoteNote({contentGuid, userId: Account.userId, sceneGuid})
    } else {
      Realnote.sendFirebaseClick("upvoteNote")
      setUserClickedOnUpvote(true);
      upvoteNote({contentGuid, userId: Account.userId, sceneGuid})
    }
  }

  return (
    <View style={styles.container}>
      {voteCount > 0 ? (
        <TouchableOpacity
          style={styles.upvoteTouchable}
          onPress={handleNavigation}>
          <Text style={[AppStyles.largeText, styles.upvoteText]}>
            {voteCount}
          </Text>
        </TouchableOpacity>
      ) : (
        <View style={styles.upvoteTouchable}>
          <Text style={[AppStyles.largeText, styles.upvoteText]}>{"0"}</Text>
        </View>
      )}
      <TouchableOpacity onPress={upvote} style={styles.icon}>
        {userClickedOnUpvote && (
          <Icon
            name="Upvoted"
            key={"NoteUpvotedButton"}
            height={22}
            width={22}
          />
        )}
        {!userClickedOnUpvote && (
          <Icon
            name="Upvote"
            key={"NoteUpvoteButton"}
            fill={"#000"}
            height={22}
            width={22}
          />
        )}
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingHorizontal: 5,
  },
  upvoteTouchable: {
    alignItems: "flex-end",
    paddingHorizontal: 3,
  },
  upvoteText: {
    textAlign: "right",
    color: AppColors.black,
    marginRight: 5,
  },
  icon: {
    flex: 0,
    justifyContent: "flex-start",
    alignItems: "flex-end",
    paddingHorizontal: 3,
    paddingVertical: 3,
  },
})

import React, {PureComponent} from "react"
import {TouchableWithoutFeedback, View} from "react-native"
import Account from "../../dataProvider/Account"
import Realnote, {emitter} from "../../bridges/RealnoteNative"
import Logger from "../../bridges/RealnoteLogger"
import ProfilePictureOverlay from "../../components/ProfilePicture/ProfilePictureOverlay"
import RewardPointsChip from "../../components/ProfilePicture/RewardPointsChip"
import ProfilePictureMenu from "../../components/ProfilePicture/ProfilePictureMenu"
import {HttpBridge} from "../../http/HttpBridge"
import StyleConstants from "../../styles/StyleConstants"
import FastImage from "../../components/XFastImage"
import AppColors from "../../styles/AppColors"

const TAG = "ProfileHeaderPicture"
const log = new Logger(TAG)
const http = new HttpBridge()

export default class ProfileHeaderPicture extends PureComponent {
  constructor(props) {
    super(props)
    var indexOfRandomAvatar = "18"
    if (this.props.userId != null) {
      indexOfRandomAvatar =
        (parseInt(this.props.userId.replace(/[^0-9]/g, "")) % 27) + 1
    }
    const nameOfRandomAvatar = "avatar_" + indexOfRandomAvatar + ".jpg"
    this.state = {
      loading: true,
      imageUrl: null,
      fallbackImageUsed: false,
      showOverlay: false,
      fallbackImage: nameOfRandomAvatar,
      showMenu: false,
    }

    if (!this.props.userId) {
      log.e("constructor error: there is no userId!")
    }
    this.isOwnImage = this.props.userId === Account.userId
    this.profilePictureMenu = React.createRef()
  }

  // region handle updates of profile picture or props.userId

  componentDidMount() {
    try {
      // TODO correct this again.
      this.getProfileImage()
      this._unsubscribe = this.props.navigation.addListener(
        "didFocus",
        this.getProfileImage,
      )
    } catch (e) {
      log.e("componentDidMount error: " + e)
    }
  }

  componentWillUnmount() {
    try {
      if (this.updatedProfilePicture) {
        this.updatedProfilePicture.remove()
      }
      if (this._unsubscribe) {
        this._unsubscribe.remove()
      }
    } catch (e) {
      log.e("componentWillUnmount error: " + e)
    }
  }

  // endregion

  getProfileImage = async () => {
    try {
      if (this.props.userId) {
        const result = await http.getProfileImageUrl(this.props.userId)
        log.d("getProfileImage Result: " + result)
        const domain = await http.getProfileImageLink()
        if (
          result != null &&
          result !== "" &&
          result !== domain + this.state.fallbackImage
        ) {
          this.setState({
            imageUrl: result,
            loading: false,
            fallbackImageUsed: false,
            showOverlay: false,
          })
          return
        } else {
          this.setState({
            imageUrl: domain + this.state.fallbackImage,
            loading: false,
            fallbackImageUsed: true,
          })
        }
      }

      this.checkIfOverlayIsNeeded()
    } catch (e) {
      log.e("getProfileImage error: " + e)
    }
  }

  checkIfOverlayIsNeeded() {
    try {
      if (
        this.props.userId === Account.userId &&
        this.props.onPressAddImage &&
        this.state.fallbackImageUsed
      ) {
        if (this.state.imageUrl == null || this.state.imageUrl == "") {
          this.setState({
            showOverlay: true,
          })
        } else {
          this.setState({
            imageUrl: this.state.imageUrl,
            fallbackImageUsed: false,
            showOverlay: false,
          })
        }
      }
    } catch (e) {
      log.e("checkIfOverlayIsNeeded error: " + e)
    }
  }

  /**
   * called when profile picture has been successfully deleted
   */
  onPictureDeleted = async () => {
    try {
      log.v("onImageDeleted")
      const domain = await http.getProfileImageLink()
      this.setState({
        imageUrl: domain + this.state.fallbackImage,
        fallbackImageUsed: true,
        showMenu: false,
      })
    } catch (e) {
      log.e("onImageDeleted error: " + e)
    }
  }

  onTakePhotoPressed = () => {
    try {
      this.setState({showMenu: false})
      if (this.props.navigation) {
        this.props.navigation.navigate("ProfileImage")
      }
    } catch (e) {
      log.e("onTakePhotoPressed error: " + e)
    }
  }

  hideMenu = async () => {
    await this.profilePictureMenu.current.revertAnimation()
    setTimeout(() => {
      this.setState({
        showMenu: false,
      })
    }, 500)
  }

  showMenu = () => {
    try {
      this.profilePictureMenu.current.initializeAnimation()
      this.setState(prevState => ({
        showMenu: !prevState.showMenu,
      }))
    } catch (e) {
      log.e("showMenu error: " + e)
    }
  }

  render = () => {
    try {
      log.v(
        "render userId = " +
          this.props.userId +
          " and this.props.showPictureMenu = " +
          this.props.showPictureMenu,
      )

      let source = {isStatic: false, uri: this.state.imageUrl}

      return (
        <TouchableWithoutFeedback
          disabled={this.props.userId !== Account.userId}
          onPress={() => {
            this.onPressImage()
          }}>
          <View
            style={[
              {
                width: StyleConstants.profileHeaderImageSize,
                height: StyleConstants.profileHeaderImageSize,
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              },
              this.props.style,
            ]}>
            {!this.state.showOverlay && (
              <FastImage
                style={{
                  width: StyleConstants.profileHeaderImageSize,
                  height: StyleConstants.profileHeaderImageSize,
                  borderRadius: StyleConstants.profileHeaderImageSize / 2,
                  borderWidth: 2,
                  borderColor: AppColors.primary,
                }}
                source={source}
                onError={() => {
                  this.setState({
                    fallbackImageUsed: true,
                    imageUrl: this.state.fallbackImage,
                  })
                }}
              />
            )}

            <ProfilePictureOverlay
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 2,
              }}
              onPress={() => {
                if (this.props.navigation) {
                  this.props.navigation.push("ProfileImage")
                }
              }}
              imageSize={StyleConstants.profileHeaderImageSize}
              hidden={!this.state.showOverlay}
            />

            <RewardPointsChip
              navigation={this.props.navigation}
              isOwn={this.props.userId === Account.userId}
              userId={this.props.userId}
              profileImageSet={!this.state.fallbackImageUsed}
            />

            <ProfilePictureMenu
              ref={this.profilePictureMenu}
              hidden={
                !this.props.showPictureMenu ||
                this.props.userId !== Account.userId ||
                !this.state.showMenu
              }
              showDeleteButton={!this.state.fallbackImageUsed}
              userId={this.props.userId}
              onTakePhotoPressed={this.onTakePhotoPressed}
              onDeleted={this.onPictureDeleted}
            />
          </View>
        </TouchableWithoutFeedback>
      )
    } catch (e) {
      log.e("render error: " + e)
      return null
    }
  }

  onPressImage() {
    try {
      if (!this.state.loading) {
        if (this.props.onPressProfileImage) {
          this.props.onPressProfileImage()
        }
        if (this.props.showPictureMenu) {
          if (this.state.showMenu) {
            this.hideMenu()
          } else {
            this.showMenu()
          }
        }
      }
    } catch (e) {
      log.e("onPressImage error: " + e)
    }
  }
}

import Account, {serverResponse} from "../dataProvider/Account"
import RealnoteLogger from "../bridges/RealnoteLogger"
import _realnote from "../bridges/RealnoteNative"
import {strings} from "../i18n"
import global from "../Global"
// import RNFetchBlob from 'react-native-fetch-blob';
import AsyncStorage from "@react-native-async-storage/async-storage"
// import {Platform} from "react-native";
import {store} from "../store/store"
import Challenge from "./Challenge"
import {getDistanceBetweenCoords} from "../assets/cityMappings"

const TAG = "HttpBridge"
const log = new RealnoteLogger(TAG)

const uuidv4 = require("uuid/v4")
const POLY_API_KEY = `AIzaSyCGcNPflKjIuq-rKZkz3Z1m8b6E0k9anL8`

const Platform = {OS: "web"}

let httpBridgeInstance = null

export class HttpBridge {
  get ServerEndpoint(): String {
    return global.baseUrl
  }

  // ServerEndpoint: String = "http://192.168.3.27:9000/api/";
  freezeLocationLongitude: String = ""
  freezeLocationLatitude: String = ""
  freezeTimestamp: String = ""
  freezeToken: String = ""

  // Might not be a clean way, but for now this caches the results of the Assets Meta Data for the GenerateContentButton
  cachedAssetsMetaData: JSON = null

  //return the same instance -> singleton
  constructor() {
    const instance = httpBridgeInstance
    if (instance) {
      return instance
    } else {
      httpBridgeInstance = this
      return this
    }
  }

  getServerEndpoint(): Promise<String> {
    return Promise.resolve(global.baseUrl)
  }

  async getUserLocationViaIP() {
    const endpoint = (await this.getServerEndpoint()) + "public/userLocation"
    const res = await fetch(endpoint, {
      method: "get",
      redirect: "follow",
      credentials: "include",
      headers: new Headers({
        authorization: "Bearer " + global.token,
      }),
    })

    const resJson = await res.json()
    return resJson
  }

  async getUserChallenges() {
    const endpoint =
      (await this.getServerEndpoint()) + "note/userChallenges/" + Account.userId
    const res = await fetch(endpoint, {
      method: "get",
      redirect: "follow",
      credentials: "include",
      headers: new Headers({
        authorization: "Bearer " + global.token,
      }),
    })

    const userlocation = (window as any).lastLocation
    const resJson = await res.json()
    resJson.forEach(challenge => {
      const challengeLocation = challenge.geoLocation.coordinates
      if (userlocation) {
        const distance = getDistanceBetweenCoords(
          challengeLocation[1],
          challengeLocation[0],
          userlocation.latitude,
          userlocation.longitude,
        )
        challenge.dist = {calculated: distance}
      }
    })
    return resJson
  }

  async addLink(key, document): Promise<String> {
    return new Promise(async (resolve, reject) => {
      if (!key || !document) {
        log.e(
          `Invalid key/document pair for deeplink: key:${key}/document:${document}`,
        )
        return null
      }

      const api = "public/addLink"
      const endpoint = (await this.getServerEndpoint()) + api
      const data = new FormData()
      data.append("key", key)
      data.append("document", document)
      const token = global.token

      return fetch(endpoint, {
        method: "post",
        body: data,
        credentials: "include",
        headers: new Headers({
          authorization: "Bearer " + global.token,
        }),
      }).then(async res => {
        if (res.status === 200) {
          resolve("")
        } else {
          reject(res.text())
        }
      })
    })
  }

  async uploadChallenge(challenge: Challenge, image) {
    if (challenge.adminId == "") {
      return {
        error: "Error: UserId Missing",
      }
    }
    if (image == null) {
      return {
        error: "Error: Image is Missing",
      }
    }
    if (challenge.website == "") {
      return {
        error: "Error: Website Missing",
      }
    }
    if (challenge.title == "") {
      return {
        error: "Error: Title is Missing",
      }
    }

    let api = "note/createChallenge"
    const endpoint = (await this.getServerEndpoint()) + api
    const data = new FormData()
    data.append("challengeImage", image, image.name)
    data.append("challenge", JSON.stringify(challenge))
    data.append("userId", Account.userId)

    return new Promise((resolve, reject) => {
      fetch(endpoint, {
        method: "post",
        body: data,
        credentials: "include",
        headers: new Headers({
          authorization: "Bearer " + global.token,
        }),
      }).then(async res => {
        if (res.status === 200) {
          let jsonRes = await res.json()
          resolve(JSON.stringify(jsonRes))
        } else {
          reject(res.text())
        }
      })
    })
  }

  async getToken() {}

  async autoRegisterLocal(adId: string): Promise<JSON> {
    if (adId == null) {
      log.v("Cannot autoRegister: adId == null")
      return
    }
    log.d("fetching autoRegisterLocal " + adId)
    return this.fetchJSONRejectable("account/autoRegisterLocal", {
      adId: adId,
    })
  }

  async unregisteredLoginInternal(isRetry = false): Promise<string> {
    let _androidId = Account.adId
    if (_androidId === undefined || _androidId === "") {
      _androidId = await Account.getUniqueUserId()
    }
    log.d("unregisteredLoginInternal " + _androidId)
    let params = new FormData()
    await this.getServerEndpoint()
    params.append("password", "realnote")
    params.append("username", _androidId)
    return new Promise((resolve, reject) => {
      log.v("trying to post unregistered Login: " + this.ServerEndpoint)
      fetch(this.ServerEndpoint + "account/loginLocal", {
        method: "post",
        body: params,
        credentials: "include",
        headers: new Headers({
          authorization: "Bearer " + global.token,
        }),
      })
        .then(async (res: Response) => {
          log.v("getting response from post")
          if ((res.statusText == "Error" || res.status == 401) && !isRetry) {
            this.unregisteredLoginInternal(true)
          } else {
            let json = await res.json()
            if (res.statusText == "success") {
              let _token = json["token"]
              let _showWelcomeScenes = json["showWelcomeScenes"]
              global.token = _token

              await AsyncStorage.setItem("token", _token)
              _realnote.sendTokenToBridge(_token)
              if (_showWelcomeScenes != null) {
                _realnote.sendStatusOfWelcomeNotesToWebApp(_showWelcomeScenes)
                global.welcomeNotesNeeded = _showWelcomeScenes
              }
            }
            resolve(JSON.stringify(json))
          }
        })
        .catch(error => {
          log.e("unregisteredLoginInternal rejection: " + error.message)
          resolve(JSON.stringify(error))
        })
        .catch(error => {
          log.e("unregisteredLogin error: " + error.toString())
          reject(JSON.stringify(error))
        })
    })
  }

  /**
   * Logs user in to RealNote with Credentials
   * If only username is supplied, the login is considered unregistered
   */
  async loginAccount(
    _username: string,
    _password: string = "password",
  ): Promise<string> {
    try {
      log.v("HttpBridge loginAccount:" + _username + " " + _password)
      if (this.ServerEndpoint == "") {
        await this.getServerEndpoint()
      }

      if (_username == null) {
        log.e("username must be of type string")
        return JSON.stringify({message: strings("Account.emptyFields")})
      }

      let params = new FormData()
      await this.getServerEndpoint()
      params.append("password", _password)
      params.append("username", _username)

      let res: Response = await fetch(
        this.ServerEndpoint + "account/loginLocal",
        {
          method: "post",
          body: params,
          credentials: "include",
          headers: new Headers({
            authorization: "Bearer " + global.token,
          }),
        },
      )

      log.v("response da")
      if (res.status === 401 || res.status === 403) {
        let returnData = {message: "unauthorized"}
        return JSON.stringify(returnData)
      } else {
        let json = await res.json()
        if (global.token != json["token"]) {
          global.token = json["token"]
          await AsyncStorage.setItem("token", json["token"])
          _realnote.sendTokenToBridge(json["token"])
        }
        if (json != null && json["showWelcomeScenes"] != null) {
          _realnote.sendStatusOfWelcomeNotesToWebApp(json["showWelcomeScenes"])
          global.welcomeNotesNeeded = json["showWelcomeScenes"]
        }

        if (Platform.OS !== "ios") {
          _realnote.checkNotificationIntent()
        }

        return JSON.stringify(json)
      }
    } catch (ex) {
      log.error(ex)

      let returnData = {message: strings("Account.failed") + " " + ex.message}
      return JSON.stringify(returnData)
    }
  }

  // Login Registered or Unregistered
  private loginInternal(): Promise<string> {
    if (Account.username === "" || Account.password === "null") {
      log.v("RealnoteManager UnregisteredLogin")
      return this.unregisteredLoginInternal()
    } else {
      log.v("RealnoteManager RegisteredLogin" + Account.username)
      // return this.loginLocal()
      return this.loginAccount(Account.username, Account.password)
    }
  }

  logOut(userId: String) {
    return new Promise<Object>((resolve, reject) => {
      if (userId != "") {
        let params = {}
        params["user"] = userId
        this.fetchJSONRejectable("account/logout", params)
          .then(result => {
            log.d("logOut result " + result)
            resolve(result)
          })
          .catch(error => {
            log.e("logOut error: " + error.toString())
          })
      } else {
        reject("empty userId")
      }
    })
  }

  /**
   * gets a boolean whether or not the user has completed the registering process
   * @param userId: userId of user
   */
  hasUserRegistered(userId: String) {
    let params = {}
    params["userId"] = userId

    return new Promise<Boolean>(resolve => {
      if (userId == null) {
        resolve(false)
      } else {
        this.fetchJSONRejectable("account/isUserRegistered", params)
          .then((result: JSON) => {
            if (JSON.stringify(result) == "false") {
              resolve(false)
            } else if (JSON.stringify(result) == "true") {
              resolve(true)
            } else {
              resolve(false)
            }
          })
          .catch(error => {
            log.e("hasUserRegistered fetchJSONRejectable error: " + error)
            resolve(false)
          })
      }
    })
  }

  /**
   * gets profile information of a user
   * @param userId
   * @param usernameAndPoints: only need the username and number of reward points of user
   * @param usernameAndFollowers: only need the username and number of followers of user
   * @param notesFollowerFollowees: only need the number of notes, followers and followees of user
   * @param username: only need the user's username
   * @param appVersion: also needs the app version which was used by the user to login the last time
   * @returns a JSON object with keys: username, numOfPostedNotes, totalRating and numOfFollowers.
   * OR rejects!!
   */
  getProfilePreview(
    userId: String,
    usernameAndPoints: boolean = false,
    usernameAndFollowers: boolean = false,
    notesFollowerFollowees: boolean = false,
    username: boolean = false,
    appVersion: boolean = false,
  ) {
    let params = new FormData()
    params.append("userId", userId.toString())
    params.append("onlyUsernamePoints", usernameAndPoints.toString())
    params.append("onlyUsernameFollowers", usernameAndFollowers.toString())
    params.append(
      "onlyNotesFollowerFollowees",
      notesFollowerFollowees.toString(),
    )
    params.append("onlyUsername", username.toString())
    params.append("appVersion", appVersion.toString())
    // params["userId"] = userId;
    // params["onlyUsernamePoints"] = usernameAndPoints.toString();
    // params["onlyUsernameFollowers"] = usernameAndFollowers.toString();
    // params["onlyNotesFollowerFollowees"] = notesFollowerFollowees.toString();
    // params["onlyUsername"] = username.toString();
    // params["appVersion"] = appVersion.toString();

    return this.fetchJSONRejectable("note/getProfilePreview", params)
  }

  /**
   * resets the password to the chosen new one for a user
   * if the correct password reset token is provided
   * @param userId: userId of user who wants to reset his/her password
   * @param token: password reset token
   * @param password: new password of user
   * @param email: email address of user
   * @param username: username of user
   * @returns JSON or rejects.
   */
  reset(
    userId: String,
    token: String,
    password: String,
    email: String,
    username: String,
  ): Promise<JSON> {
    let params = {}
    params["userId"] = userId
    params["token"] = token
    params["password"] = password
    params["email"] = email
    params["username"] = username
    return this.fetchJSONRejectable("account/reset", params)
  }

  getAccountInfo(): Promise<Object | null> {
    let params = {}
    log.v("userId: " + Account.userId)
    params["userId"] = Account.userId
    try {
      return this.fetchJSONRejectable("account/getAccountInfo", params)
    } catch (e) {
      log.e("getAccountInfo error: " + e)
      return null
    }
  }

  public fetchJSON2(
    endpoint: string,
    params: FormData,
    relogin: boolean = false,
  ): Promise<JSON> {
    let awaitUrl: Promise<String> = this.getServerEndpoint()
    return new Promise((resolveP, rejectP) => {
      awaitUrl.then(serverUrl => {
        fetch(serverUrl + endpoint, {
          method: "post",
          body: params,
          credentials: "include",
          headers: new Headers({
            authorization: "Bearer " + global.token,
          }),
        }).then(
          async (res: Response) => {
            log.v(res.status.toString())
            if (res.status === 200) {
              let jsonRes = await res.json()
              //log.v("http" + JSON.stringify(jsonRes))
              resolveP(jsonRes)
            } else if (!relogin && res.status === 403) {
              log.v("Relogin line 450")
              await this.loginInternal()
              return this.fetchJSON2(endpoint, params, true)
            } else {
              rejectP("Server Error")
            }
          },
          reject => {
            rejectP(reject)
          },
        )
      })
    })
  }

  public fetchString(
    endpoint: string,
    body: any,
    relogin: boolean = false,
    formData: FormData = null,
  ): Promise<string> {
    let awaitUrl: Promise<String> = this.getServerEndpoint()
    // create FormData from body obj
    if (formData === null) {
      formData = new FormData()
      for (let key in body) {
        formData.append(key, body[key])
      }
    }
    log.d("fetchString http fetchString:" + endpoint + JSON.stringify(formData))
    return new Promise<string>((resolveP, rejectP) => {
      if (body != null) {
        try {
          awaitUrl.then(serverUrl => {
            log.d(serverUrl)
            fetch(serverUrl + endpoint, {
              method: "post",
              body: formData,
              credentials: "include",
              headers: new Headers({
                authorization: "Bearer " + global.token,
              }),
            }).then(
              async (response: any) => {
                if (response.status === 200) {
                  let sResult = await response.text()
                  log.v("http - " + endpoint + " result: " + sResult)
                  resolveP(sResult)
                } else if (!relogin && response.status === 403) {
                  log.v("Relogin")
                  await this.loginInternal()
                  return this.fetchString(endpoint, body, true)
                } else {
                  log.e(
                    "fetchString statuscode != 403 and != 200, error: " +
                      response.status,
                  )
                  rejectP([])
                }
              },
              async reject => {
                if (!relogin) {
                  log.v("Relogin")
                  await this.loginInternal()
                  return this.fetchString(endpoint, body, true)
                } else {
                  log.e(
                    "fetchString axios reject error:" + JSON.stringify(reject),
                  )
                  rejectP(reject)
                }
              },
            )
          })
        } catch (ex) {
          log.e("fetchString http error" + ex)
        }
      } else {
        log.e("fetchString body == null")
        rejectP("[]")
      }
    })
  }

  public async fetchJSONFormData(
    endpoint: string,
    body: FormData,
    relogin: boolean = false,
  ): Promise<JSON> {
    let awaitUrl: Promise<String> = this.getServerEndpoint()
    log.d("http fetchJSON:" + endpoint)
    return new Promise<JSON>((resolveP, rejectP) => {
      if (body != null) {
        try {
          awaitUrl.then(serverUrl => {
            log.d("fetchJSONFormatData url: " + serverUrl)
            fetch(awaitUrl + endpoint, {
              method: "post",
              body: body,
              credentials: "include",
              headers: new Headers({
                authorization: "Bearer " + global.token,
              }),
            }).then(
              async (response: Response) => {
                //log.d("fetchJSONFormData response: " + JSON.stringify(response.json()))
                if (response.status === 200) {
                  let value = await response.json()
                  //log.v("http" + JSON.stringify(response))
                  resolveP(value)
                } else if (!relogin && response.status == 403) {
                  log.v("Relogin line 595")
                  await this.loginInternal()
                  return this.fetchJSONFormData(endpoint, body, true)
                } else {
                  log.e(
                    "fetchJSONFormatData status code != 403 and != 200, error:" +
                      response.status,
                  )
                  rejectP([])
                }
              },
              async reject => {
                log.d("Reject: line 603")
                if (!relogin) {
                  log.v("Relogin line 605")
                  await this.loginInternal()
                  return this.fetchJSONFormData(endpoint, body, true)
                } else {
                  log.e(
                    "fetchJSONFormatData axios reject error:" +
                      JSON.stringify(reject),
                  )
                  rejectP(reject)
                }
              },
            )
          })
        } catch (ex) {
          log.e("fetchJSONFormatData http error" + ex)
        }
      } else {
        log.e("fetchJSONFormatData body == null")
        rejectP([])
      }
    })
  }

  public async fetchJSONRejectableFormData(
    endpoint: string,
    params: FormData,
    relogin: boolean = false,
  ): Promise<JSON> {
    log.d("fetchJSONRejectableForm:" + endpoint)
    let awaitUrl = await this.getServerEndpoint()
    return new Promise<JSON>((resolveP, rejectP) => {
      if (params != null) {
        try {
          log.d(awaitUrl)
          fetch(awaitUrl + endpoint, {
            method: "post",
            body: params,
            credentials: "include",
            headers: new Headers({
              authorization: "Bearer " + global.token,
            }),
          }).then(
            async (res: Response) => {
              log.v("fetchJSONRejectable: post finished")
              if (res.status === 200) {
                resolveP(res.json())
              } else if (!relogin) {
                log.v(
                  "fetchJSONRejectable: Relogin, relogin = " +
                    relogin +
                    ", endpoint = " +
                    endpoint,
                )
                await this.loginInternal()
                this.fetchJSONRejectable(endpoint, params, true).then(
                  resolveP,
                  rejectP,
                )
              } else {
                log.e(
                  "fetchJSONRejectable error, response.status = :" +
                    res.status +
                    ", endpoint = " +
                    endpoint,
                )
                rejectP([])
              }
            },
            async rejection => {
              if (!relogin) {
                log.v(
                  "fetchJSONRejectable: rejection endpoint = " +
                    endpoint +
                    ", relogin, rejection = " +
                    JSON.stringify(rejection),
                )

                await this.loginInternal()
                this.fetchJSONRejectable(endpoint, params, true).then(
                  resolveP,
                  rejectP,
                )
              } else {
                log.e(
                  "fetchJSONRejectable error, request to endpoint " +
                    endpoint +
                    " was rejected: " +
                    JSON.stringify(rejection),
                )
                rejectP([])
              }
            },
          )
        } catch (ex) {
          log.e("http fetchJSONRejectable error" + ex)
        }
      } else {
        log.e("fetchJSONRejectable error: body == null")
        rejectP([])
      }
    })
  }

  public fetchJSONRejectable(
    endpoint: string,
    body: any,
    relogin: boolean = false,
    multipart: boolean = false,
  ): Promise<JSON> {
    log.d("fetchJSONRejectable:" + endpoint)
    let config = {
      withCredentials: true,
      headers: {
        authorization: "Bearer " + global.token,
      },
    }
    if (multipart) {
      config.headers["content-type"] = "multipart/form-data"
      config["data"] = body
      log.v("use multipart header")
      //log.v(JSON.stringify(config));
    }
    let params = new FormData()
    //check if body is not already a FormData
    if (body != null && !(body instanceof FormData)) {
      for (let i in body) {
        params.append(i, body[i])
      }
    } else {
      params = body
    }
    return new Promise<JSON>((resolveP, rejectP) => {
      if (body != null) {
        try {
          let url = this.ServerEndpoint + endpoint
          fetch(url, {
            method: "post",
            body: params,
            credentials: "include",
            headers: new Headers({
              authorization: "Bearer " + global.token,
            }),
          }).then(
            async (res: Response) => {
              let json = await res.json()

              log.v("fetchJSONRejectable: post finished")
              if (res.status === 200) {
                // log.v("http " + endpoint + ": " + JSON.stringify(res))
                resolveP(json)
              } else if (!relogin) {
                log.v(
                  "fetchJSONRejectable: Relogin, relogin = " +
                    relogin +
                    ", endpoint = " +
                    endpoint,
                )
                await this.loginInternal()
                this.fetchJSONRejectable(endpoint, body, true).then(
                  resolveP,
                  rejectP,
                )
              } else {
                log.e(
                  "fetchJSONRejectable error, response.status = :" +
                    res.status +
                    ", endpoint = " +
                    endpoint,
                )
                rejectP([])
              }
            },
            async rejection => {
              if (!relogin) {
                log.v(
                  "fetchJSONRejectable: rejection endpoint = " +
                    endpoint +
                    ", relogin, rejection = " +
                    JSON.stringify(rejection),
                )

                await this.loginInternal()
                this.fetchJSONRejectable(endpoint, body, true).then(
                  resolveP,
                  rejectP,
                )
              } else {
                log.e(
                  "fetchJSONRejectable error, request to endpoint " +
                    endpoint +
                    " was rejected: " +
                    JSON.stringify(rejection),
                )
                rejectP([])
              }
            },
          )
        } catch (ex) {
          log.e("http fetchJSONRejectable error" + ex)
        }
      } else {
        log.e("fetchJSONRejectable error: body == null")
        rejectP([])
      }
    })
  }

  public fetchJSONWithoutReject(
    endpoint: string,
    body: any,
    relogin: boolean = false,
  ): Promise<JSON> {
    log.d("fetchJSONWithoutReject:" + this.ServerEndpoint + endpoint)
    let config = {
      withCredentials: true,
      headers: {
        authorization: "Bearer " + global.token,
      },
    }

    let params = new FormData()
    for (let i in body) {
      params.append(i, body[i])
    }

    return new Promise<JSON>(resolveP => {
      if (body != null) {
        try {
          log.d(this.ServerEndpoint)
          let url = this.ServerEndpoint + endpoint
          fetch(url, {
            method: "post",
            body: params,
            credentials: "include",
            headers: new Headers({
              authorization: "Bearer " + global.token,
            }),
          }).then(
            async (res: Response) => {
              log.v("fetchJSONWithoutReject: post finished")

              let json = await res.json()
              if (res.status === 200) {
                log.v("http" + JSON.stringify(json))
                resolveP(json)
              } else if (!relogin) {
                log.v(
                  "fetchJSONRejectable: Relogin, relogin = " +
                    relogin +
                    ", endpoint = " +
                    endpoint,
                )
                //await this.login();
                this.fetchJSONWithoutReject(endpoint, body, true).then(resolveP)
              } else {
                log.e(
                  "fetchJSONWithoutReject error, response.status = :" +
                    res.status +
                    ", endpoint = " +
                    endpoint,
                )
                resolveP(json)
              }
            },
            async rejection => {
              log.v(
                "fetchJSONWithoutReject: rejection endpoint = " +
                  endpoint +
                  ", relogin, rejection = " +
                  JSON.stringify(rejection),
              )

              if (!relogin) {
                this.fetchJSONWithoutReject(endpoint, body, true).then(resolveP)
              } else {
                log.e(
                  "fetchJSONWithoutReject error, request to endpoint " +
                    endpoint +
                    " was rejected: " +
                    JSON.stringify(rejection),
                )

                if (rejection.response && rejection.response.data) {
                  const response = rejection.response.data
                  resolveP(response)
                } else if (rejection.message) {
                  resolveP(rejection.message)
                } else {
                  resolveP(rejection)
                }
              }
            },
          )
        } catch (ex) {
          log.e("http fetchJSONWithoutReject error")
          resolveP(null)
        }
      } else {
        log.e("fetchJSONWithoutReject error: body == null")
        resolveP(null)
      }
    })
  }

  async joinChallenge(challengeId): Promise<JSON> {
    log.d("joinChallenge")
    let body = {
      userId: Account.userId,
      challengeId: challengeId,
    }
    return this.fetchJSONRejectable("note/joinChallenge", body, false)
  }

  async updateChallenge(challengeData, image) {
    const {
      website,
      title,
      description,
      imageUrl,
      backgroundColor,
      shortTitle,
      geoLocation,
      challengeGuid,
    } = challengeData

    const {coordinates} = geoLocation

    if (website == "") {
      return {
        error: "Error: Website Missing",
      }
    }
    if (title == "") {
      return {
        error: "Error: Title is Missing",
      }
    }
    if (description == "") {
      return {
        error: "Error: Description is Missing",
      }
    }

    let challenge = {
      title,
      description,
      isPublic: true,
      geolocation: {coordinates: [coordinates[1], coordinates[0]]},
      website,
      imageUrl,
      shortTitle,
      backgroundColor,
      challengeGuid,
    }
    const data = new FormData()
    let api = "note/updateChallenge"

    if (image) {
      data.append("challengeImage", image, image.name)
    }
    data.append("challenge", JSON.stringify(challenge))
    // data.append("challengeId", challengeId)

    return new Promise((resolve, reject) => {
      this.fetchJSONRejectableFormData(api, data)
        .then(
          res => {
            resolve(res)
          },
          rej => {
            log.e("HttpBridge error" + rej)
            reject("HttpBridge error" + rej)
          },
        )
        .catch(error => {
          log.e("getListofContentsPublic error: " + error)
          reject("getListofContentsPublic error: " + error)
        })
    })

    // return new Promise((resolve) => {
    //   fetch(ApiEndpoint.get() + api, {
    //     method: "post",
    //     body: data,
    //     headers: new Headers({
    //       authorization: "Bearer " + _token,
    //     }),
    //   }).then(async (res) => {
    //     let jsonRes = await res.json();
    //     resolve(JSON.stringify(jsonRes));
    //   });
    // });
  }

  async getChallengeData(): Promise<JSON | null> {
    let params = new FormData()
    let location = await _realnote.getLastKnownLocation()
    // @ts-ignore
    const {latitude, longitude} = location
    this.freezeTimestamp = new Date().valueOf().toString(10)

    params.append("radius", "20000")
    params.append("userId", Account.userId)

    if (location !== null) {
      this.freezeLocationLatitude = latitude
      this.freezeLocationLongitude = longitude
      params.append("longitude", latitude)
      params.append("latitude", longitude)
    } else {
      params.append("longitude", "7.0741301")
      params.append("latitude", "50.7136063")
    }
    let test = "note/getNearChallenges"
    return new Promise((resolve, reject) => {
      this.fetchJSONRejectableFormData(test, params)
        .then(
          (res: JSON) => {
            let jsonRes = this.extendJsonListWithoutDownload(res)

            resolve(jsonRes)
          },
          rej => {
            log.e("HttpBridge error" + rej)
            reject("HttpBridge error" + rej)
          },
        )
        .catch(error => {
          log.e("getListofContentsPublic error: " + error)
          reject("getListofContentsPublic error: " + error)
        })
    })
  }

  /**
   * get a list of content of various types - actually gets scenes
   * @param listType: types of lists: "Best", "Matched", "Surroundings"
   * @param lastGuid: last guid of last request
   * @param skip: number of items to skip
   */
  getListOfContents(
    listType: string,
    lastGuid: undefined,
    skip: number = 0,
    limit: number,
  ): Promise<JSON | null> {
    log.v("---TEST---")
    let _start = Date.now()
    log.v("Explorer Timing Test " + listType + "Skip: " + skip)
    return new Promise<JSON | null>(resolve => {
      if (skip == null) {
        skip = 0
      }
      if (limit == null) {
        limit = 0
      }
      if (listType == null) {
        listType = "Hot"
      }
      log.v("Test2")
      try {
        this.getListOfContentsInner(listType, lastGuid, skip, limit)
          .then(
            (sResult: JSON) => {
              log.v(
                "Timing getListOfContents:" + (Date.now() - _start).toString(),
              )
              resolve(sResult)
            },
            rej => {
              log.v("getListOfContents rejection: " + rej)
              resolve(null)
            },
          )
          .catch((err: string) => {
            log.v("getListOfContents error: " + err)
            resolve(null)
          })
      } catch (err) {
        log.e("getListOfContents error:" + err)
        resolve(null)
      }
    })
  }

  async sendStickerRequestEmail(address, challengeGuid): Promise<void> {
    this.fetchString("note/requestSticker", {
      userId: Account.userId,
      address: JSON.stringify(address),
      challengeGuid,
    })
  }

  /**
   * gets profile image of user
   * @param userId
   * @param onlyPath: boolean whether or not
   * we only want to get the path of the image
   * rather than trying to download it also if nonexistent
   * @returns null if no profileImageis uploaded
   */
  async getProfileImageUrl(userId: String): Promise<String | null> {
    if (userId == null) {
      userId = Account.userId
    }
    const link = await this.getProfileImageLink()

    const blobName = await this.fetchString("note/getProfilePictureUrl", {
      userId: userId,
    })
    log.v("imageUrl: " + link + blobName)
    if (blobName == "" || !blobName || blobName == "[]") {
      return null
    }
    return link + blobName
  }

  async getProfileImageLink(): Promise<string> {
    let server = await this.getServerEndpoint()
    return server + "public/getProfilePicture/"
  }

  hasUserVoted(contentGuid: String, userId: String) {
    if (contentGuid != null && userId != null) {
      return new Promise<Boolean>((resolve, reject) => {
        let params = {
          guid: contentGuid,
          userId: userId,
        }
        //params.append("guid", contentGuid.toString())
        //params.append("userId", userId.toString())
        // params["guid"] = contentGuid
        // params["userId"] = userId

        this.fetchJSONRejectable("note/hasUserVoted", params)
          .then(sResult => {
            log.d("HasUserUpvoted return: " + sResult)
            if (sResult) {
              resolve(true)
            } else if (sResult == null) {
              resolve(false)
            } else {
              resolve(false)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return new Promise(resolve => resolve(false))
    }
  }

  /**
   * adds an upvote to the content including info who (userId) upvoted
   * @param guid: guid of upvoted content
   * @param userId: userId of user who voted
   * @param sceneGuid: guid of scene the content belongs to
   */
  upvoteContent(guid: String, userId: String, sceneGuid: String) {
    if (guid != null && userId != null && sceneGuid != null) {
      log.v("upvoteContent called")
      return new Promise<String>((resolve, reject) => {
        let storeState = store.getState()
        let challengeId = storeState.challengeMode.challengeID
        let challengeActive = storeState.challengeMode.isActive
        if (challengeActive) {
          this.fetchString("note/upvoteNote", {
            guid: guid,
            userId: userId,
            currentChallengeGuid: challengeId,
          })
            .then(sResult => {
              log.d("Upvote return: " + sResult)
              if (sResult === "Successfully upvoted") {
                //this.upVoteContent(guid, userId, sceneGuid);
                resolve("success")
              } else if (sResult == null) {
                reject(null)
              } else {
                resolve(sResult)
              }
            })
            .catch(error => {
              reject(error)
            })
        } else {
          this.fetchString("note/upvoteNote", {
            guid: guid,
            userId: userId,
          })
            .then(sResult => {
              log.d("Upvote return: " + sResult)
              if (sResult === "Successfully upvoted") {
                //this.upVoteContent(guid, userId, sceneGuid);
                resolve("success")
              } else if (sResult == null) {
                reject(null)
              } else {
                resolve(sResult)
              }
            })
            .catch(error => {
              reject(error)
            })
        }
      })
    }
  }

  /**
   * removes the upvote of a user
   * @param guid: content guid of formerly upvoted content
   * @param userId: userId of user who formerly upvoted the content
   * @param sceneGuid: scene guid of scene the content belongs to
   */
  deleteUpvote(guid: String, userId: String, sceneGuid: String) {
    if (guid != null && userId != null && sceneGuid != null) {
      return new Promise<String>((resolve, reject) => {
        this.fetchString("note/deleteUpvote", {
          guid: guid,
          userId: userId,
        })
          .then(sResult => {
            log.d("deleteUpvote return: " + sResult)
            if (sResult === "Successfully deleted upvote") {
              resolve("success")
            } else if (sResult == null) {
              reject(null)
            } else {
              resolve(sResult)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }

  /**
   * get the number of upvotes for a content
   * @param guid: guid of content
   */
  getContentRating(guid: String): Promise<Object | null> {
    if (guid == null) {
      guid = ""
    }

    let params = {}
    params["guid"] = guid
    try {
      return this.fetchJSONRejectable("note/getNoteRating", params)
    } catch (e) {
      log.v("getContentRating error: " + e)
      return null
    }
  }

  getUsersLikingContent(
    contentGuid: String,
    skip: Number,
  ): Promise<Object | null> {
    let sSkip = ""
    if (skip) {
      sSkip = skip.toString()
    }
    let params = {}
    params["contentGuid"] = contentGuid
    params["skip"] = sSkip

    if (contentGuid == null) {
      return null
    } else {
      try {
        return this.fetchJSONRejectable("note/getUsersLikingContent", params)
      } catch (e) {
        log.v("getUsersLikingContent error: " + e)
        return null
      }
    }
  }

  getMoreGroupModels(group: String, skip: Number): Promise<JSON> {
    return new Promise<JSON>((resolve, reject) => {
      try {
        let params = {}
        params["userId"] = Account.userId
        params["type"] = "model"
        params["group"] = group
        params["environment"] = global.environment
        if (skip != null) {
          params["skip"] = skip.toString()
        }
        // get assets preview
        this.fetchJSONRejectable("note/getMoreGroupModels", params).then(
          (Result: JSON) => {
            if (Result) {
              resolve(Result)
            } else {
              resolve(null)
            }
          },
        )

        // return assets info to react-native
      } catch (ex) {
        log.e("getAsset exception caught: " + ex)
        reject("error")
      }
    })
  }

  getAssetsUploadedByUser(skip: Number): Promise<JSON> {
    return new Promise<JSON>(async (resolve, reject) => {
      try {
        let params = {
          userId: Account.userId,
        }
        if (skip != null) {
          params["skip"] = skip.toString()
        }
        this.fetchJSONRejectable("note/getAssetsUploadedByUser", params).then(
          (Result: JSON) => {
            if (Result) {
              log.v("getAssetsUploadedByUser: " + JSON.stringify(Result))
              resolve(Result)
            } else {
              resolve(null)
            }
          },
        )
      } catch (ex) {
        log.e("getAssetsUploadedByUser exception caught: " + ex)
        reject("error")
      }
    })
  }

  async deleteAsset(guid: String): Promise<string> {
    let params = {AssetGuid: guid.toString()}
    const res = await this.fetchString("note/deleteAssets", params)
    return res
  }

  getModelData(type: String, skip: Number): Promise<JSON> {
    return new Promise<JSON>(async (resolve, reject) => {
      try {
        let params = {
          type: type.toString(),
          userId: Account.userId,
          environment: global.environment,
        }

        // params["type"] = type.trim()
        // params["userId"] = Account.userId
        if (skip != null) {
          params["skip"] = skip.toString()
          //   params["skip"] = skip.toString()
        }
        // get assets preview
        const versionAppendix =
          "appVersion=" + (await _realnote.getAppVersion())
        log.v("appendix: " + versionAppendix)

        this.fetchJSONRejectable(
          "note/getUserAssets?" + versionAppendix,
          params,
        ).then((Result: JSON) => {
          if (Result) {
            log.v("getModelData: ") //+ JSON.stringify(Result));
            resolve(Result)
          } else {
            resolve(null)
          }
        })

        // return assets info to react-native
      } catch (ex) {
        log.e("getAsset exception caught: " + ex)
        reject("error")
      }
    })
  }

  /**
   * Report a Comment in the NoteView
   * @param commentMessage
   * @param contentGuid
   * @param commentGuid The Id of the reported Comment
   * @param userId The reporting UserId
   * @param reportMessage
   */
  reportComment(
    commentMessage: String,
    contentGuid: String,
    commentGuid: String,
    userId: String,
    reportMessage: String,
  ): Promise<String | null> {
    let params = {}
    params["commentMessage"] = commentMessage
    params["contentGuid"] = contentGuid
    params["commentGuid"] = commentGuid
    params["creatorId"] = userId
    params["reportMessage"] = reportMessage
    return new Promise<String | null>(resolve => {
      try {
        this.fetchJSONRejectable("note/reportcomment", params).then(
          (result: JSON) => {
            if (
              JSON.stringify(result) === '"Ok"' ||
              JSON.stringify(result) === "Ok"
            ) {
              resolve(serverResponse.ok)
            } else if (result == null) {
              resolve(null)
            } else {
              resolve(JSON.stringify(result))
            }
          },
        )
      } catch (e) {
        log.e("reportComment error: " + e)
        resolve(null)
      }
    })
  }

  /**
   * gets a sorted list of users
   * @param type: types of sorting: "MostRewardPoints", "MostFollowers"
   */
  getUserRanking(type: String, skip: Number, limit: Number): Promise<JSON> {
    const s = skip || 0
    const l = limit || 20
    log.v(
      "getUserRanking skip, limit = " +
        skip +
        ", " +
        limit +
        ", will be passed as: " +
        s +
        ", " +
        l,
    )
    let params = new FormData()
    params.append("skip", s.toString())
    params.append("limit", l.toString())
    // params["skip"] = s.toString();
    // params["limit"] = l.toString();

    return new Promise<JSON>(resolve => {
      this.fetchJSONRejectable("note/getUsersWith" + type, params)
        .then((Result: JSON) => {
          if (Result) {
            resolve(Result)
          } else {
            resolve(null)
          }
        })
        .catch(() => {
          log.e("getUsersWith" + type + " error: ")
          resolve(null)
        })
    })
  }

  // get Map data with the query params bound and zoom level from the get request
  async getMapData(bounds, zoom) {
    let api = "note/map"
    let query = "?zoom=" + zoom + "&bounds=" + bounds
    let _token = global.token
    let serverUrl = await this.getServerEndpoint()
    return new Promise((resolve, reject) => {
      fetch(serverUrl + api + query, {
        method: "get",
        credentials: "include",
        headers: new Headers({
          authorization: "Bearer " + _token,
        }),
      }).then(async res => {
        if (res.status === 200) {
          let jsonRes = await res.json()
          resolve(jsonRes)
        } else {
          reject(res.statusText)
        }
      })
    })
  }

  getOwnScenesForMap(
    latitude: number,
    longitude: number,
    radius: number,
  ): Promise<JSON | null> {
    return new Promise<JSON | null>(resolve => {
      let params = new FormData()

      params.append("userId", Account.userId)
      params.append("longitude", longitude.toString())
      params.append("latitude", latitude.toString())
      params.append("radius", radius.toString())
      log.v("getOwnScenesForMap params: " + JSON.stringify(params))
      this.fetchJSONRejectable("note/getNearScenesOfUser", params)
        .then((result: JSON) => {
          resolve(result)
        })
        .catch(() => {
          log.e("getOwnScenesForMap: error")
          resolve(null)
        })
    })
  }

  getNearScenesForHomepage(): Promise<JSON | null> {
    return new Promise<JSON | null>(resolve => {
      let params = new FormData()

      const lastLocation = (window as any).lastLocation
      if (lastLocation) {
        params.append("limit", "100")
        params.append("scope", "#public")
        params.append("appVersion", "100")

        // params.append("userId", Account.userId)
        params.append("longitude", lastLocation.longitude.toString())
        params.append("latitude", lastLocation.latitude.toString())
        params.append("radius", "100")

        log.v("getNearScenesForHomepage params: " + JSON.stringify(params))
        this.fetchJSONRejectable("note/getNearScenes", params)
          .then((result: JSON) => {
            resolve(result)
          })
          .catch(() => {
            log.e("getOwnScenesForMap: error")
            resolve(null)
          })
      } else {
        resolve(null)
      }
    })
  }

  getScenesOfFolloweesForMap(
    latitude: number,
    longitude: number,
    radius: number,
  ): Promise<JSON | null> {
    return new Promise<JSON | null>(resolve => {
      let params = {}
      params["userId"] = Account.userId
      params["longitude"] = longitude.toString()
      params["latitude"] = latitude.toString()
      params["radius"] = radius.toString()

      this.fetchJSONRejectable("note/getNearScenesOfFollowees", params)
        .then((result: JSON) => {
          resolve(result)
        })
        .catch(() => {
          log.e("getScenesOfFolloweesForMap: error")
          resolve(null)
        })
    })
  }

  getChallengeNotesForMap(challengeID: string): Promise<JSON | null> {
    return new Promise<JSON | null>(resolve => {
      log.d("MapFilterChallenge: in http call")

      let params = {
        userId: Account.userId,
        challengeID: challengeID,
      }

      log.v("getChallengeNotesForMap params: " + JSON.stringify(params))
      this.fetchJSONRejectable("note/getChallengeScenes", params)
        .then((result: JSON) => {
          resolve(result)
        })
        .catch(() => {
          log.e("getOwnScenesForMap: error")
          resolve(null)
        })
    })
  }

  /**
   * get scenes for map
   * @param latitude
   * @param longitude
   * @param radius
   * @param maxCount
   */

  async getDataForMap(
    latitude: number,
    longitude: number,
    radius: number,
    maxCount: number,
  ): Promise<JSON> {
    try {
      log.d("http getDataForMap called")

      let params = {}
      params["longitude"] = longitude.toString()
      params["latitude"] = latitude.toString()
      params["radius"] = radius.toString()
      params["limit"] = maxCount.toString()

      return new Promise<JSON>((resolveP, rejectP) => {
        // TODO: remove this endpoint note/getScenesFromArea and use the same one as for
        //  surroundings list, because on the server, the same method is called
        this.fetchJSONRejectable("note/getScenesFromArea", params).then(
          res => {
            log.d("Http getDataForMap note/getScenesFromArea call successful")
            if (res != null) {
              let _json: JSON = res
              for (const key in _json) {
                if (Object.prototype.hasOwnProperty.call(_json, key)) {
                  let element = _json[key]
                  let jsPos = {}
                  jsPos["latitude"] = element["latitude"]
                  jsPos["longitude"] = element["longitude"]
                  _json[key]["position"] = jsPos
                }
              }
              log.d(
                "http Answer for getDataForMap: ${jsonArray.toJsonString()}",
              )
              resolveP(_json)
            } else {
              log.e("http Answer for getDataForMap was null")
              rejectP("")
            }
          },
          rej => {
            log.e("getDataForMap http fetchJSONRejectable error")
            rejectP("")
          },
        )
      })
    } catch (e) {
      log.e("getDataForMap error: " + e)
    }
  }

  /**
   * gets the number of views/scene matches for a scene
   * @param guid: guid of scene
   */
  getMatchCount(guid: String): Promise<number> {
    let params = new FormData()
    //params["sceneGuid"] = guid.toString()
    params.append("sceneGuid", guid.toString())
    return new Promise<number>(resolve => {
      this.fetchJSONRejectable("note/getMatchCount", params)
        .then((sResult: JSON | null) => {
          if (sResult == null) {
            resolve(0)
          } else {
            if (sResult["matchCount"] != null) {
              resolve(sResult["matchCount"])
            }
            resolve(0)
          }
        })
        .catch(() => {
          log.e("getMatchCount error")
          resolve(0)
        })
    })
  }

  async getListOfContentsInner(
    listType: string,
    lastGuid: string,
    skip: number,
    limit: number,
  ): Promise<JSON> {
    let params = new FormData()
    params["radius"] = "300"

    params["limit"] = 7
    params.append("appVersion", "100")
    if (this.freezeLocationLatitude === "" || listType !== "Hot") {
      this.freezeTimestamp = new Date().valueOf().toString(10)
      log.v("Getting User Location")
      // @ts-ignore
      let location: JSON = await _realnote.getLastKnownLocation()
      if (location != null) {
        this.freezeLocationLatitude = location["latitude"]
        this.freezeLocationLongitude = location["longitude"]
        params.append("longitude", location["longitude"])
        params.append("latitude", location["latitude"])
      } else {
        params.append("longitude", "7.0741301")
        params.append("latitude", "50.7136063")
      }
    } else {
      params.append("longitude", this.freezeLocationLongitude.toString())
      params.append("latitude", this.freezeLocationLatitude.toString())
    }
    if (listType === "Hot") {
      params.append("timestamp", this.freezeTimestamp.toString())
    }

    if (listType == "Surroundings") {
      params.append("radius", "999999")
    }

    if (skip != null) {
      params.append("skip", skip.toString())
    }
    if (limit != null) {
      params.append("limit", limit.toString())
    }
    if (lastGuid != null) {
      params.append("guid", lastGuid.toString())
    }
    log.v("Params for getting scenes list: " + JSON.stringify(params))
    let test = "note/get" + listType + "Scenes"
    return new Promise((resolve, reject) => {
      this.fetchJSONRejectableFormData(test, params)
        .then(
          (res: JSON) => {
            // log.v("Explore Test:" + JSON.stringify(res))
            let JsonList = this.extendJsonListWithoutDownload(res)
            resolve(JsonList)
          },
          rej => {
            log.e("HttpBridge error" + rej)
            reject("HttpBridge error" + rej)
          },
        )
        .catch(error => {
          log.e("getListofContentsPublic error: " + error)
          reject("getListofContentsPublic error: " + error)
        })
    })
  }

  async getAssetsLink(): Promise<String> {
    let url = await this.getServerEndpoint()
    return url + "public/previewImage/"
  }

  getAssetsData(type: String, skip: Number = 0): Promise<JSON> {
    return new Promise<JSON>(async (resolve, reject) => {
      try {
        let params = new FormData()
        params.append("type", type.trim())
        params.append("skip", skip.toString())
        params.append("userId", Account.userId)
        // params["type"] = type.trim()
        // params["skip"] = skip.toString()
        // params["userId"] = Account.userId
        // get assets preview
        const versionAppendix =
          "appVersion=" + (await _realnote.getAppVersion())
        log.v("appendix: " + versionAppendix)
        let assets = this.fetchJSONRejectableFormData(
          `note/getPreviewAssets?${versionAppendix}`,
          params,
        )
        resolve(assets)
      } catch (ex) {
        log.e("getAsset exception caught: " + ex)
        reject("error")
      }
    })
  }

  async getAssetsMetaDataForGenerateContentButton(): Promise<JSON> {
    if (this.cachedAssetsMetaData) {
      return this.cachedAssetsMetaData
    } else {
      return new Promise((resolve, reject) => {
        fetch(`https://realnote.one/assets/allFiles`)
          .then(async (response: any) => {
            response.text().then(async (text: string) => {
              // log.v("response text: " + text)
              const json = JSON.parse(text)
              this.cachedAssetsMetaData = json
              resolve(json)
            })
          })
          .catch(error => {
            log.e("getAssetsDataForGenerateContentButton error: " + error)
            reject(error)
          })
      })
    }
  }

  async getAssetSvgForGenerateContentButton(
    assetPath: string,
  ): Promise<String> {
    return new Promise((resolve, reject) => {
      fetch(`https://realnote.one/assets/${assetPath}`)
        .then(async (response: Response) => {
          response.text().then(text => {
            log.v("getAssetSvgForGenerateContentButton: " + text)
            resolve(text)
          })
        })
        .catch(error => {
          log.e("getAssetsDataForGenerateContentButton error: " + error)
          reject(error)
        })
    })
  }

  // Note: while the query indicates that the language requested should be in english, the results might contain localities translated to
  // english and in original. E.g. requests for coordinates in cologne might result in 10 results with some containing the name "Köln" and
  // some the name "Cologne". There does not seem to be any specific ordering or structure allowing to deduce which one contains the correct
  // english translation, requiring possible extra steps to handle that.
  async getGoogleGeocodeResults(
    lat: string,
    long: string,
    forceEnglish = false,
  ): Promise<String> {
    return new Promise((resolve, reject) => {
      const GeocodeAPIKey = "AIzaSyDoHkbYYfkopXRbFotloJJiSdPInpRMf4c"
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${GeocodeAPIKey}${
          forceEnglish ? "&region=US&language=en" : ""
        }`,
      )
        .then(async (response: any) => {
          response.text().then(text => {
            const json = JSON.parse(text)
            resolve(json)
          })
        })
        .catch(error => {
          log.e("getAssetsDataForGenerateContentButton error: " + error)
          reject(error)
        })
    })
  }

  extendJsonListWithoutDownload(jsonArray: JSON): JSON {
    for (let key in jsonArray) {
      let item = jsonArray[key]
      item["sceneGuid"] = item["guid"] as String

      let jsPos = {}
      if (item.geoLocation != null && item.geoLocation.coordinates != null) {
        jsPos["latitude"] = item.geoLocation.coordinates[1]
        jsPos["longitude"] = item.geoLocation.coordinates[0]
        item["position"] = jsPos
      }
      if (item["challengeId"] != null) {
        item["imageUrl"] =
          this.ServerEndpoint + "public/previewImage/" + item["imageUrl"]
      } else {
        if (item["version"] == null || item["version"] < 2) {
          item["previewUrl"] =
            this.ServerEndpoint +
            "public/previewImage/" +
            item["sceneGuid"] +
            ".jpeg"
        } else {
          item["previewUrl"] =
            this.ServerEndpoint +
            "public/previewImage/" +
            item["sceneGuid"] +
            ".webp"
          if (item["version"] > 3) {
            item["listUrl"] =
              this.ServerEndpoint +
              "public/previewImage/list_" +
              item["sceneGuid"] +
              ".webp"
          }
        }
      }
    }

    return jsonArray
  }

  /**
   * gets a user's followers
   * @param userId
   */
  getFollowers(userId: String): Promise<JSON | null> {
    return new Promise<JSON | null>(resolve => {
      this.fetchJSONRejectable("note/getFollowers", {userId: userId}).then(
        (sResult: JSON) => {
          resolve(sResult)
        },
        (reject: any) => {
          resolve(null)
        },
      )
    })
  }

  /**
   * get the users a user is following
   * @param userId
   */
  getFollowedUsers(userId: String): Promise<JSON | null> {
    return new Promise<JSON | null>(resolve => {
      this.fetchJSONRejectable("note/getFollowedUsers", {userId: userId}).then(
        (sResult: JSON) => {
          resolve(sResult)
        },
        (reject: any) => {
          resolve(null)
        },
      )
    })
  }

  /**
   * get user's friends, i. e. currently followers and followees
   * @param userId
   */
  getFriendsUserIds(userId: String): Promise<object[]> {
    return new Promise<Object[]>(async resolve => {
      // realnote.fetchString('note/getFriends', {"userId": userId}).then((result) => {
      //   const friends = JSON.parse(result);
      //   resolve(friends);
      const followers = await this.getFollowers(userId)
      const followees = await this.getFollowedUsers(userId)
      log.e("implement merg of followers ans followees")
      const friends = [] //  ...followers, ...followees ];
      resolve(friends)
    })
  }

  shareNote(
    _shareNoteGuid: String,
    _sourceUserId: String,
    _isGpsNote: String,
    _targetUserArray: [String],
  ) {
    return this.fetchJSONRejectable("note/shareNote", {
      shareNoteGuid: _shareNoteGuid,
      sourceUserId: _sourceUserId,
      isGpsNote: _isGpsNote as String,
      //"targetUserArray": ["f1919929-ff15-46c1-b534-c83109efc02f", "02c68a0e-c046-4501-b363-9247d270bc0c"]
      targetUserArray: _targetUserArray,
    })
  }

  shareSculpture(
    _shareNoteGuid: String,
    _sourceUserId: String,
    _isGpsNote: String,
    _targetUserArray: [String],
  ) {
    return this.fetchJSONRejectable("note/shareSculpture", {
      shareNoteGuid: _shareNoteGuid,
      sourceUserId: _sourceUserId,
      isGpsNote: _isGpsNote as String,
      targetUserArray: _targetUserArray,
    })
  }

  getPlaygrounds(
    userId: String,
    skip: Number,
    itemCount: Number,
  ): Promise<JSON> {
    log.v("getPlaygrounds called with userId: " + userId)
    const s = skip || 0
    const n = itemCount || 10
    return this.fetchJSONRejectable("note/getPlaygrounds", {
      userId: userId,
      skip: s.toString(),
      limit: n.toString(),
    })
  }

  leavePlayground(shareGuid: String, userId: String) {
    return this.fetchString("note/leavePlayground", {
      shareGuid: shareGuid,
      userId: userId,
    })
  }

  getShareData(shareSceneGuid: String): Promise<JSON> {
    return this.fetchJSONRejectable("note/getShareData", {
      shareSceneGuid: shareSceneGuid,
    })
  }

  /**
   * ownUserId follows foreignUserId
   * @param ownUserId
   * @param foreignUserId
   */
  followUser(ownUserId: String, foreignUserId: String) {
    return this.fetchString("note/followUser", {
      ownUserId: ownUserId,
      foreignUserId: foreignUserId,
    })
  }

  /**
   * ownUserId does not follow foreignUserId anymore
   * @param ownUserId
   * @param foreignUserId
   */
  unfollowUser(ownUserId: String, foreignUserId: String) {
    return this.fetchString("note/unfollowUser", {
      ownUserId: ownUserId,
      foreignUserId: foreignUserId,
    })
  }

  /**
   * checks whether ownUserId is following foreignUserId
   * @param ownUserId
   * @param foreignUserId
   */
  getIsUserFollowing(
    ownUserId: String,
    foreignUserId: String,
  ): Promise<JSON | null> {
    try {
      return this.fetchJSONRejectable("note/isUserFollowing", {
        ownUserId: ownUserId,
        foreignUserId: foreignUserId,
      })
    } catch (e) {
      log.e("getUserFollowing error: " + e)
      return null
    }
  }

  /**
   * checks whether user with userId is blocked by this user
   * @param userId userId of user we want to check
   */
  isUserBlocked(userId: String) {
    return this.fetchJSONRejectable("note/isUserBlocked", {
      blockerUserId: Account.userId,
      blockedUserId: userId,
    })
  }

  /**
   * unblocks a user
   * @param blockedUserId userId of user that should be unblocked
   */
  async unblockUser(blockedUserId: String) {
    const userId = Account.userId
    if (!userId) {
      log.error("unblockUser error: no userId!")
      return
    }
    const result = await this.fetchJSONRejectable("note/unblockUser", {
      blockerUserId: userId,
      blockedUserId: blockedUserId,
    })
    if (result["message"] === serverResponse.ok) {
    }
    return result
  }

  /**
   * deletes a user's profile image
   * @param userId
   */
  deleteProfileImage(userId: String) {
    return this.fetchString("note/deleteProfilePicture", {userId: userId})
  }

  setReferencedNotePlaced(
    sourceUserId: String,
    shareNoteGuid: String,
    sceneGuid: String,
  ) {
    let ownUserId = Account.userId
    if (sourceUserId != null && ownUserId != null) {
      this.fetchString("note/placedSharedNote", {
        shareNoteGuid: shareNoteGuid,
        targetUserId: ownUserId,
        sourceUserId: sourceUserId,
        sceneGuid: sceneGuid,
      })
        .then(res => {
          log.v("setReferencedNotePlaced: " + JSON.stringify(res))
        })
        .catch(e => {
          log.e("setReferencedNotePlaced error: " + e)
        })
    }
  }

  setPlaygroundPlaced(sourceUserId: String, sceneGuid: String) {
    const ownUserId = Account.userId
    if (sourceUserId && ownUserId) {
      return this.fetchString("note/placedSharedNote", {
        shareNoteGuid: sceneGuid,
        targetUserId: ownUserId,
        sourceUserId: sourceUserId,
        sceneGuid: sceneGuid,
      })
    }
  }

  setPlaygroundTitle(sceneGuid: String, playgroundTitle: String) {
    try {
      return this.fetchJSONRejectable("note/setPlaygroundTitle", {
        shareNoteGuid: sceneGuid,
        playgroundTitle: playgroundTitle,
      })
    } catch (e) {
      return {message: e}
    }
  }

  setPlaygroundPictureTaken(sceneGuid: String, pictureTaken: Boolean) {
    try {
      return this.fetchJSONRejectable("note/setPlaygroundPictureTaken", {
        shareNoteGuid: sceneGuid,
        playgroundPictureTaken: pictureTaken,
      })
    } catch (e) {
      log.e("setPlaygroundPictureTaken error: " + e)
      return {message: e}
    }
  }

  /**
   * get all contents belonging to a scene
   * @param sceneGuid: guid of scene whose contents are requested
   */
  getContentsInScene(sceneGuid: String): Promise<JSON | null> {
    return new Promise<JSON | null>(resolve => {
      this.fetchJSONRejectable("note/getContentsInScene", {
        sceneGuid: sceneGuid,
      })
        .then((sResult: JSON) => {
          log.d("content in scene: " + JSON.stringify(sResult))
          resolve(sResult)
        })
        .catch(() => {
          log.e("getContentsInScene error: ")
          resolve(null)
        })
    })
  }

  changePassword(newPassword: String) {
    return this.fetchJSONWithoutReject("account/changePassword", {
      userId: Account.userId,
      username: Account.username,
      newPassword: newPassword,
    })
  }

  changeEmail(newEmail: String) {
    return this.fetchJSONWithoutReject("account/changeEmail", {
      userId: Account.userId,
      username: Account.username.trim().toLowerCase(),
      newEmail: newEmail.trim().toLowerCase(),
    })
  }

  changeUsername(newUsername: String) {
    console.log("changeUsername: " + Account.userId)
    return this.fetchJSONWithoutReject("account/changeUsername", {
      userId: Account.userId.trim(),
      newUsername: newUsername.trim().toLowerCase(),
    })
  }

  /**
   * get users who have matched a scene
   * @param sceneGuid
   * @param skip: number of users to skip
   */
  getUsersFromMatches(sceneGuid: String = "", skip: Number = 0): Promise<JSON> {
    try {
      return this.fetchJSONRejectable("note/getUsersFromMatches", {
        sceneGuid: sceneGuid,
        skip: skip,
      })
    } catch (e) {
      return null
    }
  }

  /**
   * get the date a scene was last matched
   * @param sceneGuid
   */
  getLastMatchDate(sceneGuid: String): Promise<JSON> {
    try {
      return this.fetchJSONRejectable("note/getLastMatchDate", {
        sceneGuid: sceneGuid,
      })
    } catch (e) {
      return null
    }
  }

  /**
   * sends a report-note message to server
   * to report a note
   * @param username: username of user who reports a note
   * @param email: email address of user who reports a note
   * @param _reportMessage: message containing all relevant information
   */
  report(
    username: String,
    email: String,
    _sceneGuid: String,
    _contentGuid,
    _reportMessage: String,
    _creatorId,
  ) {
    return new Promise<String>((resolve, reject) => {
      this.fetchJSONRejectable("note/reportContent", {
        username: username,
        email: email,
        reportMessage: _reportMessage,
        sceneGuid: _sceneGuid,
        contentGuid: _contentGuid,
        creatorId: _creatorId,
      })
        .then(sResult => {
          log.d(
            "Feedback return: " +
              JSON.stringify(sResult) +
              " ==success: " +
              (JSON.stringify(sResult) == '"success"'),
          )
          if (JSON.stringify(sResult) === '"success"') {
            resolve(serverResponse.ok)
          } else if (sResult == null) {
            reject(null)
          } else {
            resolve(JSON.stringify(sResult))
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * @param sceneGuid
   */
  getAllMatchesOfScene(sceneGuid: String): Object {
    try {
      return this.fetchJSONRejectable("note/getAllMatchesOfScene", {
        sceneGuid: sceneGuid,
      })
    } catch (e) {
      return null
    }
  }

  /**
   * @param guid: ContentGuid
   * @param skip: SKip Comment Number
   */
  getComments(guid: String, skip: Number, limit: Number) {
    try {
      let sGuid = ""
      if (guid != null) {
        sGuid = guid.toString()
      }
      let sSkip = null
      if (skip != null) {
        sSkip = skip.toString()
      }

      let sLimit = null
      if (limit != null) {
        sLimit = limit.toString()
      }

      return this.fetchJSONRejectable("note/getContentComments", {
        guid: sGuid,
        skip: sSkip,
        limit: sLimit,
      })
    } catch (e) {
      return null
    }
  }

  /**
   * @param contentGuid: ContentGuid
   * @param commentGuid: The CommentGuid
   *
   */
  async deleteComment(
    contentGuid: String,
    commentGuid: String,
    contentOwnerId: String,
  ) {
    if (contentGuid != null && commentGuid != null && contentOwnerId != null) {
      try {
        let userId = Account.userId
        return this.fetchJSONRejectable("note/deleteComment", {
          guid: commentGuid,
          contentGuid: contentGuid,
          userId: userId,
          contentOwnerId: contentOwnerId,
        })
      } catch (e) {
        return null
      }
    }
  }

  dataURLtoBlob(dataurl) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new Blob([u8arr], {type: mime})
  }

  uploadProfilePicture(dataURL: String) {
    try {
      const binaryImageBlob = this.dataURLtoBlob(dataURL)

      // No matter the actual file type, upload with ending of .jpg, because
      // the servers searches for this when trying to delete the file and finding png's would thus need server changes
      // png's still work and naming is only relevant for search on server side, so should not be a problem.

      // const ending = ${binaryImageBlob.type.split("/")[1]}
      const ending = ".profilePicture.jpg"
      let params = new FormData()
      params.append(
        "profilePicture",
        binaryImageBlob,
        `profilePicture.${ending}`,
      )
      params.append("userId", Account.userId)
      fetch(this.ServerEndpoint + "note/uploadProfilePicture", {
        method: "post",
        body: params,
        credentials: "include",
        headers: new Headers({
          authorization: "Bearer " + global.token,
        }),
      })
    } catch (ex) {
      return null
    }
  }

  /////////////////////////
  // region unused stuff //
  /////////////////////////

  /**
   * @param userId
   * @param skip
   * @param itemCount
   */
  getSharedNotes(userId: String, skip: Number, itemCount: Number) {
    try {
      const s = skip || 0
      const n = itemCount || 5
      return this.fetchJSONRejectable("note/getShareInvitations", {
        userid: userId,
        skip: s.toString(),
        limit: n.toString(),
      })
    } catch (e) {
      return null
    }
  }

  // endregion
}

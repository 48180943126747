import React, {Component} from "react"
import {
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native"
import {strings} from "../i18n"
import AppColors from "../styles/AppColors"

export default class Input extends Component {
  state = {
    text: "", // user's input
  }

  // Update state when input changes
  changeText = value => {
    this.setState({text: value})
  }

  // Handle return press on the keyboard
  // NOTE: You don't really need it for this example, because
  // we're using a keyboard without return button, but I left it here
  // in case you'd want to switch to a different keyboard
  //onSubmitEditing = ({nativeEvent: {text}}) => this.setState({text}, this.submit);

  // Call this.props.onSubmit handler and pass the comment
  submit = () => {
    if (this.state.text) {
      this.props.onSubmit(this.state.text)
      this.setState({text: ""})
    }
  }

  render() {
    if (!this.props.enabled) {
      return (
        <View>
          <Text>{strings("checkNetwork")}</Text>
        </View>
      )
    }
    return (
      <View
        style={{
          flexDirection: "row",
          borderTopWidth: 1,
          borderColor: "#EEE",
          alignItems: "center",
          paddingLeft: 15,
        }}>
        {/* Comment input field */}
        <TextInput
          placeholder={strings("Note.addComment")}
          placeholderTextColor={AppColors.secondary}
          autoFocus={false} // focus and show the keyboard
          value={this.state.text}
          style={{
            overflow: "hidden",
            //verhinder das verspringen
            top: this.props.topTextOffset || 0,
            flex: 1,
            height: 40,
            fontSize: 15,
            color: AppColors.black,
          }}
          onChangeText={this.changeText}
          onEndEditing={this.submit} // handle input changes
        />
        {/* Post button */}
        <TouchableOpacity
          style={{
            height: 40,
            paddingHorizontal: 15,
            alignItems: "center",
            justifyContent: "center",
          }}
          onPress={this.submit}>
          {/* Apply inactive style if no input */}
          <Text
            style={[
              {
                color: AppColors.primary,
                fontWeight: "bold",
                fontFamily: "Poppins",
                textAlign: "center",
                fontSize: 15,
              },
              !this.state.text ? {color: AppColors.secondary} : [],
            ]}>
            {strings("Note.post")}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }
}

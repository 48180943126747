import React, {PureComponent} from "react"
import {View} from "react-native"
import RealnoteLogger from "../../bridges/RealnoteLogger"
import AppColors from "../../styles/AppColors"

const TAG = "TextMenu"
const log = new RealnoteLogger(TAG)

export default class TextMenu extends PureComponent {
  render() {
    try {
      return (
        <View
          style={[
            {
              paddingHorizontal: 10,
              paddingVertical: 2,
              alignSelf: "flex-end",
              backgroundColor: AppColors.backgroundGray,
              borderRadius: 8,
              borderWidth: 3,
              borderColor: AppColors.panelBorder,
              alignItems: "flex-end",
              justifyContent: "flex-end",
            },
            this.props.style,
          ]}>
          {this.props.children}
        </View>
      )
    } catch (error) {
      log.e("render error: " + error)
      return null
    }
  }
}

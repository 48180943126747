import React, {Component} from "react"
import {Picker, Text, View} from "react-native"
import realnote from "../../bridges/RealnoteNative"
import Logger from "../../bridges/RealnoteLogger"
import FastImage from "../XFastImage"
import {HttpBridge} from "../../http/HttpBridge"

const http = new HttpBridge()
const TAG = "DetectionList"
const log = new Logger(TAG)

/**
 * component that shows detection info for each match
 * including detection images
 */
export default class DetectionList extends Component {
  state = {
    info: {detections: []},
    list: [{timestamp: "current", founder: "-"}],
  }

  async downloadMatch(pick) {
    log.d("download Match: " + pick)
    if (pick != "current") {
      var res = await realnote.compareSceneToMatch(this.props.sceneGuid, pick)
      if (res != null) {
        this.setState({info: res})
      }
    }
  }

  convertDate(unix_timestamp) {
    if (unix_timestamp == "current") {
      return unix_timestamp
    }
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    var date = new Date(unix_timestamp)

    //Convert to CET
    date.setHours(date.getHours() + 1)

    // Hours part from the timestamp
    var hours = date.getHours()
    // Minutes part from the timestamp
    var minutes = "0" + date.getMinutes()

    // Will display time in 10:30:23 format
    var formattedTime =
      hours +
      ":" +
      minutes.substr(-2) +
      " - " +
      date.getDate() +
      "." +
      date.getMonth()
    return (
      new Date(unix_timestamp).toLocaleTimeString("de").substr(0, 5) +
      " - " +
      new Date(unix_timestamp).toLocaleDateString("de")
    )
  }

  componentDidMount = async () => {
    try {
      var res = await http.getAllMatchesOfScene(this.props.sceneGuid)

      var newList = this.state.list
      for (var i in res.machList) {
        //newList.push(res.machList[i]);
        newList.push(res.machList[i])
      }
      var info = await realnote.getDetectionsOfScene(this.props.sceneGuid)
      if (info != null) {
        this.setState({info: info, list: newList})
      }
    } catch (ex) {
      log.error(ex)
    }
  }

  renderSingleImage(detectionImg) {
    if (detectionImg.error) {
      return (
        <Text
          style={{
            flex: 1.5,
            alignSelf: "stretch",
          }}>
          {" "}
          {detectionImg.error}
        </Text>
      )
    } else {
      var ids = ""
      if (detectionImg.id >= 0) {
        ids += "Id: " + detectionImg.id
      }
      if (detectionImg.trackingId) {
        ids += " / TrId: " + detectionImg.trackingId
      }

      return (
        <View style={{flex: 1.5, alignSelf: "stretch"}}>
          <FastImage
            style={{
              width: 100,
              height: 100,
              resizeMode: "contain",
            }}
            source={{
              uri: detectionImg.image,
            }}
          />
          <Text>
            {detectionImg.width +
              "x" +
              detectionImg.height +
              " " +
              (detectionImg.center ? detectionImg.center : "")}
          </Text>

          <Text>
            {"d=" + detectionImg.distance + " a=" + detectionImg.direction}
          </Text>

          <Text>
            {"X:" +
              detectionImg.X +
              "; Y:" +
              detectionImg.Y +
              "; E:" +
              detectionImg.E}
          </Text>

          <Text>{ids}</Text>
        </View>
      )
    }
  }

  render() {
    let serviceItems = this.state.list.map((s, i) => {
      return (
        <Picker.Item
          key={i}
          value={s.timestamp}
          label={this.convertDate(s.timestamp) + " - " + s.founder}
        />
      )
    })

    let imgs = []
    //
    // list header
    //
    imgs.push(
      <View
        key={`listHeader`}
        style={{
          flex: 1,
          alignSelf: "stretch",
          flexDirection: "row",
          backgroundColor: "#eee",
          textColor: "black",
          borderBottomWidth: 2,
          paddingBottom: 5,
          paddingTop: 5,
          width: "100%",
        }}>
        <Text style={{flex: 1.5, color: "black"}}> Persist </Text>
        <Text style={{flex: 1, color: "black"}}> Match </Text>
        <Picker
          selectedValue={this.state.pick}
          style={{flex: 2, color: "black"}}
          onValueChange={(itemValue, itemIndex) => {
            this.downloadMatch(itemValue)
            this.setState({pick: itemValue})
          }}>
          {serviceItems}
        </Picker>
      </View>,
    )

    //
    // list
    //
    if (this.state.info.detections) {
      for (var i = 0; i < this.state.info.detections.length; i++) {
        const detection = this.state.info.detections[i]
        imgs.push(
          <View
            key={`listItem_${i}`}
            style={{
              flex: 1,
              alignSelf: "stretch",
              flexDirection: "row",
              borderBottomColor: "grey",
              borderBottomWidth: 2,
              paddingBottom: 5,
              paddingTop: 5,
              width: "100%",
            }}>
            {this.renderSingleImage(detection.persistentImage)}
            {detection.matchedImage ? (
              <View style={{flex: 1, alignSelf: "stretch"}}>
                <Text> {detection.bestSimilarity + "%"} </Text>
                <Text> {detection.matchInfo} </Text>
              </View>
            ) : (
              <View />
            )}

            {detection.matchedImage ? (
              this.renderSingleImage(detection.matchedImage)
            ) : (
              <View />
            )}
          </View>,
        )
      }
    }

    return (
      <View style={{width: "100%"}}>
        <Text>{this.state.info.matchingInfo}</Text>

        {imgs}
      </View>
    )
  }
}

/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */

import Account from "./dataProvider/Account"
import Realnote from "./bridges/RealnoteNative"
import Global from "./Global"
import initThree from "./initThree"

window.loadOrbitControls = () => {
  return new Promise((resolve, reject) => {
    if (window.OrbitControls) {
      resolve(window.OrbitControls)
    } else {
      import("./jsm/controls/OrbitControls.js").then(modulOrbitControls => {
        const {OrbitControls} = modulOrbitControls
        window.OrbitControls = OrbitControls
        resolve(OrbitControls)
      })
    }
  })
}

export default function initWeb1() {
  var hostWrapper = null
  var t0 = Date.now()
  Realnote.sendFirebaseEvent("initWeb1_start")
  function initInner(resolve, web1) {
    try {
      window.web1 = web1

      localStorage.setItem("web1OnceLoaded", "true")

      if (web1) {
        window.startupServiceWorkers().then(() => {
          initThree().then(() => {
            console.log("web1 loaded user: " + Account.username)

            hostWrapper =
              new web1.one.realnote.app.platform.wrapper.HostWrapper()
            console.log("startup index.html")
            hostWrapper.startup(scene, renderer)

            Realnote.logIn(Account.userId, Account.username)
            Realnote.sendTokenToBridge(Global.token)

            //since web1 is only loaded now, we have to make sure it is entered to the current challenge, if one is active
            if (Global.challengeId) {
              Realnote.startChallengeMode(Global.challengeId)
            }

            Realnote.sendStatusOfWelcomeNotesToWebApp(Global.welcomeNotesNeeded)

            var touchReceiver = renderer.domElement
            var touch3D = document.getElementById("Touch3D")
            touchReceiver = touch3D
            hostWrapper?.setupView3D(touchReceiver)

            // // startTacking();

            console.log("initWeb1 ready " + Date.now() - t0)
            window.web1Ready = true
            window.web1Loading = false
            Realnote.sendFirebaseEvent("initWeb1_finish")
            resolve(true)
          })
        })
      } else {
        console.error("ERROR web1 missing")
        /// web1 = window.loadWeb1()
      }
    } catch (err) {
      console.error(err)
      reject(false)
    }
  }

  return new Promise((resolve, reject) => {
    if (window.web1Ready) {
      resolve(true)
      return
    }

    if (window.web1Loading) {
      const interval = setInterval(() => {
        if (window.web1Ready) {
          clearInterval(interval)
          resolve(true)
        }
      }, 100)
      return
    }
    window.web1Loading = true

    const web1 = window.loadWeb1()
    console.log("initWeb1 loadWeb1 in time: " + Date.now() - t0)
    t0 = Date.now()
    //const web1 = null
    import("./jsm/loaders/GLTFLoader.js").then(GLTFLoaderModul => {
      const {GLTFLoader} = GLTFLoaderModul
      var loader1 = new GLTFLoader()
      window.GLTFLoader = loader1
      console.log("initWeb1 GLTFLoader finished " + Date.now() - t0)
      t0 = Date.now()
      initInner(resolve, web1)
    })

    // import("./web1/web1.js").then(web1 => {
    //   initInner(
    //     resolve,
    //     web1,
    //     CSS3DRenderer,
    //     CSS3DObject,
    //     OrbitControls,
    //     GLTFExporter,
    //     GLTFLoader,
    //   )
    // })
  })

  return new Promise((resolve, reject) => {
    import("./jsm/loaders/GLTFLoader.js").then(GLTFLoaderModul => {
      const {GLTFLoader} = GLTFLoaderModul

      import("./jsm/exporters/GLTFExporter.js").then(moduleExporter => {
        const {GLTFExporter} = moduleExporter

        import("./jsm/controls/OrbitControls.js").then(modulOrbitControls => {
          const {OrbitControls} = modulOrbitControls

          import("./jsm/renderers/CSS3DRenderer.js").then(
            modulCSS3DRenderer => {
              const {CSS3DRenderer, CSS3DObject} = modulCSS3DRenderer

              console.log("here is initWeb1()")

              // const web1 = window.loadWeb1()
              const web1 = null
              initInner(
                resolve,
                web1,
                CSS3DRenderer,
                CSS3DObject,
                OrbitControls,
                GLTFExporter,
                GLTFLoader,
              )

              // import("./web1/web1.js").then(web1 => {
              //   initInner(
              //     resolve,
              //     web1,
              //     CSS3DRenderer,
              //     CSS3DObject,
              //     OrbitControls,
              //     GLTFExporter,
              //     GLTFLoader,
              //   )
              // })
            },
          )
        })
      })
    })
  })
}

import React, {useState, useEffect, useRef} from "react"
import {StyleSheet, Platform, View} from "react-native"
import platform from "platform"
import Global from "../../Global"
import Card, {resetCardColorIndex} from "./Card"
import Realnote from "../../bridges/RealnoteNative"
import Account from "../../dataProvider/Account"
import ProfileHeader from "../ProfileScreen/ProfileHeader.js"
import RewardCard from "./RewardCard"
import ArMode from "../../ArMode"
import initWeb1 from "../../initWeb1"
import initThree from "../../initThree"
import {useIsFocused} from "@react-navigation/native"
import Card_Map from "./Card_Map"
import {HttpBridge} from "../../http/HttpBridge"
import DropdownHeader from "../../components/DropdownHeader"
import FooterNative from "./FooterNative"
import {strings} from "../../i18n"
import RealnoteScreen from "../MenuBarScreen/RealnoteScreen"
import {menuButtonType} from "../MenuBarScreen/MenuButton"
import {useSelector} from "react-redux"
import Card_ChallengeFeeds from "./IntegratedGroupFeed"
import xstyles from "./styles"
import StyleConstants from "../../styles/StyleConstants"
import Card_GotoAr from "./Card_GoToAr"

const http = new HttpBridge()

var renderCount = 0
var homePageImpressionCounter = 0
var nearScenes = null

export default function HomeScreen(props) {
  const {navigation} = props
  const challengeMode = useSelector(state => state.challengeMode)
  const [loggedIn, setLoggedIn] = useState(!Account.isDefaultWebUser)
  const [username, setUsername] = useState(null)
  const [hasUserRegistered, setHasUserRegistered] = useState(true)

  const [showChromeButton, setShowChromeButton] = useState(false)
  const isFocused = useIsFocused()

  useEffect(() => {
    if (isFocused) {
      setLoggedIn(!Account.isDefaultWebUser)
    }
  }, [isFocused])

  useEffect(() => {
    checkWebXR()
    updateImpressionCounter()
    http.hasUserRegistered(Account.userId).then(res => {
      setHasUserRegistered(res)
    })
    window.addEventListener("beforeinstallprompt", e => {
      // Prevent the mini-infobar from appearing on mobile
      // e.preventDefault();
      // Stash the event so it can be triggered later.
      // deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      // showInstallPromotion();
      // Optionally, send analytics event that PWA install promo was shown.
    })

    const elm = document.getElementById("splashMessage")
    if (elm) {
      elm.style.display = "none"
    }
    Realnote.pauseAR()
    Realnote.stopDetection()
  }, [])

  useEffect(() => {
    console.log("Home is focused")
    if (isFocused) {
      setLoggedIn(!Account.isDefaultWebUser)
      updateImpressionCounter()
      console.log("Home is focused 2")
    }
  }, [isFocused])

  function updateImpressionCounter() {
    const c = parseInt(localStorage.getItem("HomePageImpressionCounter")) || 0
    localStorage.setItem("HomePageImpressionCounter", c + 1)

    homePageImpressionCounter = c
  }

  const checkWebXR = () => {
    return new Promise(resolve => {
      if ("xr" in navigator) {
        navigator.xr
          .isSessionSupported("immersive-ar")
          .then(function (supported) {
            if (supported) {
              resolve(true)
            } else {
              if (Global.isAndroid && platform.name.indexOf("chrome") === -1) {
                setShowChromeButton(true)
              }
              resolve(false)
            }
          })
      } else {
        if (Global.isAndroid && platform.name.indexOf("chrome") === -1) {
          setShowChromeButton(true)
        }
        resolve(false)
      }
    })
  }

  function awaitStartWebApp() {
    return new Promise((resolve, reject) => {
      initThree().then(() => {
        ArMode.startAr()
        initWeb1().then(() => {
          resolve(true)
        })
      })
    })
  }

  function Card_Reward() {
    const button = {
      text: "Check it out ...",
      onPress: async () => {
        awaitStartWebApp().then(() => {
          console.log("onPress reward")
          navigation.push("OnboardingSuccess")
        })
      },
    }
    return <Card title="You got rewards" button={button} waitForWeb1></Card>
  }

  function Card_SignIn() {
    const button = {
      text: "Log in now",
      onPress: () => {
        navigation.push("SignIn", {forwardTo: "Live", onSignIn: onSignIn})
      },
    }
    return <Card title="You are not logged in" button={button}></Card>
  }

  function Card_CreateNewNote() {
    const button = {
      text: "Erstelle eine Note",
      onPress: () => {
        awaitStartWebApp().then(async () => {
          navigation.push("NoteEditorModelSelection")
        })
      },
    }

    let dims = Realnote.getDimsSync()

    let w1 = 150
    let w = Math.min(dims.width * 0.2, w1)

    var video1 = null
    if (homePageImpressionCounter % 2 == 0) {
    }

    if (true) {
      video1 = (
        <div
          style={{
            position: "relative",
            width: w1,
            height: w1,
            paddingBottom: 0,
          }}
          dangerouslySetInnerHTML={{
            __html: `<video style='position: relative; width:${w1}; height: ${w1}; object-fit: cover' autoplay muted loop playsinline crossorigin='anonymous' src='assets/images/createNewNote.mp4'/>`,
          }}
        />
      )
    } else {
      video1 = (
        <div
          style={{
            position: "relative",
            width: w1,
            height: w1,
            // paddingBottom: 70,
            // center the content
            display: "flex",
            alignItems: "center",
          }}>
          <img
            style={{
              position: "absolute",
              width: w1,
              height: w1 / 2.5,
            }}
            crossOrigin="anonymous"
            src="https://realnote.one/assets/videos/sculptureDraw.gif"
          />
        </div>
      )
    }

    return (
      <Card
        title={"Poste eine Note"}
        imageLeft={video1}
        button={button}
        waitForWeb1>
        Wähle ein Modell.
        <div style={xstyles.br} />
        Du kannst es bemalen, Selfies, Sticker und Text hinzufügen.
      </Card>
    )
  }

  function Card_CreateUserChallenges() {
    const button = {
      text: strings("CreateUserChallenge.card.button"),
      onPress: () => {
        Realnote.sendFirebaseEvent("goToCreateGroupFromHome")
        navigation.push("CreateUserChallenges")
      },
    }

    return (
      <Card title={strings("CreateUserChallenge.card.title")} button={button}>
        {strings("CreateUserChallenge.card.text")}
      </Card>
    )
  }

  function Card_CreateSculpture() {
    const button = {
      text: "Erstelle eine Skulptur",
      onPress: () => {
        awaitStartWebApp().then(async () => {
          navigation.push("SculptureEditor")
        })
      },
    }

    let dims = Realnote.getDimsSync()

    let w1 = 150
    let w = Math.min(dims.width * 0.2, w1)

    var video1 = null
    if (homePageImpressionCounter % 2 == 0) {
      video1 = (
        <div
          style={{
            width: w1,
            height: w1,
            paddingBottom: 0,
          }}
          dangerouslySetInnerHTML={{
            __html: `<video style='width:${w1}; height: ${w1}; object-fit: cover' autoplay muted loop playsinline crossorigin='anonymous' src='https://realnote.one/assets/videos/sculptureTutorial.mp4'/>`,
          }}
        />
      )
    } else {
      video1 = (
        <div
          style={{
            width: w1,
            height: w1,
            // paddingBottom: 70,
            // center the content
            display: "flex",
            alignItems: "center",
          }}>
          <img
            style={{
              width: w1,
              height: w1 / 2.5,
            }}
            crossOrigin="anonymous"
            src="https://realnote.one/assets/videos/sculptureDraw.gif"
          />
        </div>
      )
    }

    return (
      <Card
        imageLeft={video1}
        title={"Werde Bildhauer"}
        button={button}
        waitForWeb1>
        es macht Spass und ist ganz einfach Skulpturen mit verschieden Farben
        und Materialien zu erschaffen
      </Card>
    )
  }

  function Card_YourSharedNotes() {
    return (
      <Card
        title="Your Notes shared with friend"
        button={{text: "Add some more ArtWork"}}
      />
    )
  }

  function Card_YourGeolocalNotes() {
    return (
      <Card
        title="Your Notes in the real World"
        button={{text: "See them and add more"}}
      />
    )
  }

  function Card_Development() {
    const button = {
      text: "Look at data",
      onPress: () => {
        navigation.push("PpmList")
      },
    }

    return <Card title={"Performance Data"} button={button}></Card>
  }

  function Card_ChallengeImage() {
    if (challengeMode.isActive) {
      return (
        <Card style={{padding: 0, paddingLeft: 0}}>
          <img
            src={challengeMode.imageUrl}
            style={{height: 100, width: "100%"}}></img>
        </Card>
      )
    }
    return null
  }

  function Card_ChallengeTitle() {
    if (challengeMode.isActive) {
      return (
        <Card title={challengeMode.title}>
          <div>{challengeMode.description}</div>
        </Card>
      )
    }
    return null
  }

  function Card_Chrome() {
    const button = {
      text: "Geh zu Chrome",
      onPress: () => {
        window.open(
          "googlechrome://navigate?url=" + window.location.href,
          "_system",
        )
      },
    }
    return (
      <Card title={"Bitte in Chrome öffnen"} button={button}>
        Leider funktioniert Augmented Reality nur unter Chrome, bitte wechsle
        dorthin
      </Card>
    )
  }

  function Separator() {
    return <div style={{height: 30}}>&nbsp;</div>
  }

  function onSignIn() {
    setLoggedIn(true)
  }

  if (!isFocused) {
    console.log("DONT render HomeScreen (not focused")
    return null
  }

  resetCardColorIndex()
  console.log("Home rerender " + renderCount)
  renderCount++
  return (
    <RealnoteScreen
      nativeId="Explore"
      navigation={navigation}
      reverseAnimateAr={navigation.getParam("reverseAnimateAr")}
      darkUpperButtons
      upperButtonsShadow={false}
      activeButton={menuButtonType.home}>
      <View style={styles.main}>
        <DropdownHeader navigation={navigation} />
        <View style={styles.scrollArea}>
          <View style={styles.cardArea}>
            <ProfileHeader
              style={{
                paddingBottom: 10,
                paddingTop: 10,
                height: StyleConstants.profileHeaderImageSize + 50,
              }}
              userId={Account.userId}
              navigation={navigation}
              showRegisterButton={!hasUserRegistered}
              username={username}
              onPressEditProfile={() => {
                navigation.navigate("EditAccount")
              }}
            />
            {!loggedIn && <Card_SignIn />}

            {showChromeButton && <Card_Chrome />}

            {/* <RewardCard navigation={navigation} buttonDisabled={!web1Loaded} /> */}
            {/* <Card_Reward /> */}
            {/* <Card_ChallengeTitle />
            <Card_ChallengeImage /> */}
            <Card_GotoAr navigation={navigation} />
            <Card_CreateNewNote />
            <Card_CreateSculpture />

            {/* <PopularNotesHorizontalScroller navigation={navigation} /> */}
            <Card_CreateUserChallenges />

            <Card_Map navigation={navigation} />

            {/* <Card_UserChallenges /> */}

            <Card_ChallengeFeeds
              navigation={navigation}
              challengeId={challengeMode.challengeId}
              distance={challengeMode.distance}
            />

            {Global.debugMode && <Card_Development />}

            {/*
          <Card_WipNote />
          <Card_YourSharedNotes />
          */}
          </View>
          <FooterNative navigation={navigation} />
        </View>
      </View>
    </RealnoteScreen>
  )
}

const styles = StyleSheet.create(xstyles)

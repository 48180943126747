import React, {PureComponent} from "react"
import {Animated, Easing, View} from "react-native"
import RealnoteLogger from "../../bridges/RealnoteLogger"
import {serverResponse} from "../../dataProvider/Account"
import CircularIconButton from "../../screens/MenuBarScreen/CircularIconButton"
import StyleConstants from "../../styles/StyleConstants"
import {HttpBridge} from "../../http/HttpBridge"

const http = new HttpBridge()
const log = new RealnoteLogger("ProfilePictureMenu")

export default class ProfilePictureMenu extends PureComponent {
  constructor(props) {
    super(props)
    try {
      this.state = {
        readyToAnimate: false,
      }
      this.animationHasRun = false
    } catch (e) {
      log.e("constructor error: " + e)
    }
  }

  componentDidMount() {
    try {
      this.initializeAnimation()
    } catch (e) {
      log.e("componentDidMount error: " + e)
    }
  }

  initializeAnimation() {
    try {
      this.bouncy = new Animated.Value(0)
      this.simple = new Animated.Value(0)
      this.fadeAnimation = new Animated.Value(0)
      this.animationHasRun = false
      this.duration = 500
      this.setState({
        readyToAnimate: true,
      })
    } catch (e) {
      log.e("initializeAnimation error: " + e)
    }
  }

  async startAnimation() {
    try {
      if (this.state.readyToAnimate === true) {
        this.animationHasRun = true
        await Animated.parallel([
          Animated.timing(this.bouncy, {
            toValue: 1,
            duration: this.duration,
            easing: Easing.bounce,
            useNativeDriver: true,
          }),
          Animated.timing(this.simple, {
            toValue: 1,
            duration: this.duration,
            easing: Easing.ease,
            useNativeDriver: true,
          }),
          Animated.timing(this.fadeAnimation, {
            toValue: 1,
            duration: this.duration * 0.5,
            easing: Easing.ease,
            useNativeDriver: true,
          }),
        ]).start()
      } else {
        setTimeout(() => {
          this.startAnimation()
        }, 100)
      }
    } catch (e) {
      log.e("startAnimation error: " + e)
    }
  }

  async revertAnimation() {
    try {
      log.v("revertAnimation called")
      if (this.animationHasRun) {
        this.animationHasRun = false
        await Animated.parallel([
          Animated.timing(this.bouncy, {
            toValue: 0,
            duration: this.duration,
            easing: Easing.bounce,
            useNativeDriver: true,
          }),
          Animated.timing(this.simple, {
            toValue: 0,
            duration: this.duration,
            easing: Easing.ease,
            useNativeDriver: true,
          }),
          Animated.timing(this.fadeAnimation, {
            toValue: 0,
            duration: this.duration * 0.4,
            easing: Easing.ease,
            useNativeDriver: true,
          }),
        ]).start()
      }
    } catch (e) {
      log.e("revertAnimation error: " + e)
    }
  }

  onPressTakePhoto = () => {
    try {
      if (this.props.onTakePhotoPressed) {
        this.props.onTakePhotoPressed()
      } else if (this.props.navigation) {
        this.props.navigation.navigate("ProfileImage")
      }
    } catch (e) {
      log.e("onPressTakePhoto error: " + e)
    }
  }

  onPressDeletePhoto = async () => {
    try {
      // delete profile image on server
      const result = await http.deleteProfileImage(this.props.userId)
      if (result === serverResponse.ok) {
        log.d("profile image successfully deleted")
        if (this.props.onDeleted) {
          this.props.onDeleted()
        }
      } else {
        log.e("failed to delete profile image, server response: " + result)
      }
    } catch (e) {
      log.e("onPressDeletePhoto error: " + e)
    }
  }

  render() {
    try {
      if (this.props.hidden) {
        if (this.animationHasRun) {
          this.initializeAnimation()
        }
        return null
      } else {
        if (!this.animationHasRun) {
          this.startAnimation()
        }
      }

      var slideUp = this.bouncy.interpolate({
        inputRange: [0, 1],
        outputRange: [-10, -40],
      })

      var slideToRight = this.bouncy.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 35],
      })

      var slideToLeft = this.bouncy.interpolate({
        inputRange: [0, 1],
        outputRange: [0, -35],
      })

      return (
        <View
          style={{
            position: "absolute",
            top:
              StyleConstants.menuButtonSize +
              StyleConstants.buttonMarginToEdge +
              10,
          }}>
          <Animated.View
            style={{
              opacity: this.fadeAnimation,
              position: "absolute",
              top: 0,
              right: -35,
              transform: [{translateX: slideToRight}, {translateY: slideUp}],
            }}>
            <CircularIconButton
              size={45}
              extraBigTouchSurface
              name={"Gallery"}
              onPress={this.onPressTakePhoto}
            />
          </Animated.View>
          {this.props.showDeleteButton && (
            <Animated.View
              style={{
                opacity: this.fadeAnimation,
                position: "absolute",
                top: 0,
                left: -35,
                transform: [{translateX: slideToLeft}, {translateY: slideUp}],
              }}>
              <CircularIconButton
                size={45}
                extraBigTouchSurface
                name={"Trash"}
                onPress={this.onPressDeletePhoto}
              />
            </Animated.View>
          )}
        </View>
      )
    } catch (e) {
      log.e("render error: " + e)
    }
  }
}

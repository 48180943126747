import React, {useState} from "react"
import {Modal, TouchableWithoutFeedback, View, StyleSheet} from "react-native"
import ContextMenu from "../../components/ContextMenu/ContextMenu"
import ContextMenuButton from "../../components/ContextMenu/ContextMenuButton"
import TextMenu from "../../components/ContextMenu/TextMenu"
import Global from "../../Global"
import {strings} from "../../i18n"
import FullScreenOverlay from "../../components/FullScreenOverlay"
import AwesomeAlert from "../../components/AwesomeAlert"
import {useDeleteNotesMutation} from "../../store/reducers/scrollingNoteSlice"
import BlockUserAlert from "../../components/BlockedUsers/BlockUserAlert"
import RealnoteNative from "../../bridges/RealnoteNative"

export default function NoteContextMenu(props) {
  const {navigation, myOwn, noteData, userId} = props
  const [showMenu, setShowMenu] = useState(false)
  const [overlayVisibleReport, setOverlayVisibleReport] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showBlockAlert, setShowBlockAlert] = useState(false)
  const [deleteNotes] = useDeleteNotesMutation()

  function renderMenu() {
    return (
      <ContextMenu
        style={{zIndex: 10}}
        showMenu={showMenu}
        navigation={navigation}>
        <TextMenu>
          {!myOwn && !Global.adminMode && (
            <ContextMenuButton
              onPress={onPressReport}
              label={strings("Note.report")}
            />
          )}
          {(myOwn || Global.adminMode) && (
            <ContextMenuButton
              onPress={onPressDelete}
              label={strings("Note.delete")}
            />
          )}
          {!myOwn && (
            <ContextMenuButton
              style={{zIndex: 20}}
              onPress={onBlockPressed}
              label={strings("blockUser.blockUser")}
            />
          )}
        </TextMenu>
      </ContextMenu>
    )
  }

  function displayMenu() {
    if (!showMenu) {
      setShowMenu(true)
    } else {
      setShowMenu(false)
    }
  }

  function onPressReport() {
    setShowMenu(false)
    setOverlayVisibleReport(true)
  }

  function onPressDelete() {
    setShowAlert(true)
  }

  function onBlockPressed() {
    setShowBlockAlert(true)
    setShowMenu(false)
  }

  function cancelBlock() {
    setShowBlockAlert(false)
    setShowMenu(false)
  }

  function onBlockDone() {
    setShowBlockAlert(false)
    setShowMenu(false)
  }

  function deleteScene() {
    RealnoteNative.sendFirebaseClick("context_m_delete_note")
    deleteNotes({notes: [noteData]})
  }

  function awesomeAlertOnConfirmPressed() {
    deleteScene()
    setShowAlert(false)
  }

  if (!userId || myOwn === undefined) {
    return null
  }
  if (!overlayVisibleReport && !showAlert && !showBlockAlert) {
    return renderMenu()
  }

  return (
    <TouchableWithoutFeedback onPress={displayMenu} style={styles.container}>
      <View style={{flex: 1}}>
        {renderMenu()}
        {overlayVisibleReport && (
          <Modal transparent={true}>
            <FullScreenOverlay
              type={"report"}
              contentGuid={noteData.contentGuid}
              sceneGuid={noteData.sceneGuid || noteData.guid}
              creatorUserId={userId}
              hide={() => setOverlayVisibleReport(false)}
              paddingOn={true}
            />
          </Modal>
        )}
        {showAlert && (
          <AwesomeAlert
            show={showAlert}
            showProgress={false}
            title={strings("Content.alertTitleDeleteNote")}
            message={strings("Content.alertBodyDeleteNote")}
            closeOnTouchOutside={true}
            closeOnHardwareBackPress={false}
            showCancelButton={true}
            showConfirmButton={true}
            cancelText={strings("Account.abortCap")}
            confirmText={strings("ok")}
            confirmButtonColor="#DD6B55"
            onCancelPressed={() => setShowAlert(false)}
            onConfirmPressed={() => awesomeAlertOnConfirmPressed()}
          />
        )}
        <BlockUserAlert
          blockedUserId={noteData.userId}
          onBlockDone={onBlockDone}
          onCancel={cancelBlock}
          show={showBlockAlert}
        />
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 10,
    flex: 1,
  },
})

const cityNames = `Ahrweiler	AW
Aichach-Friedberg	AIC
Alb-Donau-Kreis	UL
Altenburger Land	ABG
Altenkirchen (Westerwald)	AK
Altmarkkreis Salzwedel	SAW
Altötting	AÖ 
Alzey-Worms	AZ
Amberg	AM
Amberg-Sulzbach	AS 
Ammerland	WST
Anhalt-Bitterfeld	ABI
Ansbach	AN 
Ansbach	AN
Aschaffenburg	AB 
Aschaffenburg	AB
Augsburg	AUGSBURG
Augsburg	AUGSBURG
Aurich	AUR
Bad Dürkheim	DÜW
Bad Kissingen	KG 
Bad Kreuznach	KH
Bad Tölz-Wolfratshausen	TÖL
Baden-Baden	BAD
Bamberg	BA
Bamberg	BA
Barnim	BAR
Bautzen	BZ 
Bayreuth	BT 
Bayreuth	BT
Berchtesgadener Land	BGL
Bergstraße	HP
Berlin	BERLIN
Bernkastel-Wittlich	WIL
Biberach	BC
Bielefeld	BI
Birkenfeld	BIR
Bochum	BO 
Bodenseekreis	FN 
Bonn	BN
Borken	BOR
Bottrop	BOT
Brandenburg an der Havel	BRB
Braunschweig	BS
Breisgau-Hochschwarzwald	FR
Bremen	HB
Bremerhaven	HB
Burgenlandkreis	BLK
Böblingen	BB 
Börde	BK 
Büsingen am Hochrhein	BÜS
Calw	CW
Celle	CE
Cham	CHA
Chemnitz	CHEMNITZ
Cloppenburg	CLP
Coburg	CO 
Coburg	CO
Cochem-Zell	COC
Coesfeld	COE
Cottbus	CB
Cuxhaven	CUX
Dachau	DAH
Dahme-Spreewald	LDS
Darmstadt	DA
Darmstadt-Dieburg	DA 
Deggendorf	DEG
Delmenhorst	DEL
Dessau-Roßlau	DE 
Diepholz	DH 
Dillingen a.d. Donau	DLG
Dingolfing-Landau	DGF
Dithmarschen	HEI
Donau-Ries	DON
Donnersbergkreis	KIB
Dortmund	DO
Dresden	DD
Duisburg	DU
Düren	DN 
Düsseldorf	DUS
Ebersberg	EBE
Eichsfeld	EIC
Eichstätt	EI
Eifelkreis Bitburg-Prüm	BIT
Eisenach	EA
Elbe-Elster	EE 
Emden	EMD
Emmendingen	EM
Emsland	EL
Ennepe-Ruhr-Kreis	EN 
Enzkreis	PF
Erding	ED
Erfurt	EF
Erlangen	ER
Erlangen-Höchstadt	ERH
Erzgebirgskreis	ERZ
Essen	ESSEN
Esslingen	ES 
Euskirchen	EU 
Flensburg	FL
Forchheim	FO 
Frankenthal (Pfalz)	FT
Frankfurt (Oder)	FF
Frankfurt am Main	FFM
Freiburg im Breisgau	FR
Freising	FS
Freudenstadt	FDS
Freyung-Grafenau	FRG
Friesland	FRI
Fulda	FD
Fürstenfeldbruck	FFB
Fürth	FÜ
Fürth	FÜ
Garmisch-Partenkirchen	GAP
Gelsenkirchen	GE
Gera	GERA
Germersheim	GER
Gießen	GI
Gifhorn	GF
Goslar	GS 
Gotha	GTH
Grafschaft Bentheim	NOH
Greifswald	HGW
Greiz	GRZ
Groß-Gerau	GG
Göppingen	GP
Görlitz	GR 
Göttingen	GÖ 
Günzburg	GZ 
Gütersloh	GT
Hagen	HA
Halle (Saale)	HAL
Hamburg	HH
Hameln-Pyrmont	HM
Hamm	HAM
Hanau	HU
Harburg	WL
Harz	HZ 
Havelland	HVL
Haßberge	HAS
Heidekreis	HK
Heidelberg	HD
Heidenheim	HDH
Heilbronn	HN
Heilbronn	HN
Heinsberg	HS 
Helmstedt	HE
Herford	HF
Herne	HER
Hersfeld-Rotenburg	HEF
Herzogtum Lauenburg	RZ
Hildburghausen	HBN
Hildesheim	HI 
Hochsauerlandkreis	HSK
Hochtaunuskreis	HG 
Hof	HO 
Hof	HO
Hohenlohekreis	KÜN
Holzminden	HOL
Höxter	HX 
Ilm-Kreis	IK 
Ingolstadt	IN
Jena	JENA
Jerichower Land	JL 
Kaiserslautern	KL
Kaiserslautern	KL
Karlsruhe	KA
Karlsruhe	KA
Kassel	KS 
Kassel	KS
Kaufbeuren	KF
Kelheim	KEH
Kempten (Allgäu)	KE
Kiel	KI
Kitzingen	KT
Kleve	KLE
Koblenz	KO
Konstanz	KN
Krefeld	KR
Kronach	KC 
Kulmbach	KU 
Kusel	KUS
Kyffhäuserkreis	KYF
Köln	KÖLN
Lahn-Dill-Kreis	LDK
Landau in der Pfalz	LD
Landsberg am Lech	LL
Landshut	LA 
Landshut	LA
Leer	LER
Leipzig	L
Leipzig	L  
Leverkusen	LEV
Lichtenfels	LIF
Limburg-Weilburg	LM 
Lindau (Bodensee)	LI
Lippe	LIP
Ludwigsburg	LB 
Ludwigshafen am Rhein	LU
Ludwigslust-Parchim	LUP
Lörrach	LÖ
Lübeck	HL
Lüchow-Dannenberg	DAN
Lüneburg	LG
Magdeburg	MD
Main-Kinzig-Kreis	MKK
Main-Spessart	MSP
Main-Tauber-Kreis	TBB
Main-Taunus-Kreis	MTK
Mainz	MZ
Mainz-Bingen	MZ 
Mannheim	MA
Mansfeld-Südharz	MSH
Marburg-Biedenkopf	MR 
Mayen-Koblenz	MYK
Mecklenburgische Seenplatte	MSE
Meißen	MEI
Memmingen	MM
Merzig-Wadern	MZG
Mettmann	ME
Miesbach	MB
Miltenberg	MIL
Minden-Lübbecke	MI
Mittelsachsen	FG 
Märkisch-Oderland	MOL
Märkischer Kreis	MK
Mönchengladbach	MG
Mühldorf a. Inn	MÜ 
Mülheim an der Ruhr	MH
München	MUC
München	MUC
Münster	MS
Neckar-Odenwald-Kreis	MOS
Neu-Ulm	NU 
Neubrandenburg	NB
Neuburg-Schrobenhausen	ND 
Neumarkt i.d. OPf.	NM 
Neumünster	NMS
Neunkirchen	NK
Neustadt a.d. Aisch-Bad Windsheim	NEA
Neustadt a.d. Waldnaab	NEW
Neustadt an der Weinstraße	NW
Neuwied	NR
Nienburg (Weser)	NI
Nordfriesland	NF
Nordhausen	NDH
Nordsachsen	TDO
Nordwestmecklenburg	NWM
Northeim	NOM
Nürnberg	NUE
Nürnberger Land	LAU
Oberallgäu	OA
Oberbergischer Kreis	GM
Oberhausen	OB
Oberhavel	OHV
Oberspreewald-Lausitz	OSL
Odenwaldkreis	ERB
Oder-Spree	LOS
Offenbach	OF
Offenbach am Main	OF
Oldenburg	OL
Oldenburg (Oldb)	OL
Olpe	OE
Ortenaukreis	OG 
Osnabrück	OS 
Osnabrück	OS
Ostalbkreis	AA 
Ostallgäu	OAL
Osterholz	OHZ
Ostholstein	OH
Ostprignitz-Ruppin	OPR
Paderborn	PB 
Passau	PA
Passau	PA
Peine	PE
Pfaffenhofen a.d. Ilm	PAF
Pforzheim	PF
Pinneberg	PI
Pirmasens	PS
Plön	PLÖ
Potsdam	PDM
Potsdam-Mittelmark	PM
Prignitz	PR
Rastatt	RA 
Ravensburg	RV 
Recklinghausen	RE 
Regen	REG
Regensburg	REGENSBURG
Regensburg	REGENSBURG
Region Hannover	HANNOVER
Regionalverband Saarbrücken	SB
Rems-Murr-Kreis	WN 
Remscheid	RS
Rendsburg-Eckernförde	RD 
Reutlingen	RT
Rhein-Erft-Kreis	BM
Rhein-Hunsrück-Kreis	SIM
Rhein-Kreis Neuss	NE 
Rhein-Lahn-Kreis	EMS
Rhein-Neckar-Kreis	HD
Rhein-Pfalz-Kreis	RP
Rhein-Sieg-Kreis	SU
Rheingau-Taunus-Kreis	RÜD
Rheinisch-Bergischer Kreis	GL
Rhön-Grabfeld	NES
Rosenheim	RO
Rosenheim	RO 
Rostock	LRO
Rostock	HRO
Rotenburg (Wümme)	ROW
Roth	RH 
Rottal-Inn	PAN
Rottweil	RW
Saale-Holzland-Kreis	SHK
Saale-Orla-Kreis	SOK
Saalekreis	SK 
Saalfeld-Rudolstadt	SLF
Saarlouis	SLS
Saarpfalz-Kreis	HOM
Salzgitter	SZ
Salzlandkreis	SLK
Schaumburg	SHG
Schleswig-Flensburg	SL
Schmalkalden-Meiningen	SM 
Schwabach	SC
Schwalm-Eder-Kreis	HR 
Schwandorf	SAD
Schwarzwald-Baar-Kreis	VS
Schweinfurt	SW 
Schweinfurt	SW
Schwerin	SN
Schwäbisch Hall	SHA
Segeberg	SE
Siegen-Wittgenstein	SI 
Sigmaringen	SIG
Soest	SO 
Solingen	SG
Sonneberg	SON
Speyer	SP
Spree-Neiße	SPN
St. Ingbert	IGB
St. Wendel	WND
Stade	STD
Starnberg	STA
Steinburg	IZ
Steinfurt	ST 
Stendal	SDL
Stormarn	OD
Stralsund	HST
Straubing	SR
Straubing-Bogen	SR 
Stuttgart	STR
Städteregion Aachen	AC 
Suhl	SHL
Sächsische Schweiz-Osterzgebirge	PIR
Sömmerda	SÖM
Südliche Weinstraße	SÜW
Südwestpfalz	PS 
Teltow-Fläming	TF
Tirschenreuth	TIR
Traunstein	TS 
Trier	TR
Trier-Saarburg	TR 
Tuttlingen	TUT
Tübingen	TÜ
Uckermark	UM 
Uelzen	UE
Ulm	UL
Unna	UN 
Unstrut-Hainich-Kreis	UH 
Unterallgäu	MN
Vechta	VEC
Verden	VER
Viersen	VIE
Vogelsbergkreis	VB
Vogtlandkreis	VOGTLAND
Vorpommern-Greifswald	VG 
Vorpommern-Rügen	VR 
Vulkaneifel	DAU
Völklingen	VK
Waldeck-Frankenberg	KB 
Waldshut	WT
Warendorf	WAF
Wartburgkreis	WAK
Weiden i.d. OPf.	WEN
Weilheim-Schongau	WM 
Weimar	WE
Weimarer Land	AP 
Weißenburg-Gunzenhausen	WUG
Werra-Meißner-Kreis	ESW
Wesel	WES
Wesermarsch	BRA
Westerwaldkreis	WW
Wetteraukreis	FB 
Wetzlar	WZ
Wiesbaden	WI
Wilhelmshaven	WHV
Wismar	HWI
Wittenberg	WB 
Wittmund	WTM
Wolfenbüttel	WF
Wolfsburg	WOB
Worms	WO
Wunsiedel i. Fichtelgebirge	WUN
Wuppertal	W
Würzburg	WÜ
Würzburg	WÜ 
Zollernalbkreis	BL 
Zweibrücken	ZW
Zwickau	ZWIGGE`

const longLatIndexArray = [
  [7.120833333333333, 50.47805555555556],
  [11.052777777777779, 48.427499999999995],
  [9.675833333333333, 48.34777777777778],
  [12.399166666666666, 50.95638888888889],
  [7.887777777777777, 50.79527777777778],
  [11.227222222222222, 52.67972222222222],
  [12.705277777777777, 48.209722222222226],
  [8.157222222222222, 49.75944444444445],
  [11.855833333333333, 49.442499999999995],
  [11.809722222222224, 49.358333333333334],
  [8.013333333333334, 53.218611111111116],
  [12.209166666666667, 51.64805555555556],
  [10.300833333333333, 49.22694444444445],
  [10.571944444444444, 49.302499999999995],
  [9.230277777777777, 50.044444444444444],
  [9.147777777777778, 49.97361111111111],
  [10.731944444444444, 48.35388888888889],
  [10.8925, 48.36972222222222],
  [7.378055555555555, 53.48888888888889],
  [8.110277777777778, 49.45305555555556],
  [9.964722222222221, 50.222500000000004],
  [7.6866666666666665, 49.82472222222223],
  [11.483055555555556, 47.727222222222224],
  [8.239999999999998, 48.76166666666666],
  [10.886666666666667, 49.89333333333333],
  [11.04888888888889, 49.91083333333333],
  [13.703333333333333, 52.823055555555555],
  [14.231111111111112, 51.26888888888889],
  [11.724166666666667, 49.97083333333334],
  [11.571388888888889, 49.94555555555555],
  [12.995555555555555, 47.595555555555556],
  [8.631111111111112, 49.672777777777775],
  [13.405555555555557, 52.51777777777777],
  [6.9675, 49.92444444444444],
  [9.703055555555554, 48.07694444444445],
  [8.532222222222224, 52.02055555555555],
  [7.2775, 49.712500000000006],
  [7.217222222222222, 51.48277777777778],
  [9.401111111111112, 47.73361111111111],
  [7.099166666666666, 50.73416666666667],
  [6.839166666666666, 51.85138888888889],
  [6.930000000000001, 51.52361111111111],
  [12.563333333333334, 52.40861111111111],
  [10.520555555555557, 52.2625],
  [8.133333333333335, 47.930277777777775],
  [8.809444444444445, 53.07555555555556],
  [8.578055555555554, 53.543055555555554],
  [11.883888888888889, 51.14722222222222],
  [8.942777777777778, 48.67805555555555],
  [11.347777777777779, 52.220555555555556],
  [8.690277777777778, 47.697222222222216],
  [8.635833333333332, 48.678888888888885],
  [10.10361111111111, 52.71333333333334],
  [12.695555555555556, 49.23694444444445],
  [12.921666666666667, 50.83361111111111],
  [7.94, 52.89805555555556],
  [11.094722222222222, 50.2425],
  [10.960277777777778, 50.25944444444445],
  [7.174444444444445, 50.132777777777775],
  [7.366388888888888, 51.86666666666667],
  [14.335, 51.75944444444445],
  [8.811944444444444, 53.61638888888889],
  [11.357222222222221, 48.33444444444444],
  [13.923055555555555, 52.011944444444445],
  [8.656666666666666, 49.873333333333335],
  [8.917222222222222, 49.88583333333333],
  [13.000833333333333, 48.77861111111111],
  [8.63, 53.05],
  [12.241944444444444, 51.83083333333334],
  [8.70111111111111, 52.72833333333333],
  [10.527777777777779, 48.59638888888889],
  [12.610555555555555, 48.64111111111111],
  [9.108333333333333, 54.134166666666665],
  [10.712777777777777, 48.80694444444444],
  [7.915, 49.63194444444444],
  [7.464166666666667, 51.51416666666667],
  [13.736944444444443, 51.05166666666666],
  [6.765555555555555, 51.43333333333333],
  [6.445833333333334, 50.81777777777778],
  [6.78, 51.22416666666667],
  [11.913333333333334, 48.07611111111112],
  [10.253333333333334, 51.38361111111111],
  [11.370277777777778, 48.89805555555556],
  [6.410277777777778, 50.06194444444444],
  [10.3225, 50.977222222222224],
  [13.459722222222222, 51.61222222222222],
  [7.2058333333333335, 53.370555555555555],
  [7.8975, 48.14861111111111],
  [7.402500000000001, 52.737500000000004],
  [7.313055555555556, 51.348888888888894],
  [8.827777777777778, 48.9175],
  [12.000555555555556, 48.30027777777777],
  [11.027222222222223, 50.977222222222224],
  [11.005, 49.595],
  [10.940833333333334, 49.669999999999995],
  [12.944722222222222, 50.60888888888889],
  [7.0125, 51.45583333333334],
  [9.369166666666667, 48.64805555555556],
  [6.643888888888888, 50.53638888888889],
  [9.4375, 54.7825],
  [11.173055555555555, 49.72083333333334],
  [8.354166666666666, 49.535555555555554],
  [14.551111111111112, 52.34166666666667],
  [8.6825, 50.11083333333333],
  [7.85, 47.99472222222222],
  [11.740555555555554, 48.447222222222216],
  [8.465277777777777, 48.474444444444444],
  [13.513333333333334, 48.82694444444445],
  [7.983888888888889, 53.505],
  [9.758888888888889, 50.56388888888888],
  [11.20111111111111, 48.187777777777775],
  [10.849722222222223, 49.446666666666665],
  [10.989999999999998, 49.4775],
  [11.129722222222222, 47.55638888888888],
  [7.096111111111111, 51.51083333333333],
  [12.080555555555556, 50.876111111111115],
  [8.238888888888889, 49.04805555555556],
  [8.807500000000001, 50.57027777777778],
  [10.604166666666666, 52.580000000000005],
  [10.400555555555556, 51.874722222222225],
  [10.693611111111112, 50.910555555555554],
  [7.109166666666666, 52.447222222222216],
  [13.379722222222222, 54.09583333333334],
  [12.08888888888889, 50.70111111111111],
  [8.470277777777778, 49.904444444444444],
  [9.7175, 48.66305555555555],
  [14.879166666666666, 51.197222222222216],
  [10.113333333333333, 51.565],
  [10.381111111111112, 48.35277777777778],
  [8.415833333333333, 51.86027777777778],
  [7.4675, 51.36027777777778],
  [11.970555555555556, 51.4825],
  [9.996944444444443, 53.550555555555555],
  [9.389999999999999, 52.095],
  [7.816944444444444, 51.67833333333333],
  [8.915277777777778, 50.135555555555555],
  [9.962777777777777, 53.31583333333333],
  [10.958888888888888, 51.821111111111115],
  [12.62861111111111, 52.62111111111111],
  [10.606944444444444, 50.062777777777775],
  [9.768611111111111, 52.92805555555555],
  [8.701944444444443, 49.40888888888889],
  [10.182222222222222, 48.66222222222222],
  [9.218055555555555, 49.14055555555556],
  [9.293333333333333, 49.20416666666667],
  [6.16388888888889, 51.04944444444444],
  [10.88888888888889, 52.260555555555555],
  [8.643888888888888, 52.1675],
  [7.222777777777778, 51.540277777777774],
  [9.752777777777778, 50.90611111111111],
  [10.601666666666667, 53.58888888888889],
  [10.733888888888888, 50.43388888888889],
  [9.943333333333333, 52.09],
  [8.385, 51.30694444444444],
  [8.504722222222222, 50.281666666666666],
  [11.843611111111112, 50.20277777777778],
  [11.913611111111111, 50.31638888888889],
  [9.613888888888889, 49.271388888888886],
  [9.551388888888889, 51.88055555555555],
  [9.178055555555554, 51.68472222222222],
  [10.966111111111111, 50.73777777777778],
  [11.423333333333332, 48.76555555555556],
  [11.591944444444445, 50.93],
  [12.026111111111112, 52.260555555555555],
  [7.542777777777777, 49.43194444444444],
  [7.770833333333333, 49.44305555555555],
  [8.405277777777778, 49.00972222222222],
  [8.664444444444445, 49.05166666666666],
  [9.406666666666666, 51.401111111111106],
  [9.495277777777776, 51.31472222222222],
  [10.620555555555557, 47.87972222222222],
  [11.8575, 48.82472222222223],
  [10.317777777777778, 47.723888888888894],
  [10.137222222222222, 54.32166666666667],
  [10.25611111111111, 49.753055555555555],
  [6.259166666666666, 51.651944444444446],
  [7.592499999999999, 50.36],
  [8.911944444444444, 47.799166666666665],
  [6.560833333333333, 51.3325],
  [11.371944444444445, 50.32777777777778],
  [11.482222222222223, 50.1025],
  [7.471111111111111, 49.54972222222222],
  [10.98472222222222, 51.325],
  [6.956944444444445, 50.93805555555555],
  [8.365555555555556, 50.64805555555556],
  [8.112499999999999, 49.196666666666665],
  [10.948611111111111, 48.024166666666666],
  [12.348055555555556, 48.4525],
  [12.158888888888889, 48.53861111111111],
  [7.5008333333333335, 53.23027777777778],
  [12.378055555555555, 51.33833333333334],
  [12.599166666666667, 51.22138888888889],
  [6.979722222222223, 51.0325],
  [11.116944444444444, 50.10944444444445],
  [8.199444444444445, 50.427499999999995],
  [9.882777777777779, 47.60527777777778],
  [8.950833333333332, 51.981388888888894],
  [9.123055555555556, 48.94],
  [8.447777777777778, 49.483333333333334],
  [11.475833333333334, 53.346944444444446],
  [7.773888888888889, 47.70333333333333],
  [10.68388888888889, 53.86638888888889],
  [11.123055555555556, 53.019999999999996],
  [10.615277777777777, 53.28],
  [11.632777777777779, 52.126111111111115],
  [9.404166666666667, 50.264722222222225],
  [9.662777777777778, 49.99333333333333],
  [9.725555555555555, 49.56055555555555],
  [8.434444444444445, 50.1025],
  [8.271944444444445, 50.00194444444445],
  [8.03277777777778, 49.930277777777775],
  [8.466388888888888, 49.48777777777778],
  [11.356388888888889, 51.53583333333333],
  [8.738055555555555, 50.83694444444445],
  [7.332222222222222, 50.33166666666667],
  [13.002222222222223, 53.54333333333333],
  [13.482777777777779, 51.239444444444445],
  [10.185, 47.98833333333334],
  [6.666111111111111, 49.46638888888889],
  [7.075833333333333, 51.32361111111111],
  [11.809166666666668, 47.74138888888889],
  [9.23472222222222, 49.757222222222225],
  [8.722777777777777, 52.32],
  [13.138055555555555, 50.955555555555556],
  [14.132777777777779, 52.64194444444444],
  [7.712777777777778, 51.2625],
  [6.431666666666667, 51.19444444444444],
  [12.381666666666668, 48.23916666666667],
  [6.885, 51.42805555555555],
  [11.575555555555555, 48.136944444444445],
  [11.618333333333334, 48.00416666666667],
  [7.626666666666667, 51.96194444444445],
  [9.280000000000001, 49.46666666666667],
  [10.14111111111111, 48.29805555555556],
  [13.26, 53.55722222222222],
  [11.1975, 48.66444444444444],
  [11.566666666666668, 49.215833333333336],
  [9.988333333333333, 54.07],
  [7.117222222222222, 49.37583333333333],
  [10.465277777777777, 49.56944444444445],
  [11.87138888888889, 49.725833333333334],
  [8.140833333333333, 49.35194444444445],
  [7.461388888888889, 50.51444444444444],
  [9.257777777777777, 52.64861111111111],
  [9.063611111111111, 54.617777777777775],
  [10.730833333333333, 51.50222222222222],
  [13.038333333333334, 51.44222222222222],
  [11.248888888888889, 53.81861111111112],
  [9.84888888888889, 51.74333333333333],
  [11.075277777777778, 49.450833333333335],
  [11.36888888888889, 49.49138888888889],
  [10.260555555555555, 47.57888888888889],
  [7.516944444444444, 51.0125],
  [6.852222222222222, 51.47027777777778],
  [13.205277777777777, 52.906388888888884],
  [13.943333333333333, 51.615833333333335],
  [8.979444444444445, 49.671388888888885],
  [14.219722222222222, 52.24388888888889],
  [8.82, 50.01],
  [8.764444444444445, 50.105555555555554],
  [8.389444444444443, 52.988055555555555],
  [8.213055555555554, 53.14],
  [7.976388888888889, 51.086111111111116],
  [8.016111111111112, 48.42055555555555],
  [8.161388888888888, 52.3725],
  [8.041944444444445, 52.276944444444446],
  [10.09, 48.8775],
  [10.639166666666666, 47.772777777777776],
  [8.808055555555557, 53.25111111111111],
  [10.95111111111111, 54.26083333333333],
  [12.636111111111111, 52.99111111111111],
  [8.719722222222222, 51.66416666666667],
  [13.19, 48.49638888888889],
  [13.42, 48.56944444444445],
  [10.255277777777778, 52.30416666666667],
  [11.524166666666668, 48.596111111111114],
  [8.699166666666667, 48.89138888888889],
  [9.74611111111111, 53.71666666666667],
  [7.608333333333333, 49.1975],
  [10.363333333333333, 54.24333333333333],
  [13.053611111111111, 52.400277777777774],
  [12.760555555555555, 52.226111111111116],
  [11.961666666666666, 53.10916666666667],
  [8.0975, 48.78388888888889],
  [9.780000000000001, 47.825],
  [7.1594444444444445, 51.6725],
  [13.100000000000001, 49.022777777777776],
  [12.28277777777778, 49.00388888888889],
  [12.110555555555555, 49.013888888888886],
  [9.719722222222222, 52.42194444444444],
  [6.957222222222223, 49.25138888888889],
  [9.501111111111111, 48.89888888888889],
  [7.194444444444445, 51.18],
  [9.781111111111112, 54.28916666666667],
  [9.365833333333333, 48.406388888888884],
  [6.716666666666667, 50.905],
  [7.5, 50.04416666666666],
  [6.649444444444444, 51.14361111111111],
  [7.842499999999999, 50.263333333333335],
  [8.771944444444445, 49.290277777777774],
  [8.360833333333334, 49.41638888888889],
  [7.382222222222222, 50.75277777777778],
  [8.080833333333333, 50.14194444444444],
  [7.195, 51.02305555555555],
  [10.255277777777778, 50.37138888888889],
  [12.124722222222223, 47.85583333333334],
  [12.2825, 47.895833333333336],
  [12.221666666666668, 53.911944444444444],
  [12.136111111111111, 54.08861111111111],
  [9.307500000000001, 53.250277777777775],
  [11.123888888888889, 49.20277777777778],
  [12.8675, 48.42444444444444],
  [8.5325, 48.25416666666667],
  [11.731388888888889, 50.90416666666667],
  [11.710555555555555, 50.580555555555556],
  [12.005277777777778, 51.33972222222222],
  [11.309166666666668, 50.63777777777778],
  [6.7716666666666665, 49.314166666666665],
  [7.242222222222222, 49.24861111111111],
  [10.328333333333333, 52.160555555555554],
  [11.6425, 51.85138888888889],
  [9.206388888888888, 52.29277777777778],
  [9.498333333333333, 54.62305555555555],
  [10.405000000000001, 50.63055555555555],
  [11.020833333333334, 49.32944444444445],
  [9.373333333333333, 51.02361111111111],
  [12.252777777777778, 49.367777777777775],
  [8.411111111111111, 48.01888888888889],
  [10.245, 49.94916666666666],
  [10.23361111111111, 50.04666666666667],
  [11.414444444444445, 53.62861111111111],
  [9.908611111111112, 49.144444444444446],
  [10.140555555555554, 53.919999999999995],
  [8.194722222222222, 50.937777777777775],
  [9.24111111111111, 48.038888888888884],
  [8.2175, 51.56444444444444],
  [7.082777777777777, 51.17166666666667],
  [11.133055555555556, 50.41444444444444],
  [8.436666666666667, 49.316111111111105],
  [14.595277777777778, 51.68944444444444],
  [7.115277777777777, 49.278888888888886],
  [7.100555555555555, 49.519444444444446],
  [9.418333333333333, 53.57833333333333],
  [11.281666666666668, 48.005833333333335],
  [9.519444444444446, 53.928888888888885],
  [7.579166666666667, 52.21138888888889],
  [11.839722222222223, 52.69694444444444],
  [10.331666666666667, 53.720555555555556],
  [13.09, 54.31388888888888],
  [12.5725, 48.87583333333333],
  [12.70111111111111, 48.93416666666666],
  [9.177777777777777, 48.77583333333333],
  [6.217222222222222, 50.7325],
  [10.686944444444444, 50.60944444444445],
  [13.92361111111111, 50.90333333333333],
  [11.154722222222222, 51.157777777777774],
  [8.081944444444444, 49.12],
  [7.7675, 49.20027777777778],
  [13.275833333333335, 52.07277777777778],
  [12.365277777777777, 49.8925],
  [12.579166666666666, 47.89416666666666],
  [6.641388888888889, 49.75666666666667],
  [6.843333333333333, 49.717222222222226],
  [8.794166666666667, 48.01111111111111],
  [8.987777777777778, 48.48166666666667],
  [13.861388888888888, 53.20583333333334],
  [10.548055555555555, 52.983333333333334],
  [9.990277777777777, 48.39833333333333],
  [7.738611111111111, 51.54666666666667],
  [10.551944444444445, 51.18527777777778],
  [10.39111111111111, 48.040277777777774],
  [8.222777777777777, 52.66],
  [9.175833333333333, 52.973055555555554],
  [6.326111111111111, 51.282777777777774],
  [9.27138888888889, 50.63805555555555],
  [12.235, 50.45666666666667],
  [14.066666666666668, 53.57972222222222],
  [12.814722222222223, 54.213055555555556],
  [6.748888888888889, 50.23833333333334],
  [6.854444444444444, 49.249722222222225],
  [8.88861111111111, 51.19027777777777],
  [8.21888888888889, 47.697222222222216],
  [7.958611111111111, 51.87],
  [10.210277777777778, 50.885555555555555],
  [12.160277777777779, 49.67611111111111],
  [11.073888888888888, 47.81472222222222],
  [11.329722222222221, 50.98083333333334],
  [11.549166666666666, 51.051111111111105],
  [10.893611111111111, 49.0325],
  [9.929166666666665, 51.190555555555555],
  [6.618333333333334, 51.62694444444445],
  [8.390277777777778, 53.355555555555554],
  [7.865277777777777, 50.55222222222222],
  [8.907777777777778, 50.35472222222222],
  [8.501111111111111, 50.55444444444444],
  [8.239166666666666, 50.08166666666667],
  [8.11111111111111, 53.52027777777778],
  [11.466111111111111, 53.891666666666666],
  [12.701944444444443, 51.82027777777778],
  [7.710555555555556, 53.56444444444444],
  [10.646944444444443, 52.13527777777778],
  [10.785, 52.42194444444444],
  [8.361666666666666, 49.62972222222222],
  [12.041944444444445, 50.09],
  [7.195833333333334, 51.271388888888886],
  [9.937222222222223, 49.79388888888889],
  [9.907222222222222, 49.665],
  [8.9375, 48.26722222222222],
  [7.3630555555555555, 49.24916666666667],
  [12.525833333333335, 50.75055555555556]
]

function getDistanceBetweenCoords(lat1, lon1, lat2, lon2) {
  const R = 6371e3 // metres
  const φ1 = (lat1 * Math.PI) / 180 // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180
  const Δφ = ((lat2 - lat1) * Math.PI) / 180
  const Δλ = ((lon2 - lon1) * Math.PI) / 180

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const d = R * c // in metres
  return d
}

export default function mapCoordToCity(userLat, userLon) {
  let result = null
  let distance = 100000 // min within 100km
  longLatIndexArray.forEach(([lon, lat], index) =>{
    const d = getDistanceBetweenCoords(userLat, userLon, lat, lon)
    if (d < distance) {
      distance = d
      result = index
    }
  })
  if (result) {
    const cityNameAndCode = cityNames.split("\n")[result].split("\t")
    return cityNameAndCode
  } else {
    return undefined
  }
}

export {getDistanceBetweenCoords};

import React, {useState, useEffect, useLayoutEffect} from "react"
import {useDispatch} from "react-redux"
import {View, StyleSheet} from "react-native"
import AppColors from "../styles/AppColors"
import {
  GiftedChat,
  Send,
  SystemMessage,
  Bubble,
  MessageText,
  InputToolbar,
} from "../GiftedChat/GiftedChat"
import Icon from "../components/RealnoteIcon/Icon"
import Global from "../Global"
import Socket from "../http/socket"
import {
  challengeFeedsApi,
  useGetFeedsQuery,
  useGetProfilePictureQuery,
  useGetProfilePreviewQuery,
  useSendMessageMutation,
  transformFeed,
} from "../store/reducers/ChallengeFeedsSlice"
import Account from "../dataProvider/Account"
import DropdownHeader from "../components/DropdownHeader"
import {useNavigation} from "@react-navigation/native"
import CircularIconButton from "./MenuBarScreen/CircularIconButton"

export const renderSystemMessage = props => {
  return (
      <SystemMessage
          {...props}
          containerStyle={{
            marginBottom: 15,
          }}
          textStyle={{
            fontSize: 14,
            color: AppColors.grayText,
          }}
      />
  )
}

export const renderBubble = props => {
  return (
    <Bubble
      {...props}
      wrapperStyle={{
        right: {backgroundColor: AppColors.primary, maxWidth: "90%"},
        left: {backgroundColor: AppColors.gray30, maxWidth: "90%"},
      }}
      renderMessageText={props => {
        return (
          <MessageText
            {...props}
            textStyle={{
              left: {width: "90%"},
              right: {color: AppColors.white},
            }}
          />
        )
      }}
    />
  )
}

const ChallengeFeeds = props => {
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const [description, setDescription] = useState("")
  const [groupImage, setGroupImage] = useState("")
  const [title, setTitle] = useState("")
  const [backgroundColor, setBackgroundColor] = useState("")
  const [feed, setFeed] = useState([])
  const [membersCount, setMembersCount] = useState("")
  const [user, setUser] = useState({
    _id: Account.userId,
    name: "",
    avatar: "https://i.pravatar.cc/300",
  })
  const [challengeId, setChallengeId] = useState("")
  const [distance, setDistance] = useState(0)
  const queryInput = {challengeId: props.challengeId, baseUrl: Global.baseUrl}

  const {data, refetch} = useGetFeedsQuery(queryInput, {
    refetchOnMountOrArgChange: true,
  })
  const {data: objectURL} = useGetProfilePictureQuery({userId: props.userId})
  const {data: profilePreview} = useGetProfilePreviewQuery({
    userId: props.userId,
  })
  const [sendMessage] = useSendMessageMutation()

  useEffect(() => {
    if (data !== undefined) {
      const dataFixed = JSON.parse(JSON.stringify(data))
      const {
        description,
        groupImage,
        title,
        backgroundColor,
        feed,
        participants,
      } = dataFixed
      setDescription(description)
      setGroupImage(groupImage)
      setTitle(title)
      setBackgroundColor(backgroundColor)
      setFeed(feed)
      let dist = ""
      const roundDistance = Math.round(distance)
      if (roundDistance > 0) {
        dist = `${roundDistance} m entfernt`
      }
      setMembersCount(`${participants.length} Teilnehmer ${dist}`)
    }
  }, [data])

  useEffect(() => {
    if (objectURL !== undefined) {
      setUser(val => ({...val, avatar: objectURL}))
    }
  }, [objectURL])

  useEffect(() => {
    if (profilePreview !== undefined) {
      setUser(val => ({...val, name: profilePreview.username}))
    }
  }, [profilePreview])

  useEffect(() => {
    if (props.challengeId) {
      // not running as standalone Version but integrated in webapp
      setUser(prevState => ({...prevState, _id: props.userId}))
      setChallengeId(props.challengeId)
      setDistance(props.distance)
    } else {
      const urlParams = new URLSearchParams(window.location.search)
      setUser(prevState => ({...prevState, _id: urlParams.get("userId")}))
      setChallengeId(urlParams.get("challengeId"))
      setDistance(urlParams.get("distance"))
    }
  }, [props.challengeId])

  useLayoutEffect(() => {
    Socket.subscribe("onReceiveMessage", onReceiveMessageHandler)
    Socket.connect()

    window.addEventListener("message", e => {
      if (e.data === "refreshFeed") {
        onRefresh()
      }
    })
  }, [])

  const onReceiveMessageHandler = function (data) {
    if (data.userId !== user._id) {
      let newItem = transformFeed(data)
      dispatch(
        challengeFeedsApi.util.updateQueryData(
          "getFeeds",
          queryInput,
          draftFeeds => {
            draftFeeds.feed.push(newItem)
          },
        ),
      )
    }
  }

  const onRefresh = () => {
    refetch()
  }

  const onSend = messages => {
    const newMessage = messages[0]
    const payload = {
      challengeId,
      userId: newMessage.user._id,
      message: newMessage.text,
      baseUrl: Global.baseUrl,
    }
    const successHandler = res => {
      let newItem = {
        _id: Date(),
        user: {user: {}, _id: newMessage.user._id},
        text: newMessage.text,
        createdAt: Date(),
      }
      dispatch(
        challengeFeedsApi.util.updateQueryData(
          "getFeeds",
          queryInput,
          draftFeeds => {
            draftFeeds.feed.push(newItem)
          },
        ),
      )
    }

    sendMessage(payload).unwrap().then(successHandler)
  }

  const renderSendButton = props => {
    return (
      <Send {...props}>
        <View style={{marginLeft: 10, paddingBottom: 5}}>
          <Icon name={"Send"} height={40} width={40} />
        </View>
      </Send>
    )
  }

  const renderInputToolbar = props => {
    return (
      <InputToolbar
        {...props}
        containerStyle={{
          borderWidth: 0,
          shadowOpacity: 0,
        }}
        primaryStyle={{
          backgroundColor: "white",
          alignItems: "center",
          shadowOpacity: 0,
        }}
      />
    )
  }

  const headerLeft = (
    <View style={{marginLeft: -10}}>
      <CircularIconButton
        nativeID={"ArTouchable-3"}
        buttonColor={AppColors.white}
        noCircle={true}
        size={40}
        name={"Back"}
        onPress={navigation.goBack}
      />
    </View>
  )

  return (
    <>
      <DropdownHeader headerLeft={headerLeft} />
      <View style={styles.chatContainer} nativeID={"challengeFeedMain"}>
        <GiftedChat
          messages={feed}
          onSend={onSend}
          user={user}
          inverted={false}
          textInputStyle={styles.textInputStyle}
          renderSend={renderSendButton}
          renderInputToolbar={renderInputToolbar}
          renderUsernameOnMessage={true}
          renderSystemMessage={renderSystemMessage}
          renderBubble={renderBubble}
          alwaysShowSend
          infiniteScroll={false}
          dateFormat={"DD.MM.YYYY"}
          timeFormat={"HH:mm"}
          isKeyboardInternallyHandled={false}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  chatContainer: {flex: 1, pointerEvents: "auto", padding: 10},
  textInputStyle: {
    backgroundColor: AppColors.lightPlaceholder,
    borderRadius: 18,
    paddingTop: 8,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 8,
  },
  backButton: {
    width: 40,
    height: 40,
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
  },
})

export default ChallengeFeeds

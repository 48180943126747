import AsyncStorage from "@react-native-async-storage/async-storage"
// import * as Keychain from 'react-native-keychain';
import realnote from "../bridges/RealnoteNative"
import Global from "../Global"
// import UUIDGenerator from 'react-native-uuid-generator';
// import { Platform } from "react-native";
import BlockedUsersProvider from "../dataProvider/BlockedUsersProvider"

import Logger from "../bridges/RealnoteLogger"
import {strings} from "../i18n"
import {HttpBridge} from "../http/HttpBridge"
import {v4 as uuidv4} from "uuid"

const TAG = "Account"
const log = new Logger(TAG)
const http = new HttpBridge()
const Keychain: any = {}
const UUIDGenerator: any = {}
const Platform = {OS: "web"}

export enum serverResponse {
  ok = "success",
  offlineLoggedIn = "offlineLoggedIn",
  noNetwork = "noNetwork",
  failed = "failed",
  usernameAlreadyExists = "Username already exists",
  usernameAlreadyExistsWithQuotes = "Username already exists",
  emailAlreadyExists = "Email already exists",
  wrongPassword = "Wrong password",
  usernameExistsAsMail = "Username already exists as e-mail address",
  unprocessed = "Unprocessed",
  noChangesNeeded = "No changes",
  noSuchEmail = "No such email or username"
}

class Account {
  public adId: string
  public userId: string
  public username: string
  public email: string
  public password: string
  public sessionId: string
  public isDefaultWebUser : boolean = true

  public getDisplayUsername(): string {
    return this.username
  }

  public async logIn(username: string, password: string): Promise<string> {

    this.isDefaultWebUser = username == "@web@"

    console.log ("logIn", username, password)
    return new Promise<string>((resolve, reject) => {
      if (username === undefined) {
        log.e("username must be of type string}")
        reject(strings("Account.emptyFields"))
        return
      }

      let cleanedUsername = username.trim()
      cleanedUsername = cleanedUsername.toLowerCase()

      http
        .loginAccount(cleanedUsername, password)
        .then(async (resString: string) => {
          log.d("Answer from loginAccount: " + resString)
          const result = JSON.parse(resString)
          if (result !== null) {
            this.userId = result["userId"]
            realnote.logIn(this.userId, cleanedUsername)
            if (result["message"] == serverResponse.offlineLoggedIn) {
              this.username = cleanedUsername
              resolve(strings("Account.offlineLoggedIn"))
              BlockedUsersProvider.initializeBlockedUsers()
              return
            } else if (result["message"] == serverResponse.ok) {
              log.d("onPressLogin: success: userId: " + result["userId"])
              
              this.userId = result["userId"]
              this.sessionId = result["sessionId"]
              this.username = cleanedUsername
    
              log.v("userstate: " + result["userstate"])
              if (result["userstate"] == "Admin") {
                Global.adminMode = true
              }
              if (result["showWelcomeScenes"] != null) {
                realnote.setSharedPrefBoolean(
                  "showWelcomeScenes",
                  result["showWelcomeScenes"],
                )
              }

              if (password !== undefined) {
                log.v("password:" + password)
                // Android Keychain seems to have some issues:
                // https://issuetracker.google.com/issues/147480931

                this.storeCredentials(cleanedUsername, password)
                realnote.setHasLoggedOnWithFullyRegisteredAccount()
              }
              // Save Token for Host and Web
              AsyncStorage.setItem("@data:userId", result["userId"])
              AsyncStorage.setItem("@data:sessionId", result["sessionId"])

              BlockedUsersProvider.initializeBlockedUsers()
              resolve(strings("Account.success"))
              return
            } else if (result["message"] == serverResponse.noNetwork) {
              log.e("LogIn error, no network")
              resolve(strings("Account.noNetwork"))
            } else {
              this.userId = "Error"
              log.e("LogIn error, result: " + JSON.stringify(result))

              resolve(strings("Account.failed"))
              return
            }
          } else {
            resolve(strings("Account.failed"))
          }
        })
        .catch(error => {
          log.error("logIn caught error: " + error)
          reject(strings("Account.failed"))
          realnote.logIn(this.userId, cleanedUsername)
        })
    })
  }

  public async setNewCredentials(password) {
    log.v("Setze neue Credentials")
    log.v("this.username: " + this.username)
    this.storeCredentials(this.username, password)
    this.password = password
  }

  /**
   * Abstracts the actual storage of username and password away in case the Android KeyStore is fixed
   * or we decide on another storage solution
   */
  private async storeCredentials(username: string, password: string) {
  if (!this.isDefaultWebUser) {
    if (Platform.OS === "ios") {
      await Keychain.resetGenericPassword({ service: "one.realnote.app" })
      await Keychain.setGenericPassword(username, password, {
        service: "one.realnote.app",
      })
    } else {
      // Die sharedPreferences können zwar nicht durch andere Apps ausgelesen werden,
      // können aber über root Zugriff aus dem Gerät extrahiert werden.
      await realnote.setSharedPrefString("username", username)
      await realnote.setSharedPrefString("password", password)
    }
  }
}

  /**
   * Abstracts the actual retrieval of username and password from the storage away in case the Android KeyStore is fixed
   * or we decide on another storage solution
   */
  private async getCredentials(): Promise<{
    username: string
    password: string
  }> {
    if (Platform.OS === "ios") {
      let credentials = await Keychain.getGenericPassword({
        service: "one.realnote.app",
      })
      if (credentials) {
        return credentials
      } else {
        return {username: null, password: null}
      }
    } else {
      const usernameRefString = await realnote.getSharedPrefString("username")
      const passwordRefString = await realnote.getSharedPrefString("password")
      const credentials = {
        username: usernameRefString.valueOf(),
        password: passwordRefString.valueOf(),
      }
      return credentials
    }
  }

  public async setNewCredentialsUsername(username) {
    let password = ""
    if (this.password) {
      password = this.password
    }
    await this.storeCredentials(username, password)
    log.d("new username was set in keychain")
    this.username = username
  }

  public async logOut(): Promise<string> {
    return new Promise<string>(async resolve => {
      await this.storeCredentials("", "")
      AsyncStorage.removeItem("@data:userId")
      AsyncStorage.removeItem("@data:sessionId")
      realnote.logOut(this.userId)
      this.userId = null
      this.username = null
      this.password = null
      resolve(serverResponse.ok)

      // realnote.logOut(this.sessionId).then((result) => {
      //   resolve(result.message);
      // }).catch((error) => {
      //   reject("Error");
      // })
    })
  }

  // SignUp a new User with the Unique ID
  public async autoSignUp(Id: string): Promise<string | boolean> {
    log.d("called autosignup")
    return http
      .autoRegisterLocal(Id)
      .then((result: any) => {
        if (result != null) {
          log.d("autoSignUp server response: " + JSON.stringify(result))
          if (result.message == serverResponse.ok) {
            this.username = result.username
            this.email = result.email
            this.userId = Id
            this.setNewCredentialsUsername(result.username)
            BlockedUsersProvider.initializeBlockedUsers()
            return result.username
          } else {
            if (result.length == 0) {
              log.v("No answer from autoSignUp")
              return false
            } else {
              log.v("There was an error signing up")
              return false
            }
          }
        } else {
          // return strings("Account.noNetwork");
          return false
        }
      })
      .catch(err => {
        log.v("error:" + JSON.stringify(err))
        return false
      })
  }

  public async signUp(
    username: string,
    email: string,
    password: string,
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (!Account.notEmtpy(username.trim(), password)) {
        reject(strings("Account.emptyFields"))
      } else if (!validEmail(email)) {
        reject(strings("Account.invalidEmail"))
      } else {
        let cleanedUsername = username.trim()
        cleanedUsername = cleanedUsername.toLowerCase()

        let cleanedEmail = email.trim()
        cleanedEmail = cleanedEmail.toLowerCase()

        realnote
          .signUp(cleanedUsername, cleanedEmail, password)
          .then((result: any) => {
            log.d("result: " + JSON.stringify(result))
            if (result != null) {
              if (result.message == serverResponse.ok) {
                log.d("onPressSignUp: Signing up was successful")
                this.username = cleanedUsername
                this.email = cleanedEmail
                BlockedUsersProvider.initializeBlockedUsers()
                resolve(strings("Account.success"))
              } else if (
                result.message == serverResponse.usernameAlreadyExists
              ) {
                log.d(
                  "onPressSignUp: There was an error signing up. Username already exists",
                )
                resolve(strings("Account.usernameExists"))
              } else if (result.message == serverResponse.emailAlreadyExists) {
                log.d(
                  "onPressSignUp: There was an error signing up. Email already exists",
                )
                resolve(strings("Account.emailExists"))
              } else {
                log.d("onPressSignUp: There was an error signing up.")
                resolve(strings("Account.failed"))
              }
            } else {
              reject(strings("Account.noNetwork"))
            }
          })
          .catch(error => {
            reject(strings("Account.failed"))
          })
      }
    })
  }

  public async forgot(email: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (!Account.notEmtpy(email)) {
        reject(strings("Account.emptyFields"))
      } else {
        realnote
          .forgot(email.toLowerCase())
          .then((result: any) => {
            if (result !== null) {
              if (result.message == serverResponse.ok) {
              log.d("onPressRequest: Successfully requested password reset token.");
              this.userId = result.userId;
              resolve(strings("Account.success"));
              return;
            } else if(result.message == serverResponse.noSuchEmail) {
              reject(strings("Account.emailMissing"));
              } else {
                reject(strings("Account.failed"))
              }
            } else {
              reject(strings("Account.noNetwork"))
            }
          })
          .catch(error => {
            reject(strings("Account.failed"))
          })
      }
    })
  }

  public async reset(token: string, password: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (!Account.notEmtpy(token, password)) {
        reject(strings("Account.emptyFields"))
      } else {
        realnote
          .reset(this.userId, token, password, this.email, this.username)
          .then((result: any) => {
            log.d(
              "Reset answer: " +
                result.message +
                " and is == serverResponse.ok: " +
                (result.message == serverResponse.ok),
            )
            if (result !== null) {
              if (result.message == serverResponse.ok) {
                log.d("onPressReset: Successfully reset password.")
                resolve(strings("Account.success"))
              } else {
                reject(strings("Account.failed"))
              }
            } else {
              reject(strings("Account.noNetwork"))
            }
          })
          .catch(error => {
            reject(strings("Account.failed"))
          })
      }
    })
  }

  public async loadCredentials(): Promise<void> {
    const {username, password} = await this.getCredentials()

    if (username != "") {
      this.username = username
    }
    if (password != "") {
      this.password = password
    }

    this.userId = await AsyncStorage.getItem("@data:userId")
    this.sessionId = await AsyncStorage.getItem("@data:sessionId")
  }

  public async hasCredentialsStored() {
    await this.loadCredentials()

    return (
      this.username &&
      this.username != "" &&
      this.password &&
      this.password != ""
    )
  }

  public async getUniqueUserId(): Promise<string> {
    log.d("Start get ADId in Account.ts")
    const adIdFromStorage = await realnote.getSharedPrefString("one.realnote.app.userId")
    if (adIdFromStorage && adIdFromStorage !== "") {
      this.adId = adIdFromStorage.toString()
      return this.adId
    }
    if (this.adId && this.adId != "") {
      return this.adId
    } else {
      return realnote.getAdId().then(async (result: string) => {
        log.d("Return from getADId from Kotlin is " + result)
        let id = ""
        if (!result || result === "" || result === "@adid@") {
          log.v(`adId is missing, set fallbackId`);
          id = uuidv4() + "-rn";
          realnote.setSharedPrefString("one.realnote.app.userId", id);
          return id;
        } else {
          log.v(`save adId`);
          realnote.setSharedPrefString("one.realnote.app.userId", result);
          id = result;
          return result
        }
      });
    }
  }

  static notEmtpy(...str: string[]): boolean {
    if (str === undefined) {
      return undefined
    }
    for (var i = 0; i < str.length; i++) {
      let check = str[i] !== ""
      if (!check) return false
    }
    return true
  }
}

export function validEmail(email: string): boolean {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email.toLowerCase())
}

var _account = new Account()
export default _account

import React, {useEffect, useState} from "react"
import {View, Text, StyleSheet, TextInput} from "react-native"
import {useIsFocused} from "@react-navigation/native"
import RealnoteScreen from "./MenuBarScreen/RealnoteScreen"
import {HttpBridge} from "../http/HttpBridge"
import Account, {serverResponse} from "../dataProvider/Account"
import AppColors from "../styles/AppColors"
import AppStyles, {xAppStyles} from "../styles/AppStyles"
import {strings} from "../i18n"
import Realnote from "./../bridges/RealnoteNative"
import ChipButton from "../components/ChipButton"
import FastImage from "../components/XFastImage"
import StyleConstants from "../styles/StyleConstants"
import ArMode from "../ArMode"
const http = new HttpBridge()
var autoNavigateBack = false

export default function EnterUsername(props) {
  const {navigation} = props
  const [username, setUsername] = useState("")
  const [profilePictureUrl, setProfilePictureUrl] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [keyboardOpen, setKeyboardOpen] = useState(false)
  const noteData = navigation.getParam("noteData")

  useEffect(() => {
    async function getProfileImageUrl() {
      const url = await http.getProfileImageUrl(Account.userId)
      setProfilePictureUrl(url)
    }
    getProfileImageUrl()
  }, [])

  const isFocused = useIsFocused()

  useEffect(() => {
    if (isFocused) {
      if (autoNavigateBack) {
        autoNavigateBack = false
        navigation.goBack()
      }
    }
    return () => {}
  }, [isFocused])

  async function change() {
    // todo: improve case where user is setting new username to old one
    if (username === Account.username) {
      return {message: serverResponse.ok}
    }
    const result = await http.changeUsername(username)
    return result
  }

  async function onLogin() {
    Realnote.sendFirebaseClick("EnterUsername_okay")
    if (username.length < 3) {
      setErrorMessage(strings("Account.nameTooShort"))
      setUsername("")
      return false
    }
    if (username.startsWith("@realnoteuser")) {
      setErrorMessage(strings("Account.invalidUsername"))
      setUsername("")
      return false
    } else if (username.trim() === "") {
      setErrorMessage(strings("Account.checkInput"))
      setUsername("")
      return false
    } else {
      const result = await change()
      if (result && result.message === serverResponse.ok) {
        Account.setNewCredentialsUsername(username)
        autoNavigateBack = true
        ArMode.startAr()
        navigation.navigate("Live", {
          noteData: noteData,
        })
      } else if (result.message === serverResponse.usernameAlreadyExists) {
        setUsername(result.alternative)
        setErrorMessage(
          strings("NoteEditor.usernameAlreadyExists") +
            " " +
            result.alternative +
            " " +
            strings("NoteEditor.usernameAlreadyExistsEnd"),
        )
        setTimeout(() => this.setState({error: null}), 2000)
      } else if (result.message === serverResponse.usernameExistsAsMail) {
        setErrorMessage(strings("Account.emailExists"))
        setUsername("")
      } else {
        setErrorMessage(strings("Account.failed"))
      }
      return true
    }
  }

  function onPressSkip() {
    Realnote.sendFirebaseClick("EnterUsername_skip")
    autoNavigateBack = true
    ArMode.startAr()
    navigation.navigate("Live", {
      noteData: noteData,
    })
  }

  function goBack() {
    Realnote.sendFirebaseClick("EnterUsername_back")
    navigation.goBack()
  }

  return (
    <RealnoteScreen navigation={navigation} backgroundFilled onBack={goBack}>
      <View style={keyboardOpen ? styles.containerOpen : styles.container}>
        <FastImage
          source={{
            uri: profilePictureUrl,
          }}
          style={{
            height: 150,
            width: 150,
            borderRadius: 75,
            borderWidth: 2,
            borderColor: AppColors.primary,
          }}
        />
        <Text style={[AppStyles.onboardingTitle, {marginVertical: 20}]}>
          {strings("Onboarding.Username.title")}
        </Text>

        <Text
          style={[
            AppStyles.onboardingText,
            {
              width: "80%",
              textAlign: "center",
            },
          ]}>
          {
            "Gib einen Namen ein, damit andere sehen können was du gepostet hast"
          }
        </Text>
        <TextInput
          placeholder={strings("Onboarding.Username.chooseName")}
          placeholderTextColor={AppColors.secondary}
          style={[
            AppStyles.largeText,
            {
              textAlignVertical: "center",
              width: "75%",
              paddingVertical: 10,
              paddingHorizontal: 20,
              color: AppColors.black,
              backgroundColor: AppColors.gray30,
              borderRadius: 60,
            },
          ]}
          onFocus={() => {
            setKeyboardOpen(true)
          }}
          value={username}
          onChangeText={setUsername}
          blurOnSubmit={true}
          onSubmitEditing={onLogin}
        />
        <ChipButton
          style={xstyles.button}
          textStyle={xAppStyles.largeText}
          label={"Benutzernamen wählen"}
          onPress={onLogin}
        />
        <ChipButton
          style={xstyles.skipButton}
          textStyle={xAppStyles.text}
          label={strings("NoteEditor.skip")}
          noShadow
          onPress={onPressSkip}
        />
        <Text style={styles.errorText}>{errorMessage}</Text>
      </View>
    </RealnoteScreen>
  )
}

const xstyles = {
  containerOpen: {
    position: "absolute",
    width: "100%",
    bottom: 0,
    backgroundColor: AppColors.white,
    flexDirection: "column",
    alignItems: "center",
    pointerEvents: "auto",
  },
  container: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: AppColors.white,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "auto",
  },
  button: {
    width: "75%",
    marginTop: 20,
    paddingVertical: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  errorText: {
    textAlign: "center",
    color: AppColors.highlight,
    fontSize: 20,
    marginVertical: 10,
    height: 30,
    fontFamily: "Poppins",
  },
  skipButton: {
    marginTop: 30,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: AppColors.white,
  },
}
const styles = StyleSheet.create(xstyles)


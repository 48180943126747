import { Dimensions, Platform, StyleSheet } from "react-native"

import AppColors from "./AppColors"
import StyleConstants from "./StyleConstants"
import AppConstants from "./AppConstants"

const width = Dimensions.get("screen").width
const height = Dimensions.get("screen").height

const xAppStyles = {
  // styles in alphabetical order
  activeNav: {
    borderBottomWidth: 1,
    borderColor: AppColors.highlight,
  },
  activeNavText: {
    fontSize: 18,
    color: AppColors.primary,
    fontFamily: "Poppins",
  },

  activeButton: {
    width: "70%",
    backgroundColor: AppColors.highlight,
    marginTop: "7%",
  },

  boldButtonText: {
    color: StyleConstants.buttonColor,
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Poppins",
  },

  waitingButton: {
    width: "70%",
    backgroundColor: AppColors.waiting,
    marginTop: "7%",
  },
  button: {
    width: "70%",
    backgroundColor: AppColors.primary,
    marginTop: "7%",
  },
  btnText: {
    color: AppColors.white,
    padding: 10,
    textAlign: "center",
    fontFamily: "Poppins",
  },
  btnTextDark: {
    color: AppColors.black,
    padding: 10,
    textAlign: "center",
    fontFamily: "Poppins",
  },
  btnTextLight: {
    color: AppColors.primary,
    padding: 10,
    textAlign: "center",
    fontFamily: "Poppins",
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
  doublePanelContainer: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  errorOutput: {
    width: "70%",
    marginTop: "7%",
    borderBottomWidth: 0.5,
    borderColor: AppColors.highlight,
    color: AppColors.highlight,
    fontSize: 14,
    fontFamily: "Poppins",
  },

  fillingView: {
    flex: 1,
  },
  form: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 20,
    pointerEvents: "auto",
  },
  infoText: {
    color: AppColors.text,
    fontSize: Platform.OS === "ios" ? 11 : 10,
    fontStyle: "italic",
    fontFamily: "Poppins",
  },
  hugeText: {
    color: AppColors.text,
    fontSize: height < 800 ? 24 : 36,
    fontFamily: "Poppins",
  },
  hugeTextPrimary: {
    color: AppColors.primary,
    fontSize: height < 800 ? 24 : 36,
    fontFamily: "Poppins",
  },
  inactiveNavText: {
    color: AppColors.primaryDark,
    fontSize: 18,
    fontFamily: "Poppins",
  },
  input: {
    width: "70%",
    marginTop: "5%",
    borderBottomWidth: 1,
    borderColor: AppColors.white,
    fontSize: 20,
    color: AppColors.white,
  },
  inputDark: {
    width: "70%",
    marginTop: "3%",
    borderBottomWidth: 1,
    borderColor: AppColors.white,
    fontSize: 18,
    color: AppColors.black,
  },
  largeText: {
    color: AppColors.text,
    fontSize: 18,
    fontFamily: "Poppins",
  },
  xlText: {
    color: AppColors.text,
    fontSize: height < 800 ? 20 : 22,
    fontFamily: "Poppins",
  },
  xxlText: {
    color: AppColors.text,
    fontSize: height < 800 ? 22 : 26,
    fontFamily: "Poppins",
  },
  menuScrollView: {
    marginBottom: 100,
    paddingLeft: "7%",
    paddingRight: "7%",
  },
  nav: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginBottom: "7%",
  },
  navSmall: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  navSmallWithBottomMargin: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginBottom: 15,
  },
  navTextSmall: {
    color: AppColors.primary,
    marginTop: 15,
    fontSize: 14,
    fontFamily: "Poppins",
  },
  noteThumb: {
    backgroundColor: "#00000080",
    width: "45%",
    padding: 5,
    borderColor: "#ffffff20",
    borderWidth: 1,
  },
  overlayContainer: {
    position: "absolute",
    marginTop: "10%",
    backgroundColor: AppColors.panelBackground,
    justifyContent: "space-around",
    alignItems: "center",
    paddingTop: "10%",
    width: "80%",
    height: "85%",
  },
  panel: {
    backgroundColor: AppColors.panelBackground,
    borderWidth: 1,
    borderColor: AppColors.panelBorder,
  },
  profileImg: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  profileImgSmall: {
    width: 44,
    height: 44,
    borderRadius: 22,
  },
  rankingPlacement: {
    color: AppColors.text,
    fontSize: 12,
    fontWeight: "bold",
    alignSelf: "center",
    padding: 3,
    fontFamily: "Poppins",
  },
  rowOfItems: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  shadow: {
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 6,
  },
  shortButton: {
    width: "30%",
    backgroundColor: AppColors.primary,
    marginTop: "7%",
  },
  shortButtonLight: {
    width: "30%",
    marginTop: "7%",
  },
  smallText: {
    color: AppColors.text,
    fontSize: Platform.OS === "ios" ? 11 : 10,
    fontFamily: "Poppins",
  },
  text: {
    color: AppColors.text,
    fontSize: 14,
    fontFamily: "Poppins",
  },
  brightText: {
    color: AppColors.white,
    fontSize: 14,
    padding: "10%",
    fontFamily: "Poppins",
  },
  title: {
    fontSize: 18,
    color: AppColors.white,
    textAlign: "center",
    fontFamily: "Poppins",
  },
  heading: {
    fontSize: 15,
    color: AppColors.black,
    textAlign: "center",
    fontFamily: "Poppins",
    fontWeight: "bold"
  },
  mediumText: {
    color: AppColors.black,
    fontSize: 11,
    fontFamily: "Poppins",
  },
  titlePrimary: {
    fontSize: 30,
    color: AppColors.primary,
    textAlign: "center",
    fontFamily: "Poppins",
  },
  topPanel: {
    width: "100%",
    height: StyleConstants.topBarHeight,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  touchablePanel: {
    height: 150,
    width: "45%",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: AppColors.panelBorder,
    backgroundColor: AppColors.panelBackground,
  },
  touchableMenuPanel: {
    height: 150,
    width: "45%",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: AppColors.panelBorder,
    backgroundColor: AppColors.menuPanelBackground,
  },
  touchablePanelText: {
    fontSize: 14,
    fontWeight: "bold",
    color: AppColors.primary,
    padding: 10,
    textAlign: "left",
    fontFamily: "Poppins",
  },
  touchablePanelDescription: {
    fontSize: 12,
    color: AppColors.black,
    paddingTop: 0,
    paddingLeft: 20,
    paddingRight: 10,
    paddingBottom: 10,
    textAlign: "left",
    fontFamily: "Poppins",
  },
  whiteText: {
    color: AppColors.white,
    fontSize: 14,
    padding: "10%",
    fontFamily: "Poppins",
  },
  blackText: {
    color: AppColors.black,
    fontSize: 14,
    padding: "10%",
    fontFamily: "Poppins",
  },
  clusterContainer: {
    width: 30,
    height: 30,
    padding: 6,
    borderWidth: 1,
    borderRadius: 15,
    alignItems: "center",
    borderColor: AppColors.primary,
    justifyContent: "center",
    backgroundColor: AppColors.backgroundGray,
  },
  clusterText: {
    fontSize: 13,
    color: "#000000",
    textAlign: "center",
    fontFamily: "Poppins",
  },
  textShadow: {
    textShadowColor: "rgba(0, 0, 0, 0.8)",
    textShadowRadius: 10,
    fontFamily: "Poppins",
  },
  onboardingText: {
    color: AppColors.text,
    fontSize: 16,
    marginBottom: "3%",
    width: "65%",
    flexDirection: "column",
    textAlign: "left",
    fontFamily: "Poppins",
  },
  onboardingTitle: {
    color: AppColors.primary,
    fontSize: 30,
    textAlign: "center",
    fontFamily: "Poppins",
    marginHorizontal: "5%",
    marginBottom: "5%",
    marginTop: StyleConstants.topBarHeight,
    flexDirection: "column",
  },
  // the width for lists (AssetList/ModelList) has to be restricted for Desktop-Browsers
  // to avoid to big Pictures
  maxWidthForLists: {
    maxWidth: AppConstants.maxWidth,
  },
  centerImg:{
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  }
}

export { xAppStyles }
export default StyleSheet.create(xAppStyles)

import React, {PureComponent} from "react"
import RealnoteLogger from "../../../bridges/RealnoteLogger"
import {Text, TouchableOpacity} from "react-native"
import AppStyles from "../../../styles/AppStyles"
import AppColors from "../../../styles/AppColors"
import {strings} from "../../../i18n"

const log = new RealnoteLogger("CompleteProfileButton")

export default class CompleteProfileButton extends PureComponent {
  render() {
    try {
      return (
        <TouchableOpacity
          onPress={() => {
            if (this.props.onPressEditProfile) {
              this.props.onPressEditProfile()
            } else {
              log.e("missing onPressEditProfile")
            }
          }}>
          <Text
            style={[
              AppStyles.largeText,
              {
                color: AppColors.primary,
                textDecorationLine: "underline",
                marginBottom: 8,
              },
            ]}>
            {strings("Profile.editButton")}
          </Text>
        </TouchableOpacity>
      )
    } catch (e) {
      log.e("render error: " + e)
    }
  }
}

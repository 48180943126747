import RealnoteLogger from "../bridges/RealnoteLogger"
import _realnote from "../bridges/RealnoteNative"
import {serverResponse} from "./Account"

const TAG = "BlockedUsersProvider"
const log = new RealnoteLogger(TAG)

export default class BlockedUsersProvider {
  constructor() {
    var initialized = false
    var blockedUsers = []
  }

  static initializeBlockedUsers() {
    log.v("initializeBlockedUsers called")
    if (!this.initialized) {
      this.getBlockedUsers()
    }
  }

  static async getBlockedUsers(skip, limit) {
    if (!this.initialized) {
      this.initialized = true
    }
    return new Promise(resolve => {
      _realnote
        .getBlockedUsers(skip, limit)
        .then(result => {
          try {
            log.v("getBlockedUsers got result: " + JSON.stringify(result))
            const userIds = result.data.map(d => d.userId)
            if (!skip) {
              this.blockedUsers = userIds
            }
            log.v("getBlockedUsers setBlockedUsers will be called")
            _realnote.setBlockedUsers(userIds)
          } catch (e) {
            log.e("getBlockedUsers error: " + e)
          }
          resolve(result)
        })
        .catch(e => {
          // if something is wrong, just return locally cached blockedUsers
          if (skip < this.blockedUsers.length) {
            log.e(
              "getBlockedUsers error: " +
                e +
                "\nbut returning locally cached blockedUsers",
            )
            if (limit) {
              var b = this.blockedUsers.slice(skip, skip + limit)
            } else {
              var b = this.blockedUsers.slice(skip)
            }
            resolve({message: serverResponse.ok, data: b})
          } else {
            log.e("getBlockedUsers error: " + e)
            resolve({message: "error: " + e})
          }
        })
    })
  }
}

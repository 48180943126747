import Global from "./Global"
import Account from "./dataProvider/Account"

// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app"
import {getAnalytics, logEvent} from "firebase/analytics"
import {strings} from "./i18n"
import {HttpBridge} from "./http/HttpBridge"
import {getDefaultMiddleware} from "@reduxjs/toolkit"

let logSessionId = null
let aId = null
let cId = null

const firebaseConfig = {
  apiKey: "AIzaSyD8KDHMlLSPvtjTT1GQYtYKYdgctE8cAx8",
  authDomain: "realnote-30564.firebaseapp.com",
  databaseURL: "https://realnote-30564.firebaseio.com",
  projectId: "realnote-30564",
  storageBucket: "realnote-30564.appspot.com",
  messagingSenderId: "930189210123",
  appId: "1:930189210123:web:93922731a1f27df71e0d0e",
  measurementId: "G-S8KLYVVQ9H",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
  
  
;(window as any).logFirebaseEvent = function (eventName: String, params: any) {
  try {
    host.sendEventToServer(eventName, params)

    if (!Global.debugMode) {
      console.log("firebase event from WebApp: " + eventName)
      logEvent(analytics, ("WEB_" + eventName) as any, params)
    } else {
      console.log("dont send firebase event from WebApp: " + eventName)
    }
  } catch (ex) {
    console.error(ex)
  }
}

function objectToGetParams(object) {
  var params = Object.entries(object)
    .filter(function (_a) {
      var value = _a[1]
      return value !== undefined && value !== null
    })
    .map(function (_a) {
      var key = _a[0],
        value = _a[1]
      return encodeURIComponent(key) + "=" + encodeURIComponent(String(value))
    })
  return params.length > 0 ? "?" + params.join("&") : ""
}

const host = {
  letReactHandleUi: async function (
    reactNativeOnly: Boolean,
  ): Promise<boolean> {
    return true
  },

  generateUID: function () {
    // I generate the UID from two parts here
    // to ensure the random number provide enough bits.
    let firstPart: any = (Math.random() * 46656) | 0
    let secondPart: any = (Math.random() * 46656) | 0
    firstPart = ("000" + firstPart.toString(36)).slice(-3)
    secondPart = ("000" + secondPart.toString(36)).slice(-3)
    return firstPart + secondPart
  },

  getAId: function () {
    const params = new URLSearchParams(window.location.search)
    aId = params.get("aId") || ""
  },

  getCId: function () {
    if (cId) {
    } else {
      cId = localStorage.getItem("cid")
      if (cId) {
      } else {
        cId = this.generateUID()
        localStorage.setItem("cid", cId)
      }
    }
  },

  sendEventToServer: function (eventName: String, params: any = {}) {
    try {
      if (logSessionId == null) {
        logSessionId = this.generateUID()
      }

      if (cId == null) {
        this.getCId()
      }

      if (aId == null) {
        this.getAId()
      }

      params.event = eventName

      const d = new Date().toISOString()
      const dd = d.split("T")
      params.time = dd[1].split("Z")[0]
      params.date = dd[0]

      params.userId = Account.userId
      params.userName = Account.username
      params.challengeId = Global.challengeId
      params.debug = Global.debugMode
      params.test = Global.testMode
      params.session = cId + "-" + logSessionId
      params.cId = cId
      params.aId = aId

      console.log(`sendEventToServer ${eventName} ${JSON.stringify(params)} `)

      // Logging läuft immer auf dem ProductionServer
      // const endpoint = "http://192.168.3.20:9000/api/" + "public/log"
      const endpoint = "https://realnote.azurewebsites.net/api/public/log"

      fetch(endpoint, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        // credentials:"include",
        // headers: new Headers({
        //   authorization: "Bearer " + global.token,
        // })
        body: JSON.stringify(params),
      })
        .then(respone => {})
        .catch(error => {})
    } catch (ex) {
      console.error(ex)
    }
  },

  sendFirebaseEvent: function (eventName: String, params: any = {}) {
    try {
      this.sendEventToServer(eventName, params)
      if (!Global.debugMode && !Global.testMode) {
        console.log(`sendFirebaseEvent ${eventName}`)
        //        this.sendEventToServer(eventName, params)
        logEvent(analytics, ("WEB_" + eventName) as any, params)
      } else {
        console.log("dont send firebase event from WebApp: " + eventName)
      }
    } catch (ex) {
      console.error(ex)
    }
  },

  startFirebaseTimingEvent: function (name: String) {
    console.log("IMPLEMENT startFirebaseTimingEvent !!!!")
    this.sendFirebaseEvent(name)
  },

  stopFirebaseTimingEvent: function (name: String) {
    console.log("IMPLEMENT stopFirebaseTimingEvent !!!!")
  },

  getAppVersion: async function (): Promise<String> {
    return "1.88"
  },

  sendToken: function (token: string) {
    console.log("sendToken is dummy ")
  },

  login: function (userId, username) {},
  logout: function () {},

  checkNotificationIntent: function () {},

  getAdId: async function (): Promise<string> {
    return "@adId@"
  },

  getUserLocation: async function (): Promise<string | null> {
    return null
  },

  storagePrefix(): String {
    if (Global.debugMode) {
      return "DEBUG-"
    } else {
      return "PRODUCTION-"
    }
  },

  setSharedPrefString(key: any, val: any): Promise<void> {
    console.log("setSharedPrefString ", this.storagePrefix(), key, val)
    window.localStorage.setItem(this.storagePrefix() + key, val)
    return
  },

  getSharedPrefString: async function (key: string): Promise<String> {
    return window.localStorage.getItem(this.storagePrefix() + key) || ""
    // return ""
  },

  setSharedPrefBoolean: async function (key: any, val: any): Promise<void> {
    window.localStorage.setItem(this.storagePrefix() + key, val)
    return
  },

  getSharedPrefBoolean: async function (
    key: string,
  ): Promise<string | Boolean | PromiseLike<Boolean>> {
    return window.localStorage.getItem(this.storagePrefix() + key) || ""
  },

  setSharedPrefInt(key: any, val: any): Promise<void> {
    console.log("setSharedPrefInt ", this.storagePrefix(), key, val)
    window.localStorage.setItem(this.storagePrefix() + key, val)
    return
  },

  getSharedPrefInt: async function (key: String): Promise<number> {
    return Number(window.localStorage.getItem(this.storagePrefix() + key))
  },

  setSharedPrefLong(key: any, val: any): Promise<void> {
    if (key != null && val != null) {
      window.localStorage.setItem(this.storagePrefix() + key, val)
      return
    }
  },

  getSharedPrefLong: async function (key: String): Promise<number> {
    return Number(window.localStorage.getItem(this.storagePrefix() + key))
  },

  setHasLoggedOn: function (loggedOn) {},

  stopDetection: function () {
    if (Global.isEmbedded()) {
      var w: any = window
      w.HostAR.stopDetection()
    }
  },
  startDetection: function () {
    if (Global.isEmbedded()) {
      var w: any = window
      w.HostAR.startDetection()
    }
  },

  hideUi: function () {},

  showUi: function () {},

  setLookAroundNotShown: function () {},

  checkQueueShareIntent: function () {},

  sendDeviceToken: function (userId: String, sessionId: String = null): void {},

  getAddress(lat: number, lng: number): Promise<String> {
    return new Promise<String>(resolve => {
      resolve("no addr")
    })
  },

  async getDims(): Promise<string> {
    return ""
  },

  coachingVisible(visible: boolean) {},

  setAnalyzeNote(guidScene: String) {},

  openShareMenu(dataUrl: string, appToShareTo: string): Promise<String> {
    console.error("implement openShareMenu")
    return new Promise<String>(resolve => {
      resolve("not implemented !!!!")
    })
  },

  openShareMenuUrlSpecificApp(
    url: string,
    appToShareTo: string,
  ): Promise<String> {
    const linkText = strings("ShareButton.linkText")

    console.log("openShareMenuUrlSpecificApp called " + linkText)
    return new Promise<String>(resolve => {
      if (Global.isEmbedded()) {
        var w: any = window
        w.HostAR.openShareMenuUrlSpecificApp(url, appToShareTo).then(res =>
          resolve(res),
        )
      } else {
        console.log("openShareMenuUrlSpecificApp called " + appToShareTo)
        if (appToShareTo === "whatsapp") {
          var title = linkText,
            separator = " "
          let link =
            "https://" +
            (Global.isMobileOrTablet ? "api" : "web") +
            ".whatsapp.com/send" +
            objectToGetParams({
              text: title ? title + separator + url : url,
            })
          console.log("openShareMenuUrlSpecificApp called 2" + link)
          window.open(link)
          resolve("true")
        } else if (appToShareTo === "") {
          const shareData = {
            url: url,
            text: linkText,
            title: linkText,
          }
          console.log(
            "openShareMenuUrlSpecificApp called 3" + JSON.stringify(shareData),
          )
          if (navigator.share) {
            navigator.share(shareData).catch(() => {
              resolve("noApp")
            })
          } else {
            resolve("noApp")
          }
        }
      }
    })
  },

  openVideoShareMenu(uri: String, networkToShareTo: String): Promise<String> {
    console.error("implement openShareMenuUrlSpecificApp")
    return new Promise<String>(resolve => {
      resolve("not implemented !!!!")
    })
  },

  saveImageToGallery(uriString: String) {},

  stopDetectionButContinueUpdatingCameraPosition() {
    if (Global.isEmbedded()) {
      var w: any = window
      w.HostAR.stopDetectionButContinueUpdatingCameraPosition()
    }
  },

  stopUpdatingCameraPosition() {
    if (Global.isEmbedded()) {
      var w: any = window
      w.HostAR.stopUpdatingCameraPosition()
    }
  },

  startCamera() {
    if (Global.isEmbedded()) {
      var w: any = window
      w.HostAR.startCamera()
    }
  },

  stopCamera() {
    if (Global.isEmbedded()) {
      var w: any = window
      w.HostAR.stopCamera()
    }
  },

  getPpmItems(): Promise<String> {
    return new Promise<String>(resolve => {
      resolve("{}")
    })
  },

  // showScreenshotMask: function () {
  // },

  // hideScreenshotMask: function () {
  // }
  // webviewNavigateBack
  // shareNote
  // checkSceneUploadQueue
  // deleteScreenshotFile
  // saveImageToGallery
  // logFatalException
  // getCurrentWindowSize
  // checkIfAnyNotesAreConnected
  // checkNotificationIntent
  // checkQueueShareIntent
  // getPolyFile
  // redeemPoints
  // getNotesForCarousel
  // shareRefInvite
  // inviteUser
  // getSceneBearingAndDistance
  // showToast
  // showToastInReact
  // sendChatLocalMessage
  // getLocalChatMessages
  // stopVideoRecording
  // startVideoRecording
  // openVideoShareMenu
  // openShareMenuUrl
  // openShareMenu
  // openGalleryAndIcloud
  // openIcloud
  // openGallery
  // getContentImage
  // getPpmItems
  // showUi
  // hideUi
  // startCamera
  // stopArCore
  // stopUpdatingCameraPosition
  // stopDetectionButContinueUpdatingCameraPosition
  // stopDetection
  // showOnboarding
  // startDetection
  // sceneIsCached
  // getSmallerImagePath
  // checkServerConnection
  // setAnalyzeNote
  // getDeveloperOptions
  // setDeveloperOptions
  // getAppVersion
  // getSharedPrefInt
  // setSharedPrefInt
  // getSharedPrefBoolean
  // setSharedPrefBoolean
  // getSharedPrefString
  // setSharedPrefString
  // settingsChanged
  // loadMissingUpdates
  // getNoteDataForGuid
  // getUserLocation
  // isSceneConnected
  // getNumOfNearScenes
  // setLookAroundNotShown
  // getAddress
  // setHasLoggedOn
  // getSessionId
  // logout
  // login
  // getAdId

  // sendToken
  // RNcreateGuid

  // editContent
  // hideTestScenes
}

export default host
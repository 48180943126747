export const ownNotes_fetchOwnNotes = "ownNotes/fetchOwnNotes"
export const ownNotes_fetchMoreOwnNotes = "ownNotes/fetchMoreOwnNotes"
export const ownNotes_deleteOwnNote = "ownNotes/deleteOwnNote"
export const hotNotes_fetchHotNotes = "hotNotes/fetchHotNotes"
export const hotNotes_fetchMoreHotNotes = "hotNotes/fetchMoreHotNotes"
export const surroundingNotes_fetchSurroundingNotes =
  "surroundingNotes/fetchSurroundingNotes"
export const surroundingNotes_fetchMoreSurroundingNotes =
  "surroundingNotes/fetchMoreSurroundingNotes"
export const challengeMode_fetchChallengeData =
  "challengeMode/fetchChallengeData"
export const challengeData_fetchChallengeData =
  "challengeData/fetchChallengeData"

import React, {useRef, useEffect, useState, useMemo} from "react"
import {
  Dimensions,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
} from "react-native"
import NoteHeader from "../NoteHeader"
import NoteUpvotes from "../../components/NoteUpvotes"
import CommentInput from "../../components/CommentInput"
import Comment from "../../components/Comment"
//import Map from "../MiniMap"
import global from "../../Global"
import DebugNoteView from "../../components/Developer/DebugNoteView"
import realnote from "../../bridges/RealnoteNative"
import Global from "../../Global"
import AppStyles from "../../styles/AppStyles"
import AppColors from "../../styles/AppColors"
import Account from "../../dataProvider/Account"
import {strings} from "../../i18n"
import Icon from "../../components/RealnoteIcon/Icon"
import FastImage from "../../components/XFastImage"
import TouchableOpacityMulti from "../../components/TouchableOpacityMulti"
import HeartAnimation from "../../components/NoteThumb/HeartAnimation"
import NoteContextMenu from "./NoteContextMenu"
import {v4 as uuidv4} from "uuid"
import Logger from "../../bridges/RealnoteLogger"
import EmptyContentImage, {types} from "../../components/EmptyContentImage"

const {width} = Dimensions.get("screen")
const elementWidth = 0.8 * width
const TAG = "Note"
const log = new Logger(TAG)

export default function Note(props) {
  const {
    navigation,
    note,
    scrollingNote,
    createComment,
    deleteComment,
    upvoteNote,
    downvoteNote,
    refetchScrollingNotes,
    toggleMapOpen,
  } = props
  const {navigate, getParam, addListener, goBack} = navigation
  const [error, setError] = useState(false)
  const [mapActive, setMapActive] = useState(false)
  const [debugView, setDebugView] = useState(false)

  log.d("Start Note.js")

  const noteData = useRef("")
  const heartAnimation = useRef()
  const focusListener = useRef()
  const blurListener = useRef()

  useEffect(() => {
    let nextNoteData
    focusListener.current = addListener("didFocus", didFocus)
    blurListener.current = addListener("didBlur", didBlur)

    if (note !== null) {
      nextNoteData = note
    } else {
      nextNoteData = getParam("note")
    }

    if (nextNoteData.loading) {
      realnote.handleSceneDownloaded = handleSceneDownloadedEvent
      return
    }

    if (nextNoteData.error) {
      setError(true)
      return
    }
  }, [])

  function onPressViews() {
    navigation.navigate("Viewers", {sceneGuid: note.sceneGuid})
  }

  function onPressLoadMoreComments() {
    const {contentGuid, userId} = note
    navigate("Commentary", {
      contentGuid,
      contentOwnerId: userId,
      navigation,
    })
  }

  function didFocus() {
    realnote.startFirebaseTimingEvent("note_info")
  }

  function didBlur() {
    realnote.stopFirebaseTimingEvent("note_info")
  }

  function formattedDate(unformattedDate) {
    const date = new Date(unformattedDate)
    const differenceMillis = Date.now() - date.getTime()
    let formatted = Math.floor(differenceMillis / (24 * 60 * 60 * 1000))
    if (formatted < 1) {
      formatted = Math.floor(differenceMillis / (60 * 60 * 1000))
      if (formatted < 1) {
        formatted = Math.floor(differenceMillis / (60 * 1000))
        if (formatted < 1) {
          return strings("Note.justNow")
        }
        return `${strings("Note.ago")} ${formatted} ${strings("Note.minutes")}`
      }
      return `${strings("Note.ago")} ${formatted} ${strings("Note.hours")}`
    } else if (formatted > 30) {
      return (
        `${strings("Note.on")} ` +
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear()
      )
    } else {
      if (formatted < 2) {
        return `${strings("Note.ago")} ${formatted} ${strings("Note.day")}`
      }
      return `${strings("Note.ago")} ${formatted} ${strings("Note.days")}`
    }
  }

  function handleSceneDownloadedEvent(eventData) {
    let guid = eventData["sceneGuid"]

    if (guid === null) {
      guid = eventData["guid"]
    }
    if (note.sceneGuid !== guid) {
      return
    }
    if (eventData.error) {
      setError(true)
    }

    realnote.getNoteDataForGuid(guid).then(async newNoteData => {
      if (!newNoteData.error) {
        noteData.current = newNoteData
      } else {
        setError(true)
      }
    })
  }

  function remove_Comment(guid) {
    var index = commentsArray.findIndex(element => element.guid === guid)
    if (index > -1) {
      commentsArray.splice(index, 1)
    }
    deleteComment({
      guid,
      contentGuid: note.contentGuid,
      userId: Account.userId,
    })
  }

  function submitComment(tx) {
    const commentGuid = uuidv4()
    commentsArray.push({
      contentGuid: note.contentGuid,
      comment: tx,
      userId: Account.userId,
      guid: commentGuid,
      username: Account.username,
    })

    createComment({
      contentGuid: note.contentGuid,
      tx,
      userId: Account.userId,
      commentGuid,
    })
  }

  function mapButtonPressed() {
    if (scrollingNote && toggleMapOpen) {
      toggleMapOpen(note)
    } else {
      if (mapActive) {
        setMapActive(false)
      } else {
        setMapActive(true)
      }
    }
  }

  function debugButtonPressed() {
    if (debugView) {
      setDebugView(false)
    } else {
      setDebugView(true)
    }
  }

  function onDoublePress() {
    if (Account.userId === note.userId) {
      return
    }
    const {contentGuid, sceneGuid} = note
    realnote.sendFirebaseClick("upvoteNoteDoubleClick")
    upvoteNote({contentGuid, userId: Account.userId, sceneGuid})
    heartAnimation.current.startAnimation()
    realnote.saveUpvoteToLocalScene(sceneGuid, true)
  }

  function commentsRenderItem({item: value}) {
    return (
      <Comment
        note={note}
        navigation={navigation}
        deleteItem={remove_Comment}
        comment={value}
        contentGuid={note.contentGuid}
        key={value.guid}
      />
    )
  }

  const isMyOwnNote = useMemo(
    () => note.userId === Account.userId,
    [note.userId, Account.userId],
  )

  const renderContent = useMemo(() => {
    let picSource
    if (note.version === undefined || note.version > 3) {
      picSource = {
        uri: global.baseUrl + "public/previewImage/" + note.sceneGuid + ".webp",
        isStatic: false,
      }
    } else {
      picSource = {
        uri:
          global.baseUrl + "public/previewImage/" + note.contentGuid + ".webp",
        isStatic: false,
      }
    }
    log.d("picture url: " + picSource.uri)

    return (
      <TouchableOpacityMulti
        onDoublePress={onDoublePress}
        activeOpacity={1.0}
        style={[
          AppStyles.center,
          {
            width: width,
            height: width,
          },
        ]}>
        <>
          <FastImage
            style={{
              width: width,
              height: width,
            }}
            source={picSource}
            resizeMode={"contain"}
            resizeMethod={"contain"}
          />
          <HeartAnimation
            ref={heartAnimation}
            size={elementWidth * 0.6}
            style={{
              position: "absolute",
              alignSelf: "center",
              justifySelf: "center",
            }}
          />
        </>
      </TouchableOpacityMulti>
    )
  }, [note.contentGuid, heartAnimation])

  const LoadMoreText = useMemo(() => {
    return note.comments !== undefined &&
      note.comments !== null &&
      note.comments.length > 3 ? (
      <TouchableOpacity onPress={onPressLoadMoreComments}>
        <Text style={styles.loadMoreText}>{strings("Note.loadMore")}.</Text>
      </TouchableOpacity>
    ) : null
  }, [note, note.comments])

  const HideDebugButton = useMemo(() => {
    return (
      Global.debugMode && (
        <TouchableOpacity
          onPress={debugButtonPressed}
          style={styles.hideDebugContainer}>
          <Text style={[AppStyles.activeNavText, styles.hideDebugText]}>
            {mapActive ? "Hide Debug" : "Show Debug"}
          </Text>
        </TouchableOpacity>
      )
    )
  }, [Global.debugMode, mapActive])

  // {note.position && <Map longitude={longitude} latitude={latitude} />}
  const MapView = useMemo(() => {
    //const {longitude, latitude} = note.position
    return (
      (note.isMapOpen || mapActive) && (
        <View style={styles.mapContainer}>
         
        </View>
      )
    )
  }, [note.isMapOpen, mapActive])

  const DebugView = useMemo(() => {
    return (
      Global.debugMode &&
      (!scrollingNote || debugView) && (
        <DebugNoteView
          sceneGuid={note.sceneGuid}
          contentGuid={note.contentGuid}
        />
      )
    )
  }, [Global.debugMode, scrollingNote, debugView, note])

  const commentsArray = useMemo(
    () =>
      note.comments !== null
        ? note.comments.slice(Math.max(note.comments.length - 3, 0))
        : [],
    [note.comments],
  )

  return (
    <View style={[styles.container, {
      pointerEvents: "auto",
    }]}>
      {(
        <>
          <NoteHeader
            {...props}
            userId={note.userId}
            key={`noteHeaderUser${note.userId}`}
          />
          <View style={styles.dateContainer}>
            <Text style={[AppStyles.smallText, styles.dateText]}>
              {strings("Note.creationDate") +
                " " +
                formattedDate(note.lastModified)}
            </Text>
          </View>
          {renderContent}
          <View style={styles.upvoteViewsContainer}>
            <View style={styles.upvoteViewsInnerContainer}>
              <View style={styles.upvoteViewsWrapper}>
                <View style={styles.votesContainer}>
                  <NoteUpvotes
                    note={note}
                    contentGuid={note.contentGuid}
                    sceneGuid={note.sceneGuid}
                    myOwn={isMyOwnNote}
                    upvoteNote={upvoteNote}
                    downvoteNote={downvoteNote}
                    refetchScrollingNotes={refetchScrollingNotes}
                    navigation={navigation}
                  />
                </View>
                <View style={styles.viewsContainer}>
                  <TouchableOpacity
                    onPress={onPressViews}
                    style={styles.viewsTouchable}
                    disabled={note.matchCount < 1}>
                    <Text style={[AppStyles.largeText, styles.viewsText]}>
                      {note.matchCount}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={onPressViews}
                    style={styles.viewsIcon}
                    disabled={note.matchCount < 1}>
                    <Icon
                      name={"Eye"}
                      key={"viewsButton"}
                      fill={"#000"}
                      height={22}
                      width={22}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.commentsContainer}>
            <FlatList
              data={commentsArray}
              renderItem={commentsRenderItem}
              keyExtractor={(item, index) => `Comment${index}`}
            />
            {LoadMoreText}
            <View>
              <CommentInput
                enabled={note.contentGuid}
                onSubmit={submitComment}
              />
            </View>
          </View>
          <View style={styles.mapDebugRow}>
            <TouchableOpacity
              onPress={mapButtonPressed}
              style={styles.hideMapContainer}>
                 <div style={{width:"50px", height:"50px"}}  className="lottie" data-animation-path="../../assets/animations/map2.json" data-anim-loop="true"></div>
              <Text style={[AppStyles.activeNavText, styles.hideMapText]}>
                {mapActive ? strings("Note.hideMap") : strings("Note.showMap")}
              </Text>
            </TouchableOpacity>
            {HideDebugButton}
          </View>
          {MapView}
          {DebugView}
          {scrollingNote && (
            <NoteContextMenu
              noteData={note}
              userId={note.userId}
              myOwn={isMyOwnNote}
            />
          )}
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderBottomColor: AppColors.panelBorder,
    borderBottomWidth: 2,
  },
  dateContainer: {
    zIndex: 10,
    height: 80,
    position: "absolute",
    top: 0,
    right: 0,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  dateText: {
    textAlign: "right",
    marginRight: 15,
  },
  upvoteViewsContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: AppColors.panelBorder,
  },
  upvoteViewsInnerContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  upvoteViewsWrapper: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    width: "30%",
    marginLeft: 7,
  },
  votesContainer: {
    flexDirection: "row",
    flex: 1,
  },
  viewsContainer: {
    marginLeft: 20,
    flexDirection: "row",
    flex: 1,
  },
  viewsTouchable: {
    alignItems: "flex-end",
    paddingHorizontal: 3,
  },
  viewsText: {
    textAlign: "right",
    color: AppColors.black,
    marginRight: 5,
  },
  viewsIcon: {
    flex: 0,
    justifyContent: "flex-start",
    alignItems: "flex-end",
    paddingVertical: 3,
    paddingHorizontal: 3,
  },
  commentsContainer: {
    flex: 1,
    width: width,
    backgroundColor: "#FFF",
  },
  loadMoreText: {
    paddingLeft: 10,
    paddingVertical: 2,
  },
  mapDebugRow: {
    width: width,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    height: 55,
    borderTopWidth: 1,
    borderColor: AppColors.panelBorder,
  },
  hideMapContainer: {
    flex: 0,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingHorizontal: 3,
  },
  hideMapText: {fontSize: 14, textAlign: "center"},
  hideDebugContainer: {
    flex: 0,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingHorizontal: 3,
  },
  hideDebugText: {fontSize: 14, textAlign: "center"},
  mapContainer: {
    width: width,
    height: 300,
    alignItems: "center",
    justifyContent: "flex-start",
  },
})

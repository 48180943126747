import React from 'react'
import {Text, View, StyleSheet} from 'react-native'
import AppStyles from "../../styles/AppStyles";
import {getCurrentLocalShort, strings} from "../../i18n";

export default function FooterNative(props) {

  const privacyParams = {
    title: strings("About.privacy"),
    url: `./assets/content/privacy-${getCurrentLocalShort()}.html`
  }
  const termsOfUseLinkParams = {
    title: strings("About.termsOfUseLink"),
    url: `./assets/content/privacy-${getCurrentLocalShort()}.html`
  }
  const impressumParams = {
    title: strings("About.impressum"),
    url: `./assets/content/impressum-${getCurrentLocalShort()}.html`
  }
  const kontaktLink = "https://realnote.one/kontakt/"
  const facebookLink = "https://www.facebook.com/RealNoteGmbH"
  const instaLink = "https://www.instagram.com/realnoteapp"
  const tweeterLink = "https://twitter.com/RealNote_GmbH"

  const onPressHandler = (target, params) => {
    if (target.startsWith("http")) {
      window.open(target, '_blank',
          'location=yes,height=570,width=520,scrollbars=yes,status=yes')

    } else {
      props.navigation.push(target, params)
    }
  }

  return (
      <View style={styles.container}>
        <View style={{alignItems: 'center'}}>
          <Text style={AppStyles.heading}>Legal</Text>
          <Text style={[AppStyles.mediumText, styles.gpsLink]}
                onPress={() => onPressHandler('SimpleHtmlContentPage',
                    privacyParams)}>Privacy</Text>
          <Text onPress={() => onPressHandler('SimpleHtmlContentPage',
              termsOfUseLinkParams)}
                style={[AppStyles.mediumText, styles.gpsLink]}>Terms Of
            use</Text>
        </View>
        <View style={{
          alignItems: 'center'
        }}>
          <Text style={AppStyles.heading}>About Us</Text>
          <Text onPress={() => onPressHandler('SimpleHtmlContentPage',
              impressumParams)}
                style={[AppStyles.mediumText, styles.gpsLink]}>Imprint</Text>
          <Text onPress={() => onPressHandler(kontaktLink, null)}
                style={[AppStyles.mediumText, styles.gpsLink]}>Kontakt</Text>
        </View>
        <View style={{
          alignItems: 'center'
        }}>
          <Text style={AppStyles.heading}>Social Media</Text>
          <Text onPress={() => onPressHandler(facebookLink, null)}
                style={[AppStyles.mediumText, styles.gpsLink]}>Facebook</Text>
          <Text onPress={() => onPressHandler(instaLink, null)}
                style={[AppStyles.mediumText, styles.gpsLink]}>Instagram</Text>
          <Text
              onPress={() => onPressHandler(tweeterLink, null)}
              style={[AppStyles.mediumText, styles.gpsLink]}>Twitter</Text>
        </View>
      </View>
  )
}

const styles = StyleSheet.create({
  gpsLink: {
    color: 'blue',
    textDecorationLine: 'none',
    fontWeight: "bold",
    paddingVertical: 3
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: "#ccc",
    padding: 15
  }
})

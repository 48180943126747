import React from "react"
import {ScrollView, View, Text} from "react-native"
import {Square} from "./Square"
import Logger from "../../bridges/RealnoteLogger"
import AppColors from "../../styles/AppColors"

const TAG = "ColorSelection"
const log = new Logger(TAG)

/**
 * buttons to change font color
 */
export class ColorSelection extends React.Component {
  constructor(props) {
    super(props)
    this.lastColor = "#00ff00"
  }

  state = {
    color: null,
  }

  setColor = color => {
    this.setState({color: color})
    this.lastColor = color
  }

  changeColor = c => {
    try {
      if (this.props.onPress) {
        this.props.onPress(c, this.lastColor)
        this.lastColor = c
      }

      if (this.props.onChangeColor) {
        log.v("changing color to " + c)
        let colors = [this.lastColor, c]
        log.v("colors is: " + colors)
        this.props.onChangeColor(colors, this.lastColor)
        this.lastColor = c
      }

      this.setState({color: c})
    } catch (e) {
      log.e("changeColor error: " + e)
    }
  }

  render() {
    const colors = this.props.colors
    try {
      log.d(`render ${colors}`)
      if (!colors || colors.length == 0) {
        return null
      }

      let dots = []
      for (let i = 0; i < colors.length; i++) {
        var dot

        if (colors[i] === this.state.color) {
          dot = (
            <Square
              left={0}
              top={0}
              size={35}
              color={colors[i]}
              onPress={this.changeColor}
              borderColor="#eeeeee"
              borderWidth={5}
            />
          )
        } else {
          dot = (
            <Square
              left={0}
              top={0}
              size={22}
              color={colors[i]}
              onPress={this.changeColor}
              borderColor={AppColors.secondary}
              borderWidth={1}
            />
          )
        }

        dots.push(
          <View
            width={35}
            heigth={35}
            style={{alignItems: "center"}}
            key={`circle${i}`}>
            {dot}
          </View>,
        )
        log.d(`render dot ${colors[i]}`)
      }
      const lessThanSixDots = dots.length < 6
      return (
        <View
          pointerEvents="box-none"
          style={[
            {flexDirection: "row", justifyContent: "center"},
            this.props.style,
          ]}>
          <View
            pointerEvents="box-none"
            style={{
              width: lessThanSixDots ? dots.length * 35 + 15 : 6 * 35 + 15,
              bottom: 12.5,
              backgroundColor: this.props.transparentBackground
                ? "transparent"
                : "gainsboro",
              borderColor: AppColors.secondary,
              borderWidth: 2,
              borderRadius: 15,
              height: 30,
              position: "absolute",
            }}
          />
          <ScrollView
            pointerEvents="box-none"
            keyboardShouldPersistTaps="always"
            horizontal={true}
            persistentScrollbar={false}
            showsHorizontalScrollIndicator={false}
            decelerationRate="fast"
            snapToInterval={35}
            justifyContent={lessThanSixDots ? "center" : "flex-start"}
            contentContainerStyle={{alignItems: "center"}}
            fadingEdgeLength={35 * 6}
            style={{
              width: lessThanSixDots ? dots.length * 35 + 5 : 6 * 35 + 5,
              bottom: 7.5,
              height: 40,
              paddingLeft: 2.5,
              position: "absolute",
            }}
            // TODO: quick, dity fix for colors being cut off
            //style={{paddingHorizontal: 100}}
          >
            {dots}
          </ScrollView>
        </View>
      )
    } catch (e) {
      log.e("render error: " + e)
      return <Text>{"error: " + e.message}</Text>
    }
  }
}

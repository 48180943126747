import {
  Dimensions,
  Image,
  Picker,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native"
import AppColors from "../styles/AppColors"
import React, {Component} from "react"
import {strings} from "../i18n"
import Realnote from "../bridges/RealnoteNative"
import Logger from "../bridges/RealnoteLogger"
import Account, {serverResponse} from "../dataProvider/Account"
import Icon from "./RealnoteIcon/Icon"
import AppStyles from "../styles/AppStyles"
import LoadingIcon from "./LoadingIcon"
import LocalHintManager from "./LocalHintManager"
import FastImage from "./XFastImage"
import {HttpBridge} from "../http/HttpBridge"

const TAG = "FullScreenOverlay"
const log = new Logger(TAG)
const screenWidth = Dimensions.get("screen").width

const http = new HttpBridge()

/**
 * shows a fullscreen overlay with content either being
 * 'contents' = an array of components to be shown passed through props
 * or simply the children of this component within the parent
 */
export default class FullScreenOverlay extends Component {
  commentMessage = ""
  state = {
    linkLoading: false,
  }

  constructor(props) {
    super(props)

    if (!this.props.contents && !this.props.type) {
      log.e(
        "constructor error: neither children nor type of overlay have been provided",
      )
    }
  }

  setChildren = () => {
    switch (this.props.type) {
      // // // // // // // // // // // //
      // overlay for inviting a friend //
      // // // // // // // // // // // //
      case "invite":
        this.children = (
          <View
            style={{
              borderRadius: 13,
              borderWidth: 1,
              zIndex: 5,
              backgroundColor: AppColors.white,
              width: "100%",
              padding: 20,
              marginTop: 10,
              marginHorizontal: 10,
            }}>
            <TouchableOpacity
              style={{
                position: "absolute",
                top: 5,
                left: 5,
              }}
              onPress={this.props.hide}>
              <Icon
                name={"Close"}
                height={30}
                width={30}
                fill={AppColors.black}
              />
            </TouchableOpacity>
            <Text
              style={[
                AppStyles.largeText,
                {
                  textAlign: "center",
                  fontWeight: "bold",
                },
              ]}>
              {strings("Invite.title")}
            </Text>

            <View
              style={{
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 10,
              }}>
              <FastImage
                source={"assets/images/megaphone.png"}
                style={{
                  width: "50%",
                  height: 100,
                }}
                resizeMode={"contain"}
              />
              {/*<Text style={[AppStyles.largeText, {*/}
              {/*  marginTop: 10,*/}
              {/*}]}>*/}
              {/*  {strings("Invite.body")}*/}
              {/*</Text>*/}
            </View>

            {/*Button to send report*/}
            <View
              style={{
                flex: 0,
                zIndex: 6,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                marginVertical: 10,
              }}>
              <View
                style={{
                  zIndex: 6,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 10,
                }}>
                <TouchableOpacity
                  style={{
                    backgroundColor: AppColors.backgroundGray,
                    borderRadius: 8,
                    padding: 8,
                    marginLeft: 10,
                  }}
                  onPress={async () => {
                    this.setState({linkLoading: true})
                    await this.sendInvite()
                    this.setState({
                      linkLoading: false,
                    })
                    this.props.hide()
                  }}>
                  <Text>{strings("Invite.invite")}</Text>
                  {this.state.linkLoading && (
                    <View
                      style={{
                        position: "absolute",
                        top: -7,
                        right: -6,
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 7,
                      }}>
                      <LoadingIcon size={20} />
                    </View>
                  )}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        )
        break

      // // // // // // // // // // // /
      // overlay for reporting a note //
      // // // // // // // // // // // /
      case "report":
        const ReportIssues = Object.freeze({
          illegal: "illegal",
          againstApp: "againstApp",
          confidentialContent: "confidentialContent",
          rightsViolation: "rightsViolation",
          advertisement: "advertisement",
          sexual: "sexual",
          insult: "insult",
          location: "location",
          other: "other",
        })

        let pickerItems = []
        pickerItems.push(
          <Picker.Item key={`pickerItem0`} label={""} value={""} />,
        )
        let i = 1
        for (const issue in ReportIssues) {
          pickerItems.push(
            <Picker.Item
              key={`pickerItem${i}`}
              label={strings("Report." + ReportIssues[issue])}
              value={issue}
            />,
          )
          i++
        }

        this.children = (
          <View
            style={{
              borderRadius: 13,
              borderWidth: 1,
              backgroundColor: AppColors.white,
              width: "100%",
              padding: 20,
              marginTop: "20%",
              marginHorizontal: 10,
              zIndex: 5,
            }}>
            <Text
              style={[
                AppStyles.largeText,
                {
                  fontWeight: "bold",
                },
              ]}>
              {strings("Report.reportNoteTitle")}
            </Text>
            <Text
              style={[
                AppStyles.largeText,
                {
                  marginTop: 10,
                },
              ]}>
              {strings("Report.reportNoteBody")}
            </Text>

            {/*Choice of reasons to report*/}
            <View
              style={{
                width: "100%",
                zIndex: 6,
                backgroundColor: AppColors.backgroundGray,
                marginVertical: 10,
                borderRadius: 8,
                padding: 3,
              }}>
              <Picker
                style={{
                  zIndex: 8,
                  width: "100%",
                  padding: 5,
                }}
                itemStyle={{fontSize: 14}}
                selectedValue={this.state.reportIssue || ""}
                onValueChange={itemValue =>
                  this.setState({reportIssue: itemValue})
                }>
                {pickerItems}
              </Picker>
            </View>

            <View
              style={{
                zIndex: 6,
              }}>
              <TextInput
                placeholder={strings("Report.userMessage")}
                placeholderTextColor={AppColors.grayText}
                style={[
                  AppStyles.inputDark,
                  {
                    textAlignVertical: "top",
                    width: "100%",
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: AppColors.secondary,
                    marginVertical: 10,
                  },
                ]}
                value={this.state.userMessage}
                onChangeText={text => {
                  //this.state.userMessage = text
                  this.setState({userMessage: text})
                }}
                blurOnSubmit={true}
                multiline={true}
                numberOfLines={5}
                onSubmitEditing={() => {
                  this.reportContent()
                }}
              />
            </View>

            {/*Button to send report*/}
            <View
              style={{
                flex: 0,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                marginVertical: 10,
              }}>
              <View>
                <TouchableOpacity
                  style={{
                    backgroundColor: AppColors.backgroundGray,
                    borderRadius: 13,
                    padding: 10,
                    marginHorizontal: 10,
                  }}
                  onPress={this.props.hide}>
                  <Text>{strings("Account.abort")}</Text>
                </TouchableOpacity>
              </View>
              <View>
                <TouchableOpacity
                  style={{
                    backgroundColor: AppColors.backgroundGray,
                    borderRadius: 13,
                    padding: 10,
                    marginHorizontal: 10,
                  }}
                  onPress={() => {
                    this.reportContent()
                  }}>
                  <Text>{strings("Report.reportNoteTitle")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        )
        break

      // // // // // // // // // // // /
      // overlay for reporting a comment //
      // // // // // // // // // // // /
      case "reportComment":
        const ReportCommentIssues = Object.freeze({
          illegal: "illegal",
          againstApp: "againstApp",
          confidentialContent: "confidentialContent",
          rightsViolation: "rightsViolation",
          advertisement: "advertisement",
          sexual: "sexual",
          insult: "insult",
          location: "location",
          other: "other",
        })

        let commentpickerItems = []
        commentpickerItems.push(
          <Picker.Item key={`pickerItem0`} label={""} value={""} />,
        )
        let j = 1
        for (const issue in ReportCommentIssues) {
          commentpickerItems.push(
            <Picker.Item
              key={`commentpickerItem${i}`}
              label={strings("Report." + ReportCommentIssues[issue])}
              value={issue}
            />,
          )
          j++
        }

        this.children = (
          <View
            style={{
              borderRadius: 13,
              borderWidth: 1,
              backgroundColor: AppColors.white,
              width: "100%",
              padding: 20,
              marginTop: "20%",
              marginHorizontal: 10,
              zIndex: 5,
            }}>
            <Text
              style={[
                AppStyles.largeText,
                {
                  fontWeight: "bold",
                },
              ]}>
              {strings("Report.reportCommentTitle")}
            </Text>
            <Text
              style={[
                AppStyles.largeText,
                {
                  marginTop: 10,
                },
              ]}>
              {strings("Report.reportCommentBody")}
            </Text>

            {/*Choice of reasons to report*/}
            <View
              style={{
                width: "100%",
                zIndex: 6,
                backgroundColor: AppColors.backgroundGray,
                marginVertical: 10,
                borderRadius: 8,
                padding: 3,
              }}>
              <Picker
                style={{
                  zIndex: 8,
                  width: "100%",
                  padding: 5,
                }}
                itemStyle={{fontSize: 14}}
                selectedValue={this.state.reportIssue || ""}
                onValueChange={itemValue =>
                  this.setState({reportIssue: itemValue})
                }>
                {commentpickerItems}
              </Picker>
            </View>

            <View
              style={{
                zIndex: 6,
              }}>
              <TextInput
                placeholder={strings("Report.userMessage")}
                placeholderTextColor={AppColors.grayText}
                style={[
                  AppStyles.inputDark,
                  {
                    textAlignVertical: "top",
                    width: "100%",
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: AppColors.secondary,
                    marginVertical: 10,
                  },
                ]}
                value={this.state.userMessage}
                onChangeText={text => {
                  //this.state.userMessage = text
                  this.setState({userMessage: text})
                }}
                blurOnSubmit={true}
                multiline={true}
                numberOfLines={5}
                onSubmitEditing={() => {
                  this.reportComment()
                }}
              />
            </View>

            {/*Button to send report*/}
            <View
              style={{
                flex: 0,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                marginVertical: 10,
              }}>
              <View>
                <TouchableOpacity
                  style={{
                    backgroundColor: AppColors.backgroundGray,
                    borderRadius: 13,
                    padding: 10,
                    marginHorizontal: 10,
                  }}
                  onPress={this.props.hide}>
                  <Text>{strings("Account.abort")}</Text>
                </TouchableOpacity>
              </View>
              <View>
                <TouchableOpacity
                  style={{
                    backgroundColor: AppColors.backgroundGray,
                    borderRadius: 13,
                    padding: 10,
                    marginHorizontal: 10,
                  }}
                  onPress={() => {
                    this.reportComment()
                  }}>
                  <Text>{strings("Report.reportCommentTitle")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        )
        break

      // // // // // // // // // // // /
      // overlay for reporting a note //
      // // // // // // // // // // // /
      case "instruction":
        this.children = []
        if (this.props.orientation === "left") {
          this.children.push(
            // A Triangle
            <div
              style={{
                width: 0,
                height: 0,
                borderTop: "50px solid transparent",
                borderRight: "100px solid red",
                borderBottom: "50px solid transparent",
              }}
            />,
          )
        } else {
          this.children.push(
            // A Triangle direction up
            <div
              style={{
                width: 0,
                height: 0,
                borderTop: "50px solid transparent",
                borderLeft: "100px solid red",
                borderBottom: "50px solid transparent",
              }}
            />,
          )
        }
        this.children.push(
          <View
            style={{
              borderRadius: 13,
              zIndex: 5,
              backgroundColor: AppColors.primary,
              width: "100%",
              marginHorizontal: 10,
              position: "absolute",
              padding: 20,
              top: 118,
              left: 10,
            }}>
            <Text
              style={[
                AppStyles.largeText,
                {
                  textAlign: "center",
                  fontWeight: "bold",
                },
              ]}>
              {this.props.text}
            </Text>
          </View>,
        )
        break
    }
  }

  //
  // methods
  // case: 'report'
  //

  //
  // report a note
  //

  reportContent = async () => {
    try {
      if (this.state.reportIssue == null || this.state.reportIssue == "") {
        Realnote.showToast("local", strings("Report.emptyIssue"), "long")
        return
      }
      this.props.hide()
      if (this.props.contentGuid == null || this.props.creatorUserId == null) {
        log.e(
          `reportContent error: contentGuid: ${this.props.contentGuid}, creatorUserId: ${this.props.creatorUserId}`,
        )
        Realnote.showToast("local", strings("error"), "long")
      } else {
        //
        // if both contentGuid and creatorUserId are given,
        // report note
        //
        var reportMessage = "Issue: " + this.state.reportIssue
        if (this.state.userMessage != null && this.state.userMessage != "") {
          reportMessage +=
            "User provided additional info:\n" +
            '"' +
            this.state.userMessage +
            '"'
        }
        http
          .report(
            Account.username,
            Account.email,
            this.props.sceneGuid,
            this.props.contentGuid,
            reportMessage,
            this.props.creatorUserId,
          )
          .then(result => {
            if (result === serverResponse.ok) {
              this.setState({reportIssue: null})
              Realnote.showToastOld(strings("Report.success"), true)
            } else {
              Realnote.showToastOld(strings("Report.fail"), true)
            }
          })
          .catch(error => {
            Realnote.showToastOld(strings("error"), true)
            log.e("reportContent Realnote.report error: " + error.toString())
          })
      }
    } catch (e) {
      log.e(`reportContent error: ${e}`)
      Realnote.showToast("local", strings("error"), "long")
    }
  }

  reportComment = async () => {
    try {
      if (this.state.reportIssue == null || this.state.reportIssue == "") {
        Realnote.showToast("local", strings("Report.emptyIssue"), "long")
        return
      }
      this.props.hide()
      if (this.props.contentGuid == null || this.props.creatorUserId == null) {
        log.e(
          `reportComment error: contentGuid: ${this.props.contentGuid}, creatorUserId: ${this.props.creatorUserId}`,
        )
        Realnote.showToast("local", strings("error"), "long")
      } else {
        //
        // if both contentGuid and creatorUserId are given,
        // report note
        //
        var reportMessage = "Issue: " + this.state.reportIssue
        if (this.state.userMessage != null && this.state.userMessage != "") {
          reportMessage +=
            "User provided additional info:\n" +
            '"' +
            this.state.userMessage +
            '"'
        }
        http
          .reportComment(
            this.props.commentMessage,
            this.props.contentGuid,
            this.props.commentGuid,
            this.props.creatorUserId,
            reportMessage,
          )
          .then(result => {
            log.d("RESULT FROM reportComment: " + result)
            if (result === serverResponse.ok) {
              this.setState({reportIssue: null})
              Realnote.showToastOld(strings("Report.success"), true)
            } else {
              Realnote.showToastOld(strings("Report.fail"), true)
            }
          })
          .catch(error => {
            Realnote.showToastOld(strings("error"), true)
            log.e("reportComment Realnote.report error: " + error.toString())
          })
      }
    } catch (e) {
      log.e(`reportContent error: ${e}`)
      Realnote.showToast("local", strings("error"), "long")
    }
  }
  //
  // methods
  // case 'invite'
  //

  //
  // get link to send an invitation
  //
  sendInvite() {
    try {
      return new Promise(resolve => {
        Realnote.inviteUser()
          .then(success => {
            resolve(success)
          })
          .catch(err => {
            log.e(err)
            resolve(null)
          })
      })
      // Realnote.inviteUser();
    } catch (e) {
      log.e("sendInvite error: " + e)
    }
  }

  render() {
    if (this.props.contents) {
      this.children = this.props.contents
    } else if (this.props.type) {
      this.setChildren()
    }

    if (this.props.touchable) {
      return (
        <TouchableWithoutFeedback
          onPress={this.props.hide}
          style={[
            {
              flex: 1,
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              width: screenWidth,
              zIndex: 5,
              alignItems: "center",
              justifyContent: "center",
            },
            this.props.style,
          ]}>
          <View
            style={[
              {
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                flex: 1,
                zIndex: 5,
                width: screenWidth,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:
                  this.props.backgroundColor || AppColors.veryTransparentWhite,
                paddingHorizontal: this.props.paddingOn ? "5%" : 0,
                paddingVertical: this.props.paddingOn ? "10%" : 0,
              },
              this.props.style,
            ]}>
            {this.children || this.props.children}
            <LocalHintManager
              style={styles.style}
              textStyle={styles.textStyle}
            />
          </View>
        </TouchableWithoutFeedback>
      )
    } else {
      return (
        <View
          style={[
            {
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              flex: 1,
              zIndex: 5,
              width: screenWidth,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor:
                this.props.backgroundColor || AppColors.veryTransparentWhite,
              paddingHorizontal: this.props.paddingOn ? "5%" : 0,
              paddingVertical: this.props.paddingOn ? "10%" : 0,
            },
            this.props.style,
          ]}>
          {this.children || this.props.children}
          <LocalHintManager style={styles.style} textStyle={styles.textStyle} />
        </View>
      )
    }
  }
}

const styles = StyleSheet.create({
  style: {
    backgroundColor: AppColors.backgroundGray,
    borderRadius: 18,
  },
  textStyle: {
    color: AppColors.text,
    textShadowRadius: 0,
  },
})
